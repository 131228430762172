import React, { useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';
import { HiArrowSmDown } from "react-icons/hi";
import { Link } from 'react-router-dom';

const ServiceAccordion = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [visibleCount, setVisibleCount] = useState(8);

    const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 8);
    };

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const accordionItems = [
        {
            id: 'collapse1',
            title: 'Agriculture',
            content: 'Our digital marketing strategies for agriculture focus on promoting sustainable practices and connecting with target audiences through innovative online campaigns. We help agribusinesses enhance visibility and drive engagement in a competitive market.'
        },
        {
            id: 'collapse2',
            title: 'Automative',
            content: 'Boost your brand\'s visibility and drive more leads with our tailored digital marketing strategies designed specifically for the automotive industry.'
        },
        {
            id: 'collapse3',
            title: 'Automobiles',
            content: 'We specialize in automotive marketing solutions that accelerate brand growth and drive customer engagement. Our targeted campaigns help automotive companies stand out in a crowded marketplace and boost sales.'
        },
        {
            id: 'collapse4',
            title: 'Banking',
            content: 'Our digital marketing for banking institutions focuses on building trust and increasing customer acquisition. We leverage innovative strategies to enhance online presence and drive financial growth in the competitive banking sector.'
        },
        {
            id: 'collapse5',
            title: 'Beauty & Personal Care',
            content: 'Capture the attention of beauty enthusiasts with targeted digital marketing solutions that elevate your brand and increase sales in the beauty and personal care industry.'
        },
        {
            id: 'collapse6',
            title: 'B2B',
            link: '/b2b-marketing-agency',
            content: 'Our B2B digital marketing services are crafted to generate high-quality leads and foster meaningful business relationships. We employ strategies that enhance brand authority and drive business growth in the B2B sector.'
        },
        {
            id: 'collapse7',
            title: 'B2C',
            link: '/b2c-marketing-agency',
            content: 'We create compelling B2C marketing campaigns that captivate audiences and drive consumer action. Our strategies are designed to enhance brand loyalty and maximize ROI through effective customer engagement.'
        },
        {
            id: 'collapse8',
            title: 'Consumer Durables',
            content: 'Drive consumer engagement and sales for your durable goods with innovative marketing campaigns that resonate with your target audience.'
        },
        {
            id: 'collapse9',
            title: 'Crowdfunding',
            content: 'We craft targeted marketing campaigns that amplify your crowdfunding efforts and attract potential backers. Our approach ensures your project gains the visibility and support needed to achieve funding goals.'
        },
        {
            id: 'collapse10',
            title: 'E-commerce',
            content: 'Maximize your online sales and customer retention with data-driven marketing strategies that cater to the dynamic needs of the e-commerce industry.'
        },
        {
            id: 'collapse11',
            title: 'Education',
            content: 'Our marketing strategies for education institutions are designed to attract and engage students, showcasing educational programs and services effectively. We help educational organizations build a strong online presence and drive enrollment.'
        },
        {
            id: 'collapse12',
            title: 'Electric Vehicle',
            content: 'We provide targeted marketing services for the electric vehicle sector, focusing on promoting eco-friendly innovations and increasing brand visibility. Our strategies are designed to drive adoption and growth in the EV market.'
        },
        {
            id: 'collapse13',
            title: 'Electronics',
            content: 'Our digital marketing services for electronics companies emphasize product innovation and market differentiation. We help brands connect with tech-savvy consumers and drive sales through strategic online campaigns.'
        },
        {
            id: 'collapse14',
            title: 'Energy',
            content: 'We offer specialized marketing strategies for the energy sector that highlight sustainable solutions and industry advancements. Our approach aims to increase brand awareness and foster growth in the energy market.'
        },
        {
            id: 'collapse15',
            title: 'FMCG',
            content: 'Our FMCG marketing services focus on driving brand visibility and consumer engagement through dynamic digital strategies. We help fast-moving consumer goods brands achieve their sales and marketing objectives.'
        },
        {
            id: 'collapse16',
            title: 'FinTech',
            content: 'We deliver digital marketing solutions tailored to the FinTech industry, focusing on enhancing brand credibility and attracting new clients. Our strategies are designed to drive growth and innovation in the financial technology sector.'
        },
        {
            id: 'collapse17',
            title: 'Gaming',
            content: 'Our marketing strategies for the gaming industry are crafted to boost game visibility and player engagement. We use creative campaigns to drive interest and increase downloads or sales in a competitive gaming market.'
        },
        {
            id: 'collapse18',
            title: 'Gems and Jewellery Industry',
            content: 'Showcase your exquisite designs and attract high-value customers with our luxury-focused digital marketing strategies tailored for the gems and jewelry industry.'
        },
        {
            id: 'collapse19',
            title: 'Healthcare',
            content: 'We provide digital marketing solutions for healthcare providers that focus on patient engagement and service promotion. Our strategies aim to enhance online presence and drive growth in the healthcare sector.'
        },
        {
            id: 'collapse20',
            title: 'Health & Wellness',
            content: 'Promote your health and wellness brand with strategic marketing initiatives that connect you with health-conscious audiences and drive measurable growth.'
        },
        {
            id: 'collapse21',
            title: 'Home & Decor',
            content: 'Enhance your online presence and engage design-savvy consumers with creative and effective marketing campaigns for the home and decor sector.'
        },
        {
            id: 'collapse22',
            title: 'Home Services',
            content: 'Attract more clients and grow your home services business with our customized digital marketing services that deliver real results.'
        },
        {
            id: 'collapse23',
            title: 'HVAC',
            content: 'Increase leads and customer retention for your HVAC business with our specialized marketing services that focus on driving ROI.'
        },
        {
            id: 'collapse24',
            title: 'Infrastructure',
            content: 'Our marketing strategies for infrastructure projects focus on showcasing developments and attracting investment. We help infrastructure companies build a strong online presence and engage with key stakeholders.'
        },
        {
            id: 'collapse25',
            title: 'Information Technology',
            content: 'We offer tailored digital marketing solutions for IT companies that highlight innovation and expertise. Our strategies are designed to enhance visibility and generate leads in the competitive IT sector.'
        },
        {
            id: 'collapse26',
            title: 'Insurance',
            content: 'We provide marketing solutions for the insurance industry that build trust and drive customer acquisition. Our strategies are designed to enhance brand visibility and support growth in a competitive insurance market.'
        },
        {
            id: 'collapse27',
            title: 'Large Scale Enterprises',
            content: 'Enhance your brand\'s global presence and market share with comprehensive digital marketing solutions tailored for large-scale enterprises.'
        },
        {
            id: 'collapse28',
            title: 'Legal and Law',
            content: 'Strengthen your law firm\'s online presence and generate quality leads with our specialized marketing strategies tailored for the legal industry.'
        },
        {
            id: 'collapse29',
            title: 'Lifestyle',
            content: 'Our lifestyle marketing services are crafted to engage consumers and build brand loyalty through personalized campaigns. We help lifestyle brands connect with their target audience and drive engagement.'
        },
        {
            id: 'collapse30',
            title: 'Manufacturing',
            content: 'We deliver digital marketing solutions for manufacturing companies that highlight product innovations and drive industry leadership. Our strategies are designed to enhance online presence and attract business opportunities.'
        },
        {
            id: 'collapse31',
            title: 'Medium Scale Enterprises',
            content: 'Expand your market reach and drive sustainable growth with customized marketing strategies designed for medium-scale enterprises.'
        },
        {
            id: 'collapse32',
            title: 'Micro Scale Enterprises',
            content: 'Elevate your micro-enterprise with personalized marketing campaigns that maximize reach and return on investment, even with a limited budget.'
        },
        {
            id: 'collapse33',
            title: 'Non-Profit Organization',
            content: 'Our digital marketing solutions for non-profit organizations are designed to amplify your cause and drive donations. We use compelling strategies to increase visibility, engage supporters, and achieve your mission-driven goals.'
        },
        {
            id: 'collapse34',
            title: 'Oil and Gas',
            content: 'We provide specialized marketing strategies for the oil and gas industry, focusing on enhancing brand reputation and attracting industry partners. Our approach helps drive growth and visibility in the energy sector.'
        },
        {
            id: 'collapse35',
            title: 'On Demand',
            content: 'We deliver ROI-driven digital marketing strategies for on-demand businesses, ensuring your services reach the right audience at the right time.'
        },
        {
            id: 'collapse36',
            title: 'Pharmaceuticals',
            content: 'Navigate the complexities of the pharmaceutical industry with compliant and effective digital marketing strategies that boost brand awareness and patient outreach.'
        },
        {
            id: 'collapse37',
            title: 'Professional Services',
            content: 'Build trust and authority in your industry with our targeted marketing strategies that position your professional services firm for success.'
        },
        {
            id: 'collapse38',
            title: 'Real Estate',
            content: 'Our real estate marketing solutions are designed to showcase properties and attract potential buyers or tenants. We utilize targeted online strategies to increase visibility and drive sales or leases.'
        },
        {
            id: 'collapse39',
            title: 'Retail',
            content: 'We offer digital marketing services for retail brands that drive in-store traffic and online sales. Our strategies are crafted to enhance brand presence and connect with consumers effectively.'
        },
        {
            id: 'collapse40',
            title: 'Small Scale Enterprises',
            content: 'Accelerate growth and brand recognition for your small-scale enterprise through strategic digital marketing that delivers tangible outcomes.'
        },
        {
            id: 'collapse41',
            title: 'Software-as-a-Service (SaaS)',
            link: '/saas-marketing-agency',
            content: 'Our marketing solutions for SaaS companies focus on driving user acquisition and enhancing subscription growth. We create targeted campaigns that showcase your software’s value and attract ideal customers.'
        },
        {
            id: 'collapse42',
            title: 'Sports',
            content: 'We deliver marketing strategies for the sports industry that boost brand visibility and fan engagement. Our approach helps sports teams and organizations connect with audiences and drive growth.'
        },
        {
            id: 'collapse43',
            title: 'Telecommunication',
            content: 'Our digital marketing services for telecommunications companies focus on promoting network services and innovations. We help telecom brands enhance their online presence and attract new customers.'
        },
        {
            id: 'collapse44',
            title: 'Textiles',
            content: 'Promote your textile products to a global audience with our industry-specific marketing solutions highlighting your unique offerings and driving sales.'
        },
        {
            id: 'collapse45',
            title: 'Transportation and Logistics',
            content: 'We provide marketing solutions for the transportation and logistics sector that highlight efficiency and reliability. Our strategies are designed to improve visibility and attract business opportunities.'
        },
        {
            id: 'collapse46',
            title: 'Travel and Tourism',
            content: 'Our marketing services for the travel and tourism industry are crafted to inspire travel and boost bookings. We use engaging campaigns to enhance destination visibility and drive tourism growth.'
        },
        {
            id: 'collapse47',
            title: 'Web3',
            content: 'Our marketing strategies for Web3 projects are designed to navigate the complexities of decentralized technologies and drive innovation. We help projects in the metaverse, blockchain, and beyond gain traction and achieve success.',
            link: '/web3-marketing-services',
        },
    ];


    return (
        <div className='dm-service tab-hide desktop-hide'>
            <div className='mobile-accordion-service'>
                <div className="accordion" id="accordionExample">
                    {accordionItems.slice(0, visibleCount).map((item, index) => (
                        <div key={item.id} className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                    onClick={() => handleClick(index)}
                                    type="button"
                                    aria-expanded={openIndex === index}
                                    aria-controls={item.id}
                                >
                                    {item.title}
                                    <div className="arrow-icon-btn">
                                        <TiArrowSortedDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                                    </div>
                                </button>
                            </h2>
                            <div
                                id={item.id}
                                className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body position-relative">
                                    <p className='p-text-1'>{item.content}</p>
                                    <Link reloadDocument to={item.link} className={`custom-acc-card-link`} />
                                </div>
                            </div>
                        </div>
                    ))}
                    {visibleCount < accordionItems.length && (
                        <div className='load-more'>
                            <button className='lode-more-accordion-btn' onClick={loadMore}>
                                Load More <HiArrowSmDown className='load-icon' />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ServiceAccordion;

import React from 'react'
import NFTBanner from '../../Components/Services/NFT/NFTBanner'
import Section2 from '../../Components/Services/NFT/Section2'
import Section1 from '../../Components/Services/NFT/Section1'
import Section3 from '../../Components/Services/NFT/Section3'
import Section4 from '../../Components/Services/NFT/Section4'
import Section5 from '../../Components/Services/NFT/Section5'
import Latest from '../../Components/Home/Latest/Latest'
import FAQblockchain from '../../Components/Services/NFT/Section6'


const NFT = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <NFTBanner countryCode={countryCode} />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Latest modifiedPath='Latest NFT Marketing Insights' />
      <FAQblockchain />

    </div>
  )
}

export default NFT
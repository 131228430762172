import React from 'react';
import Tabs from '../../../Pages/DigitalMarketing/Tabs'
import '../../../assets/css/style.css'

const Section4 = () => {
  const tabData = [
    {
      id: 'pills-one',
      label: '1',
      title: 'Project Analysis',
      sections: [
        {
          content: "We begin with a thorough analysis of your DeFi project to understand its unique value proposition and target audience"
        }
      ]
    },
    {
      id: 'pills-two',
      label: '2',
      title: 'Strategy Development ',
      sections: [
        {
          content: "Our team crafts a customized marketing strategy tailored to your project's specific goals and objectives."
        }
      ]
    },
    {
      id: 'pills-three',
      label: '3',
      title: 'Execution',
      sections: [
        {
          content: "We implement the marketing plan with precision, utilizing various channels and techniques to reach your target audience."
        }
      ]
    },
    {
      id: 'pills-four',
      label: '4',
      title: 'Optimization',
      sections: [
        {
          content: "Continuous monitoring and optimization ensure that our strategies deliver optimal results, adapting to changes in the market and audience behavior."
        }
      ]
    },
    {
      id: 'pills-five',
      label: '5',
      title: 'Reporting',
      sections: [
        {
          content: 'We provide detailed reports on campaign performance, offering insights and recommendations for ongoing improvement.'
        }
      ]
    },
  ]
  return (
    <div className='digital-marketing ppc'>

      <section className='digital-marketing-section-2 l-bg'>
        <h2 className='h-text-2 text-center'>
          Launch Your DeFi Project to Success<span className='defi-sec-4'><br /></span> XDMinds' Step-by-Step Marketing Blueprint
        </h2>
        <div className='tabs-div'>
          <Tabs tabData={tabData} />
        </div>
      </section>
    </div>

  );
};
export default Section4
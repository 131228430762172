import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What is GameFi?",
    answer: `GameFi, short for "Game Finance," is a fusion of gaming and decentralized finance (DeFi). It involves blockchain-based games that integrate financial elements, allowing players to earn, trade, and invest in digital assets within the game. These assets can include cryptocurrencies, NFTs (non-fungible tokens), and other virtual items that hold real-world value.`
  },
  {
    question: "How does GameFi work?",
    answer: `GameFi works by utilizing blockchain technology to create a transparent, secure, and decentralized gaming environment. Players can participate in various in-game activities, such as completing quests, winning battles, or trading virtual items, to earn rewards in the form of digital assets. These assets can then be traded, sold, or used within the game ecosystem, creating a unique play-to-earn model.`
  },
  {
    question: "Why is GameFi important for the gaming industry?",
    answer: `GameFi is revolutionizing the gaming industry by providing new opportunities for monetization, player engagement, and community building. It allows players to have true ownership of in-game assets, fostering a more immersive and rewarding gaming experience. For developers, GameFi opens up new revenue streams and ways to attract and retain players.`
  },
  {
    question: "How can XDMinds help my GameFi project succeed?",
    answer: `XDMinds can help your GameFi project succeed by developing and executing tailored marketing strategies that enhance visibility, drive player engagement, and foster community growth. Our expert team leverages in-depth industry knowledge, innovative marketing techniques, and data-driven decision making to ensure your project reaches its full potential.`
  },
  {
    question: "Why should I choose XDMinds for my GameFi marketing needs?",
    answer: `You should choose XDMinds for your GameFi marketing needs because we offer a unique combination of industry expertise, comprehensive services, and a client-centric approach. Our team is dedicated to understanding your project's goals and crafting customized strategies that deliver exceptional results. We are committed to your long-term success and work closely with you to achieve your objectives.`
  },
  {
    question: "What makes GameFi marketing different from traditional gaming marketing?",
    answer: "GameFi marketing differs from traditional gaming marketing in several key ways. It focuses on promoting the financial aspects of blockchain games, such as earning potential, asset ownership, and DeFi integration.GameFi marketing also involves educating players about blockchain technology and how it enhances the gaming experience. Additionally, it requires a strong emphasis on community building and management, as the success of GameFi projects often depends on active and engaged player communities."
  },
  {
    question: "How does XDMinds approach community building for GameFi projects?",
    answer: `XDMinds approaches community building for GameFi projects by creating strategies that foster engagement, loyalty, and growth. We utilize various channels, including social media, forums, and in-game events, to connect with players and encourage interaction. Our team also focuses on transparent communication, regular updates, and addressing player feedback to maintain a strong and vibrant community.`
  },
  {
    question: "What is the role of influencers in GameFi marketing?",
    answer: `Influencers play a crucial role in GameFi marketing by helping to amplify your project's reach and credibility. They can introduce your game to their followers, provide authentic reviews, and create buzz around new features and updates. XDMinds partners with influential figures in the gaming and blockchain communities to leverage their networks and drive engagement for your GameFi project.`
  },
  {
    question: "How can I get started with XDMinds for my GameFi project?",
    answer: `To get started with XDMinds for your GameFi project, simply reach out to us through our website or contact our team directly through WhatsApp/Telegram/Skype. We'll schedule a consultation to discuss your project's goals, challenges, and marketing needs. From there, we'll develop a customized strategy to help you achieve success in the GameFi space. Let XDMinds be your trusted partner in navigating the dynamic world of GameFi marketing.`
  },

];

const GameFiFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='blockchain-sec-6-faq'>
      <div className='faq'>
        <Container>
          <Row>
            <Col>
              <div>
                <h2>Frequently Asked Question
                </h2>
              </div>
              <div className='blockchain-faq-qa'>
                {faqData.map((faq, index) => (
                  <div key={index}>
                    <div
                      onClick={() => handleFAQClick(index)}
                      aria-controls={`faq${index}`}
                      aria-expanded={openFAQ === index}
                      className='faq-1st'
                    >
                      <div className='faq-content'>
                        <h6 className='faq-content-question'>{faq.question}</h6>
                      </div>
                      <div className='faq-icons'>
                        {openFAQ === index ? (
                          <IoIosArrowDropupCircle className='close-circle-faq' />
                        ) : (
                          <IoIosArrowDropdownCircle className='plus-circle-faq' />
                        )}
                      </div>
                    </div>
                    <Collapse in={openFAQ === index}>
                      <div id={`faq${index}`} className='faq-answer-content'>
                        {renderAnswer(faq.answer)}
                      </div>
                    </Collapse>
                  </div>
                ))}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default GameFiFAQ
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MdArrowCircleDown } from "react-icons/md";

const Section5 = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const data = [
    {
      number: 1,
      title: 'Proven Track Record of Success',
      description: 'We have a history of helping dApp projects achieve their goals through targeted and effective marketing strategies. Our clients have seen significant increases in user acquisition, engagement, and overall project visibility. Our proven track record speaks to our ability to deliver tangible results.'
    },
    {
      number: 2,
      title: 'Expert Team with Deep Industry Knowledge',
      description: 'Our team consists of seasoned marketing professionals with extensive experience in the blockchain and dApp sectors. We understand the unique challenges and opportunities within the decentralized ecosystem and use this knowledge to craft strategies that drive success. Our expertise ensures that your dApp is marketed effectively to the right audience.'
    },
    {
      number: 3,
      title: 'Comprehensive and Customized Strategies',
      description: 'We don’t believe in one-size-fits-all solutions. Our approach involves creating customized marketing strategies tailored to your specific project needs and goals. Whether it’s brand building, user acquisition, or community engagement, we design comprehensive plans that cover all aspects of dApp marketing for optimal results.'
    },
    {
      number: 4,
      title: 'Innovative and Data-Driven Approach',
      description: 'At XDMinds, we stay ahead of industry trends and leverage the latest technologies and methodologies to ensure your dApp stands out. Our data-driven approach allows us to continuously monitor performance and make informed decisions, optimizing our strategies to maximize your ROI and achieve your marketing objectives.'
    },
    {
      number: 5,
      title: 'Multi-Channel Marketing Expertise',
      description: 'We excel in executing multi-channel marketing campaigns that reach your audience across various platforms. From social media and influencer marketing to SEO, PPC, and content marketing, we ensure your dApp gets the visibility it deserves. Our integrated approach ensures a cohesive and effective marketing presence.'
    },
    {
      number: 6,
      title: 'Strong Focus on Community Building',
      description: 'Community is at the heart of any successful dApp project. We specialize in building and nurturing engaged communities through platforms like Telegram, Discord, and social media. Our community management strategies foster loyalty, support, and active participation, turning users into passionate advocates for your dApp.'
    },
    {
      number: 7,
      title: 'Transparent Reporting and Continuous Improvement',
      description: 'Transparency and accountability are core values at XDMinds. We provide regular, detailed reports on our marketing activities and their outcomes. This allows you to track progress and see the impact of our efforts. Additionally, we believe in continuous improvement, regularly refining our strategies based on performance data and feedback.'
    },
    {
      number: 8,
      title: 'Dedicated Support and Partnership',
      description: 'When you choose XDMinds, you’re not just getting a service provider; you’re gaining a dedicated partner committed to your success. We work closely with you, offering ongoing support, strategic advice, and the flexibility to adapt to your evolving needs. Our goal is to ensure your dApp project thrives in the long term.'
    },
    {
      number: 9,
      title: 'Competitive Edge in the dApp Market',
      description: 'Our innovative marketing techniques and deep industry insights give your dApp a competitive edge. We help you navigate the complexities of the decentralized application market, positioning your project for sustained growth and success.'
    },
    {
      number: 10,
      title: 'Client-Centric Approach',
      description: [
        "At XDMinds, our clients are at the center of everything we do. We listen to your needs, understand your vision, and align our strategies with your objectives. Our client-centric approach ensures that we not only meet but exceed your expectations, delivering exceptional value and results.",
        "Partnering with XDMinds means entrusting your dApp marketing to a team of experts dedicated to your project’s success. With our holistic approach, innovative strategies, and unwavering commitment to excellence, we provide the results you can trust to propel your dApp to new heights."
      ]
    },

  ];
  return (
    <div>
      <div className='blockchain-sec5'>
        <Container>
          <Row>
            <Col>
              <h2>Results You Can Trust <span className='dapps-sec5-break'><br /></span>Why Choose Extensive XDMinds for dApp Marketing?
              </h2>
              <p className='subhead'>
                Choosing the right partner for your dApp marketing needs is crucial for achieving success in the competitive decentralized applications landscape. At XDMinds, we offer a combination of industry expertise, innovative strategies, and a commitment to delivering outstanding results. Here’s why you should choose XDMinds as your dApp marketing partner:
              </p>
              <div className='metaverse-sec5 desktop-view mobile-hide'>
                <Row>
                  {data.map((item, index) => (
                    <Col
                      key={index}
                      lg={4}
                      xl={4}
                      sm={6}
                      md={6}
                      style={item.number % 3 === 0 ? { border: 'none' } : {}}
                    >
                      <div className='number-div'>
                        <div className='bc-sec5-carde-head '>
                          <p className='bc-sec5-carde-head-number'>{item.number}</p>
                          <p>{item.title}</p>
                        </div>
                        <p className='bc-sec5-card-discription'>{item.description}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='about'>
        <section className='about-section-5 p-0'>
          <div className='custom-accordion desktop-hide tab-hide'>
            <div className="accordion" id="accordionExample">
              {data.map((item, index) => (
                <div key={item.id} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                      onClick={() => handleClick(index)}
                      type="button"
                      aria-expanded={openIndex === index}
                      aria-controls={item.id}
                    >
                      {item.title}
                      <div className="arrow-icon-btn">
                        <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className='p-text-1'>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Section5
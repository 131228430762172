import React from 'react'
import AIBanner from '../../Components/Services/AI/AIBanner'
import AISection1 from '../../Components/Services/AI/AISection1'
import AISection2 from '../../Components/Services/AI/AISection2'
import AISection3 from '../../Components/Services/AI/AISection3'
import AISection4 from '../../Components/Services/AI/AISection4'
import AIFAQ from '../../Components/Services/AI/AIFAQ'
import Latest from '../../Components/Home/Latest/Latest'

const AIPage = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <AIBanner countryCode={countryCode} />
      <AISection1 />
      <AISection2 />
      <AISection3 />
      <AISection4 />
      <Latest modifiedPath='Latest AI-Driven Marketing Insights' />
      <AIFAQ />
    </div>
  )
}

export default AIPage
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';


export default function DSwiper({ name, slideData }) {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    const swiperClass = [1, 3, 5, 7, 9].includes(activeIndex) ? `${name}` : '';

    return (
        <div className={`d-swiper `}>
            <Swiper
                direction={'vertical'}
                slidesPerView={1}
                spaceBetween={30}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                onSlideChange={handleSlideChange}
                modules={[Pagination, Autoplay]}
                className={`${swiperClass}`}
            >
                {slideData.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <div className='custom-d-slide'>
                            <h4 className='h-text-9'>{slide.title}</h4>
                            <div className='slide-content'>
                                <p className='p-text-7'>{slide.content}</p>
                                {slide.content1 && <p className='p-text-7'>{slide.content1}</p>}
                                {slide.content2 && <p className='p-text-7'>{slide.content2}</p>}
                                {slide.content3 && <p className='p-text-7'>{slide.content3}</p>}
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

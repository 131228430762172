import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import '../Metaverse/Metaverse.css'
import './Defi.css'
import { FaSkype, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa'


const Section2 = () => {
    return (
        <div className='defi-sec-2'>
            <div className='blockchain-sec2'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>Power Up Your DeFi Project<span className='metaverse-sec2-break'><br /></span> Results-Driven DeFi Marketing Agency
                                </h2>
                                <p>Empower your DeFi project with XDMinds. As a results-driven DeFi marketing agency, we provide comprehensive services designed to maximize your project's potential, enhance visibility, and drive adoption. Partner with us to transform your DeFi vision into reality.
                                </p>
                            </div>
                            <div className='btn-mchallenge'>
                                <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Defi marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/defi-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                    <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                                </a>
                                <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                                </a>
                                <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Section2
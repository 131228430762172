import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FaSkype, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const DAOSection2 = () => {
    return (
        <div>
            <div className='blockchain-sec2'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>XDMinds: Top-Rated DAO Marketing Agency for <span className='metaverse-sec2-break'><br /></span>Cutting-Edge Decentralized Community Engagement
                                </h2>
                                <p>At XDMinds, we specialize in creating powerful marketing campaigns that drive decentralized community engagement. As a top-rated DAO marketing agency, we combine innovative strategies with a deep understanding of the Web3 landscape to help your DAO thrive. Our holistic approach ensures that your project not only reaches but resonates with your target audience, fostering growth and sustainability. Trust XDMinds to be your partner in navigating the complexities of decentralized community engagement.
                                </p>

                            </div>
                            <div className='btn-mchallenge'>
                                <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your DAO marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/dao-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                    <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                                </a>
                                <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                                </a>
                                <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                                </a>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default DAOSection2
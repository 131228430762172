import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import StickyStackingCards from './StickyStackingCards';
import GridCard from './GridCard';
import graph from '../../assets/images/graph.svg';
import Tabs from './Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import BreadCrumbs from '../../Components/BreadCrumbs';


const ContentMarketing = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const stickyCardData = [
        {
            title: "Establishing Expertise and Trust",
            sections: [
                {
                    content: "Content marketing allows you to showcase your industry knowledge and expertise. By providing valuable and informative content, you position your brand as a thought leader. This builds trust with your audience, making them more likely to choose your products or services over competitors."
                },
            ]
        },
        {
            title: "Improving SEO and Organic Reach",
            sections: [
                {
                    content: "High-quality content is a key driver of search engine optimization (SEO). By consistently publishing relevant and engaging content, you improve your website's visibility on search engines. This leads to increased organic traffic, bringing more potential customers to your site."
                },
            ]
        },
        {
            title: "Enhancing Customer Engagement",
            sections: [
                {
                    content: "Engaging content keeps your audience interested and encourages interaction. Whether it's through blog posts, social media updates, or videos, content marketing fosters a two-way communication channel. This engagement helps build a loyal customer base and enhances brand loyalty."
                },
            ]
        },
        {
            title: "Generating Leads and Conversions",
            sections: [
                {
                    content: "Effective content marketing guides potential customers through the buyer's journey. By addressing their pain points and providing solutions, you nurture leads and drive conversions. Well-crafted calls-to-action (CTAs) within your content can further encourage prospects to take the desired actions."
                },
            ]
        },
        {
            title: "Supporting Other Marketing Efforts",
            sections: [
                {
                    content: "Content marketing complements other marketing strategies, such as social media marketing, email campaigns, and paid advertising. Quality content can be repurposed and shared across various channels, amplifying your marketing efforts and ensuring a consistent brand message."
                },
            ]
        },
        {
            title: "Building Long-Term Relationships",
            sections: [
                {
                    content: "Content marketing is not just about immediate results; it's about building long-term relationships with your audience. By consistently delivering value, you keep your brand top-of-mind, encouraging repeat business and fostering customer loyalty."
                },
            ]
        },
    ]

    const contentData = [
        {
            title: "Creating High-Quality Content:",
            description: "We develop engaging and informative content that resonates with your target audience."
        },
        {
            title: "Implementing SEO Best Practices:",
            description: "Our content is optimized for search engines, ensuring maximum visibility and reach."
        },
        {
            title: "Enhancing Customer Engagement:",
            description: "We create content that encourages interaction and builds a loyal customer base."
        },
        {
            title: "Driving Leads and Conversions:",
            description: " Our strategic approach guides prospects through the buyer's journey, leading to higher conversion rates."
        },
        {
            title: "Fostering Long-Term Relationships: ",
            description: "We ensure your content is effectively shared across various platforms, amplifying your marketing efforts."
        },
        {
            title: "Supporting Multi-Channel Marketing:",
            description: "We focus on delivering consistent value, keeping your audience engaged and loyal to your brand."
        },
    ]

    const cardData = [
        {
            src: graph,
            alt: 'Content Landscape Audit',
            title: 'Content Landscape Audit',
            text: 'Our Content Landscape Audit provides a thorough analysis of your current content, identifying strengths and areas for improvement. We evaluate your content’s performance to develop a roadmap for enhancing its effectiveness and reach.'
        },
        {
            src: graph,
            alt: 'Content Marketing Strategy Creation',
            title: 'Content Marketing Strategy Creation',
            text: 'We craft customized Content Marketing Strategies tailored to your business goals. Our strategies are designed to attract, engage, and convert your target audience, ensuring your brand’s message resonates and drives results.'
        },
        {
            src: graph,
            alt: 'Content Development Plan',
            title: 'Content Development Plan',
            text: 'Our Content Development Plan outlines a clear and actionable roadmap for creating high-quality content. From blog posts to social media updates, we ensure your content is compelling, relevant, and aligned with your marketing objectives.'
        },
        {
            src: graph,
            alt: 'Content Publication and Distribution',
            title: 'Content Publication and Distribution',
            text: 'Maximize your content’s reach with our Publication and Distribution services.We strategically publish and distribute your content across multiple channels to ensure it reaches the right audience at the right time.'
        },
        {
            src: graph,
            alt: 'Content Analyze and Optimize',
            title: 'Content Analyze and Optimize',
            text: 'Our Content Analyze and Optimize services continuously monitor your content’s performance.We use data- driven insights to refine and improve your content strategy, ensuring ongoing success and increased ROI.'
        },
        {
            src: graph,
            alt: 'SEO and Content Marketing',
            title: 'SEO and Content Marketing',
            text: 'Integrate SEO with your content marketing for maximum impact. Our SEO and Content Marketing services ensure your content is optimized for search engines, increasing visibility and driving organic traffic to your site.'
        },
        {
            src: graph,
            alt: 'Website Content',
            title: 'Website Content',
            text: 'Engage your visitors with compelling Website Content that captures your brand’s essence.Our team creates clear, persuasive copy that enhances user experience and encourages conversions.'
        },
        {
            src: graph,
            alt: 'App Store Content',
            title: 'App Store Content',
            text: 'Optimize your app’s visibility and downloads with expertly crafted App Store Content.We focus on creating engaging descriptions and visuals that highlight your app’s unique features and benefits.'
        },
        {
            src: graph,
            alt: 'Lead Generation Content',
            title: 'Lead Generation Content',
            text: 'Drive qualified leads with targeted Lead Generation Content. We develop compelling offers and calls-to-action that encourage prospects to engage with your brand and move through the sales funnel.'
        },
        {
            src: graph,
            alt: 'User-Generated Content (UGC)',
            title: 'User-Generated Content (UGC)',
            text: 'Elevate your brand’s authenticity with UGC content marketing by XDMinds, where we turn user- generated content into powerful marketing assets.Our approach drives genuine engagement and builds trust, amplifying your brand’s voice through real customer experiences.'
        },
        {
            src: graph,
            alt: 'Technical Blog Content',
            title: 'Technical Blog Content',
            text: 'Establish your authority in the industry with in-depth Technical Blog Content. Our team of experts creates informative and engaging technical articles that showcase your expertise and attract a knowledgeable audience.'
        },
        {
            src: graph,
            alt: 'Email Newsletters',
            title: 'Email Newsletters',
            text: 'Keep your audience engaged with well-crafted Email Newsletters. We design and write newsletters that provide value, nurture relationships, and drive traffic to your website or promotions.'
        },
        {
            src: graph,
            alt: 'Ad Copywriting',
            title: 'Ad Copywriting',
            text: 'Create impactful ads with our Ad Copywriting services. We craft concise, persuasive ad copy that captures attention and drives clicks, ensuring your advertising campaigns are successful.'
        },
        {
            src: graph,
            alt: 'Guides and Ebooks',
            title: 'Guides and Ebooks',
            text: 'Position your brand as a thought leader with comprehensive Guides and Ebooks. Our team creates valuable, informative content that educates your audience and strengthens your brand’s authority.'
        },
        {
            src: graph,
            alt: 'SEO-Friendly Blogs',
            title: 'SEO-Friendly Blogs',
            text: 'Boost your online presence with SEO-Friendly Blogs. We write engaging, optimized blog posts that improve your search engine rankings and attract organic traffic to your website.'
        },
        {
            src: graph,
            alt: 'Press Releases',
            title: 'Press Releases',
            text: 'Share your news effectively with our Press Releases. We craft compelling announcements that capture media attention and convey your message clearly and professionally.'
        },
        {
            src: graph,
            alt: 'Whitepapers',
            title: 'Whitepapers',
            text: 'Demonstrate your expertise with insightful Whitepapers. We create in-depth, research-based documents that provide valuable insights and establish your brand as an industry leader.'
        },
        {
            src: graph,
            alt: 'Litepapers',
            title: 'Litepapers',
            text: 'Engage your audience with concise Litepapers. These shorter, focused documents offer quick insights and practical information, making complex topics accessible and engaging.'
        },
        {
            src: graph,
            alt: 'Blog Writing & Syndication',
            title: 'Blog Writing & Syndication',
            text: 'Expand your reach with our Blog Writing & Syndication services. We create high-quality blog content and distribute it across relevant platforms to increase visibility and engagement.'
        },
        {
            src: graph,
            alt: 'Videos and Webinars',
            title: 'Videos and Webinars',
            text: 'Enhance your content strategy with Videos and Webinars. We produce engaging video content and live webinars that educate, entertain, and convert your audience.'
        },
        {
            src: graph,
            alt: 'Social Posts Content',
            title: 'Social Posts Content',
            text: 'Boost your social media presence with compelling Social Posts Content. We create engaging and shareable social media updates that resonate with your audience and drive engagement.'
        },
        {
            src: graph,
            alt: 'Infographics',
            title: 'Infographics',
            text: 'Visualize your data with eye-catching Infographics. Our design team creates informative and attractive infographics that simplify complex information and capture audience interest.'
        },
        {
            src: graph,
            alt: 'Podcasts',
            title: 'Podcasts',
            text: 'Connect with your audience through Podcasts. We produce engaging and informative podcasts that provide value and build a loyal following.'
        },
        {
            src: graph,
            alt: 'Case Studies',
            title: 'Case Studies',
            text: 'Showcase your success with detailed Case Studies. We document and present your achievements, demonstrating the effectiveness of your products or services to potential clients.'
        },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'In-Depth Research and Analysis',
            sections: [
                {
                    content: 'We start by conducting thorough research and analysis of your industry, competitors, and target audience. This helps us identify the best opportunities and tailor our strategies to meet your specific needs.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Tailored Content Strategies',
            sections: [
                {
                    content: 'Our team crafts customized content strategies that align with your business goals. Whether it’s increasing brand awareness, driving traffic, or generating leads, we develop a roadmap that ensures your content delivers measurable results.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Engaging Content Creation',
            sections: [
                {
                    content: 'We believe that content is king. Our expert writers and designers create high-quality, engaging content that resonates with your audience. From blog posts and social media updates to videos and infographics, we ensure every piece of content is crafted to perfection.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Strategic Distribution',
            sections: [
                {
                    content: 'Creating great content is just the beginning. We strategically distribute your content across various channels to maximize its reach and impact. Whether it’s through social media, email marketing, or influencer partnerships, we ensure your content reaches the right audience at the right time.'
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Continuous Optimization',
            sections: [
                {
                    content: 'Our work doesn’t stop once the content is published.We continuously monitor its performance and make data- driven adjustments to optimize results.This ensures that your content remains relevant and effective, delivering ongoing value to your brand.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Measurable Results',
            sections: [
                {
                    content: 'At XDMinds, we believe in transparency and accountability. We provide detailed reports and analytics to track the success of our content marketing efforts. This allows us to measure ROI and continually refine our strategies for even better results.'
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What is content marketing, and why is it important for my business?",
            answer: "Content marketing involves creating and distributing valuable, relevant content to attract and engage your target audience. It's important because it helps build brand authority, improve SEO, increase customer engagement, generate leads, and drive conversions, ultimately contributing to business growth."
        },
        {
            question: "How can XDMinds help my business with content marketing?",
            answer: "XDMinds provides comprehensive content marketing services, including strategy creation, content development, publication, distribution, and optimization. Our tailored approach ensures your content resonates with your audience and drives measurable results."
        },
        {
            question: "What types of content do you create?",
            answer: "We create a wide range of content, including blog posts, social media updates, videos, infographics, ebooks, whitepapers, technical articles, email newsletters, ad copy, press releases, and more. Our content is designed to engage your audience and achieve your marketing objectives."
        },
        {
            question: "How does XDMinds develop a content marketing strategy for my business?",
            answer: "We start by understanding your business goals, target audience, and industry landscape. Based on this research, we create a customized content marketing strategy that aligns with your objectives and ensures your content reaches and resonates with your audience."
        },
        {
            question: "How do you ensure the content is SEO-friendly?",
            answer: "Our team follows SEO best practices, including keyword research, on-page optimization, and creating high-quality, relevant content. We ensure your content is optimized for search engines, improving visibility and driving organic traffic to your website."
        },
        {
            question: "Can XDMinds help with content distribution?",
            answer: "Yes, we strategically distribute your content across various channels, including social media, email, and influencer partnerships, to maximize its reach and impact. We ensure your content reaches the right audience at the right time."
        },
        {
            question: "How do you measure the success of content marketing campaigns?",
            answer: "We use various metrics to measure the success of content marketing campaigns, including website traffic, engagement rates, lead generation, conversion rates, and ROI. We provide detailed reports and analytics to track performance and make data-driven adjustments."
        },
        {
            question: "What industries do you specialize in for content marketing?",
            answer: "XDMinds has experience working with a diverse range of industries, including technology, healthcare, finance, e-commerce, education, and more. Our team is skilled at creating industry-specific content that resonates with your target audience."
        },
        {
            question: "How often should I publish new content?",
            answer: "The frequency of content publication depends on your business goals and audience preferences. We typically recommend a consistent schedule, such as weekly or bi-weekly, to keep your audience engaged and maintain a strong online presence."
        },
        {
            question: "Can XDMinds help with content repurposing?",
            answer: "Yes, we can repurpose your existing content into different formats, such as turning blog posts into infographics, videos, or social media updates. This helps extend the life of your content and reach a broader audience."
        },
        {
            question: "Do you offer customized content marketing packages?",
            answer: "Yes, we offer customized content marketing packages tailored to your business needs and budget. Our flexible packages ensure you get the most value from our services and achieve your marketing objectives."
        },
        {
            question: "How can I get started with XDMinds' content marketing services?",
            answer: "Getting started is easy. Contact us through our website or call our office to schedule a consultation. We'll discuss your needs, goals, and how our content marketing services can help your business succeed."
        },
        {
            question: "How do you stay up-to-date with the latest content marketing trends?",
            answer: "Our team stays up-to-date with the latest content marketing trends through continuous learning, industry research, and attending relevant conferences and workshops. This ensures we use the most effective and innovative strategies for your content marketing efforts."
        },
    ]

    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Top-Rated <span className='s-text-3'>Content</span> Marketing Agency for High-Impact Results
                </h1>
                <p className='p-text-1'>
                    Experience unparalleled content marketing success with XDMinds. Our expert team crafts compelling strategies that drive engagement, boost brand visibility, and deliver measurable results. Partner with us to elevate your content and achieve your business goals.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Why Content Marketing is Crucial for <br className='break' /> Business Success: Building Brand Authority
                </h2>
                <p className='p-text-2'>
                    In today's digital age, content marketing has emerged as a pivotal strategy for businesses aiming to achieve lasting success. It's not just about promoting products or services; it's about building brand authority and establishing a meaningful connection with your audience. Here's why content marketing is essential for your business:
                </p>
                <div className='event-card-div'>
                    <StickyStackingCards stickyCardData={stickyCardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Building Brand Authority with XDMinds
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we understand the power of content marketing in building brand authority. Our comprehensive content marketing services are designed to help you achieve business success by:
                </p>
                <div className='b-div'>
                    {contentData.map((content, index) => (
                        <div key={index} className='d-flex justify-content-start align-items-start'>
                            <div className='h-symbol' />
                            <div className='b-content'>
                                <h5 className='h-text-13'>{content.title}</h5>
                                <p className='p-text-9'>
                                    {content.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Full-Service Content Marketing Agency: <br className='break' /> XDMinds - Strategy, Creation, Distribution
                </h2>
                <div className='border-div position-relative'>
                    <p className='p-text-2 w-100 span-icon'>
                        At XDMinds, we are a full-service content marketing agency committed to driving your brand's success through strategic planning, exceptional content creation, and effective distribution. Our team of skilled professionals works collaboratively to understand your unique needs, crafting tailored content strategies that align with your business objectives. From ideation to execution, we ensure your message reaches the right audience, maximizes engagement, and delivers tangible results. Trust XDMinds to be your partner in creating impactful content that stands out in today's competitive digital landscape.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Boost Your Bottom Line with XDMinds' <br className='break' /> Result-Oriented Content Marketing Services
                </h2>
                <p className='p-text-2'>
                    Unlock the true potential of your brand with XDMinds' result-oriented content marketing services. Our holistic approach combines innovative strategies with data-driven insights to create content that not only resonates with your audience but also drives conversions. Whether you're looking to increase brand awareness, generate leads, or improve customer retention, our expert team is dedicated to delivering measurable outcomes that boost your bottom line. Choose XDMinds to transform your content marketing efforts and achieve sustainable growth.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Creating High-Performing Content Marketing <br className='break' /> For Your Brand Reach: XDMinds' Proven Methodology
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we excel in delivering high-performing content marketing strategies that amplify your brand's reach and impact. Our proven methodology is a result of years of expertise and a deep understanding of the digital landscape. Here's how we ensure your content stands out and drives results.
                </p>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest Content Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default ContentMarketing
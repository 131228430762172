import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './Blockchain.css'


const Section1 = () => {

  const accordionData = [
    {
      id: 'Web3',
      title: 'Blockchain Unveiled: Understanding the Basics',
      heading: '',
      content: [
        'Blockchain is a revolutionary technology that enables secure, transparent, and tamper-proof digital transactions. It is a decentralized ledger that records transactions across multiple computers, ensuring that the information is distributed and unalterable. Each block in the chain contains a list of transactions and is linked to the previous block, forming a chain of data that is cryptographically secure.',
      ],
    },
    {
      id: 'web3-marketing',
      title: 'Blockchain Explained: How It Operates',
      heading: '',
      content: [
        'Blockchain operates on a decentralized network of computers, known as nodes. When a transaction is initiated, it is broadcast to the network, where nodes validate and record it. Each validated transaction is added to a block, which is then appended to the existing chain of blocks. This process ensures that the data is transparent, secure, and immutable, as any alteration in a block would require changes to all subsequent blocks and consensus from the majority of the network.',
      ],
    },

    {
      id: 'why-web3-marketing',
      title: 'Ownership in the Blockchain Ecosystem',
      heading: '',
      content: [
        'One of the most significant aspects of blockchain technology is its decentralized nature, meaning no single entity owns it. Instead, ownership is distributed among all participants in the network. Each node holds a copy of the blockchain, and decisions are made collectively through a consensus mechanism, such as Proof of Work (PoW) or Proof of Stake (PoS).',
      ],
    },

    {
      id: 'comparison',
      title: 'Why Blockchain Matters: Its Core Purposes',
      heading: '',
      content: [
        'The primary purpose of blockchain technology is to provide a secure, transparent, and decentralized method of recording and verifying transactions. It aims to eliminate the need for intermediaries, reduce fraud, and enhance the efficiency of transaction processes across various industries, from finance and supply chain to healthcare and beyond.',
      ],
    },
    {
      id: 'benefits',
      title: 'Exploring Different Types of Blockchain Networks',
      heading: 'Blockchain technology can be classified into several types, each serving different purposes:',
      benefits: [
        {
          title: 'Public Blockchains:',
          description: 'Open to anyone and fully decentralized.',
        },
        {
          title: 'Private Blockchains:',
          description: 'Restricted to a specific group of participants, offering more control and privacy.',
        },
        {
          title: 'Consortium Blockchains:',
          description: ' Governed by a group of organizations, providing a balance between decentralization and control.',
        },
        {
          title: 'Hybrid Blockchains:',
          description: 'Combine elements of both public and private blockchains to meet specific needs.',
        },

      ],
    },

    {
      id: 'navigate',
      title: 'Navigating the Blockchain: How to Gain Access',
      heading: '',
      content: [
        'Accessing a blockchain network typically involves using a blockchain wallet or application that connects to the network. Users can participate in the network by running a full node, which requires downloading the entire blockchain, or by using light clients that interact with the network through full nodes. Access levels can vary depending on whether the blockchain is public or private.',
      ],
    },

    {
      id: 'web3-marketing-benefits',
      title: 'Why Choose Blockchain? Exploring Its Benefits',
      heading: 'Blockchain technology offers numerous benefits, including:',
      benefits: [
        {
          title: 'Transparency:',
          description: 'Transactions are recorded on a public ledger accessible to all participants.',
        },
        {
          title: 'Security:',
          description: ' Data is cryptographically secure, making it highly resistant to tampering.',
        },
        {
          title: 'Decentralization:',
          description: ' Eliminates the need for intermediaries, reducing costs and increasing efficiency.',
        },
        {
          title: 'Immutability: ',
          description: 'Once recorded, transactions cannot be altered or deleted.',
        },
        {
          title: 'Efficiency:',
          description: ' Speeds up transaction processes and reduces administrative overhead.',
        },
      ],
    },

    {
      id: 'blockchain-revolution',
      title: 'How Blockchain Revolutionizes Business Operations',
      heading: '',
      content: [
        'Blockchain technology is crucial for businesses due to its ability to enhance transparency, security, and efficiency. It can streamline operations, reduce costs associated with intermediaries, and provide a trustworthy system for recording transactions. Businesses in finance, supply chain management, healthcare, and other sectors can significantly benefit from integrating blockchain into their processes.',
      ],
    },

    {
      id: 'new-frontier',
      title: 'Understanding Blockchain Marketing: A New Frontier',
      heading: '',
      content: [
        'Blockchain marketing leverages the unique features of blockchain technology to enhance marketing strategies. It involves using blockchain to improve transparency, build trust, and create more secure and efficient advertising and customer engagement platforms. Blockchain marketing can help verify the authenticity of products, track ad spending, and ensure data privacy for consumers.',
      ],
    },

    {
      id: 'leveraging',
      title: 'The Benefits of Leveraging Blockchain in Marketing',
      heading: 'Blockchain marketing offers several advantages:',
      benefits: [
        {
          title: 'Transparency:',
          description: 'Provides verifiable and transparent tracking of marketing campaigns.',
        },
        {
          title: 'Security:',
          description: ' Protects consumer data and prevents ad fraud.',
        },
        {
          title: 'Efficiency:',
          description: 'Streamlines processes and reduces the need for intermediaries.',
        },
        {
          title: 'Trust:',
          description: 'Builds consumer trust through verified and authentic marketing practices.',
        },
        {
          title: 'Data Privacy:',
          description: 'Ensures that consumer data is handled securely and responsibly.',
        },
      ],
    },

  ];

  return (
    <div className='blockchain-sec1'>
      <Container>
        <Row>
          <Col>
            <h2> Marketing in the Blockchain Era <span className='metaverse-sec1-break'>
              <br /></span> A New Frontier and Its Advantages </h2>
            <div className='mobile-view'>

              <div className="accordion accordion-flush" id="accordionFlushExample">
                {accordionData.map((section, index) => (
                  <div className="accordion-item" key={index}>
                    <p className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        {section.title}
                      </button>

                    </p>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <h4 className='content-head'>{section.heading}</h4>
                        {section.content && section.content.map((paragraph, idx) => (
                          <p className='content-sescription' key={idx}>{paragraph}</p>
                        ))}
                        {section.benefits && (
                          <div className="benefits">
                            {section.benefits.map((benefit, idx) => (
                              <React.Fragment key={idx}>
                                <p className="benefits-discript">{benefit.title}</p>
                                <p>{benefit.description}</p>
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Section1
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const GameFiSec3 = () => {
  const data = [
    {
      number: 1,
      title: ' In-Depth Industry Expertise',
      description: "XDMinds boasts a team of seasoned professionals with deep knowledge of both the gaming and blockchain industries. Our experts understand the nuances of GameFi projects, ensuring that our marketing strategies are not only innovative but also highly effective. This unique combination of gaming and blockchain expertise allows us to craft campaigns that resonate with the target audience and drive substantial growth."
    },
    {
      number: 2,
      title: 'Comprehensive Marketing Solutions',
      description: "As a full-service marketing agency, XDMinds offers a wide range of services tailored to meet the specific needs of GameFi projects. From initial market research and competitor analysis to branding, SEO, and community management, we provide end-to-end solutions that cover every aspect of your marketing strategy. This holistic approach ensures that all elements of your campaign work together seamlessly to maximize impact."
    },
    {
      number: 3,
      title: 'Customized Strategies',
      description: "We understand that each GameFi project is unique, which is why we take a personalized approach to every campaign. Our team works closely with you to understand your project's goals, target audience, and unique selling points. Based on this information, we develop customized marketing strategies that are designed to achieve your specific objectives, ensuring that your game stands out in a crowded market."
    },
    {
      number: 4,
      title: 'Data-Driven Decision Making',
      description: "At XDMinds, we believe in the power of data to drive success. Our marketing strategies are underpinned by thorough research and data analysis, allowing us to make informed decisions that enhance the effectiveness of your campaigns. By continuously monitoring and analyzing performance metrics, we can adapt and optimize our strategies in real-time to ensure maximum ROI."
    },
    {
      number: 5,
      title: 'Innovative Campaigns',
      description: "Innovation is at the heart of everything we do. Our team is constantly exploring new and creative ways to market GameFi projects, leveraging the latest trends and technologies to stay ahead of the curve. Whether it's utilizing cutting-edge social media techniques, partnering with influential figures in the gaming community, or developing immersive content, we are always looking for new ways to captivate and engage your audience."
    },
    {
      number: 6,
      title: 'Strong Community Focus',
      description: "Building and nurturing a vibrant community is crucial for the success of any GameFi project. XDMinds excels in community management, creating strategies that foster engagement, loyalty, and growth. We understand the importance of maintaining a strong connection with your audience, and our community-building efforts ensure that your players remain engaged and invested in your game."
    },
    {
      number: 7,
      title: 'Focus on Emerging Trends',
      description: "In addition to our strong foundation in GameFi marketing, XDMinds is always at the forefront of emerging trends in the blockchain gaming space. We keep a close eye on the latest developments, from new game mechanics and monetization models to innovative marketing tools and platforms. By staying ahead of the curve, we ensure that your GameFi project not only keeps pace with industry changes but also leverages new opportunities for growth and success."
    },
    {
      number: 8,
      title: 'Client-Centric Approach',
      description: "At XDMinds, our clients are at the center of everything we do. We prioritize open communication, collaboration, and transparency, ensuring that you are always informed and involved in the marketing process. Our client-centric approach means that we are fully committed to your success, going above and beyond to exceed your expectations and deliver exceptional results."
    },
    {
      number: 9,
      title: 'Long-Term Partnership',
      description: "We believe in building long-term relationships with our clients. XDMinds is not just a service provider; we are a strategic partner dedicated to your project's ongoing success. Our commitment extends beyond the initial campaign, as we continue to provide support, insights, and optimization to ensure sustained growth and success for your GameFi project."
    },
  ];

  return (
    <div className='blockchain-sec5'>
      <Container>
        <Row>
          <Col>
            <h2>Why XDMinds Stands Out as the Premier Full-Service<span className='mbanner-break'><br /></span>
              Marketing Agency for GameFi Projects?
            </h2>

            <div className='metaverse-sec5 desktop-view'>
              <Row>
                {data.map((item, index) => (
                  <Col
                    key={index}
                    lg={4}
                    xl={4}
                    sm={6}
                    md={6}
                    style={item.number % 3 === 0 ? { border: 'none' } : {}}
                  >
                    <div className='number-div'>
                      <div className='bc-sec5-carde-head'>
                        <p className='bc-sec5-carde-head-number'>{item.number}</p>
                        <p>{item.title}</p>
                      </div>
                      <p className='bc-sec5-card-discription'>{item.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GameFiSec3
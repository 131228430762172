import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../Blockchain/Blockchain.css'
import './Web3.css'
import BreadCrumbs from '../../BreadCrumbs';

const Web3Banner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { countryCode } = props;


  return (
    <div className='web3-banner'>
      <Container>
        <Row>
          <Col>
            <BreadCrumbs />
            <div className='banner-left-sec min-100vh'>
              <h1>
                End-to-End <span className='changing-banner-content'>Web3</span><span className='home-banner-break'><br /></span>
                Digital Marketing Services</h1>
              <p>Unlock the full potential of your Web3 project with XDMinds' expert Web3 marketing services. Our team of seasoned professionals is dedicated to crafting tailored marketing strategies that amplify your presence in the Web3 landscape. From innovative go-to-market strategies to targeted social media campaigns, we provide end-to-end solutions to ensure your success in the decentralized world. Partner with us to elevate your Web3 brand and reach new heights of engagement and growth.</p>
              <div className='btn-home-banner-request-div'>
                <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
              </div>
              <ModalForm
                countryCode={countryCode}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Web3Banner
import React from 'react'
import DubaiBanner from '../../../Components/XD Countries/Web3/Dubai/DubaiBanner'
import AboutDubai from '../../../Components/XD Countries/Web3/Dubai/AboutDubai'
import DubaiWebServices from '../../../Components/XD Countries/Web3/Dubai/DubaiWeb3Services'

const DubaiPages = () => {
  return (
    <div>
        <DubaiBanner/>
        <AboutDubai/>
        <DubaiWebServices/>
    </div>
  )
}

export default DubaiPages
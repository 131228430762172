import React from 'react';
import './AnswerTab.css';
import { Col, Container, Row } from 'react-bootstrap';

const AnswerTab = () => {
  const accordionData = [
    {
      id: 'Web3',
      title: 'Exploring the Evolution of the Internet: What is Web3?',
      heading: '',
      content: [
        'Web3, or Web 3.0, represents the next phase in the evolution of the internet, transitioning from the traditional centralized web (Web2) to a decentralized ecosystem. At its core, Web3 leverages blockchain technology, smart contracts, and decentralized applications (dApps) to create a more open, secure, and user-centric digital landscape. Unlike its predecessor, Web3 empowers individuals by granting them greater control over their data and online interactions, fostering a new era of transparency and trust on the internet.',
      ],
    },
    {
      id: 'WhyWeb3',
      title: 'Unlocking the Potential of Decentralization: Why Web3 Matters',
      heading: '',
      content: [
        'Web3 is a paradigm shift that addresses many limitations of the current internet infrastructure. The decentralized nature of Web3 enhances security, privacy, and user autonomy by eliminating reliance on centralized entities. This transformation is crucial as it empowers users to own and control their digital identities and assets, reducing the risk of data breaches and censorship. By fostering a more equitable and inclusive digital environment, Web3 has the potential to revolutionize industries and redefine how we interact online.',
      ],
    },
    {
      id: 'benefits',
      title: 'Harnessing the Advantages of Web3: A New Era of Digital Innovation',
      heading: '',
      benefits: [
        {
          title: 'Enhanced Security:',
          description: 'By utilizing blockchain technology, Web3 provides a more secure infrastructure, reducing the risk of hacking and fraud.',
        },
        {
          title: 'Increased Privacy:',
          description: 'Users have greater control over their data, ensuring that personal information is not exploited by centralized entities.',
        },
        {
          title: 'Decentralization:',
          description: ' The elimination of intermediaries leads to more transparent and tamper-proof transactions and interactions.',
        },
        {
          title: 'Tokenization:',
          description: 'Web3 enables the creation and exchange of digital assets, facilitating new economic models and opportunities.',
        },
        {
          title: 'Interoperability:',
          description: ' Seamless integration and communication between different platforms and services enhance user experience and innovation.',
        },
      ],
    },
    {
      id: 'web3-marketing',
      title: 'Revolutionizing Digital Engagement: What is Web3 Marketing?',
      heading: '',
      content: [
        'Web3 marketing is an innovative approach to digital marketing that leverages the principles of Web3, including decentralization, blockchain technology, and tokenization. This new paradigm shifts the focus from traditional advertising methods to more transparent, interactive, and user-centric strategies. Web3 marketing emphasizes community engagement, data ownership, and trust-building, enabling brands to create more authentic and valuable connections with their audiences.',
      ],
    },
    {
      id: 'why-web3-marketing',
      title: 'The Future of Digital Outreach: Why Embrace Web3 Marketing?',
      heading: '',
      content: [
        'Web3 marketing is crucial for brands seeking to stay ahead in an evolving digital landscape. By embracing Web3 principles, marketers can foster deeper, more meaningful relationships with their audiences. This approach enhances transparency, trust, and user engagement, offering unique opportunities for innovation and growth. In an era where consumers demand greater control and authenticity, Web3 marketing provides a competitive edge by aligning with these evolving expectations.',
      ],
    },
    {
      id: 'web3-marketing-benefits',
      title: 'Unveiling the Advantages of Web3 Marketing: A Transformative Approach',
      heading: '',
      benefits: [
        {
          title: 'Enhanced Trust:',
          description: 'Blockchain technology ensures transparency and authenticity, building stronger trust between brands and consumers.',
        },
        {
          title: 'User Empowerment:',
          description: ' Consumers have greater control over their data, fostering a more ethical and respectful marketing environment.',
        },
        {
          title: 'Innovative Engagement:',
          description: 'Tokenization and decentralized applications enable new, interactive marketing campaigns that drive deeper engagement.',
        },
        {
          title: 'Community Building:',
          description: 'Web3 marketing emphasizes community involvement, creating more loyal and active brand advocates.',
        },
        {
          title: 'Reduced Intermediaries:',
          description: ' Direct interactions between brands and consumers eliminate the need for middlemen, reducing costs and inefficiencies.',
        },
      ],
    },
    {
      id: 'comparison',
      title: 'A Comparative Analysis: Web3 Marketing Vs Traditional Digital Marketing',
      heading: '',
      content: [
        'Web3 marketing and traditional digital marketing represent two distinct approaches to reaching and engaging audiences. Traditional digital marketing relies on centralized platforms, data collection, and targeted advertising, often leading to concerns over privacy and data misuse. In contrast, Web3 marketing leverages decentralized technologies, ensuring greater transparency, user control, and trust. While traditional methods focus on broad reach and data analytics, Web3 marketing prioritizes community engagement, authenticity, and secure interactions. Understanding these differences is essential for brands aiming to navigate the future of digital marketing effectively.',
      ],
    },
  ];

  return (
    <div className='answer-tab'>
      <Container>
        <Row>
          <Col>
            <h2>Understanding Web3 and it's Marketing:<span className='answer-tab-break'>
            </span>     <br />A Comprehensive Guide</h2>
            <div className="accordion accordion-flush" id="accordionFlushExample">
              {accordionData.map((section, index) => (
                <div className="accordion-item" key={index}>
                  <p className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${index}`}
                    >
                      {section.title}
                    </button>
                  </p>
                  <div
                    id={`flush-collapse${index}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <h4 className='content-head'>{section.heading}</h4>
                      {section.content && section.content.map((paragraph, idx) => (
                        <p className='content-sescription' key={idx}>{paragraph}</p>
                      ))}
                      {section.benefits && (
                        <div className="benefits">
                          {section.benefits.map((benefit, idx) => (
                            <React.Fragment key={idx}>
                              <p className="benefits-discript">{benefit.title}</p>
                              <p className='benefits-content'>{benefit.description}</p>
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AnswerTab;

import React from 'react'
import DAOBanner from '../../Components/Services/DAO/DAOBanner'
import Section1 from '../../Components/Services/DAO/Section1'
import DAOSection2 from '../../Components/Services/DAO/DAOSection2'
import DAOSection3 from '../../Components/Services/DAO/DAOSection3'
import DAOSection4 from '../../Components/Services/DAO/DAOSection4'
import DAOSection5 from '../../Components/Services/DAO/DAOSection5'
import Latest from '../../Components/Home/Latest/Latest'
import DAOFAQ from '../../Components/Services/DAO/DAOFAQ'



const DAOPage = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <DAOBanner countryCode={countryCode} />
      <Section1 />
      <DAOSection2 />
      <DAOSection3 />
      <DAOSection4 />
      <DAOSection5 />
      <Latest modifiedPath='Latest DAO Marketing Insights' />
      <DAOFAQ />
    </div>
  )
}

export default DAOPage
import React from 'react'
import { Button } from 'react-bootstrap'
import { FaSkype, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const Section1 = () => {
    return (
        <div>
            <div className='blockchain-sec2'>

                <div>
                    <h2>From Indie to Industry Leader:<span className='metaverse-sec2-break'><br /></span> XDMinds - Exceptional Gaming Marketing Agency
                    </h2>
                    <p>At XDMinds, we pride ourselves on transforming indie games into industry leaders. Our team of marketing professionals is passionate about gaming and committed to delivering exceptional results. Whether you're an emerging developer or a seasoned studio, we provide tailored marketing solutions that elevate your game to new heights.
                    </p>
                </div>
                <div className='btn-mchallenge'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Gaming marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/gaming-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                        <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                    </a>
                    <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                        <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                        <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Section1
import React from 'react'
import ModalForm from '../../../ModalForm/ModalForm'
import BreadCrumbs from '../../../BreadCrumbs';

const DubaiBanner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;
  return (
    <div className='aso-marketing'>
    <section className='aso-marketing-banner min-100vh digital-marketing'>
        <BreadCrumbs />
        <h1 className='h-text-1'>
        Web3 Marketing Agency in <span className='s-text-1'> Dubai</span>,<br className='break' />  United Arab Emirates
        </h1>
        <p className='p-text-1'>
        As the leading Web3 marketing agency in Dubai, we specialize in empowering businesses to thrive in the evolving digital landscape. We offer innovative and customized Web3 marketing strategies that drive engagement, build communities, and elevate your brand in the decentralized economy.
        </p>
        <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
    </section>
    <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />

    /</div>
  )
}

export default DubaiBanner
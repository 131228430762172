import React, { useRef } from 'react';
import Openingfaq from './Openingfaq';
import BreadCrumbs from '../../Components/BreadCrumbs';


const Careers = () => {
    const openPositionsRef = useRef(null);


    const handleScrollToOpenPositions = () => {
        if (openPositionsRef.current) {
            openPositionsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div className='career'>
            <section className='career-banner min-100vh'>
                <BreadCrumbs />

                <h1 className='h-text-1'>
                    Accelerate Your <span className='s-text-1'>Career</span> <br /> Shape the Future of Web3
                </h1>
                <p className='p-text-1'>
                    At XDMinds, we offer the perfect platform to fast-track your career while pioneering the future of Web3.
                    Join our innovative team and work on cutting-edge projects that are shaping the digital landscape.
                    Be a part of a community where your ideas thrive and your contributions matter.
                </p>
                <button className='request-btn' onClick={handleScrollToOpenPositions}>
                    Explore Current Openings
                </button>
            </section>
            <div className='digital-marketing'>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        Be Part of Something Bigger: Careers at XDMinds
                    </h2>
                    <div className='custom-div'>
                        <p className='p-text-2'>
                            Are you ready to make an impact in the world of Web3 marketing? At XDMinds,
                            we're not just another marketing agency; we're pioneers at the forefront of digital transformation. As a leading Web3 marketing agency, we're constantly pushing the
                            boundaries of what's possible in this dynamic and rapidly evolving field.
                        </p>
                        <p className='p-text-2'>
                            Joining our team means being part of a collaborative and innovative environment
                            where your ideas are valued, and your contributions make a real difference. Whether you're a seasoned professional or just starting your career, we offer opportunities for growth, learning, and development. Our projects span across the latest in blockchain, NFTs, and decentralized technologies,
                            giving you the chance to work on groundbreaking campaigns and strategies.
                        </p>
                        <p className='p-text-2'>
                            At XDMinds, we believe in the power of creativity and the strength of a diverse team.
                            We're looking for passionate individuals who are excited about the future of technology and marketing.
                            If you're driven, curious, and eager to be part of a team that's shaping the future, then we want to hear from you.
                            Come join us and be part of something bigger. Together, we can shape the future of Web3.
                        </p>
                    </div>
                </section>
            </div>
            <section className='career-section-2 open-position' ref={openPositionsRef}>
                <h2 className='h-text-2'>
                    Open Positions
                </h2>
                <p className='p-text-3'>
                    Explore our current job openings, or submit your resume and <br className='break' />cover letter to
                    <a href='mailto:careers@xdminds.com' className='mail-btn'>careers@xdminds.com</a>.
                </p>
                <section className='faq-opening'>
                    <Openingfaq />
                </section>

            </section>
        </div>
    );
};

export default Careers;

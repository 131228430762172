import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import ApplyModal from './ApplyModal';
import { IoArrowDownCircleSharp } from "react-icons/io5";
import { GetAllJobsApi } from '../../Helper/Redux/ReduxThunk/Homepage';


const Openingfaq = () => {
    const [rotatedIndexes, setRotatedIndexes] = useState([]);
    const [allJobs, setAllJobs] = useState(null)
    const [selectedJobTitle, setSelectedJobTitle] = useState("");

    const handleClick = (index) => {
        setRotatedIndexes(prevState => {
            if (prevState.includes(index)) {
                return prevState.filter(i => i !== index);
            } else {
                return [...prevState, index];
            }
        });
    };

    useEffect(() => {
        getALLJobs();
    }, [])

    const dispatch = useDispatch()

    const getALLJobs = async () => {
        await dispatch(GetAllJobsApi((resp) => {

            if (resp.status === true) {
                setAllJobs(resp?.data)
            }
        }))
    }

    const handleApplyClick = (jobTitle) => {
        setSelectedJobTitle(jobTitle);
    }

    return (
        <div className='opening-faq'>
            <div className="accordion" id="accordionExample">
                {allJobs && allJobs?.map((faq, index) => (
                    <div className="accordion-item" key={faq._id}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse${index}`}
                                onClick={() => handleClick(index)}
                            >
                                {faq.Job_Title}
                                <div className={`btn-icon-up ${rotatedIndexes.includes(index) ? 'rotated' : ''}`}>
                                    <IoArrowDownCircleSharp />
                                </div>
                            </button>
                        </h2>
                        <div
                            id={`collapse${index}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#accordionExample"
                        >
                            <div className="accordion-body">
                                <div className='d-flex justify-content-end'>
                                    <button className='apply-btn' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleApplyClick(faq.Job_Title)}>Apply Now</button>
                                </div>
                                <div className='overview-list'>
                                    <h6 className='h-text-head'>Job Description</h6>
                                    <div
                                        className='overview'
                                        dangerousyour-reslySetInnerHTML={{ __html: faq.Job_Description }}
                                    />
                                </div>
                                <div className='your-res-list'>
                                    <h6 className='h-text-head'>Roles & Responsibilities</h6>
                                    <div
                                        className='your-res'
                                        dangerouslySetInnerHTML={{ __html: faq.Roles_Responsibilities }}
                                    />
                                </div>
                                <div className='your-res-list'>
                                    <h6 className='h-text-head'>Required Skills</h6>
                                    <div
                                        className='your-res'
                                        dangerouslySetInnerHTML={{ __html: faq.RequiredSkills }}
                                    />
                                </div>
                                <div className='your-res-list'>
                                    <h6 className='h-text-head'>Salary Range</h6>
                                    <div
                                        className='your-res'
                                        dangerouslySetInnerHTML={{ __html: faq.SalaryRange }}
                                    />
                                </div>
                                <div className='your-res-list mt-3'>
                                    <h6 className='h-text-head'>Application Deadline</h6>
                                    <div
                                        className='your-res'
                                        dangerouslySetInnerHTML={{ __html: faq.ApplicationDeadline }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ApplyModal allJobs={allJobs} selectedJobTitle={selectedJobTitle} />
        </div>
    );
};

export default Openingfaq;
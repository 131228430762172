import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './Metaverse.css'

const Section1 = () => {

  const accordionData = [
    {
      id: 'Web3',
      title: 'What is the Metaverse?',
      content: [
        'The metaverse is a virtual universe, a collective space where physical and digital realities merge. It encompasses various virtual environments, augmented reality, and the internet. Within the metaverse, users can interact with each other and digital objects in a three-dimensional space, often using avatars. It is an evolving concept, poised to transform how we socialize, work, and entertain ourselves.',
      ],
    },


    {
      id: 'web3-marketing',
      title: 'How Does the Metaverse Work?',
      content: [
        'The metaverse operates through advanced technologies such as virtual reality (VR), augmented reality (AR), and blockchain. VR creates immersive experiences, AR overlays digital content onto the real world, and blockchain ensures secure and decentralized transactions. These technologies come together to create seamless, interactive environments. Users access the metaverse via VR headsets, AR glasses, or standard computing devices, engaging in activities ranging from gaming to virtual meetings.',
      ],
    },

    {
      id: 'web3-marketing-benefits',
      title: 'Who Owns the Metaverse?',
      content: [
        'The metaverse is a decentralized concept, meaning no single entity owns it. Instead, it is built and maintained by various stakeholders, including tech companies, developers, and users. Platforms like Decentraland, The Sandbox, and Meta (formerly Facebook) contribute to its infrastructure, but the true ownership lies in the collective participation and contributions of its community.'
      ],
    },

    {
      id: 'web3-marketing-benefits',
      title: 'What Is the Purpose of the Metaverse?',
      content: [
        'The metaverse aims to enhance human experiences by creating an immersive, interactive digital world. It offers new opportunities for social interaction, education, entertainment, and commerce. By breaking down geographical barriers, the metaverse allows people to connect, collaborate, and create in ways that were previously unimaginable. It is set to revolutionize industries and how we perceive reality.'
      ],
    },

    {
      id: 'web3-marketing-benefits',
      title: 'How Is the Metaverse Accessed?',
      content: [
        'Accessing the metaverse requires specific hardware and software. Users typically need VR headsets, AR glasses, or compatible devices like smartphones and computers. Once equipped, users can enter the metaverse through various platforms and applications designed for immersive experiences. These platforms provide the digital space for users to explore, interact, and engage in diverse activities.'
      ],
    },

    {
      id: 'web3-marketing-benefits',
      title: 'What Are the Benefits of the Metaverse?',
      content: [
        'The metaverse offers numerous benefits, including enhanced social interaction, innovative educational tools, and new economic opportunities. It enables users to connect globally, breaking down physical boundaries. Educational institutions can create interactive learning environments, and businesses can explore novel marketing and sales channels. The metaverse also fosters creativity and collaboration, leading to groundbreaking advancements across multiple fields.'
      ],
    },

    {
      id: 'web3-marketing-benefits',
      title: 'Why Is Metaverse Technology Important for Businesses?',
      content: [
        'Metaverse technology is crucial for businesses because it opens up new avenues for engagement, marketing, and sales. Companies can create immersive brand experiences, host virtual events, and establish virtual storefronts. The metaverse also allows for innovative advertising strategies and customer interaction, providing a competitive edge. Additionally, it supports remote work and collaboration, enhancing productivity and connectivity.'
      ],
    },

    {
      id: 'web3-marketing-benefits',
      title: 'What Is Metaverse Marketing?',
      content: [
        'Metaverse marketing involves promoting products and services within virtual environments. It leverages the immersive nature of the metaverse to create engaging and interactive marketing campaigns. Brands can build virtual spaces, host events, and collaborate with influencers in the metaverse. This approach allows for targeted advertising and unique customer experiences, driving brand awareness and loyalty.'
      ],
    },

    {
      id: 'web3-marketing-benefits',
      title: 'What Are the Advantages of Metaverse Marketing?',
      content: [
        'Metaverse marketing offers several advantages, including immersive customer engagement, innovative branding opportunities, and access to a global audience. Brands can create memorable experiences that resonate with users, fostering deeper connections. The interactive nature of the metaverse allows for personalized marketing strategies, enhancing customer satisfaction. Additionally, the metaverse provides valuable data insights, enabling businesses to refine their marketing efforts and achieve better results.'
      ],
    },
  ];

  return (
    <div className='metaverse-sec1'>
      <Container>
        <Row>
          <Col>
            <h2>Boost Your Brand in the Metaverse<span className='metaverse-sec1-break'>
              <br /></span>  A Comprehensive Metaverse Marketing Guide </h2>
            <div className='mobile-view'>
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {accordionData.map((section, index) => (
                  <div className="accordion-item" key={index}>
                    <p className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        {section.title}
                      </button>
                    </p>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <h4 className='content-head'>{section.heading}</h4>
                        {section.content && section.content.map((paragraph, idx) => (
                          <p className='content-sescription' key={idx}>{paragraph}</p>
                        ))}
                        {section.benefits && (
                          <div className="benefits">
                            {section.benefits.map((benefit, idx) => (
                              <React.Fragment key={idx}>
                                <p className="benefits-discript">{benefit.title}</p>
                                <p>{benefit.description}</p>
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Section1

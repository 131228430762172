import React from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import GridCard from '../DigitalMarketing/GridCard';
import graph from '../../assets/images/graph.svg';
import Tabs from '../DigitalMarketing/Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from '../DigitalMarketing/BottomFaq';

const B2C = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const cardData = [
        {
            src: graph,
            alt: 'B2C Marketing Strategy',
            title: 'B2C Marketing Strategy',
            text: 'OWe develop comprehensive marketing campaign strategies and detailed planning tailored to your B2C business objectives. Our approach ensures that every aspect of your campaign is strategically aligned for maximum impact and success.'
        },
        {
            src: graph,
            alt: 'B2C Keywords Research',
            title: 'B2C Keywords Research',
            text: 'Our in-depth keyword research identifies the most effective search terms to target your audience. By optimizing your content with high-value keywords, we improve your visibility and drive qualified traffic to your brand.'
        },
        {
            src: graph,
            alt: 'B2C Competitor Analysis',
            title: 'B2C Competitor Analysis',
            text: "We conduct thorough competitor analysis to understand your B2C market landscape. By evaluating your competitors' strategies, we help you identify opportunities and develop tactics that position your brand ahead of the competition."
        },
        {
            src: graph,
            alt: 'B2C Branding Building',
            title: 'B2C Branding Building',
            text: 'Our branding services focus on creating a strong, memorable brand identity that resonates with your target audience. We help you establish a cohesive brand image and messaging that drives recognition and loyalty.'
        },
        {
            src: graph,
            alt: 'B2C Web Portal Development',
            title: 'B2C Web Portal Development',
            text: 'We design and develop user-friendly web portals that enhance your online presence. Our portals are tailored to provide an engaging and seamless experience for your customers, supporting your business objectives.'
        },
        {
            src: graph,
            alt: 'B2C Mobile App Development',
            title: 'B2C Mobile App Development',
            text: 'Our mobile app development services create intuitive and feature-rich apps that connect with your customers on-the-go. We ensure that your app delivers a superior user experience and supports your marketing goals.'
        },
        {
            src: graph,
            alt: 'B2C SEO',
            title: 'B2C SEO',
            text: 'Our SEO services cover local, national, and international markets to boost your search engine rankings. We optimize your website to improve visibility and attract relevant traffic from diverse geographic regions.'
        },
        {
            src: graph,
            alt: 'B2C ASO',
            title: 'B2C ASO',
            text: 'Boost your B2C app’s visibility and downloads with our expert app store optimization ASO services. We specialize in optimizing app store listings, crafting compelling descriptions, and leveraging strategic keywords to enhance your app’s performance.'
        },
        {
            src: graph,
            alt: 'Paid Advertising',
            title: 'Paid Advertising',
            text: 'We manage targeted paid advertising campaigns across search engines, display networks, and social media platforms. Our strategies are designed to maximize your ad spend and generate high-quality leads.'
        },
        {
            src: graph,
            alt: 'Social Media Marketing',
            title: 'Social Media Marketing',
            text: 'Our social media marketing services enhance your brand’s presence on platforms where your audience is active. We create engaging content and execute campaigns that drive interaction and growth.'
        },
        {
            src: graph,
            alt: 'B2C Lead Generation',
            title: 'B2C Lead Generation',
            text: 'We implement effective lead generation tactics to attract and convert potential customers. Our strategies are designed to generate high-quality leads that align with your business objectives.'
        },
        {
            src: graph,
            alt: 'B2C Demand Generation',
            title: 'B2C Demand Generation',
            text: 'Our demand generation services focus on creating awareness and interest in your B2C products or services. We employ various tactics to drive demand and increase your brand’s market reach.'
        },
        {
            src: graph,
            alt: 'Content Marketing',
            title: 'Content Marketing',
            text: 'We develop compelling content that captures your audience’s attention and drives engagement. Our content marketing strategies are designed to build trust and position your brand as an industry leader.'
        },
        {
            src: graph,
            alt: 'Email Marketing',
            title: 'Email Marketing',
            text: 'Our email marketing campaigns are crafted to nurture relationships and drive conversions. We design targeted email strategies that deliver personalized messages to your audience and boost engagement.'
        },
        {
            src: graph,
            alt: 'Public Relations',
            title: 'Public Relations',
            text: 'Our PR services enhance your brand’s reputation and visibility through strategic media relations. We help you build a positive public image and manage your brand’s communication effectively.'
        },
        {
            src: graph,
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: 'We connect your brand with influencers who resonate with your target audience. Our influencer marketing strategies leverage trusted voices to amplify your brand’s message and drive consumer interest.'
        },
        {
            src: graph,
            alt: 'Performance Marketing',
            title: 'Performance Marketing',
            text: 'Our performance marketing services focus on achieving measurable results through data-driven strategies. We optimize campaigns to ensure that your B2C marketing investments deliver a strong ROI.'
        },
        {
            src: graph,
            alt: 'Programmatic Advertising',
            title: 'Programmatic Advertising',
            text: 'We utilize programmatic advertising to automate and optimize your ad placements. Our approach ensures that your ads reach the right audience at the right time for maximum effectiveness.'
        },
        {
            src: graph,
            alt: 'B2C CRO',
            title: 'B2C CRO',
            text: 'Our conversion rate optimization (CRO) services enhance your B2C website’s ability to convert visitors into customers. We use data and insights to improve user experience and drive higher conversion rates.'
        },
        {
            src: graph,
            alt: 'B2C ORM',
            title: 'B2C ORM',
            text: 'Our online reputation management (ORM) services help you maintain a positive brand image. We monitor and manage online feedback to ensure that your B2C brand is perceived favorably by your audience.'
        },
        {
            src: graph,
            alt: 'B2C Marketing Consulting',
            title: 'B2C Marketing Consulting',
            text: 'Our marketing consulting services offer expert advice and strategies to optimize your marketing efforts. We work with you to develop actionable plans that align with your business goals and drive growth.'
        },

    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Deep Understanding of Consumer Behavior',
            sections: [
                {
                    content: 'XDMinds excels in understanding the nuances of consumer behavior. By analyzing trends, preferences, and purchase patterns, we create strategies that resonate with your target audience. This insight allows us to craft messages that not only capture attention but also drive engagement and loyalty.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Innovative Marketing Strategies',
            sections: [
                {
                    content: "At XDMinds, we believe in pushing the boundaries of conventional marketing. Our team is dedicated to developing innovative strategies that differentiate your brand from the competition. Whether it's leveraging the latest digital tools or creating unique campaigns, we ensure that your brand stands out in the crowded marketplace."
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Data-Driven Decision Making',
            sections: [
                {
                    content: 'Data is at the core of our approach. We utilize advanced analytics to inform our strategies, ensuring that every decision is backed by solid data. This approach allows us to continuously optimize campaigns, improve performance, and deliver measurable results that align with your business goals.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Tailored Solutions for Every Client',
            sections: [
                {
                    content: "We recognize that every business is unique, and so are its marketing needs. XDMinds offers tailored solutions that are specifically designed to meet your brand's objectives. Our customized approach ensures that your marketing efforts are aligned with your business vision, leading to more effective outcomes."
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Expert Team with Diverse Skill Sets',
            sections: [
                {
                    content: ' Our team is composed of experts with diverse skill sets, ranging from digital marketing to creative design. This multidisciplinary expertise enables us to offer comprehensive solutions that cover every aspect of your marketing needs. With XDMinds, you get access to a team that is as passionate about your success as you are.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Focus on Long-Term Growth',
            sections: [
                {
                    content: ' At XDMinds, our focus is not just on immediate results but on long-term growth. We work closely with you to develop strategies that are sustainable and scalable, ensuring that your brand continues to thrive in the ever-changing market landscape. Our goal is to build a partnership that supports your business’s growth for years to come.'
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Commitment to Transparency and Collaboration',
            sections: [
                {
                    content: 'We believe that transparency and collaboration are key to a successful partnership. XDMinds maintains open communication throughout every stage of the project, ensuring that you are always informed and involved. This collaborative approach allows us to work together effectively, making sure that the strategies we implement align with your vision.'
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Adaptability in a Dynamic Market',
            sections: [
                {
                    content: "In today's fast-paced market, adaptability is crucial. XDMinds prides itself on being agile and responsive to changing market conditions. We continuously monitor trends and adjust our strategies as needed, ensuring that your brand remains relevant and competitive."
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Client-Centric Approach',
            sections: [
                {
                    content: "Your success is our priority. At XDMinds, we adopt a client-centric approach, putting your needs and goals at the forefront of everything we do. By understanding your unique challenges and opportunities, we create solutions that drive meaningful results for your business."
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Ethical Marketing Practices',
            sections: [
                {
                    content: "Integrity and ethics are the cornerstones of our operations. XDMinds is committed to practicing ethical marketing, ensuring that all our campaigns are not only effective but also responsible. We believe in building trust with your audience through honest and transparent communication.."
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What makes XDMinds’ B2C marketing approach different from other agencies?",
            answer: "At XDMinds, we focus on understanding your target audience deeply, leveraging innovative strategies, and using data-driven insights to create highly effective campaigns that resonate with consumers. Our tailored approach ensures that every strategy aligns with your unique business goals."
        },
        {
            question: "How does XDMinds tailor B2C marketing strategies to suit my specific industry?",
            answer: "We recognize that each industry has its own set of challenges and opportunities. Our team conducts in-depth market research and analysis to understand the specific dynamics of your industry, allowing us to create customized strategies that effectively reach and engage your target audience."
        },
        {
            question: "How will XDMinds help me increase brand awareness among my target consumers?",
            answer: "XDMinds utilizes a combination of digital channels, creative content, and targeted advertising to boost your brand's visibility. By crafting compelling narratives and delivering them through the right platforms,we help you build strong brand awareness and connect with consumers more effectively."
        },
        {
            question: "What kind of data does XDMinds use to inform its B2C marketing strategies?",
            answer: "We rely on a wide range of data sources, including consumer behavior analytics, market trends, and performance metrics, to inform our strategies. This data-driven approach ensures that every decision we make is grounded in real-time insights, optimizing the effectiveness of your marketing campaigns."
        },
        {
            question: "How does XDMinds measure the success of a B2C marketing campaign?",
            answer: "Success is measured through key performance indicators (KPIs) such as consumer engagement, conversion rates, ROI, and overall brand growth. We provide regular reports and analysis to keep you informed about the performance of your campaigns and make adjustments as needed to ensure continued success."
        },
        {
            question: "Can XDMinds help my business engage with consumers across multiple channels?",
            answer: "Absolutely. XDMinds excels in omnichannel marketing, ensuring that your brand's message is consistent and impactful across all platforms,whether it's social media, email marketing, or digital advertising. We create seamless experiences that drive engagement across all consumer touchpoints."
        },
        {
            question: "How does XDMinds stay ahead of the latest trends in B2C marketing?",
            answer: "Our team is constantly monitoring the latest trends and innovations in the marketing landscape. We invest in ongoing training and development to ensure that we are always at the forefront of the industry, allowing us to implement cutting-edge strategies that keep your brand ahead of the competition."
        },
        {
            question: "What can I expect during the initial consultation with XDMinds for B2C marketing services?",
            answer: "During the initial consultation, we will discuss your business goals, target audience, and current marketing challenges. This session allows us to understand your needs better and provide you with a customized strategy that aligns with your objectives. It's also an opportunity for you to ask questions and learn more about how we can help your business grow."
        },
        {
            question: " How does XDMinds ensure that my B2C marketing campaigns are compliant with industry regulations?",
            answer: "XDMinds is committed to ethical marketing practices and strict adherence to industry regulations. We ensure that all campaigns are compliant with legal requirements and best practices, protecting your brand’s reputation while delivering effective marketing solutions."
        },
        {
            question: "What level of involvement will I have in the B2C marketing process with XDMinds?",
            answer: "We believe in collaboration and transparency. You will be involved throughout the entire process, from strategy development to campaign execution. Regular updates and meetings ensure that your input is valued and that the marketing strategies align with your vision and expectations."
        },
    ]
    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Elevate Your Brand with XDMinds<br className='break' />  Top<span className='s-text-3'>B2C</span> Digital Marketing Agency
                </h1>
                <p className='p-text-1'>
                    Welcome to XDMinds, where we specialize in propelling your brand to new heights. As a top-notch B2C digital marketing agency, we offer innovative strategies and tailored solutions designed to enhance your brand’s visibility, engage your target audience, and drive remarkable growth. Partner with us to experience cutting-edge B2C digital marketing approaches that deliver real results.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    XDMinds – Leading the Way as Your Premier B2C Digital <br className='break' />Marketing Agency for Outstanding Business Growth
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we excel as the leading B2C digital marketing agency, dedicated to driving your business towards exceptional growth. Our expertise in the B2C sector enables us to craft innovative strategies that enhance your brand’s presence and engage your target audience effectively. By leveraging a blend of cutting-edge techniques and data-driven insights, we deliver results that not only meet but exceed your business objectives. Partner with us to experience unparalleled marketing solutions designed to elevate your brand and achieve outstanding growth in today’s competitive marketplace.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your b2c marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/b2c-marketing-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Transform Your Brand's Consumer Engagement with XDMinds' Cutting-Edge Business-to-Consumer (B2C) Marketing Services
                </h2>
                <p className='p-text-2'>
                    Discover how XDMinds can revolutionize your brand’s consumer engagement with our state-of-the-art B2C marketing services. Our approach leverages the latest digital marketing techniques and data-driven insights to create impactful campaigns tailored specifically for B2C businesses. By focusing on targeted strategies that enhance customer interaction and optimize conversion rates, we help you build lasting relationships with your audience and achieve unparalleled results. Trust XDMinds to transform your brand’s engagement and elevate your market presence with our expertise in digital marketing services.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Why XDMinds Should Be Your Go-To Business-to-Consumer <br className='break' /> (B2C) Marketing Agency Partner?
                </h2>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest B2C Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default B2C
import React, { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs'
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaSkype, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { GetAllBlogApi } from '../../Helper/Redux/ReduxThunk/Homepage';
import { HiArrowLongRight } from 'react-icons/hi2';



const Blogpage = () => {
  const targetHeadingRef = useRef(null);
  const [visibleCards, setVisibleCards] = useState(9);
  const [allBlog, setAllBlog] = useState()
  const [allCategory, setAllCategory] = useState("")


  const handleScrollToOpenPositions = () => {
    if (targetHeadingRef.current) {
      const targetPosition = targetHeadingRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: targetPosition - 100, behavior: 'smooth' });
    }
  };

  const handleShowMore = () => {
    const increment = window.innerWidth < 768 ? 3 : 9;
    setVisibleCards(prevCount => prevCount + increment);
  };

  const updateVisibleCards = () => {
    const initialVisibleCards = window.innerWidth < 768 ? 3 : 9;
    setVisibleCards(initialVisibleCards);
  };

  useEffect(() => {
    getALLBlogs();
    updateVisibleCards(); 

    window.addEventListener('resize', updateVisibleCards);
    return () => window.removeEventListener('resize', updateVisibleCards);
  }, []);

  const dispatch = useDispatch()

  const getALLBlogs = async () => {
    await dispatch(GetAllBlogApi((resp) => {

      if (resp.status === true) {
        setAllBlog(resp?.data)
        setAllCategory(resp?.category)

      }
    }))
  }

  return (
    <div className='digital-marketing blog-page'>
      <section className='digital-marketing-banner min-100vh'>
        <BreadCrumbs />
        <h1 className='h-text-1'>
          <span className='s-text-3'>Web3</span> Digital Marketing <br className='break' /> Blog Insights and Strategies
        </h1>
        <p className='p-text-1'>
          Discover the latest trends and innovative tactics in Web3 digital marketing with XDMinds. Our blog offers in-depth insights, expert advice, and practical strategies to help you succeed in the decentralized digital landscape.
        </p>
        <button className='request-btn' onClick={handleScrollToOpenPositions}>Explore Blogs</button>
      </section>
      <section className='digital-marketing-section-2 pad-30'>
        <div className='categories-section'>
          <h2 className='h-text-2 text-start mb-4'>Categories</h2>
          <div className='categories-div'>
            <Link reloadDocument className={`industry-btn active`} >
              All
            </Link>
            {allCategory && allCategory?.map((category, index) => (
              <Link reloadDocument to={`/blog/${category.categoryURL}`} className={`industry-btn ${category.active}`} key={index} >
                {category.categoryTitle}
              </Link>
            ))}
          </div>
        </div>
      </section>
      <section className='digital-marketing-section-2 d-center pad-30' ref={targetHeadingRef}>
        <div className='blog-section'>
          <div className='row g-4'>
            {allBlog && allBlog?.slice(0, visibleCards)?.map(blog => (
              <div key={blog._id} className='col-md-6 col-lg-4 col-12'>
                <div reloadDocument className='custom-blog-card card h-100'>
                  <Link reloadDocument to={`/${blog.blog_url}`} className='custom-blog-card-img'>
                    <LazyLoadImage src={blog.blog_image} alt={blog.blog_image_alt_title} />
                  </Link>
                  <div className='custom-blog-card-body'>
                    <h5 className='h-text-15'>{blog.blog_title}</h5>
                    {/* <p className='p-text-11'>{blog.blog_shot}</p> */}
                  </div>
                  <div className='custom-blog-card-tags justify-content-end'>
                    {blog.blog_tags !== "" &&
                      blog.blog_tags && blog.blog_tags.split(',')?.map((tag, index) => (
                        <span key={index} className='blog-tags-btn'>{tag.trim()}</span>
                      ))}
                    <div className='d-flex'>
                      <div className='latest-arrow-incard-div '>
                        <Link reloadDocument to={`/${blog.blog_url}`} className='btn-latest-arrow-incard'>
                          <HiArrowLongRight type='submit' className='latest-arrow-incard  float-end' />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            ))}
          </div>
        </div>
        {allBlog && allBlog?.length > visibleCards && (
          <div className='text-center mt-4'>
            <button className='show-more-btn' onClick={handleShowMore}>Show More</button>
          </div>
        )}
      </section>
      <section className='m-challenge'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='m-challenge-content'>
                <p className='challenge-content'>
                  Tell us about your marketing obstacle , and we'll <span className='mc-break'><br /> </span>create a personalized solution for you.
                </p>
                <p>
                  Is your marketing feeling more like a monster than a masterpiece? Don't fret! We specialize in slaying marketing obstacles and crafting custom plans to turn your campaigns into conquering heroes. Share your struggle, and we'll be your marketing mastermind, free of charge.
                </p>
              </div>
              <div className='btn-mchallenge'>
                <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Web3 marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/blog' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                  <button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></button>
                </a>
                <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                  <button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></button>
                </a>
                <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                  <button className='challenge-skype'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Blogpage
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MdArrowCircleDown } from "react-icons/md";

const Section5 = () => {

  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const data = [
    {
      number: 1,
      title: 'Industry Expertise',
      description: 'With extensive experience in blockchain technology and digital marketing, our team possesses deep knowledge and understanding of both fields. This unique combination allows us to craft effective marketing strategies tailored specifically for blockchain projects.'
    },
    {
      number: 2,
      title: 'Innovative Strategie',
      description: 'We are committed to staying ahead of the curve by leveraging the latest trends and technologies in blockchain marketing. Our innovative approaches ensure that your project remains competitive and relevant in the fast-paced blockchain industry.'
    },
    {
      number: 3,
      title: 'Data-Driven Approach',
      description: 'Our marketing strategies are grounded in comprehensive research and data analysis. By understanding market trends, audience behavior, and competitor tactics, we create campaigns that are highly targeted and effective.'
    },
    {
      number: 4,
      title: 'Comprehensive Marketing Services',
      description: 'We offer a full suite of blockchain marketing services, from SEO and PPC advertising to content creation and social media marketing. This holistic approach ensures that all aspects of your marketing needs are covered.'
    },
    {
      number: 5,
      title: 'Customized Solutions',
      description: 'We understand that every blockchain project is unique. Our team works closely with you to develop personalized marketing strategies that align with your specific goals and objectives.'
    },
    {
      number: 6,
      title: 'Proven Track Record',
      description: 'Our portfolio of successful blockchain marketing campaigns speaks for itself. We have helped numerous projects achieve their marketing goals, driving growth and increasing visibility in the blockchain space.'
    },
    {
      number: 7,
      title: 'Transparent Communication',
      description: 'We believe in maintaining open and transparent communication with our clients. You will receive regular updates and detailed reports on the performance of your marketing campaigns, ensuring you are always informed about the progress.'
    },
    {
      number: 8,
      title: 'Dedicated Support',
      description: 'Our commitment to your success goes beyond just implementing marketing strategies. We provide ongoing support and are always available to address any questions or concerns you may have.'
    },
    {
      number: 9,
      title: 'Result-Oriented',
      description: 'Our primary focus is on delivering measurable results. We set clear objectives and key performance indicators (KPIs) for each campaign, ensuring that our efforts translate into tangible outcomes for your project.'
    },
    {
      number: 10,
      title: 'Passion for Blockchain',
      description: "At XDMinds, we are passionate about blockchain technology and its potential to revolutionize industries. This passion drives us to go above and beyond in helping your project succeed.By choosing XDMinds as your blockchain marketing partner, you gain access to a team of dedicated professionals who are committed to driving your project's success through innovative and effective marketing strategies."
    },

  ];

  return (
    <div>
      <div className='blockchain-sec5'>
        <Container>
          <Row>
            <Col>
              <h2>Why Choose Us As a Blockchain Marketing Partner?
              </h2>
              <p className='subhead'>
                Choosing XDMinds as your blockchain marketing partner offers numerous advantages that can significantly elevate your project's success. Here's why we stand out:
              </p>

              <div className='metaverse-sec5 desktop-view mobile-hide'>
                <Row>
                  {data.map((item, index) => (
                    <Col
                      key={index}
                      lg={4}
                      xl={4}
                      sm={6}
                      md={6}
                      style={item.number % 3 === 0 ? { border: 'none' } : {}}
                    >
                      <div className='blockchain-sec5-card number-div'>
                        <div className='bc-sec5-carde-head'>
                          <p className='bc-sec5-carde-head-number'>{item.number}</p>
                          <p>{item.title}</p>
                        </div>
                        <p className='bc-sec5-card-discription'>{item.description}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='about'>
        <section className='about-section-5 p-0'>
          <div className='custom-accordion desktop-hide tab-hide'>
            <div className="accordion" id="accordionExample">
              {data.map((item, index) => (
                <div key={item.id} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                      onClick={() => handleClick(index)}
                      type="button"
                      aria-expanded={openIndex === index}
                      aria-controls={item.id}
                    >
                      {item.title}
                      <div className="arrow-icon-btn">
                        <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className='p-text-1'>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Section5
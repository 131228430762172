import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import '../Metaverse/Metaverse.css'
import { FaSkype, FaWhatsapp } from 'react-icons/fa6'
import { FaTelegramPlane } from 'react-icons/fa'


const Section2 = () => {
    return (
        <div>
            <div className='blockchain-sec2'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2> Building Trust in the Blockchain Sphere <span className='metaverse-sec2-break'><br /></span>Blockchain Marketing with XDMinds Professionals
                                </h2>
                                <p>As a premier blockchain marketing agency, XDMinds is dedicated to transforming how businesses engage with their audiences. Our expertise in blockchain technology and marketing strategies ensures that your brand achieves its full potential in the digital landscape.
                                </p>
                            </div>
                            <div className='btn-mchallenge'>
                                <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Blockchain marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/blockchain-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                    <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                                </a>
                                <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                                </a>
                                <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Section2
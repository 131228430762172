import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button, Col, Container, Row } from 'react-bootstrap';
import BreadCrumbs from '../../BreadCrumbs';

const NFTBanner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { countryCode } = props;

  return (
    <div className='web3-banner'>
      <Container>
        <Row>
          <Col>
            <BreadCrumbs />
            <div className='banner-left-sec min-100vh'>
              <h1>Best-in-class <span className='changing-banner-content'>NFT</span><span className='home-banner-break'><br /></span> Marketing Agency</h1>
              <p>Discover unparalleled success in the NFT world with XDMinds, the leading agency specializing in innovative NFT marketing strategies. Our expertise ensures your NFT projects achieve maximum visibility, engagement, and sales, positioning you at the forefront of the digital art and collectibles market.</p>
              <div className='btn-home-banner-request-div'>
                <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
              </div>
              <ModalForm
                countryCode={countryCode}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NFTBanner
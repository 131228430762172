import React from 'react'
import './Banner.css'
import { Button, Col, Container, Row } from 'react-bootstrap'
import ModalForm from '../../ModalForm/ModalForm';

const Banner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);

  const { countryCode } = props;

  return (
    <div className='web3-banner '>

      <div className='banner-left-sec min-100vh'>
        <h1>Top-Rated <span className='changing-banner-content'>Web3</span> <span className='home-banner-break'><br /></span> Digital Marketing Agency</h1>
        <p>XDMinds stands at the forefront of Web3 digital marketing, renowned for its innovative strategies and exceptional results. With a commitment to excellence, we empower businesses to thrive in the decentralized digital landscape.</p>
        <div className='btn-home-banner-request-div'>
          <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
        </div>
        <ModalForm
          countryCode={countryCode}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>

    </div>
  )
}


export default Banner
import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What services does XDMinds offer for NFT marketing?",
    answer: `XDMinds offers a comprehensive range of services, including strategy creation, SEO, PPC advertising, social media marketing, influencer marketing, and more.`
  },
  {
    question: "How does XDMinds ensure the success of an NFT project?",
    answer: `We use data-driven strategies, industry expertise, and creative marketing techniques to maximize your NFT project's visibility, engagement, and sales.`
  },
  {
    question: "Can XDMinds help with NFT marketplace optimization?",
    answer: `Yes, we optimize your presence on NFT marketplaces to ensure your project stands out and attracts potential buyers.`
  },
  {
    question: "Does XDMinds provide community-building services?",
    answer: `Absolutely. We help build and nurture communities on platforms like Telegram and Discord to foster engagement and loyalty.`
  },
  {
    question: "How does XDMinds approach NFT SEO?",
    answer: `Our SEO strategies are tailored to improve your NFT project's visibility on search engines, driving organic traffic and increasing sales.`
  },
  {
    question: "What is NFT influencer marketing?",
    answer: `We partner with influencers to promote your NFTs, leveraging their follower base to reach a wider audience.`
  },
  {
    question: "Can XDMinds handle NFT email marketing campaigns?",
    answer: `Yes, we design and implement targeted email campaigns to keep your audience informed and engaged with your NFT offerings.`
  },
  {
    question: "What makes XDMinds different from other NFT marketing agencies?",
    answer: `Our unique combination of creative strategies, data-driven approaches, and industry expertise sets us apart, ensuring the success of your NFT project.`
  },
  {
    question: "How does XDMinds measure the success of an NFT marketing campaign?",
    answer: `We use key performance indicators (KPIs) such as engagement rates, traffic, and sales to measure and analyze the success of our marketing campaigns.`
  },
  {
    question: "Can XDMinds help with lead generation for NFT projects?",
    answer: `Yes, we implement effective lead generation strategies to capture and nurture potential buyers, converting interest into sales.`
  }
];

const FAQblockchain = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='blockchain-sec-6-faq'>
      <div className='faq'>
        <Container>
          <Row>
            <Col>
              <div>
                <h2>Frequently Asked Question
                </h2>
              </div>
              <div className='blockchain-faq-qa'>
                {faqData.map((faq, index) => (
                  <div key={index}>
                    <div
                      onClick={() => handleFAQClick(index)}
                      aria-controls={`faq${index}`}
                      aria-expanded={openFAQ === index}
                      className='faq-1st'
                    >
                      <div className='faq-content'>
                        <h6 className='faq-content-question'>{faq.question}</h6>
                      </div>
                      <div className='faq-icons'>
                        {openFAQ === index ? (
                          <IoIosArrowDropupCircle className='close-circle-faq' />
                        ) : (
                          <IoIosArrowDropdownCircle className='plus-circle-faq' />
                        )}
                      </div>
                    </div>
                    <Collapse in={openFAQ === index}>
                      <div id={`faq${index}`} className='faq-answer-content'>
                        {renderAnswer(faq.answer)}
                      </div>
                    </Collapse>
                  </div>
                ))}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default FAQblockchain
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import tick from "../../assets/images/tick.svg";
import arrow from "../../assets/images/forwardarrow.svg";
import darrow from '../../assets/images/downarrow.svg'
import './Header.css'
import ModalForm from "../ModalForm/ModalForm";



const Navbar = (props) => {
    const { countryCode, socialWhatsappLink } = props;

    const [dropdown, setDropdown] = useState(null);
    const [hoveredWeb3Item, setHoveredWeb3Item] = useState("AI Marketing");
    const [hoveredServiceItem, setHoveredServiceItem] = useState("Digital Marketing");
    const [isOpen, setIsOpen] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const handleMouseEnter = (menu) => setDropdown(menu);
    const handleMouseLeave = () => setDropdown(null);
    const handleWeb3ItemHover = (item) => setHoveredWeb3Item(item);
    const handleServiceItemHover = (item) => setHoveredServiceItem(item);
    const handleLinkClick = () => setDropdown(null);

    const toggleClick = () => {
        setIsOpen(!isOpen);
    }

    const closeNav = () => {
        setIsOpen(false);
    };
    const accordionData = [
        {
            header: "Web3 Marketing",
            targetId: "collapseWeb3",
            links: [
                { to: '/ai-marketing-agency', text: 'AI Marketing', icon: tick, isLink: true },
                { to: '/ar-vr-marketing-agency', text: 'AR/VR Marketing', icon: tick, isLink: true },
                { to: '/metaverse-marketing-agency', text: 'Metaverse Marketing', icon: tick, isLink: true },
                { to: '/blockchain-marketing-agency', text: 'Blockchain Marketing', icon: tick, isLink: true },
                { to: '/nft-marketing-agency', text: 'NFT Marketing', icon: tick, isLink: true },
                { to: '/defi-marketing-agency', text: 'DeFi Marketing', icon: tick, isLink: true },
                { to: '/dapp-marketing-agency', text: 'DApps Marketing', icon: tick, isLink: true },
                { to: '/gaming-marketing-agency', text: 'Gaming Marketing', icon: tick, isLink: true },
                { to: '/crypto-marketing-agency', text: 'Crypto Marketing', icon: tick, isLink: true },
            ]
        },
        {
            header: "Digital Marketing",
            targetId: "collapseDigital",
            links: [
                { to: '/digital-marketing-agency', text: 'Full-stack Digital Marketing', icon: tick, isLink: true },
                { to: '/seo-agency', text: 'Search Engine Optimization (SEO)', icon: tick, isLink: true },
                { to: '/aso-agency', text: 'App Store Optimization (ASO)', icon: tick, isLink: true },
                { to: '/ppc-advertising-agency', text: 'Pay-Per-Click Advertising (PPC)', icon: tick, isLink: true },
                { to: '/social-media-marketing-agency', text: 'Social Media Marketing', icon: tick, isLink: true },
                { to: '/mobile-app-marketing-agency', text: 'Mobile App Marketing', icon: tick, isLink: true },
                { to: '/performance-marketing-agency', text: 'Performance Marketing', icon: tick, isLink: true },
                { to: '/programmatic-advertising-agency', text: 'Programmatic Advertising', icon: tick, isLink: true },
                { to: '/influencer-marketing-agency', text: 'Influencer Marketing', icon: tick, isLink: true },
            ]
        },
        // {
        //     header: "Industries",
        //     targetId: "collapseIndustries",
        //     links: [
        //         { to: '/agriculture', text: 'Agriculture', icon: arrow, isLink: false },
        //         { to: '/automobiles', text: 'Automobiles', icon: arrow, isLink: false },
        //         { to: '/b2b-marketing-agency', text: 'B2B', icon: arrow, isLink: true },
        //         { to: '/b2c-marketing-agency', text: 'B2C', icon: arrow, isLink: true },
        //         { to: '/banking', text: 'Banking', icon: arrow, isLink: false },
        //         { to: '/crowdfunding', text: 'Crowdfunding', icon: arrow, isLink: false },
        //         { to: '/education', text: 'Education', icon: arrow, isLink: false },
        //         { to: '/electric-vehicle', text: 'Electric Vehicle', icon: arrow, isLink: false },
        //         { to: '/electronics', text: 'Electronics', icon: arrow, isLink: false },
        //         { to: '/energy', text: 'Energy', icon: arrow, isLink: false },
        //         { to: '/fcmg', text: 'FCMG', icon: arrow, isLink: false },
        //         { to: '/fintech', text: 'FinTech', icon: arrow, isLink: false },
        //         { to: '/gaming', text: 'Gaming', icon: arrow, isLink: true },
        //         { to: '/healthcare', text: 'Healthcare', icon: arrow, isLink: false },
        //         { to: '/hospitality', text: 'Hospitality', icon: arrow, isLink: false },
        //         { to: '/information-technology', text: 'Information Technology', icon: arrow, isLink: false },
        //         { to: '/infrastructure', text: 'Infrastructure', icon: arrow, isLink: false },
        //         { to: '/insurance', text: 'Insurance', icon: arrow, isLink: false },
        //         { to: '/lifestyle', text: 'Lifestyle', icon: arrow, isLink: false },
        //         { to: '/manufacturing', text: 'Manufacturing', icon: arrow, isLink: false },
        //         { to: '/media-entertainment', text: 'Media and Entertainment', icon: arrow, isLink: false },
        //         { to: '/oil-gas', text: 'Oil and Gas', icon: arrow, isLink: false },
        //         { to: '/real-estate', text: 'Real Estate', icon: arrow, isLink: false },
        //         { to: '/retail', text: 'Retail', icon: arrow, isLink: false },
        //         { to: '/saas', text: 'SaaS', icon: arrow, isLink: true },
        //         { to: '/sports', text: 'Sports', icon: arrow, isLink: false },
        //         { to: '/telecommunication', text: 'Telecommunication', icon: arrow, isLink: false },
        //         { to: '/transportation-logistics', text: 'Transportation and Logistics', icon: arrow, isLink: false },
        //         { to: '/travel-tourism', text: 'Travel and Tourism', icon: arrow, isLink: false },
        //         { to: '/web3-marketing-services', text: 'Web3', icon: arrow, isLink: true }
        //     ]
        // }
    ];

    return (
        <div className="top-navbar">
            <nav className="navbar navbar-expand-lg p-0 h-100">
                <div className="container-fluid flex-nowrap h-100">
                    <Link reloadDocument to="/" className="navbar-brand" onClick={closeNav}>
                        <div className="logo-image" />
                        {/* <LazyLoadImage src={logo} alt="logo" className="logo" /> */}
                    </Link>

                    {/* <=============================DESKTOP VIEW========================================> */}

                    <div className="nav-pages tab-hide mobile-hide h-100">
                        <ul className="navbar-nav justify-content-evenly w-100 h-100">
                            <li className="nav-item">
                                <Link reloadDocument to="/about" className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li
                                className="nav-item"
                                onMouseEnter={() => handleMouseEnter("web3")}
                                onMouseLeave={handleMouseLeave}
                            >
                                <span className="nav-link">
                                    Web3
                                    <LazyLoadImage src={darrow} alt="Down-arrow" />
                                </span>
                                {dropdown === "web3" && (
                                    <div className="dropdown-menu web3-dropdown">
                                        <div className="dropdown-content">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="even-nav-split">
                                                        <div className="content-1">
                                                            <ul className="dropdown-links">
                                                                {[
                                                                    { name: "AI Marketing", pname: "AI Marketing", path: "/ai-marketing-agency" },
                                                                    { name: "AR/VR Marketing", pname: "AR/VR Marketing", path: "/ar-vr-marketing-agency" },
                                                                    { name: "Metaverse Marketing", pname: "Metaverse Marketing", path: "/metaverse-marketing-agency" },
                                                                    { name: "Blockchain Marketing", pname: "Blockchain Marketing", path: "/blockchain-marketing-agency" },
                                                                    { name: "NFT Marketing", pname: "NFT Marketing", path: "/nft-marketing-agency" },
                                                                    { name: "DeFi Marketing", pname: "DeFi Marketing", path: "/defi-marketing-agency" },
                                                                    { name: "dApps Marketing", pname: "dApps Marketing", path: "/dapp-marketing-agency" },
                                                                    { name: "Gaming Marketing", pname: "Gaming Marketing", path: "/gaming-marketing-agency" },
                                                                    { name: "Crypto Marketing", pname: "Crypto Marketing", path: "/crypto-marketing-agency" },
                                                                ].map((item, index) => (
                                                                    <Link
                                                                        reloadDocument
                                                                        key={index}
                                                                        to={item.path}
                                                                        className="dropdown-item"
                                                                        onClick={handleLinkClick}
                                                                        onMouseEnter={() => handleWeb3ItemHover(item.pname)}
                                                                    >
                                                                        <LazyLoadImage src={tick} alt="tick" /> {item.name}
                                                                    </Link>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className="d-flex justify-content-end ">
                                                            <Link
                                                                reloadDocument
                                                                to="/web3-marketing-services"
                                                                className="arrow-btn"
                                                                onClick={handleLinkClick}
                                                            ></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </li>
                            <li
                                className="nav-item"
                                onMouseEnter={() => handleMouseEnter("services")}
                                onMouseLeave={handleMouseLeave}
                            >
                                <span className="nav-link">
                                    Services
                                    <LazyLoadImage src={darrow} alt="Down-arrow" />
                                </span>
                                {dropdown === "services" && (
                                    <div className="dropdown-menu">
                                        <div className="dropdown-content">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="even-nav-split">
                                                        <div className="content-1">
                                                            <ul className="dropdown-links">
                                                                {[
                                                                    { name: "Full-Stack Digital Marketing", pname: "Digital Marketing", path: "/digital-marketing-agency" },
                                                                    { name: "Search Engine Optimization (SEO)", pname: "Search Engine Optimization (SEO)", path: "/seo-agency" },
                                                                    { name: "App Store Optimization (ASO)", pname: "App Store Optimization (ASO)", path: "/aso-agency" },
                                                                    { name: "Pay-Per-Click Advertising (PPC)", pname: "Pay-Per-Click Advertising (PPC)", path: "/ppc-advertising-agency" },
                                                                    { name: "Social Media Marketing", pname: "Social Media Marketing", path: "/social-media-marketing-agency" },
                                                                    { name: "Mobile App Marketing", pname: "Mobile App Marketing", path: "/mobile-app-marketing-agency" },
                                                                    { name: "Performance Marketing", pname: "Performance Marketing", path: "/performance-marketing-agency" },
                                                                    { name: "Programmatic Advertising", pname: "Programmatic Advertising", path: "/programmatic-advertising-agency" },
                                                                    { name: "Influencer Marketing", pname: "Influencer Marketing", path: "/influencer-marketing-agency" },
                                                                ].map((item, index) => (
                                                                    <Link
                                                                        reloadDocument
                                                                        key={index}
                                                                        to={item.path}
                                                                        className="dropdown-item"
                                                                        onClick={handleLinkClick}
                                                                        onMouseEnter={() => handleServiceItemHover(item.pname)}
                                                                    >
                                                                        <LazyLoadImage src={tick} alt="tick" /> {item.name}
                                                                    </Link>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <Link
                                                                reloadDocument
                                                                to="/digital-marketing-agency"
                                                                className="arrow-btn"
                                                                onClick={handleLinkClick}
                                                            >
                                                                {/* Optional: Add arrow icon or text */}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </li>
                            <li
                                className="nav-item"
                            // onMouseEnter={() => handleMouseEnter("industries")}
                            // onMouseLeave={handleMouseLeave}
                            >
                                <Link reloadDocument to="/industries" className="nav-link">
                                    Industries
                                    {/* <LazyLoadImage src={darrow} alt="Down-arrow" /> */}
                                </Link>
                                {dropdown === "industries" && (
                                    <div className="dropdown-menu">
                                        <div className="dropdown-content">
                                            <div className="even-nav-split">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="content-1">
                                                            <ul className="dropdown-links">
                                                                {[
                                                                    { name: "Agriculture", path: "/agriculture", isLink: false },
                                                                    { name: "Automobiles", path: "/automobiles", isLink: false },
                                                                    { name: "B2B", path: "/b2b-marketing-agency", isLink: true },
                                                                    { name: "B2C", path: "/b2c-marketing-agency", isLink: true },
                                                                    { name: "Banking", path: "/banking", isLink: false },
                                                                    { name: "Crowdfunding", path: "/crowdfunding", isLink: false },
                                                                    { name: "Education", path: "/education", isLink: false },
                                                                    { name: "Electric Vehicle", path: "/electric-vehicle", isLink: false },
                                                                    { name: "Electronics", path: "/electronics", isLink: false },
                                                                    { name: "Energy", path: "/energy", isLink: false },
                                                                ].map((item, index) => (
                                                                    <li key={index} className="list-style-none border-bottom-none">
                                                                        {item.isLink ? (
                                                                            <Link
                                                                                to={item.path}
                                                                                className="dropdown-item border-bottom-none"
                                                                                onClick={handleLinkClick}
                                                                            >
                                                                                <LazyLoadImage src={arrow} alt="arrow" className="i-arrow" /> {item.name}
                                                                            </Link>
                                                                        ) : (
                                                                            <span
                                                                                className="dropdown-item industry-items border-bottom-none"
                                                                                onClick={handleLinkClick}
                                                                            >
                                                                                <LazyLoadImage src={arrow} alt="arrow" className="i-arrow" /> {item.name}
                                                                            </span>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="content-1">
                                                            <ul className="dropdown-links">
                                                                {[
                                                                    { name: "FMCG", path: "/fmcg", isLink: false },
                                                                    { name: "FinTech", path: "/fintech", isLink: false },
                                                                    { name: "Gaming", path: "/gaming", isLink: true },
                                                                    { name: "Healthcare", path: "/healthcare", isLink: false },
                                                                    { name: "Hospitality", path: "/hospitality", isLink: false },
                                                                    { name: "Information Technology", path: "/information-technology", isLink: false },
                                                                    { name: "Infrastructure", path: "/infrastructure", isLink: false },
                                                                    { name: "Insurance", path: "/insurance", isLink: false },
                                                                    { name: "Lifestyle", path: "/lifestyle", isLink: false },
                                                                    { name: "Manufacturing", path: "/manufacturing", isLink: false },
                                                                ].map((item, index) => (
                                                                    <li key={index} className="list-style-none border-bottom-none">
                                                                        {item.isLink ? (
                                                                            <Link
                                                                                to={item.path}
                                                                                className="dropdown-item border-bottom-none"
                                                                                onClick={handleLinkClick}
                                                                            >
                                                                                <LazyLoadImage src={arrow} alt="arrow" className="i-arrow" /> {item.name}
                                                                            </Link>
                                                                        ) : (
                                                                            <span
                                                                                className="dropdown-item industry-items border-bottom-none"
                                                                                onClick={handleLinkClick}
                                                                            >
                                                                                <LazyLoadImage src={arrow} alt="arrow" className="i-arrow" /> {item.name}
                                                                            </span>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="content-1">
                                                            <ul className="dropdown-links">
                                                                {[
                                                                    { name: "Media and Entertainment", path: "/media-entertainment", isLink: false },
                                                                    { name: "Oil and Gas", path: "/oil-gas", isLink: false },
                                                                    { name: "Real Estate", path: "/real-estate", isLink: false },
                                                                    { name: "Retail", path: "/retail", isLink: false },
                                                                    { name: "SaaS", path: "/saas", isLink: true },
                                                                    { name: "Sports", path: "/sports", isLink: false },
                                                                    { name: "Telecommunication", path: "/telecommunication", isLink: false },
                                                                    { name: "Transportation and Logistics", path: "/transportation-logistics", isLink: false },
                                                                    { name: "Travel and Tourism", path: "/travel-tourism", isLink: false },
                                                                    { name: "Web3", path: "/web3-marketing-services", isLink: true },
                                                                ].map((item, index) => (
                                                                    <li key={index} className="list-style-none border-bottom-none">
                                                                        {item.isLink ? (
                                                                            <Link
                                                                                to={item.path}
                                                                                className="dropdown-item border-bottom-none"
                                                                                onClick={handleLinkClick}
                                                                            >
                                                                                <LazyLoadImage src={arrow} alt="arrow" className="i-arrow" /> {item.name}
                                                                            </Link>
                                                                        ) : (
                                                                            <span
                                                                                className="dropdown-item industry-items border-bottom-none"
                                                                                onClick={handleLinkClick}
                                                                            >
                                                                                <LazyLoadImage src={arrow} alt="arrow" className="i-arrow" /> {item.name}
                                                                            </span>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <Link
                                                        to="/industries"
                                                        className="arrow-btn"
                                                        onClick={handleLinkClick}
                                                    ></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </li>
                            <li className="nav-item">
                                <Link reloadDocument to="/blog" className="nav-link">
                                    Blog
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link reloadDocument to="/careers" className="nav-link">
                                    Careers
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link reloadDocument to="/contact-us" className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="book-btn-div">
                        <button className="nav-btn ms-lg-auto tab-hide mobile-hide" onClick={() => setModalShow(true)}>Book Intro Call</button>
                    </div>

                    {/* <=============================MOBILE VIEW========================================> */}

                    <button className="nav-collapse-btn desktop-hide" onClick={toggleClick} />
                    <div className={`nav-collapse-section ${isOpen ? 'open' : ''} desktop-hide`}>
                        <div className="nav-pages-mobile mt-3">
                            <Link reloadDocument to='/about' className="nav-link-mobile" onClick={closeNav}>About</Link>
                            <div className="accordion" id="accordionExample">
                                {accordionData.map((item, index) => (
                                    <div className="accordion-item w-100" key={index}>
                                        <h2 className="accordion-header w-100">
                                            <button className="accordion-button collapsed w-100" type="button" data-bs-toggle="collapse" data-bs-target={`#${item.targetId}`} aria-expanded="false" aria-controls={item.targetId}>
                                                {item.header}
                                            </button>
                                        </h2>
                                        <div id={item.targetId} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                {item.links.map((link, linkIndex) => (
                                                    link.isLink ? (
                                                        <Link
                                                            reloadDocument
                                                            to={link.to}
                                                            className="sub-nav-link"
                                                            onClick={closeNav}
                                                            key={linkIndex}>
                                                            <LazyLoadImage src={link.icon} alt={link.text} />
                                                            {link.text}
                                                        </Link>
                                                    ) : (
                                                        <span
                                                            className="sub-nav-link industry-items"
                                                            onClick={closeNav}
                                                            key={linkIndex}>
                                                            <LazyLoadImage src={link.icon} alt={link.text} />
                                                            {link.text}
                                                        </span>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Link reloadDocument to="/industries" className="nav-link-mobile">Industries</Link>
                            <Link reloadDocument to='/blog' className="nav-link-mobile" onClick={closeNav}>Blog</Link>
                            <Link reloadDocument to='/careers' className="nav-link-mobile" onClick={closeNav}>Careers</Link>
                            <Link reloadDocument to='/contact-us' className="nav-link-mobile" onClick={closeNav}>Contact</Link>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center gap-3 w-100 mt-3">
                            <button className="nav-btn mt-2" onClick={() => setModalShow(true)}>Book Intro Call</button>
                            <span className="s-text-1">OR</span>
                            <div className="social-links">
                                <p className="p-text-5">
                                    Connect With Us Instantly
                                </p>
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                    <a href={socialWhatsappLink} className="social-btn ws-btn" onClick={closeNav} />
                                    <a href="https://t.me/xd_minds" target="_blank" rel="noreferrer" className="social-btn ts-btn" onClick={closeNav} />
                                    <a href="skype:live:.cid.7361579f2e5c83ea?chat" target="_blank" rel="noreferrer" className="social-btn ss-btn" onClick={closeNav} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} pageType={"Pre_Header_Popup_Form"} />
        </div>
    );
};

export default Navbar;

import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What are AI-driven digital marketing services?",
    answer: `AI-driven digital marketing services leverage artificial intelligence technologies to enhance various aspects of digital marketing. This includes using AI for data analysis, customer segmentation, personalized content creation, predictive analytics, and automated marketing campaigns to improve efficiency, targeting, and ROI.`
  },
  {
    question: "How can AI improve my marketing strategy?",
    answer: `AI can enhance your marketing strategy by providing data-driven insights, automating routine tasks, personalizing customer interactions, predicting trends, and optimizing campaign performance. This leads to more effective targeting, higher engagement rates, and improved ROI.`
  },
  {
    question: "What types of AI-driven services does XDMinds offer?",
    answer: `XDMinds offers a comprehensive range of AI-driven services, including AI chatbot marketing, generative AI content marketing, AI-driven SEO, PPC advertising, social media marketing, email marketing, and more. Our services cover every aspect of digital marketing to ensure a cohesive and effective strategy.`
  },
  {
    question: "How does AI-driven SEO differ from traditional SEO?",
    answer: `AI-driven SEO uses advanced algorithms and machine learning models to analyze search patterns, optimize keywords, and personalize search results. This approach is more efficient and accurate than traditional SEO, leading to higher search rankings and better visibility for your website.`
  },
  {
    question: "Can AI-driven marketing help with customer personalization?",
    answer: `Yes, AI-driven marketing excels at personalization. By analyzing customer data and behavior, AI can create highly personalized marketing messages and experiences tailored to individual preferences. This results in higher engagement and conversion rates.`
  },
  {
    question: "How does AI chatbot marketing work?",
    answer: `AI chatbot marketing involves using AI-powered chatbots to interact with customers in real-time. These chatbots can answer questions, provide recommendations, and guide customers through their buying journey, enhancing customer service and improving conversion rates.`
  },
  {
    question: "Is AI-driven marketing suitable for my industry?",
    answer: `AI-driven marketing is versatile and can be tailored to suit any industry. Whether you're in healthcare, finance, retail, manufacturing, or any other sector, AI can enhance your marketing efforts by providing industry-specific insights and solutions.`
  },
  {
    question: "How can AI-driven marketing help increase ROI?",
    answer: `AI-driven marketing helps increase ROI by optimizing ad spend, improving targeting accuracy, automating repetitive tasks, and providing actionable insights. This leads to more efficient campaigns, reduced costs, and higher returns on investment.`
  },
  {
    question: "What is generative AI content marketing?",
    answer: `Generative AI content marketing uses AI algorithms to create high-quality, engaging content tailored to your audience. This includes blog posts, social media updates, video scripts, and more. It ensures your content is unique, relevant, and optimized for performance.`
  },
  {
    question: "How do I get started with AI-driven digital marketing services from XDMinds?",
    answer: `Getting started is easy. Contact us through our website or give us a call. Our team of experts will assess your business needs, discuss your marketing goals, and create a customized AI-driven marketing strategy designed to achieve exceptional results.`
  }
];

const AIFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='ai-faq'>
      <div className='blockchain-sec-6-faq'>
        <div className='faq'>
          <Container>
            <Row>
              <Col>
                <div>
                  <h2>Frequently Asked Questions
                  </h2>
                </div>
                <div className='blockchain-faq-qa'>
                  {faqData.map((faq, index) => (
                    <div key={index}>
                      <div
                        onClick={() => handleFAQClick(index)}
                        aria-controls={`faq${index}`}
                        aria-expanded={openFAQ === index}
                        className='faq-1st'
                      >
                        <div className='faq-content'>
                          <h6 className='faq-content-question'>{faq.question}</h6>
                        </div>
                        <div className='faq-icons'>
                          {openFAQ === index ? (
                            <IoIosArrowDropupCircle className='close-circle-faq' />
                          ) : (
                            <IoIosArrowDropdownCircle className='plus-circle-faq' />
                          )}
                        </div>
                      </div>
                      <Collapse in={openFAQ === index}>
                        <div id={`faq${index}`} className='faq-answer-content'>
                          {renderAnswer(faq.answer)}
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default AIFAQ
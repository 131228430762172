import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import GridCard from './GridCard';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import Tabs from './Tabs';
import EventCard from './EventCard';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import graph from '../../assets/images/graph.svg';
import BreadCrumbs from '../../Components/BreadCrumbs';
import { Link } from 'react-router-dom';


const DigitalMarketing = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;


    const cardData = [
        {
            src: graph,
            alt: 'Brand Marketing',
            title: 'Brand Marketing',
            text: 'Enhance your brand identity and visibility through strategic branding initiatives that resonate with your target audience and set you apart from the competition.'
        },
        {
            src: graph,
            link: '/seo-agency',
            alt: 'Search Engine Optimization (SEO)',
            title: 'Search Engine Optimization (SEO)',
            text: 'Improve your website’s ranking on search engines with our expert SEO services, driving organic traffic and increasing your online visibility.'
        },
        {
            src: graph,
            link: '/aso-agency',
            alt: 'App Store Optimization (ASO)',
            title: 'App Store Optimization (ASO)',
            text: 'Maximize your app’s potential with ASO techniques that improve its ranking in app stores, driving more downloads and user engagement.'
        },
        {
            src: graph,
            link: '/dao-marketing-agency',
            alt: 'Digital Asset Optimization (DAO)',
            title: 'Digital Asset Optimization (DAO)',
            text: 'Maximize the value of your digital assets with our Digital Asset Optimization services. We enhance the performance and visibility of your online content and resources through strategic optimization techniques.'
        },
        {
            src: graph,
            link: '/search-engine-marketing-agency',
            alt: 'Search Engine Marketing Agency',
            title: 'Search Engine Marketing Agency',
            text: 'Dominate search engine rankings with our data-driven SEM strategies. Drive targeted traffic and conversions.'
        },
        {
            src: graph,
            link: '/ppc-advertising-agency',
            alt: 'PPC Advertising Agency',
            title: 'PPC Advertising Agency',
            text: 'Maximize your ad spending with our precision PPC campaigns. Achieve exceptional ROI and drive business growth.'
        },
        {
            src: graph,
            link: '/social-media-marketing-agency',
            alt: 'Social Media Marketing',
            title: 'Social Media Marketing',
            text: 'Engage with your audience on their favorite platforms through tailored social media strategies that increase brand awareness and foster community.'
        },
        {
            src: graph,
            alt: 'Social Media Advertising',
            title: 'Social Media Advertising',
            text: 'Reach a larger, targeted audience with precise social media advertising campaigns that drive engagement and conversions.'
        },
        {
            src: graph,
            link: '/content-marketing-agency',
            alt: 'Content Marketing',
            title: 'Content Marketing',
            text: 'Craft compelling content that informs, entertains, and converts, establishing your brand as a thought leader in your industry.'
        },
        {
            src: graph,
            link: '/mobile-app-marketing-agency',
            alt: 'Mobile App Advertising',
            title: 'Mobile App Advertising',
            text: 'Promote your mobile app through targeted advertising strategies that drive downloads and user engagement.'
        },
        {
            src: graph,
            alt: 'Email Marketing',
            title: 'Email Marketing',
            text: 'Nurture relationships with your audience through personalized email campaigns that drive engagement and conversions.'
        },
        {
            src: graph,
            alt: 'Media & PR Marketing',
            title: 'Media & PR Marketing',
            text: 'Build a positive brand image and gain media exposure through strategic media and PR efforts.'
        },
        {
            src: graph,
            link: '/influencer-marketing-agency',
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: 'Partner with influencers to reach a broader audience and build trust with potential customers through authentic endorsements.'
        },
        {
            src: graph,
            alt: 'Omnichannel Marketing',
            title: 'Omnichannel Marketing',
            text: 'Seamlessly integrate your marketing efforts across all channels for a consistent brand experience. Our omnichannel strategies ensure cohesive communication and enhanced customer engagement.'
        },
        {
            src: graph,
            link: '/performance-marketing-agency',
            alt: 'Performance Marketing',
            title: 'Performance Marketing',
            text: 'Focus on results-driven marketing strategies that optimize your advertising spend and deliver measurable outcomes.'
        },
        {
            src: graph,
            link: '/programmatic-advertising-agency',
            alt: 'Programmatic Advertising',
            title: 'Programmatic Advertising',
            text: 'Automate your advertising efforts with programmatic ad buying, ensuring efficient and effective campaign execution.'
        },
        {
            src: graph,
            alt: 'Affiliate Marketing',
            title: 'Affiliate Marketing',
            text: 'Expand your reach and increase sales through affiliate marketing programs that reward partners for driving traffic and conversions.'
        },
        {
            src: graph,
            alt: 'Inbound Marketing',
            title: 'Inbound Marketing',
            text: 'Attract, engage, and delight customers with inbound marketing strategies that turn strangers into loyal customers.'
        },
        {
            src: graph,
            alt: 'Amazon Marketing',
            title: 'Amazon Marketing',
            text: 'Optimize your presence on Amazon with tailored marketing strategies that increase visibility and drive sales on the platform.'
        },
        {
            src: graph,
            alt: 'Audio Marketing',
            title: 'Audio Marketing',
            text: 'XDMinds pioneers Audio Marketing by crafting compelling podcasts and voice advertisements that resonate with audiences, amplifying brand presence and engagement through innovative sound strategies.'
        },
        {
            src: graph,
            alt: 'Video Marketing',
            title: 'Video Marketing',
            text: 'We elevate brands with Video Marketing expertise, producing captivating content that drives conversions and builds connections, utilizing cinematic storytelling and cutting-edge production techniques.'
        },
        {
            src: graph,
            link: '/ai-marketing-agency',
            alt: 'AI Marketing',
            title: 'AI Marketing',
            text: 'Leverage artificial intelligence to create personalized marketing experiences, optimize campaigns, and improve decision-making processes.'
        },
        {
            src: graph,
            alt: 'AI Chatbot Marketing',
            title: 'AI Chatbot Marketing',
            text: 'We transform customer interactions with AI Chatbot Marketing, integrating intelligent bots that deliver personalized experiences, streamline customer support, and enhance brand loyalty through automated, responsive solutions.'
        },
        {
            src: graph,
            alt: 'Instant Message Marketing',
            title: 'Instant Message Marketing',
            text: 'We excel in instant message marketing, connecting brands with customers through targeted messaging campaigns on popular platforms and ensuring immediate engagement and impactful communication strategies.'
        },
        {
            src: graph,
            link: '/ar-vr-marketing-agency',
            alt: 'AR/VR Marketing Agency',
            title: 'AR/VR Marketing Agency',
            text: 'Immerse your audience in unforgettable experiences with our cutting-edge AR/VR marketing solutions. From concept to creation, we deliver virtual worlds that drive engagement and sales.'
        },
        {
            src: graph,
            alt: 'Conversion Rate Optimization (CRO)',
            title: 'Conversion Rate Optimization (CRO)',
            text: 'Improve your website’s performance by optimizing user experience and increasing conversion rates.'
        },
        {
            src: graph,
            link: '/online-reputation-management-agency',
            alt: 'Online Reputation Management (ORM)',
            title: 'Online Reputation Management (ORM)',
            text: 'Protect and enhance your brand’s online reputation through proactive reputation management strategies.'
        },
        {
            src: graph,
            alt: 'SMS Marketing',
            title: 'SMS Marketing',
            text: 'We excel in SMS Marketing, delivering targeted campaigns that leverage the immediacy of text messaging to reach and engage audiences effectively, driving conversions and customer loyalty through personalized communication.'
        },
        {
            src: graph,
            alt: 'Native Advertising',
            title: 'Native Advertising',
            text: 'We specialize in Native Advertising, seamlessly integrating branded content into the natural user experience across digital platforms, ensuring authentic engagement, and maximizing brand visibility in a non-disruptive manner.'
        },
        {
            src: graph,
            alt: 'Website Marketing',
            title: 'Website Marketing',
            text: 'We optimize Website Marketing strategies to enhance online presence and drive traffic, employing SEO, content marketing, and user-centric design to attract, convert, and retain visitors, ensuring measurable growth and business success.'
        },
        {
            src: graph,
            alt: 'Hyperlocal Marketing',
            title: 'Hyperlocal Marketing',
            text: 'Target customers in your immediate area with precision. Our hyperlocal marketing strategies enhance your local presence and drive foot traffic to your business.'
        },
        {
            src: graph,
            alt: 'Viral marketing',
            title: 'Viral marketing',
            text: 'Unleash the power of viral marketing with XDMinds, where innovative strategies ignite buzz and skyrocket your brand’s visibility.Our tailored campaigns create unstoppable momentum, capturing attention and driving exponential growth.'
        },
        {
            src: graph,
            alt: 'Meme Marketing',
            title: 'Meme Marketing',
            text: 'Discover the power of Meme Marketing with XDMinds, where we create viral content that resonates and engages your target audience. Amplify your brand’s online presence through humor, relatability, and creativity with our expert meme marketing strategies.'
        },
    ];

    const industries = [
        { name: 'Agriculture' }, { name: 'Automobiles' }, { link: '/b2b-marketing-agency', name: 'B2B' }, { link: '/b2c-marketing-agency', name: 'B2C' }, { name: 'Banking' }, { name: 'Crowd Funding' }, { name: 'Education' },
        { name: 'Electric Vehicle' }, { name: 'Electronics' }, { name: 'Energy' }, { name: 'FMCG' }, { name: 'FinTech' }, { name: 'Gaming' }, { name: 'Healthcare' },
        { name: 'Hospitality' }, { name: 'Information Technology' }, { name: 'Infrastructure' }, { name: 'Insurance' }, { name: 'Lifestyle' }, { name: 'Manufacturing' },
        { name: 'Media & Entertienment' }, { name: 'Oil & Gas' }, { name: 'Real Estate' }, { name: 'Retail' }, { link: '/saas-marketing-agency', name: 'SaaS' }, { name: 'Sports' },
        { name: 'Telecommunications' }, { name: 'Transportation & Logistics' }, { name: 'Travel & Tourism' }, { link: '/web3-marketing-services', name: 'Web3' },
    ]

    const faqData = [
        {
            question: "What types of businesses can benefit from XDMinds' digital marketing services?",
            answer: "XDMinds offers digital marketing solutions for a wide range of businesses, including startups, scale-ups, large-scale enterprises, and conglomerates. Our tailored strategies are designed to meet the unique needs of each business, regardless of size or industry. Whether you're looking to increase brand awareness, drive traffic, generate leads, or boost sales, our services can help you achieve your goals."
        },
        {
            question: "How do you determine the best digital marketing strategy for my business?",
            answer: "Our approach begins with a thorough understanding of your business, industry, and goals. We conduct comprehensive market research and competitor analysis to identify opportunities and challenges. Based on these insights, we develop a customized digital marketing strategy that aligns with your objectives and targets the right audience through the most effective channels."
        },
        {
            question: "How long does it take to see results from digital marketing?",
            answer: "The timeline for seeing results from digital marketing can vary depending on several factors, including the nature of your business, the competitive landscape, and the specific strategies implemented. Typically, SEO and content marketing efforts may take several months to show significant results, while PPC and social media advertising can deliver more immediate outcomes. We work closely with you to set realistic expectations and ensure continuous optimization for sustained success."
        },
        {
            question: "How does XDMinds measure the success of digital marketing campaigns?",
            answer: "At XDMinds, we measure the success of our digital marketing campaigns using Key Performance Indicators (KPIs) that align with your business objectives. These KPIs include website traffic, engagement rates, lead generation, conversion rates, and return on investment (ROI). We provide detailed analytics and reporting to give you clear insights into your campaign’s performance and make data-driven adjustments for continuous improvement."
        },
        {
            question: "How does XDMinds stay ahead of digital marketing trends?",
            answer: "XDMinds is committed to staying at the forefront of digital marketing by continuously monitoring industry trends and innovations. Our team regularly participates in professional development, industry conferences, and training sessions to ensure we are using the latest tools and techniques. We also leverage cutting-edge technology and data-driven insights to create innovative strategies that keep our clients ahead of the competition."
        },
        {
            question: "What makes XDMinds different from other digital marketing agencies?",
            answer: "XDMinds stands out for its client-centric approach, data-driven strategies, and commitment to measurable results. We offer a holistic range of digital marketing services tailored to your unique business needs. Our team of experts leverages cutting-edge technology and creative excellence to deliver innovative solutions. With a proven track record of success and a focus on long-term partnerships, we are dedicated to driving your business growth."
        },
        {
            question: "How do you ensure the quality and originality of your content?",
            answer: "Quality and originality are at the core of our content creation process. Our team of experienced content creators conducts thorough research to produce relevant, engaging, and unique content that aligns with your brand voice and objectives. We use advanced plagiarism detection tools to ensure all content is 100% original. Additionally, our content goes through multiple rounds of review and optimization to maintain the highest standards of quality."
        },
        {
            question: "Can XDMinds help with international digital marketing campaigns?",
            answer: "Yes, XDMinds has extensive experience in managing international digital marketing campaigns. We understand the complexities of targeting audiences across different regions and cultures and tailor our strategies to ensure relevance and effectiveness. Our global approach includes multilingual content creation, localized SEO, and region-specific advertising to help your business succeed on an international scale."
        },
        {
            question: "How does XDMinds customize digital marketing strategies for different clients?",
            answer: "XDMinds customize digital marketing strategies by first understanding your business, industry, and goals. We conduct thorough research and analysis to identify opportunities and challenges specific to your market. Based on these insights, we develop a bespoke strategy that includes the most effective channels, tactics, and tools for your unique needs. Our personalized approach ensures that every campaign is aligned with your objectives and delivers the best possible results."
        },
    ];

    const eventCardData = [
        {
            title: "Expertise Across Industries",
            sections: [
                {
                    subtitle: "Diverse Experience:",
                    content: "With extensive experience across various industries, XDMinds understands the unique challenges and opportunities in your market. Our team has successfully executed campaigns for startups, scale-ups, large enterprises, and conglomerates."
                },
                {
                    subtitle: "Specialized Knowledge:",
                    content: "Our expertise covers a wide range of digital marketing disciplines, including SEO, PPC, social media, content marketing, and more. This allows us to create comprehensive and effective strategies tailored to your business."
                }
            ]
        },
        {
            title: "Data-Driven Strategies",
            sections: [
                {
                    subtitle: "Analytical Approach:",
                    content: "At XDMinds, we believe in the power of data. Our strategies are based on thorough research and analytics, ensuring that every decision is backed by solid data and insights."
                },
                {
                    subtitle: "Continuous Optimization:",
                    content: "We continuously monitor and analyze the performance of your campaigns, making data-driven adjustments to maximize ROI and achieve your business objectives."
                }
            ]
        },
        {
            title: "Innovative Solutions",
            sections: [
                {
                    subtitle: "Cutting-Edge Technology:",
                    content: "We leverage the latest digital marketing tools and technologies to deliver innovative solutions that keep you ahead of the competition."
                },
                {
                    subtitle: "Creative Excellence:",
                    content: "Our team of creative professionals develops compelling and engaging content that captures your audience’s attention and drives engagement."
                }
            ]
        },
        {
            title: "Measurable Results",
            sections: [
                {
                    subtitle: "Key Performance Indicators (KPIs):",
                    content: "We set clear and measurable KPIs for every campaign, ensuring transparency and accountability. Our focus on metrics like traffic, engagement, conversions, and ROI means you can track the success of your marketing efforts."
                },
                {
                    subtitle: "Comprehensive Reporting:",
                    content: "We provide detailed analytics and reporting, giving you a clear understanding of your campaign’s performance and the impact on your business."
                }
            ]
        },
        {
            title: "Client-Centric Approach",
            sections: [
                {
                    subtitle: "Customized Strategies:",
                    content: "We understand that every business is unique. Our digital marketing strategies are customized to meet your specific goals, challenges, and market conditions."
                },
                {
                    subtitle: "Dedicated Support:",
                    content: "Our team is committed to your success. We offer continuous support, strategic advice, and proactive solutions to help you navigate the ever-changing digital landscape."
                }
            ]
        },
        {
            title: "KPI Focus",
            sections: [
                {
                    subtitle: "Website Traffic:",
                    content: "Increased visits to your website from organic and paid channels."
                },
                {
                    subtitle: "Engagement:",
                    content: "Higher engagement rates on social media platforms and website content."
                },
                {
                    subtitle: "Lead Generation:",
                    content: "Increased number of qualified leads generated through various marketing channels."
                },
                {
                    subtitle: "Conversion Rate:",
                    content: "Improved conversion rates, turning more visitors into customers."
                },
                {
                    subtitle: "Return on Investment (ROI):",
                    content: "Higher ROI from your digital marketing campaigns, ensuring you get the best value for your investment."
                }
            ]
        }
    ];

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Discovery and Research',
            sections: [
                {
                    subtitle: 'Understanding Your Business',
                    content: 'We begin by gaining a deep understanding of your business, industry, and goals. This involves thorough discussions with your team, analyzing your current digital presence, and identifying key opportunities and challenges.'
                },
                {
                    subtitle: 'Market Analysis',
                    content: 'Our experts conduct comprehensive market research to understand your competitive landscape, target audience, and emerging trends. This data forms the basis for creating a tailored digital marketing strategy.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Strategy Development',
            sections: [
                {
                    subtitle: 'Setting Objectives',
                    content: 'Clear, measurable objectives are essential for success. We work with you to define your digital marketing goals, whether they are increasing brand awareness, driving website traffic, generating leads, or boosting sales.'
                },
                {
                    subtitle: 'Crafting the Strategy',
                    content: 'Using insights from our research, we develop a bespoke digital marketing strategy. This includes selecting the right channels, tactics, and tools to achieve your objectives efficiently and effectively.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Implementation',
            sections: [
                {
                    subtitle: 'Content Creation',
                    content: 'Our team of creative professionals develops compelling and relevant content that resonates with your target audience. This includes blog posts, social media updates, videos, infographics, and more.'
                },
                {
                    subtitle: 'Campaign Execution',
                    content: 'We launch targeted campaigns across various digital channels such as search engines, social media, email, and display advertising. Our focus is on reaching the right audience at the right time with the right message.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Optimization and Monitoring',
            sections: [
                {
                    subtitle: 'Real-Time Tracking:',
                    content: 'Digital marketing is dynamic, and we continuously monitor the performance of your campaigns in real time. This allows us to make data-driven adjustments to improve results.'
                },
                {
                    subtitle: 'A/B Testing',
                    content: 'We implement A/B testing to identify the most effective strategies and optimize your campaigns for maximum impact.'
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Analysis and Reporting',
            sections: [
                {
                    subtitle: 'Detailed Analytics',
                    content: 'We provide comprehensive analytics and reporting to give you clear insights into your campaign performance. Our reports include key metrics such as traffic, engagement, conversions, and ROI.'
                },
                {
                    subtitle: 'Strategic Adjustments',
                    content: 'Based on the data, we make strategic adjustments to your campaigns, ensuring continuous improvement and sustained success.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Continuous Improvement',
            sections: [
                {
                    subtitle: 'Ongoing Support',
                    content: 'Digital marketing is an ongoing process, and we offer continuous support to adapt to changing market conditions, new technologies, and evolving business goals.'
                },
                {
                    subtitle: 'Innovative Solutions',
                    content: 'We stay ahead of the curve by embracing the latest digital marketing trends and technologies, ensuring that your business remains competitive and relevant.'
                }
            ]
        },
    ];

    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Results Driven <br className='break' /> <span className='s-text-3'>Digital</span> Marketing Agency
                </h1>
                <p className='p-text-1'>
                    At XDMinds, we empower businesses with our full-stack digital marketing solutions, designed to drive growth,
                    enhance online presence, and deliver measurable results. Whether you're a startup looking to establish
                    your brand or an enterprise aiming to scale new heights, our expert team leverages cutting-edge
                    strategies to achieve your goals.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    XDMinds: ROI-driven Digital Marketing Agency for <br className='break' /> Startups, Scale-Ups, Large-scale Enterprises, <br className='break' /> and Conglomerates
                </h2>
                <p className='p-text-2'>
                    XDMinds stands at the forefront of digital marketing innovation and is committed to delivering ROI-driven strategies
                    tailored for startups, scale-ups, large-scale enterprises, and conglomerates. Our holistic approach combines
                    creativity with data-driven insights to create compelling marketing campaigns that resonate with
                    your target audience. From increasing brand visibility to driving customer engagement and
                    conversions,we partner with you every step of the way to ensure your marketing
                    investment yields substantial returns. At XDMinds, our goal is to transform your digital presence into a powerful growth engine.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your digital marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/digital-marketing-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-3'>
                <h2 className='h-text-3'>
                    How does Digital Marketing drive measurable <br className='break' /> Business Growth?
                </h2>
                <div className='custom-div'>
                    <p className='p-text-3'>
                        Digital marketing harnesses the power of data and analytics to drive measurable business growth. By targeting the
                        right audience with personalized content, leveraging SEO and PPC for increased visibility, and utilizing
                        social media and email marketing to engage and convert customers, businesses can see clear
                        and quantifiable improvements in their performance. Digital marketing also allows for
                        real-time tracking and optimization, ensuring that marketing efforts are continually refined for maximum impact.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg d-center'>
                <h2 className='h-text-2 t-center'>
                    The Importance of Digital Marketing for <br className='break' /> Business Growth
                </h2>
                <div className='border-div position-relative'>
                    <p className='p-text-2 w-100 span-icon t-center'>
                        In today's digital-first world, effective digital marketing is essential for business growth.
                        It helps businesses reach a wider audience, build strong brand recognition, and engage with customers in meaningful ways.
                        Digital marketing enables companies to compete in the global marketplace, adapt to changing consumer behaviors,
                        and achieve higher ROI compared to traditional marketing methods. It is a key driver for generating leads, increasing sales, and fostering long-term customer loyalty.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-3'>
                <h2 className='h-text-3'>
                    Traditional vs. Digital: Which Delivers Better ROI?
                </h2>
                <div className='custom-div'>
                    <p className='p-text-3'>
                        While traditional marketing methods like print ads and TV commercials have their merits, digital marketing often delivers better ROI due to its precision targeting, cost-effectiveness, and measurable results. Digital marketing campaigns can be tailored to specific demographics and interests, allowing for more efficient use of marketing budgets. The ability to track and analyze performance in real time also means that digital strategies can be quickly adjusted for optimal outcomes, making it a more agile and adaptable approach to marketing.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Comprehensive Digital Marketing Services & <br className='break' /> Solutions for your Business Growth
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we offer comprehensive digital marketing solutions designed to fuel your business growth. Our services span all aspects of digital marketing, ensuring a cohesive and integrated approach to your marketing strategy. From boosting your search engine rankings to creating engaging social media content, our expert team is dedicated to driving results that matter. Let us help you navigate the digital landscape and achieve your business objectives with our tailored solutions.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-center'>
                <h2 className='h-text-2 t-center'>
                    Precision Digital Marketing Services <br className='break' /> for Every Industry
                </h2>
                <p className='p-text-2 t-center'>
                    At XDMinds, we tailor our digital marketing services to meet the unique needs of every industry. Our data-driven strategies ensure precision targeting and effective engagement, maximizing ROI across various sectors. From tech startups to established enterprises, we deliver customized solutions that drive success.
                </p>
                <div className='industry-div'>
                    {industries.map((industry, index) => (
                        industry.link ? (
                            <Link reloadDocument className='industry-btn c-pointer' to={industry.link} key={index}>
                                {industry.name}
                            </Link>
                        ) : (
                            <div className='industry-btn c-default' key={index}>
                                {industry.name}
                            </div>
                        )
                    ))}
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg d-center'>
                <h2 className='h-text-2 t-center'>
                    Decoding Digital Success: The XDMinds’ <br className='break' /> Digital Marketing Methodology
                </h2>
                <p className='p-text-2 t-center'>
                    At XDMinds, our proven methodology for digital success is built on a foundation of innovation, data-driven insights, and client-centric strategies. Here’s a closer look at how we deliver exceptional results for our clients:
                </p>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-center'>
                <h2 className='h-text-2 t-center'>
                    Why Choose XDMinds as Your Digital Marketing Partner?
                </h2>
                <p className='p-text-2 t-center'>
                    Choosing the right digital marketing partner is crucial for achieving your business goals and driving growth. Here’s why XDMinds stands out as the ideal choice for your digital marketing needs:
                </p>
                <div className='event-card-div'>
                    <EventCard eventCardData={eventCardData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest Digital Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default DigitalMarketing
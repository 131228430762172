import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: " What makes XDMinds' approach to DAO marketing different from other agencies?",
    answer: `At XDMinds, we tailor our DAO marketing strategies to the unique needs of each project, ensuring a personalized approach that aligns with your goals. Our expertise in the decentralized space, combined with innovative tactics and data-driven insights, sets us apart from generic marketing solutions.`
  },
  {
    question: "How can XDMinds help my DAO project gain traction in a competitive market?",
    answer: `XDMinds leverages advanced techniques and a deep understanding of the DAO ecosystem to enhance your project's visibility and engagement. By utilizing targeted outreach, strategic partnerships, and community building, we drive meaningful interactions and growth for your DAO.`
  },
  {
    question: "What kind of results can I expect from XDMinds' DAO marketing services?",
    answer: `With XDMinds, you can expect measurable improvements in key performance indicators such as community engagement, user acquisition, and overall project awareness. Our focus on data-driven strategies ensures that we provide clear, actionable results that contribute to your DAO’s success.`
  },
  {
    question: "How does XDMinds ensure transparency and communication throughout the DAO marketing process?",
    answer: `We prioritize open and transparent communication with our clients by providing regular updates, detailed reports, and ongoing consultations. This approach ensures you’re always informed about the progress of your DAO marketing efforts and can make informed decisions.`
  },
  {
    question: " Can XDMinds assist in building a strong community for my DAO?",
    answer: `Absolutely. XDMinds specializes in community building by fostering genuine interactions and creating engaging content that resonates with your target audience. Our strategies are designed to cultivate a loyal and active community that supports and advocates for your DAO.`
  },

];

const DAOFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);
  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='blockchain-sec-6-faq'>
      <div className='faq'>
        <Container>
          <Row>
            <Col>
              <div>
                <h2>Frequently Asked Question
                </h2>
              </div>
              <div className='blockchain-faq-qa'>
                {faqData.map((faq, index) => (
                  <div key={index}>
                    <div
                      onClick={() => handleFAQClick(index)}
                      aria-controls={`faq${index}`}
                      aria-expanded={openFAQ === index}
                      className='faq-1st'
                    >
                      <div className='faq-content'>
                        <h6 className='faq-content-question'>{faq.question}</h6>
                      </div>
                      <div className='faq-icons'>
                        {openFAQ === index ? (
                          <IoIosArrowDropupCircle className='close-circle-faq' />
                        ) : (
                          <IoIosArrowDropdownCircle className='plus-circle-faq' />
                        )}
                      </div>
                    </div>
                    <Collapse in={openFAQ === index}>
                      <div id={`faq${index}`} className='faq-answer-content'>
                        {renderAnswer(faq.answer)}
                      </div>
                    </Collapse>
                  </div>
                ))}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default DAOFAQ
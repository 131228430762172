import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';

const Section3 = () => {

    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Marketing Strategy Creation',
            text: 'Develop a customized marketing plan tailored to your NFT project, ensuring a strategic approach to reach your target audience.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Website Optimization',
            text: 'Enhance your NFT website for better user experience and SEO performance, driving more traffic and engagement.',
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'NFT SEO',
            text: "Implement effective search engine optimization strategies to increase your NFT project's visibility on search engines.",
        },
        {
            link: "/ppc-advertising-agency",
            imgSrc: <FaChartLine />,
            title: 'NFT PPC Advertising',
            text: 'Leverage pay-per-click advertising campaigns to drive targeted traffic and boost your NFT sales.',
        },
        {
            link: "/social-media-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'NFT Social Media Marketing',
            text: 'Utilize social media platforms to create buzz, engage with potential buyers, and build a strong online presence for your NFT project.',
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'NFT Influencer Marketing',
            text: 'Partner with influencers to promote your NFTs, tapping into their follower base to reach a wider audience.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Email Marketing',
            text: 'Engage your audience through targeted email campaigns, keeping them informed and interested in your NFT offerings.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT PR Marketing',
            text: 'Gain media coverage and public relations support to build credibility and increase exposure for your NFT project.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Community Marketing',
            text: 'Build and nurture a community around your NFT project, fostering engagement and loyalty among your audience.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Marketplace Marketing',
            text: 'Optimize your presence on NFT marketplaces to ensure your project stands out and attracts potential buyers.',
        },
        {
            link: "/gaming-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'NFT Gaming Marketing',
            text: 'Promote NFT-based games, driving player engagement and increasing in-game asset sales.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Sports Marketing',
            text: 'Leverage the popularity of sports to market NFTs related to athletes, teams, and events, creating a strong fan base.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Virtual Land Marketing',
            text: 'Market virtual real estate NFTs to attract investors and enthusiasts interested in digital land ownership.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Collection Marketing',
            text: 'Promote entire NFT collections to showcase the breadth and depth of your offerings, attracting collectors and investors.',
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'NFT Content Marketing',
            text: 'Create compelling content that educates, entertains, and engages your audience, driving interest in your NFTs.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Telegram Marketing',
            text: "Utilize Telegram groups and channels to build a community and engage with potential buyers in real-time.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Discord Marketing',
            text: "Leverage Discord servers to create a vibrant community around your NFT project, fostering interaction and loyalty.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Video Marketing',
            text: "Create engaging video content to showcase your NFTs, explain their value, and attract potential buyers.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'NFT Lead Generation',
            text: "Implement strategies to capture and nurture leads, converting interested parties into loyal buyers.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Omnichannel Marketing',
            text: "Integrate multiple marketing channels to create a cohesive and effective marketing strategy for your NFT project.",
        },
    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (

        <div className='blockchain-sec-4'>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h2>Maximize Your NFT Project's Impact and ROI <span className='msec4-break'><br />
                            </span>XDMinds Comprehensive NFT Marketing Services
                            </h2>
                        </div>
                        <div className='industries-sub-head'>
                            <p>
                                Effortless NFT marketing success starts here. Our team of experts unlocks the full potential of your project with proven strategies that maximize impact.
                            </p>
                        </div>

                        <div className='desktop-industries-view'>
                            {chunkedCardData.map((chunk, index) => (
                                <div className='home-industries-card-div' key={index}>
                                    <Row>
                                        {chunk.map((card, cardIndex) => (
                                            <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                {card.link ? (
                                                    <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Link>
                                                ) : (
                                                    <div className='c-default h-100'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                        </div>



                        <div className='mobile-view-industries'>
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={3}
                                loop={true}
                                onSwiper={(swiper) => setCardSwiper(swiper)}
                                modules={[Scrollbar, Pagination, Autoplay]}
                                autoplay={{
                                    delay:5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}
                                className="ps-swiper"
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    460: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 6,
                                        spaceBetween: 50,
                                    },
                                }}
                            >
                                {cardData.map((card, index) => (
                                    <SwiperSlide key={index}>
                                        {card.link ? (
                                            <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        ) : (
                                            <div className='c-default h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Section3
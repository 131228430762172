import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What makes XDMinds different from other dApp marketing agencies?",
    answer: `XDMinds stands out due to our proven track record, expert team with deep industry knowledge, and customized, data-driven marketing strategies. We offer comprehensive multi-channel marketing services and focus heavily on community building and transparent reporting.`
  },
  {
    question: "What services does XDMinds offer for dApp marketing?",
    answer: `XDMinds offers a wide range of dApp marketing services, including strategy creation, branding, product marketing, SEO, PPC advertising, social media marketing, influencer marketing, email marketing, PR marketing, community marketing, gaming marketing, content marketing, Telegram and Discord marketing, video marketing, lead generation, omnichannel marketing, and whitepaper/Litepaper creation.`
  },
  {
    question: "How does XDMinds create a dApp marketing strategy?",
    answer: `We start with an initial consultation to understand your project and goals. Then, we conduct market research and competitor analysis to gather insights. Based on this information, we develop a customized, comprehensive marketing strategy tailored to your dApp’s specific needs and objectives.`
  },
  {
    question: "What is the importance of community building in dApp marketing?",
    answer: `Community building is crucial for the success of any dApp project. It fosters user loyalty, engagement, and advocacy. A strong community provides valuable feedback, supports the project, and helps spread the word, contributing to the overall growth and success of the dApp.`
  },
  {
    question: "How does XDMinds measure the success of a dApp marketing campaign?",
    answer: `We use advanced analytics tools to track key performance indicators (KPIs) such as user acquisition, engagement, conversion rates, and ROI.We provide regular, detailed reports to keep you informed about the performance of our marketing efforts and make data-driven adjustments to optimize results.`
  },
  {
    question: "What is the process for engaging influencers to promote my dApp?",
    answer: `We identify and partner with relevant influencers who align with your dApp’s target audience. Our team manages the entire process, from negotiating terms to creating promotional content and tracking the results of influencer campaigns to ensure maximum impact and engagement.`
  },
  {
    question: "How does XDMinds ensure the security and privacy of my dApp project during marketing?",
    answer: `Security and privacy are top priorities for XDMinds. We use secure communication channels and adhere to strict confidentiality agreements. Our team is trained to handle sensitive information responsibly, ensuring your project’s data is protected throughout the marketing process.`
  },
  {
    question: "How quickly can I expect to see results from XDMinds’ marketing efforts?",
    answer: `The timeline for seeing results can vary depending on the specific goals and strategies implemented. Typically, you can expect to see initial improvements within the first few months, with more significant and sustained growth developing over a longer period as our strategies take effect.`
  },
  {
    question: "How can I get started with XDMinds’ dApp marketing services?",
    answer: `Getting started is easy. Contact us to schedule an initial consultation, where we’ll discuss your project, goals, and specific needs. From there, we’ll develop a tailored marketing strategy and begin implementing our comprehensive services to help your dApp achieve its full potential.`
  },

];

const DappsFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='ai-faq'>
      <div className='blockchain-sec-6-faq'>
        <div className='faq'>
          <Container>
            <Row>
              <Col>
                <div>
                  <h2>Frequently Asked Questions
                  </h2>
                </div>
                <div className='blockchain-faq-qa'>
                  {faqData.map((faq, index) => (
                    <div key={index}>
                      <div
                        onClick={() => handleFAQClick(index)}
                        aria-controls={`faq${index}`}
                        aria-expanded={openFAQ === index}
                        className='faq-1st'
                      >
                        <div className='faq-content'>
                          <h6 className='faq-content-question'>{faq.question}</h6>
                        </div>
                        <div className='faq-icons'>
                          {openFAQ === index ? (
                            <IoIosArrowDropupCircle className='close-circle-faq' />
                          ) : (
                            <IoIosArrowDropdownCircle className='plus-circle-faq' />
                          )}
                        </div>
                      </div>
                      <Collapse in={openFAQ === index}>
                        <div id={`faq${index}`} className='faq-answer-content'>
                          {renderAnswer(faq.answer)}
                        </div>
                      </Collapse>
                    </div>
                  ))}

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default DappsFAQ
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';

const WebSection3 = () => {
    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Go-to-Market Strategy',
            text: "Develop a robust go-to-market plan tailored to your Web3 project, ensuring a successful launch and sustained growth in the decentralized market.",
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'Web3 SEO',
            text: "Optimize your Web3 website for search engines with our specialized SEO strategies, enhancing your online visibility and driving organic traffic.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Paid Ads',
            text: "Leverage targeted paid advertising campaigns to reach your ideal audience and maximize your ROI in the Web3 space.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Social Media Ads',
            text: "Engage and convert your audience with effective social media advertising across platforms like Twitter, LinkedIn, and Instagram.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Inbound Marketing',
            text: 'Attract, engage, and delight customers with our comprehensive inbound marketing strategies tailored to the Web3 environment.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Community Marketing',
            text: "Build and nurture a loyal community around your Web3 project with our community marketing services, fostering engagement and advocacy.",
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Web3 Influencer Marketing',
            text: " Partner with key influencers in the Web3 space to amplify your brand's reach and credibility through authentic endorsements.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Display Ads',
            text: ' Enhance your online presence with visually appealing display ads that capture attention and drive engagement in the Web3 ecosystem.'
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 PR Marketing',
            text: "Boost your brand's reputation and visibility with strategic PR campaigns designed to generate buzz and media coverage for your Web3 project.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Video Advertising',
            text: ' Harness the power of video content to tell your story and engage your audience with compelling video advertising campaigns.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Telegram And Discord Marketing',
            text: 'Leverage popular messaging platforms like Telegram and Discord to build and engage with your Web3 community.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Email Marketing',
            text: "Reach your audience directly with targeted email campaigns that drive engagement and conversions for your Web3 project.",
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Web3 Content Marketing',
            text: 'Create and distribute high-quality content that educates, informs, and engages your audience, driving traffic and building authority in the Web3 space.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Reputation Management',
            text: 'Protect and enhance your online reputation with our proactive reputation management services, ensuring positive perceptions of your Web3 brand.',
        },
        {
            link: "/metaverse-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Metaverse Marketing',
            text: "Tap into the potential of the metaverse with marketing strategies designed to engage users in virtual worlds and immersive experiences.",
        },
        {
            link: "/blockchain-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Blockchain Marketing',
            text: "Promote your blockchain solutions with targeted marketing campaigns that highlight the unique benefits and innovations of your technology.",
        },
        {
            link: "/dao-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'DAO Marketing',
            text: "Drive engagement and participation in your decentralized autonomous organization (DAO) with tailored marketing strategies.",
        },
        {
            link: "/nft-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'NFT Marketing',
            text: "Boost your NFT project’s visibility and sales with specialized marketing tactics that appeal to collectors and enthusiasts in the NFT community.",
        },
        {
            link: "/gaming-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Gaming Marketing',
            text: "Elevate your Web3 gaming project with comprehensive marketing services that attract players and build a thriving gaming community.",
        },
        {
            link: "/defi-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'DeFi Marketing',
            text: "Promote your decentralized finance (DeFi) solutions with strategies that highlight your unique value proposition and drive user adoption.",
        },
        {
            link: "/dapp-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'dApps Marketing',
            text: "Increase the visibility and usage of your decentralized applications (dApps) with targeted marketing campaigns that reach your ideal audience.",
        },
        {
            link: "/crypto-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Crypto Marketing',
            text: "Enhance your cryptocurrency project’s reach and credibility with marketing strategies designed to attract investors and users.",
        },
        {
            link: "/ico-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'ICO Marketing',
            text: "Ensure a successful initial coin offering (ICO) with strategic marketing campaigns that generate buzz and attract investors.",
        },
        {
            link: "/sto-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'STO Marketing',
            text: "Promote your security token offering (STO) with comprehensive marketing services that highlight the benefits and opportunities of your tokens.",
        },
        {
            link: "/ido-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'IDO Marketing',
            text: "Drive participation in your initial decentralized exchange offering (IDO) with targeted marketing strategies that reach potential investors.",
        },
        {
            link: "/ieo-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'IEO Marketing',
            text: "Maximize the success of your initial exchange offering (IEO) with marketing campaigns designed to attract and engage investors.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Bounty And Airdrop Campaigns',
            text: " Boost engagement and reward your community with well-planned bounty and airdrop campaigns that drive awareness and participation.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Marketing Consulting',
            text: "We offer expert Web3 marketing consulting to drive your project's growth and impact in the decentralized world.",
        },


    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (
        <div>
            <div className='blockchain-sec-4'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>Your Full-Spectrum Web3 Marketing Partner,Delivering <span className='msec4-break'><br />
                                </span>End-to-End Web3 Marketing Services and Solutions

                                </h2>
                            </div>
                            <div className='industries-sub-head'>
                                <p>
                                    XDMinds is your ultimate partner in the Web3 marketing journey, offering a full spectrum of services designed to deliver end-to-end solutions. From the initial concept to execution, our holistic approach ensures that every aspect of your marketing strategy is seamlessly integrated and aligned with your business goals. Our team of experts specializes in creating bespoke strategies that encompass everything from Web3 SEO and paid ads to community engagement and influencer partnerships. With XDMinds, you gain access to a comprehensive suite of services that drive visibility, engagement, and growth for your Web3 project. Trust us to be your guiding force in the decentralized digital world.

                                </p>
                            </div>

                            <div className='desktop-industries-view'>
                                {chunkedCardData.map((chunk, index) => (
                                    <div className='home-industries-card-div' key={index}>
                                        <Row>
                                            {chunk.map((card, cardIndex) => (
                                                <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                    {card.link ? (
                                                        <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Link>
                                                    ) : (
                                                        <div className='c-default h-100'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                            </div>



                            <div className='mobile-view-industries'>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop={true}
                                    dots="true"
                                    onSwiper={(swiper) => setCardSwiper(swiper)}
                                    modules={[Scrollbar, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{ clickable: true }}
                                    className="ps-swiper"
                                    breakpoints={{
                                        0: { slidesPerView: 1, spaceBetween: 10 },
                                        460: { slidesPerView: 1, spaceBetween: 5 },
                                        768: { slidesPerView: 1, spaceBetween: 30 },
                                        1024: { slidesPerView: 6, spaceBetween: 50 },
                                    }}
                                >
                                    {cardData.map((card, index) => (
                                        <SwiperSlide key={index}>
                                            {card.link ? (
                                                <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            ) : (
                                                <div className='c-default h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default WebSection3
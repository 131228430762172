import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import GridCard from './GridCard';
import graph from '../../assets/images/graph.svg';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import Tabs from './Tabs';
import BreadCrumbs from '../../Components/BreadCrumbs';
import { Link } from 'react-router-dom';


const MobileAppMarketing = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const cardData = [
        {
            src: graph,
            alt: 'App Pre & Post Launch Marketing Strategy',
            title: 'App Pre & Post Launch Marketing Strategy',
            text: 'We create robust marketing strategies that cater to both pre-launch excitement and post-launch sustainability, ensuring your app garners attention and maintains user interest.'
        },
        {
            src: graph,
            alt: 'App Pre & Post Launch Campaigns',
            title: 'App Pre & Post Launch Campaigns',
            text: 'Our team develops compelling marketing campaigns that target the right audience, leveraging various channels to maximize reach and impact.'
        },
        {
            src: graph,
            alt: 'App SEO',
            title: 'App SEO',
            text: 'Enhance your app’s discoverability with our specialized app SEO services, driving organic traffic and improving search rankings.'
        },
        {
            src: graph,
            alt: 'App Store Optimization (ASO)',
            title: 'App Store Optimization (ASO)',
            text: 'Optimize your app’s presence on both Google Play and Apple App Store with our ASO services, improving visibility and download rates.'
        },
        {
            src: graph,
            alt: 'Google Play Store Optimization',
            title: 'Google Play Store Optimization',
            text: 'Tailor your app’s listing for maximum exposure and conversion on the Google Play Store, attracting more users and boosting downloads.'
        },
        {
            src: graph,
            alt: 'Apple App Store Optimization',
            title: 'Apple App Store Optimization',
            text: 'Enhance your app’s visibility on the Apple App Store with our expert optimization techniques, driving more downloads and engagement.'
        },
        {
            src: graph,
            alt: 'App Content Marketing',
            title: 'App Content Marketing',
            text: 'Create valuable and engaging content that resonates with your target audience, driving organic growth and fostering user loyalty.'
        },
        {
            src: graph,
            alt: 'Mobile App Pay-Per-Click Marketing',
            title: 'Mobile App Pay-Per-Click Marketing',
            text: 'Increase your app’s visibility and downloads with targeted pay- per - click campaigns, driving immediate and measurable results.'
        },
        {
            src: graph,
            alt: 'App Google Ads',
            title: 'App Google Ads',
            text: 'Leverage the power of Google Ads to reach potential users, drive app installs, and increase engagement through strategic ad placements.'
        },
        {
            src: graph,
            alt: 'App Social Media Paid Advertising',
            title: 'App Social Media Paid Advertising',
            text: 'Utilize social media platforms to advertise your app, targeting specific demographics and increasing downloads through paid campaigns.'
        },
        {
            src: graph,
            alt: 'App Social Media Management',
            title: 'App Social Media Management',
            text: 'Manage and grow your app’s social media presence with our comprehensive social media management services, fostering community engagement and loyalty.'
        },
        {
            src: graph,
            alt: 'App YouTube Advertising',
            title: 'App YouTube Advertising',
            text: 'Reach a wider audience with targeted YouTube ads, showcasing your app’s features and benefits through engaging video content.'
        },
        {
            src: graph,
            alt: 'App Media & PR Marketing',
            title: 'App Media & PR Marketing',
            text: 'Build a strong brand presence with our media and PR marketing services, generating buzz and increasing app visibility through strategic outreach.'
        },
        {
            src: graph,
            alt: 'Monetizing Your App',
            title: 'Monetizing Your App',
            text: 'Maximize your app’s revenue potential with our monetization strategies, turning user engagement into profitable returns.'
        },
        {
            src: graph,
            alt: 'Conversion Rate Optimization',
            title: 'Conversion Rate Optimization',
            text: 'Optimize your app’s conversion rates with data - driven strategies that enhance user experience and increase download- to - install ratios.'
        },
        {
            src: graph,
            alt: 'App Reputation Management',
            title: 'App Reputation Management',
            text: 'Protect and enhance your app’s reputation with our reputation management services, ensuring positive user feedback and addressing any concerns promptly.'
        },
        {
            src: graph,
            alt: 'App Social Content Calendar Development & Posting',
            title: 'App Social Content Calendar Development & Posting',
            text: 'Develop and manage a strategic social content calendar that aligns with your marketing goals, ensuring consistent and engaging content.'
        },
        {
            src: graph,
            alt: 'Mobile App Growth Consulting',
            title: 'Mobile App Growth Consulting',
            text: 'Benefit from our expert consulting services, guiding your app’s growth strategy and providing actionable insights for sustained success.'
        },
    ]

    const industries = [
        { name: 'Agriculture' }, { name: 'Automobiles' }, { link: '/b2b-marketing-agency', name: 'B2B' }, { link: '/b2c-marketing-agency', name: 'B2C' }, { name: 'Banking' }, { name: 'Crowd Funding' }, { name: 'Education' },
        { name: 'Electric Vehicle' }, { name: 'Electronics' }, { name: 'Energy' }, { name: 'FMCG' }, { name: 'FinTech' }, { name: 'Gaming' }, { name: 'Healthcare' },
        { name: 'Hospitality' }, { name: 'Information Technology' }, { name: 'Infrastructure' }, { name: 'Insurance' }, { name: 'Lifestyle' }, { name: 'Manufacturing' },
        { name: 'Media & Entertienment' }, { name: 'Oil & Gas' }, { name: 'Real Estate' }, { name: 'Retail' }, { link: '/saas-marketing-agency', name: 'SaaS' }, { name: 'Sports' },
        { name: 'Telecommunications' }, { name: 'Transportation & Logistics' }, { name: 'Travel & Tourism' }, { link: '/web3-marketing-services', name: 'Web3' },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Expertise Across the Mobile App Ecosystem',
            sections: [
                {
                    content: `At XDMinds, we have a deep understanding of the mobile app landscape. Our team of seasoned professionals brings extensive experience in crafting and executing marketing strategies tailored to both pre-launch and post-launch phases. This expertise ensures your app gets the attention it deserves, from initial development through to market success.`,
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Customer-Centric Service',
            sections: [
                {
                    content: `Your success is our top priority. We provide personalized support and maintain open communication throughout our partnership, ensuring that your needs are met and expectations are exceeded.`,
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Data-Driven Strategies',
            sections: [
                {
                    content: `We leverage data-driven insights to inform every decision, from campaign creation to performance evaluation. Our approach ensures that your marketing efforts are optimized for maximum impact, driving downloads and engagement based on real user behavior and market trends.`,
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Expertise and Experience',
            sections: [
                {
                    content: `With years of experience in the mobile app marketing industry, we bring a wealth of knowledge and expertise to the table. Our team stays ahead of industry trends and best practices to deliver cutting-edge solutions.`,
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Comprehensive Marketing Approach',
            sections: [
                {
                    content: `From pre-launch buzz to post-launch growth, we offer a full spectrum of marketing services. Our end-to-end approach covers everything from app SEO and ASO to social media management and paid advertising, providing a cohesive and effective marketing solution.`,
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Tailored Strategies for Optimal Growth',
            sections: [
                {
                    content: `We recognize that each app is unique, and so are the challenges it faces. Our team designs customized marketing strategies that align with your app’s goals, target audience, and market position. This personalized approach helps in achieving the best possible results.`,
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Cutting-Edge Tools and Techniques',
            sections: [
                {
                    content: `Staying ahead of industry trends is crucial for success. XDMinds utilizes the latest tools and techniques to keep your app competitive. From advanced analytics to innovative marketing tactics, we ensure your app benefits from the most effective strategies available.`,
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Focused Growth',
            sections: [
                {
                    content: `We prioritize your app’s growth by implementing strategies that not only boost downloads but also enhance user retention and engagement. Our goal is to drive sustainable success and long-term value for your app.`,
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Dedicated Support and Collaboration',
            sections: [
                {
                    content: `At XDMinds, we believe in building strong partnerships with our clients. Our dedicated team offers ongoing support and transparent communication, ensuring that you are always informed and involved in the marketing process.`,
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Focus on Long-Term Success',
            sections: [
                {
                    content: `We are committed to not just short-term gains but long-term success. Our strategies are designed to build sustainable growth and foster ongoing user engagement, helping your app thrive in the competitive mobile marketplace.`,
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What is mobile app marketing, and why is it important?",
            answer: "Mobile app marketing involves a range of strategies and tactics designed to increase the visibility of your app, drive downloads, and boost user engagement. It is crucial because it helps your app stand out in a crowded marketplace, attract the right audience, and achieve sustained growth. Effective mobile app marketing ensures that your app reaches potential users, converts them into loyal customers, and maximizes its market potential."
        },
        {
            cshow: 'cshow',
            question: "What services does XDMinds offer for mobile app marketing?",
            answer: "XDMinds offers a comprehensive suite of mobile app marketing services, including:",
            list1: "App Pre & Post Launch Strategy & Planning: Tailored strategies for both pre-launch and post-launch phases.",
            list2: "App SEO & ASO: Optimization of app visibility and search rankings on app stores.",
            list3: "Content Marketing: Creating engaging content to attract and retain users.",
            list4: "Paid Advertising: Managing Google Ads, social media ads, and YouTube advertising.",
            list5: "Social Media Management: Developing and executing social media strategies.",
            list6: "Media & PR Marketing: Generating media coverage and public relations efforts.",
            list7: "Monetization Strategies: Implementing methods to maximize app revenue.",
            list8: "Conversion Rate Optimization: Enhancing user experience to improve conversion rates.",
            list9: "Reputation Management: Managing and improving app reputation and user feedback.",
        },
        {
            question: "How does XDMinds approach app store optimization (ASO)?",
            answer: "Our ASO approach involves optimizing your app's title, description, keywords, and visuals to improve its ranking in app store search results. We conduct thorough keyword research, create compelling app descriptions, and design eye-catching icons and screenshots to attract users and drive downloads."
        },
        {
            question: "What is the difference between pre-launch and post-launch marketing?",
            answer: "Pre-launch marketing focuses on generating buzz and building anticipation before your app is released. This includes creating a launch strategy, running teaser campaigns, and building a user base. Post-launch marketing, on the other hand, aims to sustain momentum by optimizing performance, engaging users, and driving ongoing growth through continuous marketing efforts."
        },
        {
            question: "How does XDMinds use data and analytics in mobile app marketing?",
            answer: "We use data and analytics to track the performance of your marketing campaigns, measure user behavior, and assess app engagement. This data-driven approach allows us to make informed decisions, optimize strategies, and ensure that marketing efforts are aligned with your app's goals and target audience."
        },
        {
            question: "Can XDMinds help with both Android and iOS app marketing?",
            answer: "Yes, XDMinds provides marketing services for both Android and iOS apps. We tailor our strategies to the specific requirements of each platform, including optimization for the Google Play Store and Apple App Store, to ensure maximum visibility and effectiveness."
        },
        {
            question: "How does XDMinds handle app content marketing?",
            answer: "Our content marketing services involve creating and distributing valuable content that resonates with your target audience. This includes blog posts, articles, press releases, social media content, and other forms of media designed to engage users, drive traffic, and promote your app's unique features and benefits."
        },
        {
            question: "What is involved in XDMinds' app monetization strategies?",
            answer: "Our app monetization strategies focus on identifying and implementing revenue-generating opportunities, such as in-app purchases, subscription models, and advertising. We analyze your app’s usage patterns and market trends to develop a monetization plan that maximizes profitability while maintaining a positive user experience."
        },
        {
            question: "How does XDMinds manage app reputation?",
            answer: "We actively monitor and manage your app’s reputation by addressing user feedback, responding to reviews, and implementing strategies to improve overall ratings. Our goal is to ensure that your app maintains a positive image and builds trust with users."
        },
        {
            question: "What should I expect during the onboarding process with XDMinds?",
            answer: "During the onboarding process, we will conduct a thorough assessment of your app, define your marketing goals, and develop a customized strategy. You can expect clear communication, detailed planning, and ongoing support as we implement and refine our marketing efforts to achieve your app's objectives."
        },
    ]
    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Customer-Centric <br className='break' /> <span className='s-text-3'>Mobile App</span> Marketing Agency
                </h1>
                <p className='p-text-1'>
                    Unlock the true potential of your mobile app with XDMinds, a customer-centric mobile app marketing agency dedicated to driving exponential growth. Our tailored strategies and innovative marketing techniques ensure your app reaches the right audience, boosts downloads, and fosters lasting user engagement. Partner with us to transform your mobile app into a market leader.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Industry-Leading Mobile App Marketing Agency <br className='break' /> Boost Your App Downloads, Engagement, and User Acquisition
                </h2>
                <div className='border-div position-relative'>
                    <p className='p-text-2 w-100 span-icon'>
                        At XDMinds, we pride ourselves on being at the forefront of mobile app marketing, committed to boosting your app's downloads and user engagement. Our industry-leading expertise and comprehensive marketing solutions are designed to propel your app to new heights. From strategic planning to execution, we provide end-to-end services that encompass every aspect of mobile app marketing. Our dedicated team of professionals leverages data-driven insights, cutting-edge tools, and creative approaches to ensure your app stands out in a crowded marketplace. With XDMinds, your app is not just another application; it becomes a valuable tool that resonates with users, drives growth, and achieves measurable success.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Driving Your Mobile App Success <br className='break' /> Pre & Post-Launch App Marketing Services for Optimal Growth
                </h2>
                <p className='p-text-2'>
                    Navigating the competitive landscape of mobile apps requires a strategic approach from pre-launch to post-launch. At XDMinds, we offer comprehensive app marketing services that ensure your app's success at every stage. Our expert team crafts tailored marketing strategies that drive optimal growth and maximize your app's potential. From initial planning to post-launch analysis, we provide end-to-end services designed to enhance visibility, increase downloads, and boost user engagement. With XDMinds, you can be confident that your app will achieve sustained growth and long-term success.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Tailored End-To-End Mobile App Marketing Solutions <br className='break' /> for Every Industry
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we specialize in delivering tailored end-to-end mobile app marketing solutions designed to meet the unique needs of every industry. Our comprehensive approach ensures that your app not only stands out but also thrives in its specific market.
                </p>
                <div className='industry-div'>
                    {industries.map((industry, index) => (
                        industry.link ? (
                            <Link reloadDocument className='industry-btn c-pointer' to={industry.link} key={index}>
                                {industry.name}
                            </Link>
                        ) : (
                            <div className='industry-btn c-default' key={index}>
                                {industry.name}
                            </div>
                        )
                    ))}
                </div>
            </section>
            <section className='digital-marketing-section-2 l-bg'>
                <h2 className='h-text-2'>
                    Why Choose XDMinds as Your Trusted <br className='break' /> Mobile App Marketing Partner?
                </h2>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest Mobile App Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default MobileAppMarketing
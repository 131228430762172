import React from 'react'
import GameFIBanner from '../../Components/Services/GameFi/GameFIBanner'
import GameFiSec1 from '../../Components/Services/GameFi/GameFiSec1'
import GameFiSec2 from '../../Components/Services/GameFi/GameFiSec2'
import GameFiSec3 from '../../Components/Services/GameFi/GameFiSec3'
import Latest from '../../Components/Home/Latest/Latest'
import GameFiFAQ from '../../Components/Services/GameFi/GameFiFAQ'
import WorkCountry from '../../Components/Home/WorkCountry/WorkCountry'
import BookFrom from '../../Components/Home/BookForm/BookFrom'

const GameFiPage = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <GameFIBanner countryCode={countryCode} />
      <GameFiSec1 />
      <GameFiSec2 />
      <GameFiSec3 />
      <Latest modifiedPath='Latest GameFi Marketing Insights' />
      <GameFiFAQ />
      <WorkCountry />
      <BookFrom />

    </div>
  )
}

export default GameFiPage
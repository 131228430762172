import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import graph from '../../assets/images/graph.svg';
import GridCard from './GridCard';
import EventCard from './EventCard';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import BreadCrumbs from '../../Components/BreadCrumbs';



const AsoMarketing = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const cardData = [
        {
            src: graph,
            alt: 'ASO Go-to-Market Strategy',
            title: 'ASO Go-to-Market Strategy',
            text: 'Our ASO go-to-market strategy ensures your app launches with maximum impact. We develop a tailored plan to position your app effectively in the market, leveraging competitive insights and strategic planning for a successful debut.'
        },
        {
            src: graph,
            alt: 'App Indexing',
            title: 'App Indexing',
            text: 'Improve your app’s discoverability with our app indexing services. We ensure your app is properly indexed by app stores and search engines, enhancing its visibility and making it easier for users to find.'
        },
        {
            src: graph,
            alt: 'App Keyword Research and Optimization',
            title: 'App Keyword Research and Optimization',
            text: 'Identify and integrate high-impact keywords with our comprehensive research and optimization services. We analyze search trends to optimize your app’s metadata, increasing its chances of appearing in relevant searches.'
        },
        {
            src: graph,
            alt: 'App Competitor Analysis',
            title: 'App Competitor Analysis',
            text: "Stay ahead of the competition with our detailed app competitor analysis. We evaluate your competitors' strategies to uncover opportunities and refine your ASO approach for superior results."
        },
        {
            src: graph,
            alt: 'App Metadata Optimization',
            title: 'App Metadata Optimization',
            text: "Enhance your app’s store listing with our metadata optimization services. We optimize titles, descriptions, and other metadata elements to improve search visibility and attract more users."
        },
        {
            src: graph,
            alt: 'App Title and Description Optimization',
            title: 'App Title and Description Optimization',
            text: "Craft compelling app titles and descriptions that captivate users and improve search rankings. Our optimization techniques ensure your app stands out and communicates its value effectively."
        },
        {
            src: graph,
            alt: 'App Icon and Screenshot Design',
            title: 'App Icon and Screenshot Design',
            text: "Design eye-catching app icons and engaging screenshots to boost user interest and download rates. Our design services create visuals that make your app irresistible to potential users."
        },
        {
            src: graph,
            alt: 'App Store Listing Optimization',
            title: 'App Store Listing Optimization',
            text: "Optimize your app store listing to maximize its visibility and appeal. We refine every aspect of your listing to ensure it effectively converts visitors into loyal users."
        },
        {
            src: graph,
            alt: 'App Localization Services',
            title: 'App Localization Services',
            text: "Reach a global audience with our app localization services. We adapt your app’s content and metadata for different languages and regions, enhancing its relevance and appeal across diverse markets."
        },
        {
            src: graph,
            alt: 'A/B Testing for Store Assets',
            title: 'A/B Testing for Store Assets',
            text: "Improve your app’s performance with A/B testing for store assets. We test various elements such as icons and descriptions to determine the most effective combinations for driving downloads."
        },
        {
            src: graph,
            alt: 'App Store Review and Rating Management',
            title: 'App Store Review and Rating Management',
            text: "Manage and improve your app’s reviews and ratings with our expert services. We monitor feedback, respond to reviews, and implement strategies to enhance your app’s reputation and user satisfaction."
        },
        {
            src: graph,
            alt: 'App Store Analytics',
            title: 'App Store Analytics',
            text: "Gain valuable insights into your app’s performance with our analytics services. We track key metrics and provide actionable insights to refine your ASO strategy and drive growth."
        },
        {
            src: graph,
            alt: 'App Update and Maintenance Optimization',
            title: 'App Update and Maintenance Optimization',
            text: "Ensure your app remains competitive with our update and maintenance optimization services. We provide recommendations for regular updates and improvements to keep your app fresh and engaging."
        },
        {
            src: graph,
            alt: 'Social Proof Integration',
            title: 'Social Proof Integration',
            text: "Boost your app’s credibility with social proof integration. We leverage user testimonials, ratings, and endorsements to build trust and encourage more downloads."
        },
        {
            src: graph,
            alt: 'Conversion Rate Optimization (CRO)',
            title: 'Conversion Rate Optimization (CRO)',
            text: "Enhance your app’s conversion rates with our CRO services. We optimize your app store listing and user experience to increase the percentage of visitors who download and engage with your app."
        },
        {
            src: graph,
            alt: 'App Promotion Strategies',
            title: 'App Promotion Strategies',
            text: "Implement effective app promotion strategies to increase visibility and attract more users. Our marketing techniques drive targeted traffic and enhance your app’s presence in the app store."
        },
        {
            src: graph,
            alt: 'App Data Analysis and Reporting',
            title: 'App Data Analysis and Reporting',
            text: "Analyze and report on your app’s performance with our data analysis services. We provide comprehensive reports and insights to help you make informed decisions and optimize your ASO efforts."
        },
        {
            src: graph,
            alt: 'ASO Consulting',
            title: 'ASO Consulting',
            text: "Receive expert guidance with our ASO consulting services. We offer tailored advice and strategic recommendations to help you navigate the complexities of app store optimization and achieve your goals."
        }
    ];

    const eventCardData = [
        {
            title: 'Customized ASO Strategies',
            sections: [
                {
                    content: "At XDMinds, we don’t believe in one-size-fits-all solutions. We craft personalized ASO strategies tailored to your app’s unique needs and target audience, ensuring maximum impact and relevance."

                },
            ]
        },
        {
            title: 'Comprehensive Market Analysis',
            sections: [
                {
                    content: " We dive deep into market research and competitor analysis to understand the landscape and identify opportunities. This allows us to position your app effectively and leverage insights for better optimization."

                },
            ]
        },
        {
            title: 'Innovative Optimization Techniques',
            sections: [
                {
                    content: "Our approach combines creativity with the latest optimization techniques. From keyword research and metadata optimization to eye-catching visuals, we apply innovative methods to enhance your app’s visibility and appeal."

                },
            ]
        },
        {
            title: 'Continuous Monitoring and Adjustment',
            sections: [
                {
                    content: " ASO is an ongoing process, and we stay ahead of the curve by continuously monitoring your app’s performance. We adjust our strategies based on real-time data to ensure sustained growth and adaptability."

                },
            ]
        },
        {
            title: 'Holistic Approach to User Engagement',
            sections: [
                {
                    content: "We focus on improving not just rankings but also user engagement. Our strategies include optimizing app descriptions, visuals, and user feedback management to enhance overall user experience and satisfaction."

                },
            ]
        },
        {
            title: 'Expert Guidance and Support',
            sections: [
                {
                    content: " Our team provides ongoing support and expert guidance throughout the ASO process. We’re here to answer your questions, address concerns, and offer insights to help you make informed decisions."

                },
            ]
        },
        {
            title: 'Focus on Long-Term Success',
            sections: [
                {
                    content: "We aim for sustainable growth rather than short-term gains. By implementing strategies that drive continuous improvement, XDMinds ensures your app remains competitive and successful in the long run."

                },
            ]
        },
        {
            title: 'Transparent Reporting',
            sections: [
                {
                    content: " We believe in transparency and keep you informed with detailed reports on your app’s performance. Our clear and actionable insights help you understand the impact of our efforts and the value we bring."

                },
            ]
        },
        {
            title: 'User-Centric Approach',
            sections: [
                {
                    content: "We prioritize the needs and preferences of your target users. Our strategies are designed to resonate with your audience, improving engagement and driving more downloads."

                },
            ]
        },
        {
            title: 'Comprehensive ASO Services',
            sections: [
                {
                    content: "Our services cover all aspects of ASO, including keyword optimization, app title and description enhancements, visual assets, and reviews management. We ensure every element is optimized for maximum impact."

                },
            ]
        },
        {
            title: 'Data-Driven Insights',
            sections: [
                {
                    content: "XDMinds utilizes comprehensive analytics to guide our ASO efforts. We track performance metrics and user behaviors to make informed decisions and continually optimize your app’s presence."

                },
            ]
        },
        {
            title: 'Commitment to Excellence',
            sections: [
                {
                    content: "At XDMinds, we’re committed to delivering excellence in every aspect of ASO. Our passion for helping apps succeed drives us to constantly refine our methods and achieve the best results for your app."

                },
            ]
        },
    ]
    const faqData = [
        {
            question: "What exactly is App Store Optimization (ASO)?",
            answer: "App Store Optimization (ASO) is the process of enhancing your app’s visibility and ranking in app stores like Google Play and the Apple App Store. At XDMinds, we optimize various elements, including keywords, app titles, and descriptions, to help your app attract more users and increase downloads."
        },
        {
            question: "How can XDMinds improve my app’s ranking on Google Play and the Apple App Store?",
            answer: "XDMinds employs a range of techniques to boost your app’s ranking. We conduct thorough keyword research, optimize your app’s metadata, design compelling visuals, and monitor performance to ensure your app stands out in both Google Play and the Apple App Store."
        },
        {
            question: " Why is keyword research important for ASO?",
            answer: "Keyword research is crucial because it helps identify the terms and phrases potential users are searching for. By integrating these keywords into your app’s title, description, and other elements, XDMinds ensures your app is more likely to appear in relevant searches, driving more traffic and downloads."
        },
        {
            question: "How does XDMinds handle app metadata optimization?",
            answer: "At XDMinds, we optimize your app’s metadata, including the title, description, and tags, to make it more appealing and searchable. Our team uses data-driven insights to craft compelling descriptions and select the best keywords to enhance your app’s visibility in both app stores."
        },
        {
            question: "What role do app icons and screenshots play in ASO?",
            answer: "App icons and screenshots are vital for grabbing users’ attention and encouraging downloads. XDMinds designs eye-catching icons and engaging screenshots that highlight your app’s features and benefits, making it stand out in the Google Play and Apple App Store."
        },
        {
            question: "How often should I update my app’s ASO strategy?",
            answer: "Regular updates are key to maintaining high visibility. XDMinds recommends revisiting and refining your ASO strategy periodically, especially after major app updates or shifts in market trends, to keep your app competitive and relevant."
        },
        {
            question: "What is A/B testing, and how does it benefit ASO?",
            answer: "A/B testing involves creating different versions of your app’s listing elements and comparing their performance. XDMinds uses A/B testing to identify which app icons, screenshots, or descriptions work best, allowing us to optimize your app’s store presence for maximum impact."
        },
        {
            question: "How does XDMinds approach app localization?",
            answer: "Localization involves adapting your app’s content for different languages and regions. XDMinds provides comprehensive localization services to ensure your app resonates with users in various markets, enhancing its appeal and increasing downloads across global app stores."
        },
        {
            question: "Can XDMinds help with managing app reviews and ratings?",
            answer: "Yes, XDMinds can assist with managing your app’s reviews and ratings. We monitor user feedback, respond to reviews, and implement strategies to improve your app’s reputation and user satisfaction, helping to build trust and encourage more positive reviews."
        },
        {
            question: "What kind of results can I expect from XDMinds’ ASO services?",
            answer: "With XDMinds’ ASO services, you can expect improved app visibility, higher rankings in Google Play and the Apple App Store, and increased downloads. Our data-driven approach and ongoing optimization efforts are designed to drive sustained growth and achieve your app’s success."
        },
    ];

    return (
        <div className='aso-marketing'>
            <section className='aso-marketing-banner min-100vh digital-marketing'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Expert <span className='s-text-1'>ASO</span> Agency <br className='break' /> Boost Your App Downloads
                </h1>
                <p className='p-text-1'>
                    Unlock the full potential of your app with XDMinds, the premier ASO marketing agency dedicated to
                    elevating your app’s presence in the app store. Our tailored strategies enhance your app's ranking,
                    visibility, and download rates, ensuring it stands out in a crowded marketplace.
                    Experience the difference of expert-driven ASO solutions designed to drive growth and success.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <div className='digital-marketing '>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        XDMinds: A Leading ASO Marketing Company for <br className='break' /> Enhanced App Visibility and Growth
                    </h2>
                    <p className='p-text-2'>
                        At XDMinds, we are at the forefront of App Store Optimization (ASO), delivering unparalleled
                        expertise to elevate your app’s performance. Our agency specializes in crafting bespoke ASO
                        strategies that boost your app’s visibility and drive significant growth. With a deep understanding
                        of app store algorithms and user behavior, XDMinds ensures your app gains the attention it deserves,
                        leading to higher rankings and increased downloads. Trust us to be your partner in navigating the
                        complexities of app store marketing and achieving your business goals.
                    </p>
                    <div className='social-buttons'>
                        <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your aso marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/aso-agency' className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </a>
                        <a href='https://t.me/xd_minds' className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </a>
                        <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </a>
                    </div>
                </section>
            </div>

            <section className='aso-marketing-section-2 digital-marketing'>
                <h2 className='h-text-2'>
                    Achieve Top Rankings with Expert Google Play <br className='break' /> & Apple App Store Optimization Services <br className='break' />
                </h2>
                <p className='p-text-2'>
                    Elevate your app’s success with XDMinds, your trusted partner for expert Google Play and Apple App
                    Store optimization services. Our specialized team employs advanced techniques to boost your app’s
                    visibility, enhance rankings, and drive downloads across both major app stores. With XDMinds,
                    you get tailored strategies that ensure your app stands out and performs at its best,
                    helping you achieve top rankings and reach your business goals.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <div className='digital-marketing'>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        Why XDMinds Is Your Go-To for ASO Services: A Thorough Overview of Our Approach to Boosting App Visibility and Driving Downloads
                    </h2>
                    <p className='p-text-2'>
                        Choosing XDMinds for your App Store Optimization (ASO) services means partnering with a team dedicated to driving your
                        app’s success. Here’s why we stand out:
                    </p>
                    <div className='event-card-div'>
                        <EventCard eventCardData={eventCardData} />
                    </div>
                </section>
                <section>
                    <Latest modifiedPath='Latest ASO Marketing Insights' />
                </section>
                <section className='iot '>
                    <section className='iot-section-4 iot-section-2 '>
                        <h2 className='h-text-2'>
                            Frequently Asked Questions
                        </h2>
                        <BottomFaq faqData={faqData} />
                    </section>
                </section>
            </div>

            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default AsoMarketing

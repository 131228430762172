import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../Blockchain/Blockchain.css'
import './AI.css'

const AISection1 = () => {
    const accordionData = [
        {
            id: 'Web3',
            title: 'What is AI? - Understanding Artificial Intelligence',
            heading: '',
            content: [
                'Artificial Intelligence (AI) refers to the simulation of human intelligence in machines designed to think and learn like humans. These systems can perform tasks that typically require human intelligence, such as visual perception, speech recognition, decision-making, and language translation.',
            ],
        },
        {
            id: 'web3-marketing',
            title: 'Why AI? - The Benefits and Importance of AI',
            heading: '',
            content: [
                "AI offers numerous benefits, including increased efficiency, enhanced decision-making, and the ability to process and analyze vast amounts of data quickly. It helps businesses automate routine tasks, improve customer experiences, and gain valuable insights that drive growth and innovation.",
            ],
        },

        {
            id: 'wanuf',
            title: 'How does AI work? - The Mechanics of Artificial Intelligence',
            heading: '',
            content: [
                'AI works by using algorithms and machine learning models to analyze data, identify patterns, and make predictions. It involves various technologies, such as neural networks, deep learning, and natural language processing, which enable machines to learn from experience and continuously improve their performance.',
            ],
        },

        {
            id: 'comparison',
            title: 'Which Industries Benefit from AI? - AI Across Different Sectors',
            heading: '',
            content: [
                'AI has a profound impact across various industries, including healthcare, finance, retail, manufacturing, and logistics. It enhances operational efficiency, enables personalized customer experiences, optimizes supply chains, and drives innovation in product development and service delivery.',
            ],
        },


        {
            id: 'navigate',
            title: "What is AI Marketing? - AI's Role in Marketing",
            heading: '',
            content: [
                'AI marketing involves leveraging artificial intelligence to enhance marketing strategies and campaigns. It includes using AI-powered tools for data analysis, customer segmentation, content generation, and personalized marketing. AI enables marketers to deliver targeted, relevant, and timely messages to their audiences.',
            ],
        },



        {
            id: 'blockchain-revolution',
            title: 'How AI Drives Business Growth - AI as a Business Accelerator',
            heading: '',
            content: [
                'AI accelerates business growth by optimizing operations, enhancing customer experiences, and providing actionable insights. It enables businesses to make data-driven decisions, automate processes, and innovate more rapidly, resulting in increased competitiveness and profitability.',
            ],
        },

        {
            id: 'wianm',
            title: "AI's Impact on Marketing - Transforming the Marketing Landscape",
            heading: '',
            content: [
                'AI is transforming the marketing landscape by enabling hyper-personalization, predictive analytics, and automated marketing workflows. It helps marketers understand customer behavior, predict trends, and deliver more effective and efficient marketing campaigns.',
            ],
        },
        {
            id: 'eonm',
            title: 'AI in Digital Marketing - AI for Online Success',
            heading: '',
            content: [
                'AI is revolutionizing digital marketing by providing tools and techniques that improve targeting, engagement, and conversion rates. From chatbots and personalized content to programmatic advertising and predictive analytics, AI is key to achieving online success.',
            ],
        },
        {
            id: 'winm',
            title: 'Personalization with AI - Tailoring Marketing for Individuals',
            heading: '',
            content: [
                'AI enables marketers to deliver highly personalized experiences by analyzing individual preferences, behaviors, and interactions. This level of personalization enhances customer satisfaction, loyalty, and engagement, driving better marketing outcomes.',
            ],
        },



    ];
    return (
        <div className='ai-sec-1'>
            <div className='blockchain-sec1'>
                <Container>
                    <Row>
                        <Col>
                            <h2> What is Artificial Intelligence (AI) and  <span className='metaverse-sec1-break'>
                                <br /></span>How is it Revolutionizing Digital Marketing?</h2>
                            <div className='mobile-view'>
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    {accordionData.map((section, index) => (
                                        <div className="accordion-item" key={index}>
                                            <p className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapse${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`flush-collapse${index}`}
                                                >
                                                    {section.title}

                                                </button>
                                            </p>
                                            <div
                                                id={`flush-collapse${index}`}
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <h4 className='content-head'>{section.heading}</h4>
                                                    {section.content && section.content.map((paragraph, idx) => (
                                                        <p className='content-sescription' key={idx}>{paragraph}</p>
                                                    ))}
                                                    {section.benefits && (
                                                        <div className="benefits">
                                                            {section.benefits.map((benefit, idx) => (
                                                                <React.Fragment key={idx}>
                                                                    <p className="benefits-discript">{benefit.title}</p>
                                                                    <p>{benefit.description}</p>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AISection1
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MdArrowCircleDown } from "react-icons/md";

const Section4 = () => {

  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const data = [
    {
      number: 1,
      title: 'Tailored Strategy Development ',
      description: "At XDMinds, we understand that every game is unique. We start by immersing ourselves in your game’s concept, target audience, and market positioning. Our team crafts a customized marketing strategy designed to highlight your game's unique features and appeal to your ideal players. This bespoke approach ensures that your marketing efforts are precisely aligned with your game's vision and goals."
    },
    {
      number: 2,
      title: 'Comprehensive Market Research',
      description: 'Our success starts with understanding the landscape. We conduct thorough market research to analyze current trends, competitive dynamics, and player preferences. This data-driven approach helps us identify key opportunities and threats, allowing us to fine-tune your marketing strategy for maximum impact.'
    },
    {
      number: 3,
      title: 'Creative Campaign Design',
      description: 'Creativity is at the heart of our marketing campaigns. We develop visually compelling and engaging content that resonates with your target audience. From eye-catching visuals and captivating trailers to interactive social media posts and influencer collaborations, our creative solutions are designed to generate buzz and drive engagement.'
    },
    {
      number: 4,
      title: 'Multi-Channel Promotion',
      description: " To ensure your game reaches a wide audience, we leverage a multi-channel marketing approach. We utilize various platforms, including social media, gaming forums, email campaigns, and influencer partnerships. By diversifying your promotional efforts, we increase your game's visibility and attract players from different touchpoints."
    },
    {
      number: 5,
      title: 'Targeted Advertising',
      description: "Effective advertising is crucial for gaming marketing. Our team uses advanced targeting techniques to place ads in front of the right audience segments. We focus on demographic, geographic, and behavioral targeting to ensure that your ads reach potential players who are most likely to be interested in your game."
    },
    {
      number: 6,
      title: 'Data-Driven Optimization',
      description: "At XDMinds, we believe in the power of data. We continuously monitor and analyze the performance of your marketing campaigns. By evaluating key metrics and user feedback, we make informed adjustments to optimize your campaigns for better results and higher ROI."
    },
    {
      number: 7,
      title: 'Engagement and Community Building',
      description: "Building a loyal player base is essential for long-term success. We foster community engagement through interactive content, regular updates, and direct communication with players. Our goal is to create a vibrant community around your game, enhancing player retention and encouraging word-of-mouth referrals."
    },
    {
      number: 8,
      title: 'Performance Tracking and Reporting',
      description: "Transparency is key to a successful partnership. We provide regular performance reports that detail the success of your marketing campaigns. These reports include insights into key metrics, campaign effectiveness, and recommendations for future strategies. Our commitment to transparency ensures you’re always informed about your game's marketing progress."
    },
    {
      number: 9,
      title: 'Expert Guidance and Support',
      description: " Partnering with XDMinds means gaining access to a team of experienced marketing professionals who are dedicated to your game’s success. We offer ongoing support and strategic advice, helping you navigate the complexities of gaming marketing and make informed decisions."
    },
    {
      number: 10,
      title: 'Long-Term Partnership',
      description: "We view our relationship with clients as a long-term partnership. Beyond initial marketing efforts, we work with you to adapt and refine strategies as your game evolves and market conditions change. Our aim is to support your game’s growth and ensure sustained success over time."
    },
  ];

  return (
    <div className='game-sec4'>
      <div className='blockchain-sec5'>
        <Container>
          <Row>
            <Col>
              <h2>Why Partner with XDMinds for Your Gaming Marketing Needs?
              </h2>
              <p className='subhead'>
                Choosing XDMinds means opting for excellence and expertise. Our team of seasoned professionals brings a deep understanding of the gaming industry, ensuring your game reaches its full potential. We offer personalized marketing strategies, innovative solutions, and a data-driven approach to guarantee success. With XDMinds, you gain a committed partner dedicated to achieving remarkable results, driving player engagement, and maximizing your game's impact in the market. Trust us to elevate your game's presence and help you achieve your marketing objectives.
              </p>

              <div className='metaverse-sec5 desktop-view mobile-hide'>
                <Row>
                  {data.map((item, index) => (
                    <Col
                      key={index}
                      lg={4}
                      xl={4}
                      sm={6}
                      md={6}
                      style={item.number % 3 === 0 ? { border: 'none' } : {}}
                    >
                      <div className='number-div'>
                        <div className='bc-sec5-carde-head'>
                          <p className='bc-sec5-carde-head-number'>{item.number}</p>
                          <p>{item.title}</p>
                        </div>
                        <p className='bc-sec5-card-discription'>{item.description}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='about'>
        <section className='about-section-5 p-0'>
          <div className='custom-accordion desktop-hide tab-hide'>
            <div className="accordion" id="accordionExample">
              {data.map((item, index) => (
                <div key={item.id} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                      onClick={() => handleClick(index)}
                      type="button"
                      aria-expanded={openIndex === index}
                      aria-controls={item.id}
                    >
                      {item.title}
                      <div className="arrow-icon-btn">
                        <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className='p-text-1'>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Section4
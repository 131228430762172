import React from 'react'
import { Button } from 'react-bootstrap'
import './Metaverse.css'
import { FaSkype, FaWhatsapp } from 'react-icons/fa6'
import { FaTelegramPlane } from 'react-icons/fa'

const Section3 = () => {
  return (
    <div className='metaverse-sec-3'>

      <div className='m-challenge-content'>
        <h3 className='challenge-content'>
          Your Guide to Metaverse Marketing Success <span className='metaverse-sec2-break'><br /></span>Partner with XDMinds

        </h3>
        <p>
          XDMinds stands at the forefront of the digital revolution as a top-rated metaverse digital marketing agency. We harness the power of Web3 technologies to deliver unparalleled marketing solutions, transforming the way brands engage with their audiences in the virtual world. Our expertise and innovative approach ensure your brand thrives in this new digital landscape.
        </p>
      </div>
      <div className='btn-mchallenge'>
        <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Metaverse marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/metaverse-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
          <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
        </a>
        <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
          <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
        </a>
        <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
          <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
        </a>
      </div>

    </div>
  )
}

export default Section3
import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button } from 'react-bootstrap';
import './AI.css';
import BreadCrumbs from '../../BreadCrumbs';

const AIBanner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { countryCode } = props;


  return (

    <div className='web3-banner'>

      <BreadCrumbs />
      <div className='banner-left-sec min-100vh'>
        <h1>Top-Tier <span className='changing-banner-content'>AI</span> Marketing Agency<span className='home-banner-break'><br /></span>  for Unparalleled Results</h1>
        <p>Welcome to XDMinds, the premier AI marketing agency dedicated to delivering unparalleled results. Our innovative AI-driven strategies are designed to elevate your brand, maximize your ROI, and transform your marketing efforts. With cutting-edge technology and a team of seasoned experts, we provide customized solutions that cater to your unique business needs. Join us and experience the future of marketing today.</p>

        <div className='btn-home-banner-request-div'>
          <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
        </div>

        <ModalForm
          countryCode={countryCode}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>

    </div>

  )
}

export default AIBanner
import React, { useState } from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BreadCrumbs from '../../Components/BreadCrumbs';
import graph from '../../assets/images/graph.svg';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import Web3GridCard from '../DigitalMarketing/Web3GridCard';
import Latest from '../../Components/Home/Latest/Latest';
import FAQblockchain from '../../Components/Services/Blockchain/FAQblockchain';
import { MdArrowCircleDown } from "react-icons/md";


const IEO = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    const { countryCode } = props;

    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    const web3CardData = [
        {
            src: graph,
            alt: 'IEO Campaign GTM Strategy',
            title: 'IEO Campaign GTM Strategy',
            text: "Develop a robust campaign strategy and meticulous planning to ensure a successful IEO launch, tailored to your project’s specific needs and goals."
        },
        {
            src: graph,
            alt: 'IEO Market Research',
            title: 'IEO Market Research',
            text: "Conduct in-depth market research and analysis to identify trends, opportunities, and target audiences, providing a solid foundation for your marketing efforts."
        },
        {
            src: graph,
            alt: 'IEO Brand Development',
            title: 'IEO Brand Development',
            text: "Craft a compelling brand identity and strategic positioning that sets your IEO apart from competitors, resonating with potential investors and participants."
        },
        {
            src: graph,
            alt: 'Pre & Post IEO Marketing',
            title: 'Pre & Post IEO Marketing',
            text: "Implement targeted marketing strategies both before and after your IEO launch to build anticipation, sustain momentum, and ensure long-term success."
        },
        {
            src: graph,
            alt: 'IEO Tokenomics',
            title: 'IEO Tokenomics',
            text: "Provide expert consultation on tokenomics and incentive structures to optimize your IEO's attractiveness and sustainability."
        },
        {
            src: graph,
            alt: 'IEO Website Development',
            title: 'IEO Website Development',
            text: "Create a professional, user-friendly website that effectively showcases your IEO project, providing essential information and engaging potential investors."
        },
        {
            src: graph,
            alt: 'Exchange Listing Guidance',
            title: 'Exchange Listing Guidance',
            text: "Our expert team provides strategic guidance to ensure your token is listed on top exchanges for maximum exposure."
        },
        {
            src: graph,
            alt: 'Token Sale Strategy Advisory',
            title: 'Token Sale Strategy Advisory',
            text: "Our advisory services offer tailored strategies to optimize your token sale and achieve outstanding results."
        },
        {
            src: graph,
            alt: 'Crypto Ads Management',
            title: 'Crypto Ads Management',
            text: "We manage and optimize crypto ad campaigns to drive targeted traffic and enhance your token's visibility."
        },
        {
            src: graph,
            alt: 'Whitepaper/Litepaper',
            title: 'Whitepaper/Litepaper',
            text: "Develop comprehensive and informative whitepapers and litepapers that clearly communicate your IEO's vision, goals, and mechanics."
        },
        {
            src: graph,
            alt: 'Social Media Campaigns',
            title: 'Social Media Campaigns',
            text: "Leverage social media platforms to build awareness, engage with your audience, and drive participation in your IEO."
        },
        {
            src: graph,
            alt: 'Content Marketing',
            title: 'Content Marketing',
            text: "Produce high-quality, informative content that educates and engages your target audience about your IEO project and its benefits."
        },
        {
            src: graph,
            alt: 'IEO Community Building',
            title: 'IEO Community Building',
            text: "Foster a vibrant and engaged community around your IEO through strategic community-building initiatives and effective management practices."
        },
        {
            src: graph,
            alt: 'Email Marketing Campaign',
            title: 'Email Marketing Campaign',
            text: "Utilize targeted email campaigns to keep potential investors informed and engaged with the latest updates and developments of your IEO."
        },
        {
            src: graph,
            alt: 'Press Releases',
            title: 'Press Releases',
            text: "Distribute compelling press releases to major publications and platforms to gain media coverage and visibility for your IEO."
        },
        {
            src: graph,
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: "Partner with key influencers in the Web3 space to amplify your IEO’s reach and credibility, driving higher engagement and participation."
        },
        {
            src: graph,
            alt: 'IEO SEO',
            title: 'IEO SEO',
            text: "Optimize your online presence to improve search engine rankings and attract organic traffic to your IEO project."
        },
        {
            src: graph,
            alt: 'Paid Advertising',
            title: 'Paid Advertising',
            text: "Implement targeted paid advertising campaigns to reach a wider audience and drive traffic to your IEO, enhancing visibility and engagement."
        },
        {
            src: graph,
            alt: 'Airdrop & Bounty Campaigns',
            title: 'Airdrop & Bounty Campaigns',
            text: "Design and execute airdrop and bounty campaigns to incentivize participation and broaden your IEO's reach."
        },
        {
            src: graph,
            alt: 'IEO Event Promotion',
            title: 'IEO Event Promotion',
            text: "Plan, promote, and manage events to engage your community and showcase your IEO project, creating buzz and driving participation."
        },
        {
            src: graph,
            alt: 'Omnichannel Marketing',
            title: 'Omnichannel Marketing',
            text: "Adopt a holistic marketing approach that integrates multiple channels to deliver a consistent and engaging message across all platforms."
        },
        {
            src: graph,
            alt: 'Marketing Analytics',
            title: 'Marketing Analytics',
            text: "Utilize advanced analytics and reporting tools to track performance, measure success, and make data-driven decisions to optimize your marketing efforts."
        },
        {
            src: graph,
            alt: 'IEO Marketing Consulting',
            title: 'IEO Marketing Consulting',
            text: "Receive expert advice and consultation to navigate the complexities of IEO marketing and achieve your project's goals."
        },


    ];
    const cardData = [
        {
            id: 1,
            title: 'Understanding Your Unique Needs',
            description: "The journey to a successful Initial Exchange Offering (IEO) begins with a deep understanding of your project’s unique needs and goals. At XDMinds, we take the time to thoroughly analyze your project, its objectives, target audience, and market positioning. This foundational step ensures that every marketing strategy we develop is tailored specifically to your project, setting the stage for effective and targeted promotional efforts."
        },
        {
            id: 2,
            title: 'Deep Understanding of the Crypto Landscape',
            description: "At XDMinds, we pride ourselves on our comprehensive understanding of the cryptocurrency market. Our team stays updated with the latest trends, regulatory changes, and market dynamics. This deep knowledge allows us to craft strategies that are not only current but also forward-thinking, ensuring your IEO is positioned to capture the interest of investors and enthusiasts alike."
        },
        {
            id: 3,
            title: 'Customized Marketing Strategy',
            description: "Every IEO project is unique, and so should be its marketing strategy. XDMinds excels in developing customized marketing plans tailored to the specific needs and goals of your project. We take the time to understand your vision, target audience, and competitive landscape to create a strategy that highlights your strengths and maximizes your reach."
        },
        {
            id: 4,
            title: 'Data-Driven Decision Making',
            description: "At XDMinds, we believe in the power of data to drive success. Our approach to IEO marketing is heavily data-driven, using advanced analytics tools to track the performance of our campaigns. This allows us to make informed decisions, optimize strategies in real-time, and ensure that every marketing dollar spent is contributing to your project's success."
        },
        {
            id: 5,
            title: ' Continuous Optimization and Support',
            description: "The crypto market is ever-changing, and so are its marketing demands. XDMinds provides continuous optimization and support throughout your IEO journey. We monitor campaign performance, gather feedback, and make necessary adjustments to ensure that your marketing efforts remain effective and aligned with your evolving goals."
        },
        {
            id: 6,
            title: 'Transparent Reporting',
            description: "Transparency is at the core of our operations. XDMinds offers detailed and transparent reporting on all marketing activities. We provide regular updates and comprehensive reports that outline the performance of your campaigns, giving you clear insights into your IEO’s progress and the impact of our efforts."
        },
        {
            id: 7,
            title: 'Commitment to Success',
            description: "Our ultimate goal is your success. XDMinds is dedicated to ensuring that your IEO not only reaches its funding goals but also establishes a strong foundation for future growth. We go above and beyond to support your project, offering expert advice, innovative solutions, and unwavering commitment to your vision."
        },
    ];
    const faqData = [
        {
            question: "What makes XDMinds the best choice for IEO marketing? ",
            answer: "At XDMinds, we combine deep industry expertise with a tailored approach to each project. Our team of experienced professionals understands the unique challenges of IEOs and develops customized marketing strategies that align with your project’s goals and target audience. Our comprehensive services, from content creation to community building, ensure your IEO gains maximum visibility and engagement."
        },
        {
            question: "How does XDMinds help in building a strong community for our IEO?",
            answer: "Community building is a core component of our IEO marketing strategy. We leverage social media platforms like Twitter, Telegram, Discord, Reddit, and LinkedIn to create a vibrant and engaged community around your project. Our approach includes regular updates, interactive posts, and community engagement activities designed to foster loyalty and excitement among your supporters."
        },
        {
            question: " What types of content does XDMinds create for IEO marketing?",
            answer: "XDMinds specializes in creating a wide range of high-quality content tailored to your project’s needs. This includes blog posts, whitepapers, videos, infographics, and more. Our content is designed to inform, educate, and captivate your target audience, highlighting your project’s strengths and addressing potential investors’ questions and concerns."
        },
        {
            question: " How does XDMinds utilize influencer partnerships for IEO marketing?",
            answer: "We have an extensive network of industry influencers that we collaborate with to amplify your project’s reach. We carefully select influencers who align with your project’s values and goals to ensure authentic and impactful endorsements. These partnerships help build credibility and trust, driving more interest and participation in your IEO."
        },
        {
            question: "What role does data play in XDMinds' IEO marketing strategy?",
            answer: "Data-driven optimization is key to our marketing strategy. We continuously monitor and analyze performance metrics throughout the campaign to identify what’s working and what needs adjustment. This approach allows us to refine our strategies in real-time, ensuring optimal results and a dynamic, responsive campaign."
        },
        {
            question: "How does XDMinds ensure transparency in their marketing services?",
            answer: "Transparency is a cornerstone of our client relationships. We maintain open and honest communication with regular updates and detailed reports on campaign performance. This transparency ensures that you are always informed and confident in the direction of your IEO campaign, allowing for a collaborative and trust-based partnership."
        },
        {
            question: " How does XDMinds tailor its marketing strategies to different projects?",
            answer: "We understand that each IEO project is unique, so we take the time to thoroughly analyze your project’s specific needs, goals, and target audience. This personalized approach allows us to develop and execute marketing strategies that are perfectly aligned with your project’s objectives, ensuring maximum impact and effectiveness."
        },
        {
            question: "What kind of analytics and reporting can we expect from XDMinds?",
            answer: "XDMinds provides detailed analytics and reporting throughout your IEO marketing campaign. We track key performance indicators such as website traffic, social media engagement, and conversion rates. These insights are shared with you regularly, allowing for informed decision-making and continuous optimization of the marketing strategy."
        },
        {
            question: "How does XDMinds handle potential challenges or setbacks during the campaign?",
            answer: "Our team is experienced in navigating the complexities of IEO marketing. We proactively identify potential challenges and address them with strategic solutions. By maintaining flexibility and adaptability, we ensure that your campaign remains on track and achieves its goals despite any obstacles that may arise."
        },

    ];



    return (
        <div className='ieo'>
            <div className='digital-marketing'>
                <section className='digital-marketing-banner min-100vh'>
                    <BreadCrumbs />
                    <h1 className='h-text-1'>
                        Go-To  <span className='s-text-3'>IEO</span> Marketing Agency   <br className='break' />
                        for Maximizing Visibility
                    </h1>
                    <p className='p-text-1'>
                        Tap into the full potential of your Initial Exchange Offering (IEO) with XDMinds, a leading IEO
                        marketing agency focused on maximizing visibility and boosting token sales. Our skilled team blends
                        innovative strategies with deep industry insights to ensure your IEO project achieves exceptional success. Collaborate
                        with us to enhance your token sale campaign and excel in the competitive Web3 landscape.
                    </p>
                    <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
                </section>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        XDMinds: Top-Rated IEO Marketing Agency for <br className='break' />Effective Strategies and Exceptional Results


                    </h2>
                    <p className='p-text-2'>
                        XDMinds stands out as a top-rated IEO marketing agency, renowned for delivering effective strategies
                        and exceptional results. Our deep understanding of the IEO ecosystem, combined with our commitment to
                        innovation, ensures that your token sale campaign is meticulously planned and executed. We take a tailored
                        approach to each project, crafting bespoke marketing solutions that align with your unique goals and drive
                        tangible outcomes. From strategic planning to
                        community engagement, XDMinds is your trusted partner in achieving IEO success.
                    </p>
                    <div className='social-buttons'>
                        <button className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </button>
                    </div>
                </section>
                <section className='digital-marketing-section-2 '>
                    <h2 className='h-text-2'>
                        XDMinds' Strategic IEO Marketing Services: <br className='break' /> Drive Higher Visibility and Engagement for Your IEO Projects
                    </h2>
                    <p className='p-text-2'>
                        At XDMinds, we offer a comprehensive suite of strategic IEO marketing services designed to drive higher visibility
                        and engagement for your IEO projects. Our holistic approach encompasses every aspect of the marketing
                        process, ensuring that your token sale campaign reaches its full potential. With our expertise and
                        innovative tactics, we position your IEO for maximum impact and sustained growth in the competitive
                        Web3 arena.
                    </p>

                    <div className='grid-cards'>
                        <Web3GridCard web3CardData={web3CardData} />
                    </div>
                </section>
            </div>
            <div className='about'>
                <section className='about-section-5'>
                    <h2 className='h-text-2'>
                        Why XDMinds is Your Ultimate Choice for <br className='break' />Successful IEO Marketing?
                    </h2>
                    <p className='p-text-2'>
                        In the fast-paced world of Initial Exchange Offerings (IEOs), having the right marketing partner
                        can make all the difference between success and obscurity. XDMinds stands out as the ultimate choice
                        for successful IEO marketing.
                        Here’s a step-by-step breakdown of why XDMinds is the perfect partner for your IEO journey.
                    </p>
                    <div className='custom-div-5 mobile-hide'>
                        <div className='row g-3'>
                            {cardData.map((card) => (
                                <div key={card.id} className='col-lg-4 col-md-6 col-12'>
                                    <div className='card custom-card-5 h-100'>
                                        <div className='custom-card-head'>
                                            <h1 className='h-text-3'>{card.id}</h1>
                                            <h2 className='h-text-4'>{card.title}</h2>
                                        </div>
                                        <div className='card-body'>
                                            {card.description && <p className='p-text-4'>{card.description}</p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='custom-accordion desktop-hide tab-hide'>
                        <div className="accordion" id="accordionExample">
                            {cardData.map((item, index) => (
                                <div key={item.id} className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                            onClick={() => handleClick(index)}
                                            type="button"
                                            aria-expanded={openIndex === index}
                                            aria-controls={item.id}
                                        >
                                            {item.title}
                                            <div className="arrow-icon-btn">
                                                <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id={item.id}
                                        className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p className='p-text-1'>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
            <section>
                <Latest modifiedPath='Latest IEO Marketing Insights' />
            </section>
            <section>
                <FAQblockchain faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default IEO

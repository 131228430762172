import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button } from 'react-bootstrap';
import BreadCrumbs from '../../BreadCrumbs';

const GameBanner = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;
    return (

        <div className='web3-banner '>

            <BreadCrumbs />
            <div className='banner-left-sec min-100vh'>
                <h1>Top-Notch <span className='changing-banner-content'>Gaming </span><span className='home-banner-break'><br /></span> Marketing Agency</h1>
                <p>In the competitive world of gaming, you need a partner who understands the landscape and knows how to make your game stand out. XDMinds is your top-notch gaming marketing agency, dedicated to turning your vision into a global sensation. With our innovative strategies and unparalleled expertise, we help you capture the market and engage players like never before.</p>
                <div className='btn-home-banner-request-div'>
                    <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
                </div>
                <ModalForm
                    countryCode={countryCode}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </div>

    )
}

export default GameBanner
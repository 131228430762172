import React from 'react';
import Tabs from '../../../Pages/DigitalMarketing/Tabs'
import '../../../assets/css/style.css'

const Section4 = () => {
    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Initial Consultation and Goal Setting',
            sections: [
                {
                    subtitle: "Understand your dApp project, objectives, and target audience.",
                    content: "Our process begins with an in-depth consultation to gain a comprehensive understanding of your project. We identify your goals, target audience, and key performance indicators (KPIs) to establish a clear roadmap for your marketing strategy. This step ensures that our efforts align perfectly with your vision and objectives.",

                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Market Research and Competitor Analysis',
            sections: [
                {
                    subtitle: "Gather insights on the market landscape and competitor strategies.",
                    content: "We conduct thorough market research to identify trends, opportunities, and challenges within the dApp ecosystem. This includes analyzing your competitors’ strategies and performance to understand what works and what doesn’t. The insights gained from this research inform our strategic decisions and help us craft a unique value proposition for your dApp."
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Strategy Development',
            sections: [
                {
                    subtitle: "Create a customized, data-driven marketing strategy.",
                    content: "Based on our research and your specific goals, we develop a comprehensive marketing strategy tailored to your dApp. This strategy outlines the key marketing channels, tactics, and campaigns we will use to achieve your objectives. We focus on creating a cohesive plan that integrates various marketing elements for maximum impact."
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Branding and Messaging',
            sections: [
                {
                    subtitle: "Establish a strong, recognizable brand identity.",
                    content: "We work with you to develop a compelling brand identity that resonates with your target audience. This includes crafting a unique value proposition, key messaging, and visual elements that reflect your dApp’s essence. A strong brand identity helps differentiate your project and builds trust and recognition in the market."
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Content Creation and Optimization',
            sections: [
                {
                    subtitle: "Produce high-quality, engaging content.",
                    content: "Content is at the heart of our marketing strategy. We create informative, engaging, and SEO-optimized content that educates your audience and promotes your dApp. This includes blog posts, articles, videos, infographics, and more. Our content strategy ensures that your dApp is visible and appealing to potential users and investors."
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Multi-Channel Marketing Execution',
            sections: [
                {
                    subtitle: "Implement marketing campaigns across various channels.",
                    content: "We execute targeted marketing campaigns across multiple channels, including social media, email, search engines, and influencers. Our multi-channel approach ensures that we reach your audience wherever they are, driving traffic, engagement, and conversions. We continuously monitor campaign performance and make adjustments to optimize results."
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Community Building and Engagement',
            sections: [
                {
                    subtitle: "Foster a loyal and active user community",
                    content: "Building a strong community is crucial for the success of any dApp. We focus on engaging with your audience through platforms like Telegram, Discord, and social media. Our community management strategies encourage interaction, support, and loyalty, turning users into advocates for your dApp."
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Performance Monitoring and Analytics',
            sections: [
                {
                    subtitle: "Track and analyze marketing performance",
                    content: "We use advanced analytics tools to track the performance of our marketing campaigns. By monitoring key metrics and KPIs, we gain valuable insights into what’s working and what needs improvement. This data-driven approach allows us to make informed decisions and continuously optimize our strategies for better results."
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Continuous Optimization and Support',
            sections: [
                {
                    subtitle: "Ensure long-term success and growth",
                    content: "Our commitment to your success doesn’t end with the launch of marketing campaigns. We provide ongoing support and optimization to ensure that your dApp continues to grow and thrive. This includes regular performance reviews, strategy adjustments, and the introduction of new tactics to keep your marketing efforts fresh and effective."
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Reporting and Feedback',
            sections: [
                {
                    subtitle: "Maintain transparency and alignment with your goals",
                    content: "We provide regular, detailed reports on the performance of our marketing efforts. These reports include insights into campaign results, user engagement, and ROI. We also seek your feedback to ensure that our strategies remain aligned with your evolving goals and expectations."
                }
            ]
        },
        {
            id: 'pills-eleven',
            label: '11',
            title: '',
            sections: [
                {
                    subtitle: "",
                    content: "By following this step-by-step process, XDMinds ensures that your dApp marketing strategy is thorough, effective, and results-driven. Our holistic approach covers every aspect of marketing, providing you with the tools and support needed to achieve long-term success in the competitive dApp ecosystem."
                }
            ]
        },
    ]

    return (
        <div className='dapp-sec-4'>
            <div className='digital-marketing ppc'>

                <section className='digital-marketing-section-2 l-bg'>
                    <h2 className='h-text-2 text-center'>
                        Implement a Winning dApp Marketing Strategy<br />The XDMinds Approach
                    </h2>
                    <p className='p-text-2 text-center'>
                        At XDMinds, we believe in a structured, data-driven approach to ensure your decentralized application (dApp) reaches its full potential. Our step-by-step process is designed to cover all aspects of marketing, from initial strategy creation to continuous optimization and support. Here’s how we do it:
                    </p>
                    <div className='tabs-div'>
                        <Tabs tabData={tabData} />
                    </div>
                </section>

            </div>
        </div>
    )
}

export default Section4
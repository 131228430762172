import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../Blockchain/Blockchain.css'

const Section1 = () => {

  const accordionData = [
    {
      id: 'Web3',
      title: 'What is a Decentralized Application (dApp)?',
      heading: '',
      content: [
        'A decentralized application, or dApp, is a software application that operates on a decentralized network, typically a blockchain. Unlike traditional apps that rely on centralized servers, dApps leverage distributed ledger technology to ensure transparency, security, and autonomy. They function without a central authority, offering users greater control and privacy.',
      ],
    },
    {
      id: 'web3-marketing',
      title: "Decentralized Apps Vs Traditional Apps",
      heading: '',
      content: [
        "Traditional apps run on centralized servers, making them susceptible to single points of failure and control by a central entity. In contrast, dApps are distributed across a network of nodes, enhancing security and resilience. Traditional apps often involve intermediaries, while dApps operate peer-to-peer, reducing costs and increasing efficiency.",
      ],
    },

    {
      id: 'wanuf',
      title: 'How Do dApps Work?',
      heading: '',
      content: [
        'dApps function through smart contracts, self-executing contracts with the terms of the agreement directly written into code. These smart contracts run on blockchain networks like Ethereum, ensuring that the application logic is executed consistently and transparently. Users interact with dApps through a user interface, while the underlying blockchain ensures the integrity of the data and transactions.',
      ],
    },

    {
      id: 'comparison',
      title: 'Why Use a dApp?',
      heading: '',
      content: [
        'Using a dApp provides numerous advantages, including enhanced security, transparency, and user control. Since dApps are decentralized, they are less vulnerable to hacking and censorship. They also enable trustless interactions, where users can engage without needing to trust a central authority or intermediary.',
      ],
    },


    {
      id: 'navigate',
      title: 'Examples of Decentralized Applications',
      heading: '',
      content: [
        "Some popular examples of dApps include:"
      ],
      benefits: [
        {
          title: 'Cryptokitties: ',
          description: 'A blockchain-based game where players can collect, breed, and trade virtual cats.',
        },
        {
          title: 'Uniswap:',
          description: 'A decentralized exchange that allows users to trade cryptocurrencies directly from their wallets.',
        },
        {
          title: 'Aave:',
          description: 'A decentralized lending platform enabling users to borrow and lend cryptocurrencies without intermediaries.',
        },

      ],
    },




    {
      id: 'blockchain-revolution',
      title: 'The Future of dApps',
      heading: '',
      content: [
        'The future of dApps looks promising, with increasing adoption across various industries such as finance, gaming, and supply chain management. As blockchain technology continues to evolve, we can expect more sophisticated and user-friendly dApps to emerge, driving further decentralization and innovation.',
      ],
    },

    {
      id: 'wianm',
      title: 'Key Features of Decentralized Applications',
      heading: '',
      benefits: [
        {
          title: 'Decentralization:',
          description: ' Operates on a distributed network, eliminating central points of control.',
        },
        {
          title: 'Transparency: ',
          description: 'All transactions and data are recorded on a public ledger.',
        },
        {
          title: 'Security: ',
          description: ' Enhanced security through cryptographic protocols.',
        },
        {
          title: 'Immutability:  ',
          description: 'Data once recorded cannot be altered, ensuring integrity.',
        },
        {
          title: 'Interoperability:',
          description: 'Ability to interact with other dApps and blockchain networks.',
        },

      ],
    },

    {
      id: 'aoda',
      title: 'Advantages of Decentralized Apps',
      heading: '',
      benefits: [
        {
          title: 'Enhanced Security:',
          description: 'Reduced risk of hacking and data breaches.',
        },
        {
          title: 'Increased Transparency:',
          description: 'Open-source code and public ledger ensure accountability.',
        },
        {
          title: 'Cost Efficiency:',
          description: 'Lower transaction fees due to the elimination of intermediaries.',
        },
        {
          title: 'User Control:',
          description: 'Greater privacy and control over personal data.',
        },
        {
          title: 'Censorship Resistance: ',
          description: 'Uninterrupted service without central authority interference.',
        },

      ],
    },
    {
      id: 'eonm',
      title: 'What is dApps Marketing?',
      heading: '',
      content: [
        'dApps marketing involves promoting decentralized applications to target users and investors through strategic marketing efforts. This includes creating awareness, driving engagement, and fostering user adoption by leveraging various digital marketing channels and techniques tailored specifically for the dApp ecosystem.',
      ],
    },
    {
      id: 'winm',
      title: 'Why Should You Do Marketing for dApps Projects?',
      heading: '',
      content: [
        'Marketing is crucial for dApp projects to stand out in a competitive landscape. Effective marketing strategies help attract users, build a loyal community, and drive the adoption and growth of the application. It also plays a vital role in educating the market about the benefits and functionalities of the dApp, thereby increasing trust and credibility.',
      ],
    },

    {
      id: 'aoda',
      title: 'What Are the Advantages of dApps Marketing?',
      heading: '',
      benefits: [
        {
          title: 'Increased Visibility:',
          description: 'Reach a broader audience and raise awareness.',
        },
        {
          title: 'User Acquisition:',
          description: ' Attract and retain users through targeted campaigns.',
        },
        {
          title: 'Community Building:',
          description: ' Foster a loyal and engaged user community.',
        },
        {
          title: 'Brand Recognition:',
          description: ' Establish a strong and recognizable brand presence.',
        },
        {
          title: 'Investor Confidence:',
          description: ' Enhance credibility and attract potential investors.',
        },

      ],
    },

  ];

  return (
    <div className='blockchain-sec1'>
      <Container>
        <Row>
          <Col>
            <h2> The Future is Decentralized<span className='metaverse-sec1-break'>
              <br /></span> Learn How dApps Can Revolutionize Your World
            </h2>
            <div className='mobile-view'>
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {accordionData.map((section, index) => (
                  <div className="accordion-item" key={index}>
                    <p className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        {section.title}

                      </button>

                    </p>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <h4 className='content-head'>{section.heading}</h4>
                        {section.content && section.content.map((paragraph, idx) => (
                          <p className='content-sescription' key={idx}>{paragraph}</p>
                        ))}
                        {section.benefits && (
                          <div className="benefits">
                            {section.benefits.map((benefit, idx) => (
                              <React.Fragment key={idx}>
                                <p className="benefits-discript">{benefit.title}</p>
                                <p>{benefit.description}</p>
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Section1
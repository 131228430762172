import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import '../Metaverse/Metaverse.css'
import { FaSkype, FaWhatsapp } from 'react-icons/fa6'
import { FaTelegramPlane } from 'react-icons/fa'

const GameFiSec1 = () => {
    return (
        <div>
            <div className='blockchain-sec2'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>XDMinds: The Go-To GameFi Marketing Agency<span className='metaverse-sec2-break'><br /></span> for Innovative Blockchain Gaming Solutions
                                </h2>
                                <p>XDMinds stands at the forefront of the GameFi marketing industry, renowned for its innovative approach to blockchain gaming solutions. Our team of experts is dedicated to crafting tailored marketing strategies that cater to the unique needs of GameFi projects. We combine deep industry knowledge with cutting-edge techniques to ensure your game achieves maximum visibility, engagement, and growth. Trust XDMinds to be your partner in navigating the dynamic world of blockchain gaming and driving unparalleled success.
                                </p>
                            </div>
                            <div className='btn-mchallenge'>
                                <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your GameFi marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/gamefi-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                    <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                                </a>
                                <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                                </a>
                                <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default GameFiSec1
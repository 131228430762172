import React from 'react'
import CryptoBanner from '../../Components/Services/Crypto/CryptoBanner'
import Section1 from '../../Components/Services/Crypto/Section1'
import Section2 from '../../Components/Services/Crypto/Section2'
import Section3 from '../../Components/Services/Crypto/Section3'
import Latest from '../../Components/Home/Latest/Latest'
import CryptoFAQ from '../../Components/Services/Crypto/CryptoFAQ'

const CryptoPage = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <CryptoBanner countryCode={countryCode} />
      <Section1 />
      <Section2 />
      <Section3 />
      <Latest modifiedPath='Latest Crypto Marketing Insights' />
      <CryptoFAQ />
    </div>
  )
}

export default CryptoPage
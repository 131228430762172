import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MdArrowCircleDown } from "react-icons/md";

const WebSection4 = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const data = [
    {
      number: 1,
      title: ' Comprehensive Market Analysis',
      description: 'We begin by conducting a thorough analysis of the Web3 market to identify trends, opportunities, and potential challenges. Our team examines your target audience, competitors, and industry dynamics to create a solid foundation for your marketing strategy.'
    },
    {
      number: 2,
      title: 'Tailored Go-to-Market Strategy',
      description: "Based on our market analysis, we develop a bespoke go-to-market strategy that aligns with your project's goals and vision. This includes defining your unique value proposition, positioning your brand, and outlining the most effective channels and tactics for reaching your audience."
    },
    {
      number: 3,
      title: 'SEO Optimization for Web3',
      description: "Our SEO experts optimize your website and content for search engines, ensuring that your Web3 project ranks high in search results. By targeting relevant keywords and implementing best practices, we drive organic traffic and enhance your online visibility."
    },
    {
      number: 4,
      title: 'Targeted Paid Advertising Campaigns',
      description: "We create and manage targeted paid advertising campaigns across various platforms, including Google Ads, social media, and Web3-specific channels. Our data-driven approach ensures that your ads reach the right audience, maximizing ROI and driving conversions."
    },
    {
      number: 5,
      title: 'Engaging Social Media Strategies',
      description: "Our social media team crafts compelling content and strategies to engage your audience on platforms like Twitter, LinkedIn, and Reddit. We leverage the power of social media to build a strong online presence, foster community engagement, and drive brand awareness."
    },
    {
      number: 6,
      title: 'Content Marketing Excellence',
      description: "Content is king in the Web3 space, and our team of content creators produces high-quality, informative, and engaging content that resonates with your audience. From blog posts and articles to whitepapers and case studies, we establish your brand as a thought leader in the industry."
    },
    {
      number: 7,
      title: 'Community Building and Management',
      description: 'We understand the importance of community in the Web3 ecosystem. Our experts help you build and manage a vibrant community on platforms like Telegram, Discord, and other relevant channels. We foster meaningful interactions, encourage participation, and create a loyal following for your project.'
    },
    {
      number: 8,
      title: ' Influencer Partnerships',
      description: "We identify and collaborate with key influencers in the Web3 space to amplify your brand's reach and credibility. Our influencer marketing strategies ensure that your project gains visibility and trust within the community."
    },
    {
      number: 9,
      title: ' Data-Driven Campaign Optimization',
      description: "Our commitment to continuous improvement means we constantly monitor and analyze the performance of your marketing campaigns. Using advanced analytics tools, we gather insights and make data-driven adjustments to optimize your campaigns for better results."
    },
    {
      number: 10,
      title: ' Transparent Reporting and Communication',
      description: "We believe in transparency and keep you informed at every step of the process. Our detailed reports and regular updates provide clear insights into the progress and effectiveness of your marketing efforts, ensuring you stay informed and confident in our partnership."
    },

  ];

  return (
    <div className='web3-sec4'>
      <div className='blockchain-sec5'>
        <Container>
          <Row>
            <Col>
              <h2>Looking for a Web3 Marketing Edge? XDMinds Has It
              </h2>
              <p className='subhead'>
                In the rapidly evolving world of Web3, staying ahead of the competition requires a strategic and innovative approach to marketing. At XDMinds, we offer the edge you need to succeed, providing a step-by-step guide to navigating the complex landscape of Web3 marketing. Here's how we help you gain that competitive advantage:
              </p>

              <div className='metaverse-sec5 desktop-view mobile-hide'>
                <Row>
                  {data.map((item, index) => (
                    <Col
                      key={index}
                      lg={4}
                      xl={4}
                      sm={6}
                      md={6}
                      style={item.number % 3 === 0 ? { border: 'none' } : {}}
                    >
                      <div className='blockchain-sec5-card number-div'>
                        <div className='bc-sec5-carde-head'>
                          <p className='bc-sec5-carde-head-number'>{item.number}</p>
                          <p>{item.title}</p>
                        </div>
                        <p className='bc-sec5-card-discription'>{item.description}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
      <div className='about'>
        <section className='about-section-5 p-0'>
          <div className='custom-accordion desktop-hide tab-hide'>
            <div className="accordion" id="accordionExample">
              {data.map((item, index) => (
                <div key={item.id} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                      onClick={() => handleClick(index)}
                      type="button"
                      aria-expanded={openIndex === index}
                      aria-controls={item.id}
                    >
                      {item.title}
                      <div className="arrow-icon-btn">
                        <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className='p-text-1'>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};


export default WebSection4
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';



const GridCard = ({ cardData }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="row g-3">
            {cardData.map((card, index) => {
                return (
                    <div className='col-md-6 col-lg-4 col-12' key={index}>
                        <div
                            className={`custom-card card h-100 ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => toggleClick(index)}
                        >
                            <LazyLoadImage src={card.src} alt={card.alt} effect="blur" />
                            <h5 className='h-text-4'>{card.title}</h5>
                            <p className={`p-text-4 ${index === activeIndex ? 'active' : ''}`}>{card.text}</p>
                            <Link reloadDocument to={card.link} className={`custom-card-link ${card.link ? 'l-show' : 'd-none'}`} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default GridCard;

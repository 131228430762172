import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import TopFaq from './TopFaq';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import GridCard from './GridCard';
import graph from '../../assets/images/graph.svg';
import Tabs from './Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import BreadCrumbs from '../../Components/BreadCrumbs';



const PerformanceMarketing = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const topFaqData = [
        {
            question: "What is performance marketing?",
            answer: "Performance marketing is a results-driven strategy where advertisers pay only for specific actions, such as clicks, leads, or sales, rather than for exposure."
        },
        {
            question: "How does performance marketing differ from traditional advertising?",
            answer: "Traditional advertising often charges based on exposure (impressions), while performance marketing charges based on measurable actions taken by the consumer, ensuring more accountable spending."
        },
        {
            question: "Why is performance marketing becoming increasingly popular?",
            answer: "Performance marketing is gaining popularity because it offers measurable results and allows businesses to optimize their advertising spend by paying only for successful outcomes."
        },
        {
            question: "What are the benefits of performance marketing?",
            answer: "It provides transparency, measurable outcomes, and the ability to optimize campaigns in real-time, leading to better ROI and more efficient use of marketing budgets."
        },
        {
            question: "Who benefits from performance marketing?",
            answer: "Both advertisers and consumers benefit; advertisers gain cost-effective marketing, while consumers receive more relevant and targeted ads."
        },
        {
            question: "When should a business consider using performance marketing?",
            answer: "Businesses should consider performance marketing when they want measurable, results-oriented campaigns that directly tie marketing spend to specific outcomes."
        },
        {
            question: "Which are the most common performance marketing channels?",
            answer: "Common channels include search engine marketing (SEM), social media ads, display advertising, affiliate marketing, and email marketing."
        },
        {
            question: "What kind of results can I expect from performance marketing?",
            answer: "Results vary based on campaign goals, but businesses can expect improved ROI, increased conversions, and more efficient use of their marketing budget."
        },
        {
            question: "How much does performance marketing typically cost?",
            answer: "The cost varies depending on the chosen channels, target audience, and campaign complexity. However, it often offers better cost-efficiency compared to traditional methods."
        },
        {
            question: "Who can help me with performance marketing?",
            answer: "XDMinds is a leading performance marketing agency that can help you design and implement effective, results-driven campaigns tailored to your business goals."
        },
    ]

    const cardData = [
        {
            src: graph,
            alt: 'Performance Marketing Strategy Creation',
            title: 'Performance Marketing Strategy Creation',
            text: 'Craft tailored performance marketing strategies that align with your business goals and drive measurable results. Our approach ensures optimal resource allocation and campaign effectiveness.'
        },
        {
            src: graph,
            alt: 'Website SEO',
            title: 'Website SEO',
            text: 'Enhance your website’s visibility and search rankings with targeted performance marketing strategies designed for SEO success.XDMinds integrates advanced analytics and optimization techniques to drive organic traffic and improve search engine performance.'
        },
        {
            src: graph,
            alt: 'Search Engine Marketing',
            title: 'Search Engine Marketing',
            text: 'Increase your online visibility and attract high-quality traffic with targeted search engine marketing campaigns. We focus on strategic keyword bidding and ad placement to maximize your ROI.'
        },
        {
            src: graph,
            alt: 'PPC Advertising',
            title: 'PPC Advertising',
            text: 'Drive immediate results with expertly managed pay-per-click advertising. Our data-driven approach ensures that every click translates into valuable leads and conversions.'
        },
        {
            src: graph,
            alt: 'Google Ads',
            title: 'Google Ads',
            text: 'Leverage XDMinds’ expertise to optimize your Google Ads campaigns, driving high-quality traffic and maximizing ROI. Our data-driven approach ensures your ads reach the right audience and achieve measurable results.'
        },
        {
            src: graph,
            alt: 'Email Campaigns',
            title: 'Email Campaigns',
            text: 'Enhance your email marketing strategy with XDMinds’ performance-focused techniques. We craft targeted campaigns that boost open rates, drive conversions, and deliver a strong return on your investment.'
        },
        {
            src: graph,
            alt: 'ASO',
            title: 'ASO',
            text: 'Elevate your app’s visibility and downloads with XDMinds’ App Store Optimization (ASO) services. Our strategies improve your app’s ranking and attractiveness, ensuring it stands out in crowded app marketplaces.'
        },
        {
            src: graph,
            alt: 'Mobile App Advertising',
            title: 'Mobile App Advertising',
            text: 'Promote your mobile app effectively through targeted ads tailored for mobile users. Our strategies are designed to boost downloads and user engagement.'
        },
        {
            src: graph,
            alt: 'Social Media Advertising',
            title: 'Social Media Advertising',
            text: 'Leverage social media platforms to connect with your audience and enhance brand awareness. Our campaigns are designed to drive engagement and foster brand loyalty.'
        },
        {
            src: graph,
            alt: 'YouTube Ads',
            title: 'YouTube Ads',
            text: 'Maximize the impact of your YouTube advertising with XDMinds. Our targeted ad strategies drive viewer engagement and conversions, making your video campaigns more effective and ROI-focused.'
        },
        {
            src: graph,
            alt: 'Display Advertising',
            title: 'Display Advertising',
            text: 'Utilize visually compelling display ads to capture audience attention across various websites. Our approach is focused on enhancing brand visibility and driving traffic.'
        },
        {
            src: graph,
            alt: 'Native Advertising',
            title: 'Native Advertising',
            text: 'Integrate seamlessly with content to provide a non-intrusive advertising experience. Our native ads are designed to engage users while aligning with their interests.'
        },
        {
            src: graph,
            alt: 'Marketplace Advertising',
            title: 'Marketplace Advertising',
            text: 'Optimize your product listings and ads on major marketplaces to increase visibility and drive sales. Our strategies are tailored to meet the unique demands of each platform.'
        },
        {
            src: graph,
            alt: 'Content Syndication',
            title: 'Content Syndication',
            text: 'Expand your reach with XDMinds’ content syndication strategies. We distribute your content across high-traffic platforms, driving engagement and enhancing your brand’s visibility to a broader audience.'
        },
        {
            src: graph,
            alt: 'B2B',
            title: 'B2B',
            text: 'Develop targeted strategies for business-to-business marketing that drive lead generation and strengthen client relationships. Our approach is tailored to the unique needs of B2B audiences.'
        },
        {
            src: graph,
            alt: 'B2C',
            title: 'B2C',
            text: 'Create compelling campaigns aimed at individual consumers, focusing on increasing brand loyalty and driving sales. Our strategies are designed to resonate with B2C audiences.'
        },
        {
            src: graph,
            alt: 'D2C',
            title: 'D2C',
            text: 'Enhance your direct-to-consumer marketing efforts to drive online sales and build strong customer relationships. Our campaigns are crafted to engage and convert your target audience.'
        },
        {
            src: graph,
            alt: 'SaaS',
            title: 'SaaS',
            text: 'Optimize your marketing strategies for Software as a Service (SaaS) to drive user acquisition and subscription growth. We focus on attracting and retaining valuable customers.'
        },
        {
            src: graph,
            alt: 'Retail',
            title: 'Retail',
            text: 'Boost your retail marketing efforts with strategies that drive foot traffic and online sales. Our solutions are tailored to both physical stores and e-commerce platforms.'
        },
        {
            src: graph,
            alt: 'Web3 Projects',
            title: 'Web3 Projects',
            text: 'Implement cutting-edge marketing strategies for Web3 and blockchain-based projects. We focus on reaching tech-savvy audiences and maximizing engagement in the Web3 space.'
        },
        {
            src: graph,
            alt: 'Retargeting/Remarketing',
            title: 'Retargeting/Remarketing',
            text: 'Re-engage users who have previously interacted with your brand through targeted ads. Our retargeting strategies are designed to convert potential customers who are already familiar with your products.'
        },
        {
            src: graph,
            alt: 'Conversion Rate Optimization',
            title: 'Conversion Rate Optimization',
            text: 'Enhance your website and marketing efforts to improve the percentage of visitors who convert into customers. We use data-driven techniques to maximize your conversion rates.'
        },
        {
            src: graph,
            alt: 'Attribution Modeling',
            title: 'Attribution Modeling',
            text: 'Gain insights into how different marketing channels contribute to conversions. Our attribution modeling helps you understand and optimize your marketing mix.'
        },
        {
            src: graph,
            alt: 'Feed Management',
            title: 'Feed Management',
            text: 'Ensure your product feeds are accurately managed and optimized for performance across various platforms. Our solutions improve product visibility and ad effectiveness.'
        },
        {
            src: graph,
            alt: 'Performance Reporting and Analysis',
            title: 'Performance Reporting and Analysis',
            text: 'Receive comprehensive reports and actionable insights on campaign performance. Our analysis helps you make informed decisions and refine your marketing strategies.'
        },
        {
            src: graph,
            alt: 'Continuous Monitoring & Optimization',
            title: 'Continuous Monitoring & Optimization',
            text: 'Keep your campaigns performing at their best with ongoing monitoring and adjustments. We continuously optimize to ensure your marketing efforts achieve the highest possible return.'
        },
        {
            src: graph,
            alt: 'Performance Marketing Consulting',
            title: 'Performance Marketing Consulting',
            text: 'We deliver tailored performance marketing consulting to optimize your digital strategies and drive measurable results. Our expert guidance ensures your campaigns achieve maximum efficiency and ROI.'
        },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Expertise and Experience',
            sections: [
                {
                    content: `Our team of seasoned professionals brings years of experience in performance marketing, ensuring strategies that deliver results.`,
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Data-Driven Approach',
            sections: [
                {
                    content: `We leverage advanced analytics to create targeted campaigns that optimize ROI and drive measurable outcomes.`,
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Tailored Solutions',
            sections: [
                {
                    content: `XDMinds offers customized marketing strategies tailored to your specific business goals and industry needs.`,
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Full-Funnel Support',
            sections: [
                {
                    content: `From initial click to final conversion, we provide comprehensive support to enhance every stage of the customer journey.`,
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'End-to-End Services',
            sections: [
                {
                    content: `From initial strategy development to continuous optimization, we offer comprehensive support across the entire marketing funnel.`,
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Cutting-Edge Technology',
            sections: [
                {
                    content: `We utilize the latest tools and technologies to drive precision and efficiency in every campaign.`,
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Innovative Solutions',
            sections: [
                {
                    content: `We stay ahead of industry trends to provide innovative performance marketing solutions that keep your brand at the forefront of the market.`,
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Transparent Communication',
            sections: [
                {
                    content: `Our commitment to clear and open communication ensures you’re always informed about campaign progress and performance.`,
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Transparent Reporting',
            sections: [
                {
                    content: `Get detailed performance reports that provide clear insights and actionable data for informed decision-making.`,
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Continuous Optimization',
            sections: [
                {
                    content: `Our commitment to continuous monitoring and adjustment ensures your campaigns remain effective and responsive to market changes.`,
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What makes XDMinds’ performance services unique?",
            answer: "XDMinds distinguishes itself with a blend of cutting-edge technology and personalized strategies tailored to each client’s specific needs, ensuring highly effective performance marketing solutions."
        },
        {
            question: "How does XDMinds measure the success of performance marketing campaigns?",
            answer: "We use advanced analytics and performance metrics to track and evaluate the success of campaigns, providing detailed reports that highlight ROI, conversion rates, and other key indicators."
        },
        {
            question: "What types of businesses can benefit from XDMinds’ performance marketing services?",
            answer: "Our services are designed to benefit a wide range of businesses, from startups and small enterprises to large corporations, across various industries including Web3, B2B, B2C, D2C, e-commerce, SaaS, retail, and more."
        },
        {
            question: "How does XDMinds ensure continuous improvement in performance marketing campaigns?",
            answer: "We implement ongoing monitoring and optimization strategies, regularly adjusting campaigns based on performance data and market trends to continually enhance results."
        },
        {
            question: "What can clients expect in terms of communication and reporting from XDMinds?",
            answer: "Clients can expect transparent, regular updates and comprehensive reports that provide clear insights into campaign performance, along with proactive communication to address any questions or concerns."
        },
    ]
    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Data-Driven <br className='break' /> <span className='s-text-3'>Performance</span> Marketing Agency
                </h1>
                <p className='p-text-1'>
                    At XDMinds, we leverage advanced data analytics to craft tailored performance marketing strategies that drive maximum ROI and conversions. Our approach combines cutting-edge technology with a deep understanding of consumer behavior, ensuring every campaign is optimized for success.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Top FAQs About Performance Marketing
                </h2>
                <TopFaq topFaqData={topFaqData} />
            </section>
            <section className='digital-marketing-section-2 l-bg'>
                <div className='contact-div'>
                    <h2 className='h-text-2'>
                        Full-Funnel Performance Marketing Agency: <br className='break' /> From Click to Conversion
                    </h2>
                    <p className='p-text-2'>
                        XDMinds specializes in full-funnel performance marketing, guiding clients from the initial click through to final conversion. Our comprehensive approach ensures a seamless customer journey and maximizes the value of every interaction.
                    </p>
                    <div className='social-buttons'>
                        <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your performance marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/performance-marketing-agency' className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </a>
                        <a href='https://t.me/xd_minds' className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </a>
                        <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </a>
                    </div>
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Boost ROI and Conversions Through Expert <br className='break' /> Performance Marketing Services
                </h2>
                <p className='p-text-2'>
                    With a team of seasoned professionals, XDMinds delivers expert performance marketing services designed to enhance ROI and drive conversions. We offer customized strategies that align with your business objectives and market trends.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 l-bg'>
                <h2 className='h-text-2'>
                    Why Trust XDMinds for Your <br className='break' /> Performance Marketing Needs?
                </h2>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest Performance Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default PerformanceMarketing
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AI.css'
import { MdArrowCircleDown } from "react-icons/md";

const AISection4 = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const data = [
    {
      number: 1,
      title: 'Expertise and Experience',
      description: 'XDMinds boasts a team of seasoned professionals with extensive expertise in AI and digital marketing. Our years of experience in the industry enable us to deliver innovative, effective, and customized AI-driven marketing solutions tailored to your unique business needs.'
    },
    {
      number: 2,
      title: 'Cutting-Edge Technology',
      description: "We leverage the latest AI technologies and tools to ensure your marketing strategies are at the forefront of innovation. Our advanced AI algorithms and machine learning models enhance targeting, personalization, and campaign optimization, delivering superior results."
    },
    {
      number: 3,
      title: 'Comprehensive Services',
      description: 'XDMinds offers a full suite of AI-driven marketing services, from AI chatbot marketing and generative AI content creation to AI-driven SEO and PPC advertising. Our holistic approach ensures that all aspects of your marketing strategy are integrated and optimized for maximum impact.'
    },
    {
      number: 4,
      title: 'Data-Driven Insights',
      description: "We harness the power of AI to analyze vast amounts of data, providing you with actionable insights and detailed analytics. Our data-driven approach enables us to make informed decisions, optimize your campaigns, and continuously improve your marketing performance."
    },
    {
      number: 5,
      title: 'Personalized Solutions',
      description: 'At XDMinds, we understand that every business is unique. We take the time to understand your specific goals, challenges, and market dynamics, delivering personalized AI-driven marketing solutions that align with your objectives and drive measurable results.'
    },
    {
      number: 6,
      title: 'Proven Track Record',
      description: "Our portfolio of successful AI-driven marketing campaigns speaks for itself. We have helped numerous clients achieve significant growth, increased engagement, and enhanced ROI through our innovative AI marketing strategies."
    },
    {
      number: 7,
      title: 'Customer-Centric Approach',
      description: " We prioritize our clients' success and satisfaction. Our customer-centric approach ensures that we work closely with you, maintaining transparent communication and providing ongoing support to achieve your marketing goals."
    },
    {
      number: 8,
      title: 'Scalability and Flexibility',
      description: " Our AI-driven marketing solutions are designed to scale with your business. Whether you're a startup or an established enterprise, we offer flexible and scalable solutions that grow with your needs and adapt to changing market conditions."
    },
    {
      number: 9,
      title: 'Ethical AI Practices',
      description: " XDMinds is committed to ethical AI practices, ensuring that our AI solutions are transparent, fair, and respect user privacy. We adhere to the highest standards of ethical AI implementation, providing you with trustworthy and responsible AI-driven marketing services."
    },
    {
      number: 10,
      title: ' Competitive Advantage',
      description: "Partnering with XDMinds gives you a competitive edge in the market. Our innovative AI-driven marketing strategies position your brand at the forefront of the industry, helping you stay ahead of the competition and achieve sustained growth."
    },

  ];

  return (
    <div className='AI-sec4'>
      <div className='blockchain-sec5'>
        <Container>
          <Row>
            <Col>
              <h2>Why Choose XDMinds As Your AI-Driven Marketing Partner?
              </h2>
              <div className='metaverse-sec5 desktop-view mobile-hide'>
                <Row>
                  {data.map((item, index) => (
                    <Col
                      key={index}
                      lg={4}
                      xl={4}
                      sm={6}
                      md={6}
                      style={item.number % 3 === 0 ? { border: 'none' } : {}}
                    >
                      <div className='number-div'>
                        <div className='bc-sec5-carde-head'>
                          <p className='bc-sec5-carde-head-number'>{item.number}</p>
                          <p>{item.title}</p>
                        </div>
                        <p className='bc-sec5-card-discription'>{item.description}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='about'>
        <section className='about-section-5 p-0'>
          <div className='custom-accordion desktop-hide tab-hide'>
            <div className="accordion" id="accordionExample">
              {data.map((item, index) => (
                <div key={item.id} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                      onClick={() => handleClick(index)}
                      type="button"
                      aria-expanded={openIndex === index}
                      aria-controls={item.id}
                    >
                      {item.title}
                      <div className="arrow-icon-btn">
                        <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                    data-bs-parent="#accordionExample"                  >
                    <div className="accordion-body">
                      <p className='p-text-1'>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default AISection4
import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What makes XDMinds different for Crypto marketing?",
    answer: `We understand the unique challenges of the Cryptocurrency landscape and have a proven track record of helping projects achieve real growth.`
  },
  {
    question: "Do you offer custom Crypto marketing plans?",
    answer: `Absolutely! We tailor our strategies to each project's specific needs and target audience.`
  },
  {
    question: "Can XDMinds help me reach a global Crypto audience?",
    answer: `Our team has expertise in international marketing and can help you connect with users worldwide.`
  },
  {
    question: "How do you measure the success of a Crypto marketing campaign?",
    answer: `We track key metrics like website traffic, social media engagement, and community growth to demonstrate ROI.`
  },
  {
    question: "What kind of content creation does XDMinds do for Crypto projects?",
    answer: `We create engaging content like blog posts, articles, social media graphics, and video explainers to educate and attract users.`
  },
  {
    question: "How can XDMinds help with building a Crypto community?",
    answer: `We develop strategies to foster engagement on social media platforms, forums, and other online communities relevant to your project.`
  },
  {
    question: "Does XDMinds offer Influencer marketing for Crypto projects?",
    answer: `Yes, we can identify and partner with relevant influencers in the Crypto space to expand your reach and build trust.`
  },
  {
    question: "How can XDMinds help with Search Engine Optimization (SEO) for Crypto projects?",
    answer: `Our team optimizes your project's website and content to rank higher in search engine results for relevant keywords.`
  },
  {
    question: "What are the benefits of Public Relations (PR) for Crypto marketing?",
    answer: `XDMinds can help secure media coverage in top publications to generate positive brand awareness and credibility.`
  },
  {
    question: "How much do XDMinds Crypto marketing services cost?",
    answer: `We offer flexible pricing plans to fit your budget. Contact us for a free consultation to discuss your specific needs.`
  },
  {
    question: "What types of Crypto projects has XDMinds worked with in the past?",
    answer: `We have experience marketing various projects, from DeFi platforms and NFTs to blockchain startups and token offerings.`
  },
  {
    question: "Can XDMinds help me with marketing a new coin launch (ICO/IDO)?",
    answer: `Yes, we have a proven strategy for generating pre-launch buzz, attracting investors, and ensuring a successful launch.`
  },
  {
    question: "How can XDMinds help with ongoing community management for my Crypto project?",
    answer: `Our team can help you manage your online communities, respond to inquiries, and keep your audience engaged.`
  },
  {
    question: "Does XDMinds stay up-to-date on the latest Crypto marketing trends?",
    answer: `Absolutely! We continuously research and adapt our strategies to leverage the newest and most effective marketing tactics in the Crypto space.`
  },
  {
    question: "What kind of reporting does XDMinds provide for Crypto marketing campaigns?",
    answer: `We offer regular reports with key metrics and data insights to track progress and optimize your campaign performance.`
  },
  {
    question: "Can XDMinds help me develop a brand identity for my Crypto project?",
    answer: `Yes, we can assist in crafting a strong brand message and visual identity that resonates with your target audience.`
  },
  {
    question: "How can XDMinds help me with marketing my Crypto project on social media platforms?",
    answer: `We develop engaging social media content calendars, manage your online presence, and run targeted social media ad campaigns.`
  },
  {
    question: "Does XDMinds offer any educational resources on Crypto marketing?",
    answer: `Yes, we provide access to helpful resources like blog posts, webinars, and ebooks to empower you with Crypto marketing knowledge.`
  },
  {
    question: "What are the legal considerations for Crypto marketing?",
    answer: `XDMinds stays informed on the latest legal regulations surrounding Crypto marketing and ensures your campaigns comply with all guidelines.`
  },
  {
    question: "How can I get started with XDMinds Crypto marketing services?",
    answer: `Contact us today for a free consultation to discuss your project's goals and receive a customized marketing plan.`
  },

];

const CryptoFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div>
      <div className='blockchain-sec-6-faq'>
        <div className='faq'>
          <Container>
            <Row>
              <Col>
                <div>
                  <h2>Frequently Asked Questions </h2>
                </div>
                <div className='blockchain-faq-qa'>
                  {faqData.map((faq, index) => (
                    <div key={index}>
                      <div
                        onClick={() => handleFAQClick(index)}
                        aria-controls={`faq${index}`}
                        aria-expanded={openFAQ === index}
                        className='faq-1st'
                      >
                        <div className='faq-content'>
                          <h6 className='faq-content-question'>{faq.question}</h6>
                        </div>
                        <div className='faq-icons'>
                          {openFAQ === index ? (
                            <IoIosArrowDropupCircle className='close-circle-faq' />
                          ) : (
                            <IoIosArrowDropdownCircle className='plus-circle-faq' />
                          )}
                        </div>
                      </div>
                      <Collapse in={openFAQ === index}>
                        <div id={`faq${index}`} className='faq-answer-content'>
                          {renderAnswer(faq.answer)}
                        </div>
                      </Collapse>
                    </div>
                  ))}

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default CryptoFAQ
import React from 'react'
import Web3Banner from '../../Components/Services/Web3/Web3Banner'
import WebSection1 from '../../Components/Services/Web3/WebSection1'
import WebSection3 from '../../Components/Services/Web3/WebSection3'
import WebSection4 from '../../Components/Services/Web3/WebSection4'
import Web3FAQ from '../../Components/Services/Web3/Web3FAQ'
import Latest from '../../Components/Home/Latest/Latest'

const Web3Page = (props) => {
  const { countryCode } = props;

  return (
    <div>
      <Web3Banner countryCode={countryCode} />
      <WebSection1 />
      <WebSection3 />
      <WebSection4 />
      <Latest modifiedPath='Latest Web3 Marketing Insights' />
      <Web3FAQ />


    </div>
  )
}

export default Web3Page
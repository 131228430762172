import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Industries.css'
import Card from 'react-bootstrap/Card';
import { HiArrowLongRight } from "react-icons/hi2";
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { LiaIndustrySolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';

const industriesData = [
    { title: 'Crypto', text: 'Craft engaging content that demystifies blockchain technology and builds trust in your crypto project. Leverage influencer marketing to tap into passionate crypto communities.' },
    { title: 'B2B', link: '/b2b-marketing-agency', text: 'Develop thought leadership campaigns that showcase your expertise and solutions. Utilize targeted advertising on LinkedIn and industry publications to connect with key decision-makers.' },
    { title: 'B2C', link: '/b2c-marketing-agency', text: 'Create compelling narratives that resonate with your target consumers. Implement data-driven social media strategies to spark conversations and build brand loyalty.' },
    { title: 'SaaS', link: '/saas-marketing-agency', text: 'Generate high-quality leads with free trials and freemium models. Design user-centric content that educates potential customers on the value proposition of your SaaS solution.' },
    { title: 'Fintech', text: 'Highlight the security and convenience of your financial technology solutions. Partner with trusted financial bloggers and influencers to build brand credibility within the fintech ecosystem.' },
    { title: 'E-commerce', text: 'Optimize your online store for seamless customer journeys. Implement retargeting campaigns to re-engage website visitors and convert them into paying customers.' },
    { title: 'Healthcare', text: 'Educate patients with informative content addressing their specific needs. Leverage telehealth consultations to enhance accessibility and build trust with potential patients.' },
    { title: 'Real Estate', text: 'Showcase properties with stunning virtual tours and high-quality photography. Utilize social media advertising to target potential buyers based on location, budget, and property preferences.' },
    { title: 'Education', text: 'Develop engaging online courses and interactive learning experiences. Foster student communities through social media platforms and targeted email marketing campaigns.' },
    { title: 'Gaming', link: '/gaming-marketing-agency', text: 'Build hype for your game with captivating trailers and behind-the-scenes content. Partner with gaming influencers and streamers to generate excitement within the gaming community.' },
    { title: 'Logistics', text: 'Emphasize your reliability, speed, and competitive pricing. Utilize targeted PPC campaigns to reach businesses seeking efficient and cost-effective logistics solutions.' },
    { title: 'Media & Entertainment', text: 'Create a buzz with interactive social media campaigns and exclusive content. Explore influencer marketing opportunities to reach new audiences and expand your fanbase.' },
];

const Industries = () => {
    const [countriesswiper, setCountriesSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (countriesswiper !== null) {
            countriesswiper.autoplay.stop();
        }
    };

    const handleMouseLeave = () => {
        if (countriesswiper !== null) {
            countriesswiper.autoplay.start();
        }
    };
    const rows = [];
    const colsPerRow = 4;

    for (let i = 0; i < industriesData.length; i += colsPerRow) {
        rows.push(
            <div className='home-industries-card-div' key={i}>
                <Row>
                    {industriesData.slice(i, i + colsPerRow).map((industry, index) => (
                        <Col xl={3} sm={6} lg={3} key={index}>
                            {industry.link ? (
                                <Link reloadDocument to={industry.link} className='text-decoration-none h-100'>
                                    <Card className='industries-card h-100'>
                                        <Card.Body>
                                            <p className='img-home-industries-card'>
                                                <LiaIndustrySolid className='img-home-industries-card' />
                                            </p>
                                            <Card.Title><h3>{industry.title}</h3></Card.Title>
                                            <Card.Text>{industry.text}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            ) : (
                                <div className='text-decoration-none c-default h-100'>
                                    <Card className='industries-card h-100'>
                                        <Card.Body>
                                            <p className='img-home-industries-card'>
                                                <LiaIndustrySolid className='img-home-industries-card' />
                                            </p>
                                            <Card.Title><h3>{industry.title}</h3></Card.Title>
                                            <Card.Text>{industry.text}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            )}
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
    return (
        <div className='industries'>
            <Container>
                <Row>
                    <Col>
                        <div className='industries-head'>

                            <h2>From Traditional to Transformative:<span className='industries-head-break'><br /></span> Web3 Marketing Solutions for Legacy Industries</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='desktop-industries-view'>
                {rows}
            </div>
            <div className='mobile-view-industries'>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={3}
                    loop={true}
                    dots="true"
                    onSwiper={(swiper) => setCountriesSwiper(swiper)}
                    modules={[Scrollbar, Pagination, Autoplay]}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    className="ps-swiper"
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        460: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 50,
                        },
                    }}
                >
                    {industriesData.map((industry, index) => (
                        <SwiperSlide key={index}>
                            {industry.link ? (
                                <Link to={industry.link} className='text-decoration-none h-100'>
                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        <Card.Body>
                                            <p className='img-home-industries-card'>
                                                <LiaIndustrySolid className='img-home-industries-card' />
                                            </p>
                                            <Card.Title><h3>{industry.title}</h3></Card.Title>
                                            <Card.Text>{industry.text}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            ) : (
                                <div className='c-default h-100'>
                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        <Card.Body>
                                            <p className='img-home-industries-card'>
                                                <LiaIndustrySolid className='img-home-industries-card' />
                                            </p>
                                            <Card.Title><h3>{industry.title}</h3></Card.Title>
                                            <Card.Text>{industry.text}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className='industries-navi-div mt-5'>
                <Link reloadDocument to='/industries' className=' navi-page-industries'><HiArrowLongRight /> </Link>
            </div>
        </div>
    )
}

export default Industries
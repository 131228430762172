import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button } from 'react-bootstrap';
import './Blockchain.css'
import BreadCrumbs from '../../BreadCrumbs';

const BlockchainBanner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { countryCode } = props;



  return (

    <div className='web3-banner'>

      <BreadCrumbs />
      <div className='banner-left-sec  min-100vh'>
        <h1>Market Leading <span className='changing-banner-content'>Blockchain</span><span className='home-banner-break'><br /></span> Marketing Agency</h1>
        <p>Maximize your blockchain project's potential with XDMinds, the premier blockchain marketing agency. Our innovative strategies and unmatched expertise guarantee your brand's prominence in the dynamic blockchain environment. Collaborate with us to achieve growth, engagement, and success.</p>
        <div className='btn-home-banner-request-div'>
          <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
        </div>
        <ModalForm
          countryCode={countryCode}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>

    </div>
  )
}

export default BlockchainBanner
import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What types of games do you market?",
    answer: `We market a wide variety of games, including mobile, console, PC, Web3, VR, AR, blockchain, NFT, crypto, and betting games.`
  },
  {
    question: "How do you tailor your marketing strategies to different games?",
    answer: `Our strategies are customized based on the game's genre, target audience, and unique features. We conduct thorough market research to develop the most effective approach for each game.`
  },
  {
    question: "What marketing channels do you use?",
    answer: `We utilize a multi-channel approach, including social media, influencer partnerships, email marketing, online ads, content marketing, and community engagement.`
  },
  {
    question: "How do you measure the success of your marketing campaigns?",
    answer: `We use advanced analytics tools to track key performance indicators (KPIs) such as player acquisition, engagement, retention, and return on investment (ROI). We provide regular reports to keep you informed of campaign performance.`
  },
  {
    question: "Can you help with pre-launch marketing for my game?",
    answer: `Yes, we offer comprehensive pre-launch marketing services, including teaser campaigns, beta testing promotions, and community building to create buzz and anticipation for your game's release.`
  },
  {
    question: "Do you offer post-launch support?",
    answer: `Absolutely. We provide ongoing marketing support post-launch to maintain player engagement, encourage user-generated content, and manage updates and events.`
  },
  {
    question: "How do you engage with the gaming community?",
    answer: `We build and nurture player communities through social media, forums, and in-game events. We encourage interaction and feedback to keep players engaged and loyal.`
  },
  {
    question: "What makes XDMinds different from other gaming marketing agencies?",
    answer: `Our deep industry knowledge, innovative strategies, personalized approach, and commitment to delivering exceptional results set us apart. We focus on understanding your game and audience to create impactful campaigns.`
  },
  {
    question: "Can you handle international marketing?",
    answer: `Yes, we have experience in global marketing and can tailor strategies to different regions, ensuring your game reaches a worldwide audience.`
  },
  {
    question: "How do I get started with XDMinds?",
    answer: `Simply contact us to discuss your gaming marketing needs. We'll set up an initial consultation to understand your goals and develop a customized marketing plan to achieve them.`
  },

];

const GameFAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div>
      <div className='blockchain-sec-6-faq'>
        <div className='faq'>
          <Container>
            <Row>
              <Col>
                <div>
                  <h2>Frequently Asked Questions</h2>
                </div>
                <div className='blockchain-faq-qa'>
                  {faqData.map((faq, index) => (
                    <div key={index}>
                      <div
                        onClick={() => handleFAQClick(index)}
                        aria-controls={`faq${index}`}
                        aria-expanded={openFAQ === index}
                        className='faq-1st'
                      >
                        <div className='faq-content'>
                          <h6 className='faq-content-question'>{faq.question}</h6>
                        </div>
                        <div className='faq-icons'>
                          {openFAQ === index ? (
                            <IoIosArrowDropupCircle className='close-circle-faq' />
                          ) : (
                            <IoIosArrowDropdownCircle className='plus-circle-faq' />
                          )}
                        </div>
                      </div>
                      <Collapse in={openFAQ === index}>
                        <div id={`faq${index}`} className='faq-answer-content'>
                          {renderAnswer(faq.answer)}
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default GameFAQ
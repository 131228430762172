import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import '../Metaverse/Metaverse.css'
import { FaSkype, FaWhatsapp } from 'react-icons/fa6'
import { FaTelegramPlane } from 'react-icons/fa'

const WebSection1 = () => {

    return (
        <div className='crypto-sec-1'>
            <div className='blockchain-sec2'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2> XDMinds: Your All-in-One Web3 Digital Marketing <span className='metaverse-sec2-break'><br /></span> Agency for Maximum ROI
                                </h2>
                                <p>At XDMinds, we understand the complexities and opportunities of the Web3 ecosystem. As a premier Web3 marketing agency, we are committed to guiding you through the intricacies of this dynamic landscape. Our team of experts leverages deep industry knowledge and innovative marketing techniques to help you navigate and thrive in the decentralized world. Whether you're launching a new project or looking to enhance your current Web3 presence, XDMinds offers comprehensive solutions tailored to your unique needs. With a focus on results-driven strategies and cutting-edge technologies, we are your trusted partner in achieving unparalleled success in the Web3 space.
                                </p>
                            </div>
                            <div className='btn-mchallenge'>
                                <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Web3 marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/web3-marketing-services' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                    <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                                </a>
                                <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                                </a>
                                <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default WebSection1
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './Mchallenge.css';
import { FaSkype, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";

const Mchallenge = () => {
  return (
    <div className='m-challenge'>
      <Container>
        <Row>
          <Col>
            <div className='m-challenge-content'>
              <p className='challenge-content'>
                Tell us about your marketing obstacle , and we'll <span className='mc-break'><br /> </span>create a personalized solution for you.
              </p>
              <p>
                Is your marketing feeling more like a monster than a masterpiece? Don't fret! We specialize in slaying marketing obstacles and crafting custom plans to turn your campaigns into conquering heroes. Share your struggle, and we'll be your marketing mastermind, free of charge.


              </p>
            </div>
            <div className='btn-mchallenge'>
              <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Web3 marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
              </a>
              <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
              </a>
              <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                <Button className='challenge-skype'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Mchallenge
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';

const AISection3 = () => {
    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'Value-led AI Marketing Strategy',
            text: "Our value-led AI marketing strategy focuses on delivering maximum value to your business by leveraging AI to enhance customer engagement, optimize campaigns, and drive ROI. We create tailored strategies that align with your business goals and market demands.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'AI Chatbot Marketing',
            text: "Enhance customer interactions and support with AI-powered chatbots that provide instant, accurate responses. Our AI chatbot marketing solutions improve customer satisfaction, streamline communication, and boost conversion rates.",
        },
        {
            link: '/generative-engine-optimization-agency',
            imgSrc: <FaChartLine />,
            title: 'Generative AI Content Marketing',
            text: " Leverage the power of generative AI to create high-quality, engaging content tailored to your audience. Our AI-driven content marketing solutions ensure your brand stands out with unique, relevant, and compelling content.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Brand Marketing',
            text: ' Build a strong, recognizable brand with AI-driven brand marketing strategies. We use AI to analyze market trends, customer preferences, and competitive landscapes, helping you create a distinctive and impactful brand identity.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Product Marketing',
            text: 'Optimize your product marketing efforts with AI. We use AI tools to identify target audiences, predict trends, and personalize marketing messages, ensuring your products reach the right customers at the right time.',
        },
        {
            link: '/seo-agency',
            imgSrc: <FaChartLine />,
            title: 'SEO Marketing',
            text: "Boost your search engine rankings with AI-driven SEO marketing. Our AI tools analyze search trends, optimize content, and improve your website's visibility, driving more organic traffic and higher conversions.",
        },
        {
            link: '/ppc-advertising-agency',
            imgSrc: <FaChartLine />,
            title: 'PPC Advertising',
            text: 'Maximize your ad spend efficiency with AI-driven PPC advertising. Our AI algorithms optimize bidding strategies, target the right audiences, and improve ad performance, ensuring you get the best ROI from your campaigns.',
        },
        {
            link: '/social-media-marketing-agency',
            imgSrc: <FaChartLine />,
            title: 'Social Media Marketing',
            text: ' Enhance your social media presence with AI-driven strategies. We use AI to analyze social media trends, optimize content, and engage with your audience, driving higher engagement and brand loyalty.'
        },
        {
            link: '/mobile-app-marketing-agency',
            imgSrc: <FaChartLine />,
            title: 'Mobile App Marketing',
            text: "Increase your mobile app's visibility and downloads with AI-driven marketing strategies. We use AI to target the right users, personalize marketing messages, and optimize app store listings, ensuring your app's success.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Email Marketing',
            text: ' Improve your email marketing campaigns with AI-driven solutions. Our AI tools analyze user behavior, segment audiences, and personalize content, resulting in higher open rates, engagement, and conversions.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Inbound Marketing',
            text: 'Attract, engage, and delight customers with AI-driven inbound marketing. We use AI to create personalized content, optimize user journeys, and nurture leads, turning prospects into loyal customers.',
        },
        {
            link: '/influencer-marketing-agency',
            imgSrc: <FaChartLine />,
            title: 'Influencer Marketing',
            text: "Boost your brand's reach and credibility with AI-driven influencer marketing. Our AI tools identify the best influencers for your brand, analyze their impact, and optimize collaborations for maximum ROI.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Community Marketing',
            text: 'Build and nurture a strong community around your brand with AI-driven community marketing. We use AI to engage with your audience, foster meaningful interactions, and drive brand advocacy.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Media & PR Marketing',
            text: 'Enhance your media and PR efforts with AI-driven strategies. Our AI tools analyze media trends, optimize press releases, and identify the best channels for your brand, ensuring maximum exposure and impact.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Instant Message Marketing',
            text: ' Leverage instant messaging platforms with AI-driven marketing solutions. We use AI to personalize messages, automate responses, and engage with your audience in real-time, driving higher engagement and conversions.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Audio Marketing',
            text: " Reach your audience through AI-driven audio marketing. Our AI tools create personalized audio content, optimize ad placements, and analyze listener behavior, ensuring your audio marketing campaigns are effective and impactful.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Video Marketing',
            text: " Create compelling video content with AI-driven video marketing solutions. We use AI to analyze viewer preferences, optimize video content, and personalize messages, driving higher engagement and conversions.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Omnichannel Marketing',
            text: " Deliver a seamless customer experience across all channels with AI-driven omnichannel marketing. Our AI tools integrate data from multiple sources, personalize interactions, and optimize campaigns for a cohesive brand experience.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Personalized Marketing',
            text: "Enhance your marketing efforts with AI-driven personalized marketing. We use AI to analyze customer data, segment audiences, and create tailored messages, ensuring your marketing is relevant and impactful.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Affiliate Marketing',
            text: "Optimize your affiliate marketing efforts with AI-driven solutions. Our AI tools analyze affiliate performance, identify top-performing partners, and optimize campaigns, driving higher conversions and ROI.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Smart Search Marketing',
            text: "Improve your search marketing with AI-driven strategies. Our AI tools analyze search behavior, optimize keywords, and personalize search results, ensuring your brand appears at the top of search results.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Marketing for Industries',
            text: " Tailor your marketing strategies to specific industries with AI-driven solutions. We use AI to analyze industry trends, identify opportunities, and create targeted campaigns, ensuring your marketing is effective and relevant.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'AI & ML Integration',
            text: "Leverage the power of AI & ML with XDMinds' digital marketing solutions. Our advanced analytics and automation tools optimize campaigns, enhance targeting, and maximize ROI.",
        },

        {
            imgSrc: <FaChartLine />,
            title: 'Marketing Consultation',
            text: "Get expert advice and insights with our AI-driven marketing consultation services. Our team of AI experts provides personalized recommendations, strategic guidance, and actionable insights to optimize your marketing efforts and drive growth.",
        },
    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [countriesswiper, setCountriesSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (countriesswiper !== null) {
            countriesswiper.autoplay.stop();
        }
    };

    const handleMouseLeave = () => {
        if (countriesswiper !== null) {
            countriesswiper.autoplay.start();
        }
    };
    return (
        <div className='AI-sec-3'>
            <div className='blockchain-sec-4'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>XDMinds:  Delivering Exceptional ROI with<span className='msec4-break'><br />
                                </span> Innovative AI -Driven Digital Marketing Services
                                </h2>
                            </div>
                            <div className='industries-sub-head'>
                                <p>
                                    XDMinds excels in providing innovative AI marketing services and solutions that deliver exceptional ROI. Our comprehensive approach integrates AI-powered tools and techniques to enhance every aspect of your marketing strategy. Whether it's through predictive analytics, personalized content, or automated workflows, we ensure that your marketing efforts are efficient, effective, and impactful. With XDMinds, you gain a competitive edge by leveraging the full potential of AI to drive growth, engagement, and success in today's dynamic market.
                                </p>
                            </div>

                            <div className='desktop-industries-view'>
                                {chunkedCardData.map((chunk, index) => (
                                    <div className='home-industries-card-div' key={index}>
                                        <Row>
                                            {chunk.map((card, cardIndex) => (
                                                <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                    {card.link ? (
                                                        <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Link>
                                                    ) : (
                                                        <div className='c-default h-100'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}

                            </div>
                            <div className='mobile-view-industries'>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop={true}
                                    dots="true"
                                    onSwiper={(swiper) => setCountriesSwiper(swiper)}
                                    modules={[Scrollbar, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{ clickable: true }}
                                    className="ps-swiper"
                                    breakpoints={{
                                        0: { slidesPerView: 1, spaceBetween: 10 },
                                        460: { slidesPerView: 1, spaceBetween: 5 },
                                        768: { slidesPerView: 1, spaceBetween: 30 },
                                        1024: { slidesPerView: 6, spaceBetween: 50 },
                                    }}
                                >
                                    {cardData.map((card, index) => (
                                        <SwiperSlide key={index}>
                                            {card.link ? (
                                                <Link reloadDocument to={card.link} className='text-decoration-none'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            ) : (
                                                <div className='c-default h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default AISection3
import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import GridCard from './GridCard';
import graph from '../../assets/images/graph.svg';
import Tabs from './Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import BreadCrumbs from '../../Components/BreadCrumbs';

const SEMAgency = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;



    const cardData = [
        {
            src: graph,
            alt: 'Campaign Strategy and Planning',
            title: 'Campaign Strategy and Planning',
            text: 'Develop a comprehensive SEM campaign strategy tailored to your business goals, ensuring precise planning and execution for optimal results.'
        },
        {
            src: graph,
            alt: 'Keyword Research',
            title: 'Keyword Research',
            text: 'Conduct thorough keyword research to identify the most relevant and high-performing keywords that will drive targeted traffic to your website.'
        },
        {
            src: graph,
            alt: 'Competitor Analysis',
            title: 'Competitor Analysis',
            text: "Analyze your competitors' SEM strategies to uncover opportunities and create a competitive edge for your campaigns."
        },
        {
            src: graph,
            alt: 'SEM Campaign Setup',
            title: 'SEM Campaign Setup',
            text: "Set up your SEM campaigns with meticulous targeting to reach your ideal audience and maximize the effectiveness of your ads."
        },
        {
            src: graph,
            alt: 'Ad Copy Creation and Optimization',
            title: 'Ad Copy Creation and Optimization',
            text: 'Craft compelling ad copy and continuously optimize it to improve click-through rates (CTR) and conversion rates.'
        },
        {
            src: graph,
            alt: 'Bid Management and Budget Allocation',
            title: 'Bid Management and Budget Allocation',
            text: 'Manage bids and allocate budgets strategically to ensure cost-effective advertising and maximum ROI.'
        },
        {
            src: graph,
            alt: 'Ad Extensions Optimization',
            title: 'Ad Extensions Optimization',
            text: 'Optimize ad extensions to enhance the visibility and performance of your ads, providing additional information to potential customers.'
        },
        {
            src: graph,
            alt: 'Google Ads',
            title: 'Google Ads',
            text: 'Utilize Google Ads to create and manage campaigns that reach a vast audience and drive significant traffic to your website.'
        },
        {
            src: graph,
            alt: 'Microsoft Ads',
            title: 'Microsoft Ads',
            text: "Leverage Microsoft Ads to tap into an additional audience and expand your reach across different search engines."
        },
        {
            src: graph,
            alt: 'Local SEM Campaigns',
            title: 'Local SEM Campaigns',
            text: "Implement local SEM campaigns to target customers in specific geographic areas, increasing local visibility and driving foot traffic."
        },
        {
            src: graph,
            alt: 'Shopping Ads',
            title: 'Shopping Ads',
            text: "Create and manage shopping ads to showcase your products prominently in search results, driving sales and revenue."
        },
        {
            src: graph,
            alt: "Mobile Advertising ",
            title: "Mobile Advertising ",
            text: "Optimize ads for mobile devices and run app install campaigns to capture the growing mobile audience and increase app downloads."
        },
        {
            src: graph,
            alt: 'Display Advertising',
            title: "Display Advertising",
            text: "Design and implement display advertising campaigns with engaging banner and video ads to build brand awareness and reach a wider audience."
        },
        {
            src: graph,
            alt: 'Audience Remarketing and Retargeting',
            title: 'Audience Remarketing and Retargeting',
            text: "Utilize remarketing and retargeting strategies to re-engage past visitors and convert them into customers with personalized ads."
        },
        {
            src: graph,
            alt: 'Conversion Rate Optimization (CRO)',
            title: 'Conversion Rate Optimization (CRO)',
            text: "Enhance conversion rates through landing page optimization and ad copy A/B testing, ensuring a seamless user experience."
        },
        {
            src: graph,
            alt: 'Conversion Tracking and Analytics',
            title: 'Conversion Tracking and Analytics',
            text: "Implement robust conversion tracking and analytics to monitor campaign performance and make data-driven decisions for continuous improvement."
        },
        {
            src: graph,
            alt: 'Customized Reporting and Insights',
            title: 'Customized Reporting and Insights',
            text: "Provide customized reports and insights that offer a clear understanding of your SEM campaign's performance and areas for enhancement."
        },
        {
            src: graph,
            alt: 'SEM Consulting',
            title: 'SEM Consulting',
            text: "Offer expert SEM consulting services to guide you through best practices, strategy development, and campaign optimization for long-term success."
        },

    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Tailored SEM Strategies',
            sections: [
                {
                    content: 'We understand that each business is unique, and so are its marketing needs. At XDMinds, we develop tailored SEM strategies that align with your specific business objectives and target audience. Our personalized approach ensures maximum relevance and effectiveness of your campaigns.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'In-Depth Market Research',
            sections: [
                {
                    content: 'Effective SEM starts with understanding the market landscape. We conduct comprehensive market research to identify industry trends, competitor activities, and customer behavior. This deep dive into market dynamics helps us craft strategies that give you a competitive edge.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Advanced Keyword Research',
            sections: [
                {
                    content: 'Keywords are the backbone of any successful SEM campaign. Our experts use advanced tools and techniques to perform meticulous keyword research, identifying high-performing keywords that will drive quality traffic to your site. We focus on long-tail keywords to capture more targeted and conversion-ready audiences.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Competitor Analysis',
            sections: [
                {
                    content: 'Staying ahead of the competition is crucial. We perform thorough competitor analysis to uncover their strategies, strengths, and weaknesses. This insight allows us to position your SEM campaigns more effectively and exploit market opportunities they may have missed.'
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Strategic Campaign Planning',
            sections: [
                {
                    content: 'A well-planned campaign is essential for success. We create a detailed SEM campaign plan that outlines our approach, from ad creation to budget allocation and bid management. Our strategic planning ensures that every aspect of your campaign is optimized for the best results.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Creative Ad Copy and Design',
            sections: [
                {
                    content: "Our team of creative professionals crafts compelling ad copy and eye-catching designs that resonate with your target audience. We emphasize clear, persuasive messaging that drives clicks and conversions while maintaining your brand's voice and identity."
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Precision Targeting',
            sections: [
                {
                    content: 'Reaching the right audience at the right time is key to SEM success. We use precision targeting techniques to ensure your ads are seen by the most relevant and interested users. This approach maximizes the efficiency of your ad spend and improves overall campaign performance.'
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Continuous Optimization',
            sections: [
                {
                    content: "SEM is not a set-and-forget strategy. We continuously monitor and optimize your campaigns to improve performance. Our team conducts regular A/B testing, analyzes performance data, and makes data-driven adjustments to enhance your campaign's effectiveness."
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Comprehensive Reporting and Insights',
            sections: [
                {
                    content: "Transparency and clarity are essential in understanding your campaign's success. We provide comprehensive reports that detail key performance metrics, insights, and recommendations. Our reports are designed to help you understand the impact of our SEM efforts and identify areas for further improvement."
                }
            ]
        },
        {
            id: 'pills-tenth',
            label: '10',
            title: 'Expert Guidance and Support',
            sections: [
                {
                    content: "Our commitment to your success doesn’t end with campaign execution. We offer ongoing expert guidance and support to ensure your SEM strategy evolves with changing market conditions and business needs.Our team is always available to answer your questions and provide strategic advice."
                }
            ]
        },
        {
            id: 'pills-eleven',
            label: '11',
            title: 'Holistic Digital Marketing Integration',
            sections: [
                {
                    content: "SEM works best when integrated with other digital marketing efforts. At XDMinds, we ensure your SEM campaigns are seamlessly integrated with your overall digital marketing strategy, including SEO, content marketing, and social media marketing. This holistic approach amplifies your online presence and drives better results."
                }
            ]
        },
        {
            id: 'pills-twelve',
            label: '12',
            title: 'Focus on ROI',
            sections: [
                {
                    content: "Ultimately, our goal is to deliver a strong return on investment (ROI) for your business. We focus on strategies that not only drive traffic but also convert visitors into customers. Our performance-driven approach ensures that your marketing dollars are spent wisely and effectively."
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What is SEM and how can it benefit my business?",
            answer: "SEM, or Search Engine Marketing, is a digital marketing strategy used to increase the visibility of a website in search engine results pages (SERPs) through paid advertising. SEM can benefit your business by driving targeted traffic to your website, increasing brand awareness, and generating leads and conversions, ultimately boosting your revenue."
        },
        {
            question: "How does XDMinds develop an effective SEM strategy for my business?",
            answer: "At XDMinds, we start by understanding your business goals, target audience, and industry landscape. We conduct thorough market research and competitor analysis, followed by detailed keyword research. Using these insights, we create a customized SEM strategy that includes ad creation, precise targeting, and continuous optimization to ensure your campaigns deliver maximum results."
        },
        {
            question: "What types of SEM campaigns does XDMinds offer?",
            answer: "XDMinds offers a wide range of SEM campaigns, including Google Ads, Microsoft Ads, local SEM campaigns, shopping ads, mobile advertising, display advertising, and audience remarketing. We tailor each campaign to meet your specific business objectives and reach your target audience effectively."
        },
        {
            question: "How does XDMinds ensure my SEM campaigns reach the right audience?",
            answer: "We use precision targeting techniques, including demographic targeting, geographic targeting, and behavioral targeting, to ensure your ads are shown to the most relevant and interested users. Additionally, we continuously monitor and optimize your campaigns to refine targeting and improve performance."
        },
        {
            question: "What is included in XDMinds' keyword research process?",
            answer: "Our keyword research process involves identifying high-performing and relevant keywords that align with your business goals. We use advanced tools to analyze search volume, competition, and user intent. We focus on both short-tail and long-tail keywords to capture a broad and targeted audience, respectively."
        },
        {
            question: "How does XDMinds optimize ad copy and design?",
            answer: "Our creative team crafts compelling ad copy that resonates with your target audience and drives engagement. We continuously test and optimize ad copy through A/B testing to improve click-through rates (CTR) and conversion rates. Our designers create visually appealing ads that maintain your brand's identity and attract user attention."
        },
        {
            question: "What metrics does XDMinds track to measure SEM campaign performance?",
            answer: "We track a range of key performance metrics, including impressions, clicks, click-through rates (CTR), cost per click (CPC), conversion rates, cost per conversion, and return on ad spend (ROAS). These metrics provide a comprehensive view of your campaign's performance and help us make data-driven optimizations."
        },
        {
            question: " How does XDMinds handle bid management and budget allocation?",
            answer: "Our team of experts manages bids and allocates budgets strategically to ensure cost-effective advertising. We continuously monitor bidding strategies and adjust bids based on performance data to maximize ROI and stay within your budget constraints."
        },
        {
            question: "What is conversion rate optimization (CRO) and how does XDMinds implement it?",
            answer: "Conversion rate optimization (CRO) involves improving the percentage of website visitors who complete desired actions, such as making a purchase or filling out a form. At XDMinds, we implement CRO through landing page optimization, ad copy A/B testing, and analyzing user behavior to identify and address conversion barriers."
        },
        {
            question: "How often does XDMinds provide reporting and insights?",
            answer: "We provide regular, comprehensive reports that detail your SEM campaign's performance, key metrics, and insights. Our reports include actionable recommendations for further optimization. We ensure transparency and keep you informed about the progress and impact of your SEM efforts."
        },
        {
            question: "Can XDMinds integrate SEM with my other digital marketing efforts?",
            answer: "Yes, XDMinds takes a holistic approach to digital marketing. We integrate your SEM campaigns with other digital marketing strategies, including SEO, content marketing, and social media marketing. This integration ensures a cohesive and effective online presence, amplifying your overall digital marketing efforts."
        },
        {
            question: "What kind of support and consultation does XDMinds offer for SEM services?",
            answer: "We offer ongoing support and consultation to ensure your SEM strategy remains effective and aligned with your business goals. Our team is always available to answer your questions, provide strategic advice, and make adjustments to your campaigns as needed to achieve optimal results."
        },
    ]

    return (
        <div className='digital-marketing seo'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Professional <span className='s-text-3'>SEM</span> Agency  for <br className='break' />
                    Effective SEM Marketing Solutions
                </h1>
                <p className='p-text-1'>
                    Discover the true potential of your business with XDMinds, your go-to SEM agency for impactful search engine marketing solutions. Our team of experts crafts customized SEM strategies designed to attract high-quality traffic, boost conversions, and enhance your brand's visibility online. Partner with us and witness exceptional digital growth through precisely targeted SEM campaigns that deliver results.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>

            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    How Can Our Search Engine Marketing Agency<br className='break' /> Benefit Your Business?
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we understand the dynamic nature of digital marketing and the crucial role that search engine marketing (SEM) plays in achieving online success. Our comprehensive SEM services are designed to enhance your online visibility, attract high-quality leads, and maximize your return on investment (ROI). By leveraging advanced tools and strategies, we ensure your business stands out in the competitive digital landscape.
                </p>
                <div className='social-buttons'>
                    <button className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </button>
                    <button className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </button>
                    <button className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </button>
                </div>
            </section>

            <section className='digital-marketing-section-3'>
                <h2 className='h-text-3'>
                    XDMinds: Your Trusted SEM Partner<br className='break' />for End-to-End Search Engine Marketing and Digital Growth
                </h2>
                <div className='custom-div'>
                    <p className='p-text-3'>
                        XDMinds is your trusted partner for end-to-end search engine marketing (SEM) solutions and digital growth. As the best SEM agency, we combine our deep industry knowledge, innovative strategies, and cutting-edge technology to deliver exceptional SEM services tailored to your unique business needs. Our team of seasoned SEM experts is dedicated to driving measurable results, from increasing website traffic to boosting conversions and revenue. We work closely with you to understand your business objectives, develop a customized SEM plan, and execute it with precision, ensuring sustainable digital growth and a competitive edge in the market.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Drive High-Quality Traffic and Conversions with XDMinds' <br className='break' />Cutting-Edge Search Engine Marketing Services
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we harness the power of cutting-edge search engine marketing (SEM) services to drive high-quality traffic and conversions for your business. Our holistic approach encompasses everything from meticulous keyword research to advanced bid management, ensuring your ads reach the right audience at the right time. With a focus on continuous optimization and performance tracking, we fine-tune your campaigns to maximize ROI and achieve your business goals. Let us elevate your online presence with our comprehensive SEM solutions designed to deliver tangible results.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Why XDMinds Stands Out as Your Top SEM Agency?
                </h2>
                <p className='p-text-2'>Choosing the right search engine marketing (SEM) agency can make a significant difference in achieving your business goals. At XDMinds, we go beyond the conventional to offer exceptional SEM services that set us apart. Here’s why XDMinds stands out as your top SEM agency, step by step:</p>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath="Latest Search Engine Marketing Insights" />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default SEMAgency
import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './WorkCountry.css';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import france from '../../../Images/Countries/web3-digital-markeing-agency-in-france.webp';
import dubai from '../../../Images/Countries/web3-digital-markeing-agency-in-dubai-uae.webp';
import hongkong from '../../../Images/Countries/web3-digital-markeing-agency-in-hong-kong.webp';
import uk from '../../../Images/Countries/web3-digital-markeing-agency-in-uk.webp';
import singapore from '../../../Images/Countries/web3-digital-markeing-agency-in-singapore.webp';
import India from '../../../Images/Countries/web3-digital-markeing-agency-in-india.webp';


const WorkCountry = () => {
  const countriesData = [
    {
      title: 'India',
      imgSrc: India,
      address: '61, V.P. Complex, T.P.K Main Road, Vasantha Nagar, Madurai, Tamil Nadu, India 625003',
      alt: 'web3-digital-markeing-agency-in-india',
      tclass: 'india-class',

    },
    {
      title: 'France',
      imgSrc: france,
      address: '19 Bd de la Mer, 64700 Hendaye, France',
      alt: 'web3-digital-markeing-agency-in-france',
      tclass: 'france-class',

    },
    {
      title: 'Dubai',
      imgSrc: dubai,
      address: '103, Al Khail gate phase 1, Al Quoz 2,Dubai',
      alt: 'web3-digital-markeing-agency-in-dubai-uae',
      tclass: 'dubai-class',



    },
    {
      title: 'Hong Kong',
      imgSrc: hongkong,
      address: "Unit 1603, 16th Floor, The L. Plaza, 367 - 375 Queen's Road Central, Sheung Wan, Hong Kong",
      alt: 'web3-digital-markeing-agency-in-hong-kong',
      tclass: 'hk',
    },
  {
    title: 'United Kingdom',
    imgSrc: uk,
    address: '23 Oxbridge Avenue, Stockton-on-Tees, N/A, United Kingdom TS18 4JD',
    alt:'web3-digital-markeing-agency-in-uk',
    tclass: 'uk-class',
contentClass:'uk-content',
  },
  {
    title: 'Singapore',
    imgSrc: singapore,
    address: '35 Kallang pudding road, #06-12 Tong Lee Building Block A, Singapore 349314',
    alt:'web3-digital-markeing-agency-in-singapore',
    tclass: 'singapore-class',
  },
  ];


const [countriesswiper, setCountriesSwiper] = useState(null);

const goNext = () => {
  if (countriesswiper !== null) {
    countriesswiper.slideNext();
  }
};

const goPrev = () => {
  if (countriesswiper !== null) {
    countriesswiper.slidePrev();
  }
};

const handleMouseEnter = () => {
  if (countriesswiper !== null) {
    countriesswiper.autoplay.stop();
  }
};

const handleMouseLeave = () => {
  if (countriesswiper !== null) {
    countriesswiper.autoplay.start();
  }
};

return (
  <div className='world-country'>
    <Container>
      <Row>
        <Col>
          <div className='country-content'>
            <p className='country-content-head'>
              Beyond Borders, Beyond Limits<span className='world-break'><br /></span> Your Marketing Partner for International Success
            </p>
          </div>
          <div className='swiper-components-wc-div'>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              loop={true}
              dots="true"
              onSwiper={(swiper) => setCountriesSwiper(swiper)}
              modules={[Scrollbar, Pagination, Autoplay]}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              className="ps-swiper"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
              }}
            >
              {countriesData.map((country, index) => (
                <SwiperSlide key={index}>
                  <article className="card" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <img
                      className="card__background"
                      src={country.imgSrc}
                      alt={country.alt}
                      width="1920"
                      height="2193"
                    />
                    <div className={`card__content | flow ${country.contentClass}`}>
                      <div className="card__content--container | flow">
                        <p className={`card__title ${country.tclass}`}>{country.title}</p>
                        <br />
                        <p className="card__description">
                          {country.address}
                        </p>
                      </div>
                    </div>
                  </article>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="navi-wc-div-ps">
            <Button className="btn-navi-prev" onClick={goPrev}><RiArrowLeftSLine /></Button>
            <Button className="btn-navi-next" onClick={goNext}><RiArrowRightSLine /></Button>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);
}

export default WorkCountry;

import React from 'react'
import Tabs from '../../../Pages/DigitalMarketing/Tabs'
import '../../../assets/css/style.css'

const Section3 = () => {
    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Comprehensive Market Research',
            sections: [
                {
                    content: "We begin by conducting thorough market research to understand the current landscape, identify key competitors, and analyze potential audiences. This research helps us pinpoint the unique value propositions of your crypto project and tailor our strategies to effectively position it in the market."
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Defining Clear Objectives and KPIs',
            sections: [
                {
                    content: 'Setting clear objectives and key performance indicators (KPIs) is crucial for measuring success. We work with you to define specific, measurable goals for your crypto project, such as increasing awareness, driving engagement, or achieving token sales. Our approach ensures that every strategy and tactic aligns with these objectives.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Crafting a Compelling Narrative',
            sections: [
                {
                    content: 'A compelling narrative is essential for capturing attention and building trust. We develop a strong, engaging story for your crypto project that highlights its benefits, technology, and vision. This narrative will be consistently communicated across all marketing channels to resonate with your target audience and differentiate your project from others.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Building a Robust Online Presence',
            sections: [
                {
                    content: "Establishing a robust online presence is key to gaining traction. We create and optimize your website, ensuring it is user-friendly, informative, and optimized for search engines. Additionally, we set up and manage profiles on relevant social media platforms and crypto forums to increase visibility and engagement."
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Implementing Targeted Content Strategies',
            sections: [
                {
                    content: 'Content is a powerful tool for educating and engaging potential investors and users. We develop a comprehensive content strategy that includes blog posts, whitepapers, case studies, and thought leadership articles. This content is designed to inform, engage, and position your project as an authority in the crypto space.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Leveraging Influencer Partnerships',
            sections: [
                {
                    content: "Influencer marketing can significantly boost your project's visibility and credibility. We identify and partner with influential figures in the crypto community to amplify your message. Our strategic approach ensures that these partnerships are authentic and aligned with your project’s goals."
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: ' Executing Strategic PR Campaigns',
            sections: [
                {
                    content: "Public relations play a crucial role in building credibility and attracting attention. We craft and execute strategic PR campaigns to secure media coverage, press releases, and interviews in prominent crypto and financial publications. This exposure helps establish your project's legitimacy and reach a wider audience."
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Running Targeted Advertising Campaigns',
            sections: [
                {
                    content: "Paid advertising can accelerate your project's growth. We design and manage targeted advertising campaigns across various platforms, including social media, search engines, and crypto-specific sites. Our data-driven approach ensures that your ads reach the right audience and deliver measurable results."
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Engaging with the Community',
            sections: [
                {
                    content: "Building a strong community around your crypto project is essential for long-term success. We engage with potential users and investors through community forums, social media, and online events. Our efforts focus on fostering relationships, addressing concerns, and encouraging active participation in your project."
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Monitoring, Analyzing, and Optimizing',
            sections: [
                {
                    content: 'Continuous monitoring and analysis are vital for refining strategies and achieving desired outcomes. We track key metrics and performance data to evaluate the effectiveness of our efforts. Based on these insights, we make data-driven adjustments to optimize campaigns and ensure ongoing growth and traction.'
                }
            ]
        },
        {
            id: 'pills-eleven',
            label: '11',
            title: 'Scaling and Expanding Reach',
            sections: [
                {
                    content: 'Once initial traction is achieved, we focus on scaling your marketing efforts to expand reach and drive sustained growth. This includes exploring new marketing channels, increasing ad spend, and leveraging advanced tactics to continue building momentum for your crypto project.'
                }
            ]
        },
    ]
    return (

        <div className='digital-marketing ppc'>
            <section className='digital-marketing-section-2 l-bg'>
                <h2 className='h-text-2 text-center'>
                    From Zero to Traction XDMinds’ Step-by-Step<br />  Approach to Marketing Your Crypto Project
                </h2>
                <p className='p-text-2 text-center'>
                    Marketing a crypto project involves navigating a dynamic and competitive landscape. At XDMinds, we use a systematic, step-by-step approach to propel your project from zero visibility to significant traction. Here’s how we do it:
                </p>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
        </div>
    )
}

export default Section3
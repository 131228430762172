import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import DSwiper from './DSwiper';
import EventCard from './EventCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import GridCard from './GridCard';
import graph from '../../assets/images/graph.svg';
import Tabs from './Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import BreadCrumbs from '../../Components/BreadCrumbs';


const SearchEngineOptimization = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const eventCardData = [
        {
            title: "Metaverse SEO",
            sections: [
                {
                    content: "Optimize your presence in the Metaverse with our targeted SEO strategies. We focus on enhancing visibility and discoverability in virtual worlds, ensuring your Metaverse projects reach their full potential. Our expert team adapts SEO techniques to the unique dynamics of the Metaverse ecosystem."
                }
            ]
        },
        {
            title: "Blockchain SEO",
            sections: [
                {
                    content: "Boost your blockchain project’s online visibility with our specialized SEO services. We optimize your site for relevant keywords, enhance technical performance, and build authoritative backlinks. Our strategies ensure your blockchain solutions stand out in a competitive market."
                }
            ]
        },
        {
            title: "NFT SEO",
            sections: [
                {
                    content: "Maximize the reach of your NFT projects through our expert NFT SEO services. We optimize your content and listings to attract more collectors and investors. Our approach ensures your NFTs gain visibility, driving higher engagement and sales."
                }
            ]
        },
        {
            title: "DeFi SEO",
            sections: [
                {
                    content: "Enhance your DeFi platform’s search engine rankings with our comprehensive DeFi SEO services. We focus on keyword optimization, technical SEO, and content marketing to attract more users. Our strategies help you gain a competitive edge in the DeFi space."
                }
            ]
        },
        {
            title: "dApp  SEO",
            sections: [
                {
                    content: "Improve the discoverability of your decentralized applications with our specialized dApp SEO services. We optimize your dApp for search engines, ensuring it reaches a broader audience. Our techniques drive organic traffic and increase user adoption."
                }
            ]
        },
        {
            title: "Crypto  SEO",
            sections: [
                {
                    content: "Elevate your crypto projects with our dedicated SEO services for exchanges, tokens, and wallets. We optimize your online presence to attract more traders, investors, and users. Our strategies are designed to boost visibility, credibility, and engagement in the crypto market."
                }
            ]
        },
    ]

    const cardData = [
        {
            src: graph,
            alt: 'SEO Strategy / Roadmap Creation',
            title: 'SEO Strategy / Roadmap Creation',
            text: 'Develop a strategic roadmap tailored to your business goals. We create actionable SEO plans that guide your efforts and ensure long-term success.'
        },
        {
            src: graph,
            alt: 'Website SEO Audit',
            title: 'Website SEO Audit',
            text: 'Conduct a thorough audit of your website to identify areas of improvement. Our detailed analysis helps uncover technical issues and optimization opportunities.'
        },
        {
            src: graph,
            alt: 'On-Page SEO',
            title: 'On-Page SEO',
            text: 'Optimize your website’s content and structure for search engines. We focus on elements like meta tags, headings, and keyword usage to improve rankings.'
        },
        {
            src: graph,
            alt: 'Off-Page SEO',
            title: 'Off-Page SEO',
            text: 'Strengthen your website’s authority and credibility through effective off-page SEO strategies. We focus on building high-quality backlinks, managing social signals, and improving online reputation to boost your search engine rankings.'
        },
        {
            src: graph,
            alt: 'Technical SEO',
            title: 'Technical SEO',
            text: 'Address backend issues affecting your site’s performance.Our technical SEO services ensure your website is fast, secure, and easily crawlable by search engines.'
        },
        {
            src: graph,
            alt: 'Local SEO',
            title: 'Local SEO',
            text: 'Boost your visibility in local search results. We optimize your online presence for local searches, helping you attract nearby customers and increase foot traffic.'
        },
        {
            src: graph,
            alt: 'International SEO',
            title: 'International SEO',
            text: 'Expand your reach globally with our international SEO strategies. We optimize your website for multiple languages and regions to target global audiences effectively.'
        },
        {
            src: graph,
            alt: 'WordPress SEO',
            title: 'WordPress SEO',
            text: 'Enhance your WordPress site’s performance with specialized SEO techniques. We optimize your theme, plugins, and content for maximum search engine visibility.'
        },
        {
            src: graph,
            alt: 'Mobile SEO',
            title: 'Mobile SEO',
            text: 'Ensure your website is mobile-friendly and ranks well in mobile searches. Our mobile SEO services focus on responsive design and fast loading times.'
        },
        {
            src: graph,
            alt: 'Voice Search SEO',
            title: 'Voice Search SEO',
            text: 'Enhance your website’s visibility in voice search results. Our voice search optimization services ensure your content is easily discoverable and ranks high for voice queries, driving more traffic and engagement.'
        },
        {
            src: graph,
            alt: 'Content SEO',
            title: 'Content SEO',
            text: 'Create and optimize high-quality content that ranks. We focus on keyword research, content creation, and on-page optimization to boost your search engine rankings.'
        },
        {
            src: graph,
            alt: 'White-Hat SEO',
            title: 'White-Hat SEO',
            text: 'Employ ethical SEO practices that comply with search engine guidelines. Our white-hat SEO strategies ensure sustainable growth and avoid penalties.'
        },
        {
            src: graph,
            alt: 'Quality Link Building',
            title: 'Quality Link Building',
            text: 'Build high-quality backlinks to enhance your site’s authority. We use ethical link-building techniques to improve your site’s credibility and rankings.'
        },
        {
            src: graph,
            alt: 'Small Business / Startup SEO',
            title: 'Small Business / Startup SEO',
            text: 'Tailored SEO strategies for small businesses and startups. We help you compete with larger companies by optimizing your online presence for local searches.'
        },
        {
            src: graph,
            alt: 'Enterprise SEO',
            title: 'Enterprise SEO',
            text: 'Comprehensive SEO solutions for large enterprises. We address complex SEO challenges and provide scalable strategies to improve your site’s performance.'
        },
        {
            src: graph,
            alt: 'FinTech SEO',
            title: 'FinTech SEO',
            text: 'Elevate your financial technology services with specialized FinTech SEO strategies. We optimize your site for relevant keywords, ensuring high visibility and attracting targeted traffic from the financial sector.'
        },
        {
            src: graph,
            alt: 'Education SEO',
            title: 'Education SEO',
            text: 'Boost your educational institution’s online presence with tailored Education SEO services. We enhance search visibility for schools, universities, and e-learning platforms, driving more inquiries and enrollments.'
        },
        {
            src: graph,
            alt: 'D2C SEO',
            title: 'D2C SEO',
            text: 'Maximize your direct-to-consumer brand’s online reach with expert D2C SEO. We focus on optimizing product pages and creating engaging content to attract and convert your target audience effectively.'
        },
        {
            src: graph,
            alt: 'Saas SEO',
            title: 'Saas SEO',
            text: 'Optimize your Software as a Service platform for search engines. Our SaaS SEO services focus on targeted keyword research, content strategy, and technical optimization to drive organic traffic and increase conversions.'
        },
        {
            src: graph,
            alt: 'Technology SEO',
            title: 'Technology SEO',
            text: 'Drive growth in the tech sector with our specialized SEO strategies. We optimize for complex tech keywords, improve user experience, and enhance your online visibility to attract targeted traffic.'
        },
        {
            src: graph,
            alt: 'Gaming SEO',
            title: 'Gaming SEO',
            text: 'Level up your gaming website with our tailored SEO services. We focus on optimizing gaming content, boosting search rankings, and engaging your target audience to increase traffic and player base.'
        },
        {
            src: graph,
            alt: 'Media & Entertainment SEO',
            title: 'Media & Entertainment SEO',
            text: 'Capture audience attention with our media and entertainment SEO expertise. We enhance your content’s discoverability, improve organic reach, and ensure your media assets rank high on search engines.'
        },
        {
            src: graph,
            alt: 'Pharma & Healthcare SEO',
            title: 'Pharma & Healthcare SEO',
            text: 'Navigate the competitive healthcare market with our expert SEO solutions. We optimize your website for medical keywords, ensure compliance, and drive qualified traffic to boost patient engagement.'
        },
        {
            src: graph,
            alt: 'SEO Consulting',
            title: 'SEO Consulting',
            text: 'Unlock your website’s potential with our professional SEO consulting services. We provide actionable insights, strategic guidance, and tailored recommendations to enhance your search engine performance.'
        },
        {
            src: graph,
            alt: 'B2B / B2C SEO',
            title: 'B2B / B2C SEO',
            text: 'Customized SEO strategies for B2B and B2C companies. We understand the unique needs of each market and tailor our approaches to drive relevant traffic.'
        },
        {
            src: graph,
            alt: 'E-commerce SEO',
            title: 'E-commerce SEO',
            text: 'Optimize your online store to attract more customers. Our ecommerce SEO services focus on product optimization, site structure, and user experience.'
        },
        {
            src: graph,
            alt: 'YouTube SEO',
            title: 'YouTube SEO',
            text: 'Enhance your video content’s visibility on YouTube. We optimize your videos for search, ensuring they reach a larger audience and drive more engagement.'
        },
        {
            src: graph,
            alt: 'Amazon SEO',
            title: 'Amazon SEO',
            text: 'Increase your product rankings on Amazon with our SEO services. We optimize your product listings to improve visibility and sales on the platform.'
        },
        {
            src: graph,
            alt: 'Conversion Rate Optimization (CRO)',
            title: 'Conversion Rate Optimization (CRO)',
            text: 'Improve your website’s ability to convert visitors into customers. Our CRO services focus on enhancing user experience and optimizing key conversion points.'
        },
        {
            src: graph,
            alt: 'Online Reputation Management (ORM)',
            title: 'Online Reputation Management (ORM)',
            text: 'Manage and improve your online reputation. We monitor, address, and mitigate negative reviews and build a positive image for your brand.'
        },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'In-Depth Market Research',
            sections: [
                {
                    content: 'We begin by conducting thorough market research to understand your industry, competitors, and target audience. This helps us identify the best keywords and opportunities for your SEO strategy.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Comprehensive Website Audit',
            sections: [
                {
                    content: 'Our team performs a detailed audit of your website to identify technical issues, on-page SEO opportunities, and areas for improvement. This ensures your site is optimized for search engines from the ground up.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Customized SEO Strategy Development',
            sections: [
                {
                    content: 'Based on our research and audit findings, we develop a tailored SEO strategy that aligns with your business goals. This includes a roadmap for keyword optimization, content creation, and technical enhancements.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Keyword Optimization',
            sections: [
                {
                    content: 'We optimize your website’s content, meta tags, and structure using targeted keywords. This helps improve your search engine rankings and ensures your site is relevant to user queries.'
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Content Creation and Optimization',
            sections: [
                {
                    content: 'Our team creates high-quality, engaging content that resonates with your audience and is optimized for search engines. This includes blog posts, articles, and other content that drives organic traffic.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Technical SEO Enhancements',
            sections: [
                {
                    content: 'We address technical aspects of your website, such as site speed, mobile-friendliness, and crawlability. This ensures your site meets search engine requirements and provides a seamless user experience.'
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Quality Link Building',
            sections: [
                {
                    content: 'We implement a strategic link-building campaign to acquire high-quality backlinks from reputable sources. This enhances your website’s authority and credibility, boosting your search engine rankings.'
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Monitoring and Analytics',
            sections: [
                {
                    content: 'We continuously monitor your website’s performance using advanced analytics tools. This helps us track the effectiveness of our SEO efforts and make data-driven adjustments to improve results.'
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Regular Reporting and Adjustments',
            sections: [
                {
                    content: 'Our team provides regular reports on your website’s SEO performance, including rankings, traffic, and conversions. Based on these insights, we adjust our strategy to ensure ongoing optimization and success.'
                }
            ]
        },
        {
            id: 'pills-tenth',
            label: '10',
            title: 'Continuous Improvement',
            sections: [
                {
                    content: 'SEO is an ongoing process, and we remain committed to continually improving your website’s performance. We stay updated with the latest industry trends and algorithm changes to keep your site competitive.'
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What is SEO, and why is it important for my business?",
            answer: "SEO, or Search Engine Optimization, is the process of improving your website's visibility on search engines like Google. It is crucial for your business because it helps attract organic traffic, increases brand awareness, and improves your online presence, leading to higher conversions and growth."
        },
        {
            question: "How does XDMinds approach SEO for clients?",
            answer: "At XDMinds, we follow a comprehensive, data-driven approach to SEO. We start with in-depth market research and a detailed website audit, develop a customized SEO strategy, optimize your website content and structure, implement technical SEO enhancements, and engage in quality link-building. We continuously monitor performance and adjust our strategies to ensure optimal results."
        },
        {
            question: "How long does it take to see results from SEO?",
            answer: "SEO is a long-term investment, and results can vary depending on factors like your industry, competition, and current website status. Typically, clients begin to see noticeable improvements in search rankings and organic traffic within 3 to 6 months. However, the best results are often seen after 6 to 12 months of consistent SEO efforts."
        },
        {
            question: "What are the key components of your SEO services?",
            answer: "Our SEO services include keyword research, on-page optimization, technical SEO, content creation and optimization, quality link building, local and international SEO, mobile SEO, and ongoing monitoring and reporting. We tailor our services to meet the unique needs of each client."
        },
        {
            question: "Do you provide customized SEO strategies for different industries?",
            answer: "Yes, we understand that each industry has unique challenges and opportunities. Our team at XDMinds develops customized SEO strategies tailored to the specific needs of your business, ensuring the most effective approach to improving your search engine rankings and online visibility."
        },
        {
            question: "How do you measure the success of your SEO campaigns?",
            answer: "We use a variety of metrics to measure the success of our SEO campaigns, including search engine rankings, organic traffic, conversion rates, and user engagement. We provide regular reports and insights to keep you informed about the progress and impact of our SEO efforts."
        },
        {
            question: "Can you help with local SEO for my business?",
            answer: "Absolutely! Our local SEO services focus on optimizing your online presence for local searches. We help improve your visibility in local search results, manage your Google My Business profile, and build local citations to attract nearby customers."
        },
        {
            question: "What is technical SEO, and why is it important?",
            answer: "Technical SEO involves optimizing the technical aspects of your website, such as site speed, mobile-friendliness, crawlability, and security. It is essential because it ensures that search engines can easily access, crawl, and index your site, leading to better rankings and a seamless user experience."
        },
        {
            question: "Do you offer ongoing SEO maintenance and support?",
            answer: "Yes, SEO is an ongoing process that requires continuous optimization and monitoring. We provide ongoing SEO maintenance and support to ensure your website remains optimized and competitive. Our team stays updated with the latest industry trends and algorithm changes to keep your site performing at its best."
        },
        {
            question: "How do I get started with XDMinds' SEO services?",
            answer: "Getting started is easy! Contact us to schedule a consultation, and our team will discuss your business goals and current SEO status. We will then develop a tailored SEO strategy to help you achieve your objectives and drive sustainable growth."
        },
    ]

    const slideData = [
        {
            title: 'SEO: The Unsung Hero of Digital Success',
            content: `Search Engine Optimization (SEO) plays a crucial yet often overlooked role in digital marketing success. It is the backbone of online visibility, driving organic traffic and improving search engine rankings. By optimizing your website for search engines, you can attract more qualified leads, increase brand awareness, and achieve long-term growth. At XDMinds, we understand the nuances of SEO and leverage our expertise to help your business thrive in the competitive digital landscape.`,
        },
        {
            title: 'Never Underestimate the Power of SEO',
            content: `In the digital world, SEO is a powerful tool that can transform your online presence. Effective SEO strategies can boost your website's visibility, drive targeted traffic, and enhance user experience. By investing in SEO, you can stay ahead of your competitors, reach your target audience more effectively, and achieve sustainable growth. At XDMinds, we prioritize SEO to ensure your website ranks high and delivers measurable results.`,
        },
        {
            title: 'Skyrocket Your Website Traffic Through Proven SEO Tactics',
            content: `Unlock the full potential of your website with our proven SEO tactics. At XDMinds, we use data-driven strategies and industry best practices to improve your site's search engine rankings and drive high-quality traffic. From comprehensive keyword research and on-page optimization to quality link building and technical SEO, our approach is designed to deliver significant and lasting results. Let us help you skyrocket your website traffic and achieve your business goals.`,
        },
        {
            title: 'How XDMinds Helps You Grow Your Business Through Cutting-Edge SEO Services?',
            content: `At XDMinds, we specialize in leveraging advanced SEO techniques to propel your business forward. Our comprehensive approach begins with in-depth market research and a thorough website audit to identify opportunities and areas for improvement. We then develop a customized SEO strategy tailored to your business goals, focusing on keyword optimization, content creation, and technical enhancements.`,
            content1: `Our team implements targeted on-page and off-page SEO tactics, ensuring your website ranks higher in search engine results and attracts more qualified traffic. By optimizing your site’s structure, enhancing user experience, and building high-quality backlinks, we boost your online visibility and authority.`,
            content2: `Additionally, we continuously monitor your website’s performance, using data-driven insights to adjust strategies and maximize results. Our ongoing commitment to staying updated with the latest SEO trends and algorithm changes ensures your business remains competitive and achieves sustainable growth.`,
            content3: `With XDMinds’ cutting-edge SEO services, you can expect increased organic traffic, higher search engine rankings, and improved conversion rates, ultimately driving your business’s success in the digital landscape.`,
        },
    ];
    return (
        <div className='digital-marketing seo'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Results-Driven <span className='s-text-3'>SEO</span> Agency<br className='break' />
                    With Seasoned SEO Professionals
                </h1>
                <p className='p-text-1'>
                    Transform your online presence with our ROI-driven SEO services. From meticulous keyword research to advanced conversion optimization, we ensure your digital strategy delivers measurable results.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2'>
                <div className='d-swiper-div'>
                    <DSwiper name='d-bg' slideData={slideData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Expert SEO Company: Boost Your Online Visibility <br className='break' /> and Drive Organic Traffic
                </h2>
                <div className='border-div position-relative'>
                    <p className='p-text-2 w-100 span-icon'>
                        At XDMinds, we specialize in elevating your brand's online presence through expert SEO strategies. Our team of seasoned professionals leverages the latest SEO techniques to boost your website's visibility and drive organic traffic. We understand the dynamic nature of search engine algorithms and continuously adapt to ensure your website ranks high for relevant searches. By focusing on both on-page and off-page SEO, we provide a comprehensive solution that enhances your site's authority and relevance, ultimately driving more qualified traffic and increasing your ROI.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Comprehensive SEO Marketing Services for <br className='break' /> Web3 Startups and Enterprises
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we offer specialized SEO marketing services tailored for Web3 startups and enterprises. Our comprehensive approach includes keyword optimization, technical SEO, and content strategy to enhance your online visibility. Trust us to drive organic traffic and position your Web3 project for success in the digital landscape.
                </p>
                <div className='event-card-div'>
                    <EventCard eventCardData={eventCardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Schedule a call to discover how our <br className='break' /> SEO services can benefit your business.
                </h2>
                <p className='p-text-2'>
                    Ready to elevate your online presence? Schedule a call with XDMinds today and let our SEO experts guide you through tailored strategies designed to boost your visibility and drive organic traffic. Learn how our comprehensive SEO solutions can help your business achieve measurable results.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your seo services. Shall we start the discussion? Lead from https://www.xdminds.com/seo-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    XDMinds: Your Trusted SEO Agency for <br className='break' /> Cutting-Edge SEO Services and Exceptional Online Visibility
                </h2>
                <p className='p-text-2'>
                    XDMinds is your go-to partner for cutting-edge SEO services designed to enhance your online visibility. As a trusted SEO agency, we are committed to delivering exceptional results through innovative strategies and a client-centric approach. Our expertise covers a wide range of SEO services, ensuring that every aspect of your website's performance is optimized for success. With XDMinds, you can expect a tailored SEO strategy that meets your unique business needs, helping you achieve sustainable growth and a strong online presence.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Setting the SEO Standard: <br className='break' /> XDMinds' Innovative Approach Explained
                </h2>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest SEO Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default SearchEngineOptimization


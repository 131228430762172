import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What is metaverse marketing?",
    answer: `Metaverse marketing involves promoting brands, products, and services within virtual worlds using augmented reality (AR) and virtual reality (VR) technologies. It aims to create immersive and interactive experiences that engage users in new and innovative ways.`
  },
  {
    question: "How can metaverse marketing benefit my business?",
    answer: `Metaverse marketing can increase brand visibility, deepen customer engagement, and open new revenue streams. By creating unique and interactive experiences, your brand can stand out in the competitive digital landscape and build stronger connections with your audience.`
  },
  {
    question: "What services does XDMinds offer for metaverse marketing?",
    answer: `XDMinds offers a comprehensive range of metaverse marketing services, including strategy creation, SEO, PPC advertising, branding, social media marketing, PR, influencer marketing, and community building. Our services are designed to help your brand thrive in the metaverse. `
  },
  {
    question: "Why should I choose XDMinds as my metaverse marketing partner?",
    answer: `XDMinds combines deep expertise in Web3 technologies with innovative marketing strategies to deliver exceptional results. Our team is passionate about pushing the boundaries of digital marketing, ensuring your brand maximizes its potential in the metaverse.`
  },
  {
    question: "How do you develop a metaverse marketing strategy?",
    answer: `We start with an in-depth consultation to understand your brand's goals and target audience. Based on this information, we conduct thorough research and analysis, develop a tailored strategy, create engaging content, and execute the campaign across the most suitable metaverse platforms.`
  },
  {
    question: "Can small businesses benefit from metaverse marketing?",
    answer: `Absolutely! The metaverse offers unique opportunities for businesses of all sizes. Small businesses can leverage immersive experiences to reach new audiences, build brand awareness, and engage customers innovatively.`
  },
  {
    question: "What is the future of metaverse marketing?",
    answer: `The future of metaverse marketing is promising, with continuous advancements in AR/VR technologies and increasing user adoption. As the metaverse evolves, it will become an essential part of digital marketing strategies, offering new ways to connect with audiences.`
  },
  {
    question: "How do you measure the success of metaverse marketing campaigns?",
    answer: `We measure success using key performance indicators (KPIs) such as user engagement, reach, conversion rates, and ROI. We also utilize advanced analytics tools to gain insights into user behavior and campaign effectiveness, allowing us to optimize strategies for better results.`
  },
  {
    question: "What types of content can be created for metaverse marketing?",
    answer: `Content for metaverse marketing can include 3D models, virtual events, AR/VR experiences, interactive ads, and more. Our team specializes in creating engaging and immersive content that resonates with your target audience and enhances your brand's presence in the virtual world.`
  }
];

const Section6FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='metaverse-sec-6-faq'>
      <div className='faq'>
        <Container>
          <Row>
            <Col>
              <div>
                <h2>Frequently Asked Questions</h2>
              </div>

              {faqData.map((faq, index) => (
                <div key={index}>
                  <div
                    onClick={() => handleFAQClick(index)}
                    aria-controls={`faq${index}`}
                    aria-expanded={openFAQ === index}
                    className='faq-1st'
                  >
                    <div className='faq-content'>
                      <h6 className='faq-content-question'>{faq.question}</h6>
                    </div>
                    <div className='faq-icons'>
                      {openFAQ === index ? (
                        <IoIosArrowDropupCircle className='close-circle-faq' />
                      ) : (
                        <IoIosArrowDropdownCircle className='plus-circle-faq' />
                      )}
                    </div>
                  </div>
                  <Collapse in={openFAQ === index}>
                    <div id={`faq${index}`} className='faq-answer-content'>
                      {renderAnswer(faq.answer)}
                    </div>
                  </Collapse>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Section6FAQ
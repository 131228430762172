import React, { useState } from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BreadCrumbs from '../../Components/BreadCrumbs';
import graph from '../../assets/images/graph.svg';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import Web3GridCard from '../DigitalMarketing/Web3GridCard';
import Latest from '../../Components/Home/Latest/Latest';
import FAQblockchain from '../../Components/Services/Blockchain/FAQblockchain';
import { MdArrowCircleDown } from "react-icons/md";



const P2e = (props) => {

    const { countryCode } = props;

    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const cardData = [
        {
            id: 1,
            title: 'Deep Industry Expertise',
            description: "XDMinds boasts a team of seasoned professionals with extensive experience in the gaming and blockchain industries. Our deep understanding of Play-to-Earn (P2E) mechanics and trends enables us to craft marketing strategies that are not only innovative but also highly effective. We stay ahead of industry developments to ensure your P2E game remains competitive and appealing to players."
        },
        {
            id: 2,
            title: 'Tailored Marketing Strategies',
            description: "We recognize that each P2E game is unique, with its own set of challenges and opportunities. XDMinds develops customized marketing strategies that align with your game’s specific goals and target audience. By tailoring our approach, we maximize the impact of your marketing efforts, ensuring your game attracts and retains players."
        },
        {
            id: 3,
            title: 'Comprehensive Market Research',
            description: "Understanding the market landscape is crucial for the success of any P2E game. XDMinds conducts thorough market research to identify trends, competitor strengths and weaknesses, and audience preferences. This research forms the foundation of our marketing strategies, allowing us to position your game effectively in a crowded market."
        },
        {
            id: 4,
            title: 'Creative Brand Building',
            description: "Brand identity is a critical component of any successful P2E game. XDMinds excels in creating compelling brand narratives that resonate with players. We develop engaging visual and written content that highlights the unique aspects of your game, building a strong brand presence that attracts and retains a dedicated player base."
        },
        {
            id: 5,
            title: 'Data-Driven Decision Making',
            description: "At XDMinds, we believe in the power of data to drive marketing success. Our data-driven approach involves constant monitoring and analysis of key performance indicators (KPIs). This allows us to make informed decisions, optimize campaigns in real-time, and ensure that your marketing strategy delivers measurable results."
        },
        {
            id: 6,
            title: 'Effective Community Engagement',
            description: "Building and maintaining an active community is essential for the longevity of a P2E game. XDMinds focuses on fostering a vibrant community around your game through strategic engagement initiatives. We create and manage interactive platforms where players can connect, share experiences, and stay informed about game updates, fostering a sense of loyalty and belonging."
        },
        {
            id: 7,
            title: 'Cutting-Edge Digital Marketing Techniques',
            description: "XDMinds utilizes the latest digital marketing techniques to enhance your game’s visibility and reach. From search engine optimization (SEO) to social media campaigns and influencer partnerships, we employ a multi-channel approach that ensures your game reaches the right audience through the most effective channels."
        },
        {
            id: 8,
            title: 'Continuous Support and Consultation',
            description: "Our commitment to your game’s success doesn’t end with the launch. XDMinds provides ongoing support and consultation to help you navigate the ever-changing P2E landscape. We offer expert advice and insights to refine your marketing strategies, address emerging challenges, and capitalize on new opportunities."
        },
        {
            id: 9,
            title: 'Transparent Communication',
            description: "We believe in maintaining transparent communication with our clients. XDMinds keeps you informed at every stage of the marketing process, providing regular updates and detailed reports. Our open communication ensures that you have a clear understanding of our strategies and the progress of your marketing campaigns."
        },
        {
            id: 10,
            title: 'Dedication to Client Success',
            description: "Ultimately, our primary goal is to ensure the success of your P2E game. XDMinds is dedicated to delivering exceptional results that exceed your expectations. We take pride in our work and are committed to helping you achieve your vision for your game, providing the expertise and support needed to make your project a resounding success.With XDMinds as your P2E marketing partner, you can be confident in our ability to deliver a comprehensive, strategic, and impactful marketing campaign tailored to your game’s unique needs. Our commitment to excellence and client success makes us the most trusted P2E gaming marketing agency for your needs."
        },


    ];

    const web3CardData = [
        {
            src: graph,
            alt: 'P2E GTM Strategy ',
            title: 'P2E GTM Strategy ',
            text: "We develop customized marketing strategies that outline clear objectives, target audiences, and key performance indicators to ensure the success of your P2E game."
        },
        {
            src: graph,
            alt: 'P2E Keywords Research',
            title: 'P2E Keywords Research',
            text: "Our team conducts thorough keyword research to identify and target the most relevant and high-performing keywords for your P2E game, enhancing discoverability and search engine rankings."
        },
        {
            src: graph,
            alt: 'P2E Competitor Analysis',
            title: 'P2E Competitor Analysis',
            text: "We perform detailed competitor analysis to understand market dynamics, identify opportunities, and craft strategies that give your game a competitive edge."
        },
        {
            src: graph,
            alt: 'P2E Brand Development ',
            title: 'P2E Brand Development ',
            text: "We help you create a strong brand identity that resonates with players and positions your P2E game as a leader in the market."
        },
        {
            src: graph,
            alt: 'P2E SEO',
            title: 'P2E SEO',
            text: "Our SEO strategies improve your online visibility, driving organic traffic to your game’s website and enhancing player acquisition."
        },
        {
            src: graph,
            alt: 'P2E ASO',
            title: 'P2E ASO',
            text: "We optimize your game’s presence on app stores to increase visibility, downloads, and player retention through effective App Store Optimization (ASO) techniques."
        },
        {
            src: graph,
            alt: 'P2E Paid Advertising',
            title: 'P2E Paid Advertising',
            text: "We design and implement targeted paid advertising campaigns to reach a wider audience and attract more players to your P2E game."
        },
        {
            src: graph,
            alt: 'Social Media Marketing',
            title: 'Social Media Marketing',
            text: "Our social media campaigns build a strong online presence, engage with your audience, and drive traffic to your game through strategic content and interactions."
        },
        {
            src: graph,
            alt: 'P2E Community Building ',
            title: 'P2E Community Building ',
            text: "We focus on building and managing a vibrant community around your P2E game, fostering loyalty and ongoing engagement."
        },
        {
            src: graph,
            alt: 'P2E Content Marketing',
            title: 'P2E Content Marketing',
            text: "Our content marketing strategies include engaging blog posts, articles, and multimedia content that educate and inform potential players about your game."
        },
        {
            src: graph,
            alt: 'P2E Email Marketing',
            title: 'P2E Email Marketing',
            text: "We create targeted email marketing campaigns that keep your audience informed and engaged with regular updates and exclusive content."
        },
        {
            src: graph,
            alt: 'Press Releases',
            title: 'Press Releases ',
            text: "We craft and distribute press releases to major media outlets, ensuring your game gains the attention and coverage it deserves."
        },
        {
            src: graph,
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: "We collaborate with key influencers in the gaming space to amplify your reach and credibility, driving more players to your game."
        },
        {
            src: graph,
            alt: 'Affiliate Marketing',
            title: 'Affiliate Marketing',
            text: "Our P2E affiliate marketing strategies drive targeted traffic and boost player engagement through influential partnerships."
        },
        {
            src: graph,
            alt: 'Airdrops and Giveaways',
            title: 'Airdrops and Giveaways',
            text: "Maximize your game's visibility and attract new players with our expertly crafted P2E airdrop and giveaway campaigns."
        },
        {
            src: graph,
            alt: 'P2E Token Sale and Launch Strategy',
            title: 'P2E Token Sale and Launch Strategy',
            text: "We provide expert guidance on token sale and launch strategies to maximize investor appeal and project sustainability."
        },
        {
            src: graph,
            alt: 'P2E Event Marketing',
            title: 'P2E Event Marketing',
            text: "We organize and promote events that showcase your game, providing opportunities for networking and player engagement."
        },
        {
            src: graph,
            alt: 'Omnichannel Marketing',
            title: 'Omnichannel Marketing',
            text: "Our omnichannel approach ensures a consistent and cohesive marketing message across all platforms and channels."
        },
        {
            src: graph,
            alt: 'Marketing Analytics and Reporting',
            title: 'Marketing Analytics and Reporting',
            text: "We provide detailed analytics and reporting to track the performance of your marketing efforts and optimize strategies."
        },
        {
            src: graph,
            alt: 'P2E Marketing Consulting',
            title: 'P2E Marketing Consulting',
            text: "Our consulting services offer expert advice and insights to guide your marketing strategy and decision-making process."
        },

    ];
    const faqData = [
        {
            question: "What is Play-to-Earn (P2E) gaming and why is it popular?",
            answer: "Play-to-Earn (P2E) gaming is a model where players earn rewards, typically in the form of cryptocurrency or NFTs, by playing games. This model has gained popularity because it allows players to monetize their gaming activities, creating new economic opportunities and fostering a sense of ownership and community within the game."
        },
        {
            question: " How can XDMinds help my P2E game stand out in a crowded market?",
            answer: "XDMinds specializes in crafting unique and tailored marketing strategies for P2E games. By understanding the specific needs of your game and its target audience, we develop customized campaigns that enhance visibility, engage players, and drive growth, ensuring your game stands out in a competitive market."
        },
        {
            question: " What are the key elements of a successful P2E marketing strategy?",
            answer: "A successful P2E marketing strategy typically includes comprehensive market research, strong brand development, effective community engagement, and data-driven decision-making. These elements work together to create a cohesive and impactful marketing campaign that attracts and retains players."
        },

        {
            question: "How does XDMinds engage with the player community for P2E games?",
            answer: "XDMinds focuses on building and nurturing a vibrant player community through interactive platforms, social media engagement, and regular updates. By fostering a sense of loyalty and belonging, we help maintain an active and enthusiastic player base that supports the long-term success of your game."
        },
        {
            question: "How does XDMinds measure the success of a P2E marketing campaign?",
            answer: "We measure the success of a P2E marketing campaign using key performance indicators (KPIs) such as player acquisition, engagement rates, retention rates, and revenue growth. Continuous monitoring and analysis of these metrics allow us to optimize campaigns in real-time and ensure that we deliver measurable results that align with your goals."
        },

    ];


    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className='p2e about'>
            <div className='digital-marketing'>
                <section className='digital-marketing-banner min-100vh'>
                    <BreadCrumbs />
                    <h1 className='h-text-1'>
                        Top-Rated Play-to-Earn   <br />
                        <span className='s-text-3'>P2E</span>
                        Game Marketing Agency
                    </h1>
                    <p className='p-text-1'>
                        In the evolving landscape of blockchain gaming, achieving success with Play-to-Earn (P2E)
                        games requires a strategic and comprehensive marketing approach. XDMinds stands as the top-rated P2E marketing agency, dedicated to transforming your gaming project into a thriving ecosystem. Our innovative strategies and proven expertise ensure that your P2E game not only reaches but captivates its target audience,
                        driving sustained growth and engagement.
                    </p>
                    <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
                </section>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        XDMinds - Superior Play-to-Earn <br className='break' /> (P2E) Marketing Agency for Enhanced Player Engagement

                    </h2>
                    <p className='p-text-2'>
                        At XDMinds, we specialize in delivering superior marketing solutions tailored for Play-to-Earn (P2E) games.
                        Our mission is to enhance player engagement and community growth through strategic marketing initiatives. With a deep understanding of the gaming industry and a passion for blockchain technology, we craft personalized marketing campaigns that resonate with players and drive long-term success. Whether you are launching a new P2E game or looking to boost an existing one,
                        XDMinds is your trusted partner in navigating the competitive gaming landscape.
                    </p>
                    <div className='social-buttons'>
                        <button className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </button>
                    </div>
                </section>
                <section className='digital-marketing-section-2 '>
                    <h2 className='h-text-2'>
                        Experience Unmatched Play-to-Earn (P2E) Marketing  <br className='break' /> Excellence with XDMinds' Comprehensive P2E Marketing Services
                    </h2>

                    <div className='grid-cards'>
                        <Web3GridCard web3CardData={web3CardData} />
                    </div>
                </section>
            </div>

            <section className='about-section-5'>
                <h2 className='h-text-2'>
                    What Makes XDMinds the Most Trusted <br className='break' /> P2E Game Marketing Agency for Your Needs?
                </h2>
                <div className='custom-div-5 mt-2 mobile-hide'>
                    <div className='row g-3'>
                        {cardData.map((card) => (
                            <div key={card.id} className='col-lg-4 col-md-6 col-12'>
                                <div className='card custom-card-5 h-100'>
                                    <div className='custom-card-head'>
                                        <h1 className='h-text-3'>{card.id}</h1>
                                        <h2 className='h-text-4'>{card.title}</h2>
                                    </div>
                                    <div className='card-body'>
                                        {card.description && <p className='p-text-4'>{card.description}</p>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='custom-accordion desktop-hide tab-hide'>
                    <div className="accordion" id="accordionExample">
                        {cardData.map((item, index) => (
                            <div key={item.id} className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                        onClick={() => handleClick(index)}
                                        type="button"
                                        aria-expanded={openIndex === index}
                                        aria-controls={item.id}
                                    >
                                        {item.title}
                                        <div className="arrow-icon-btn">
                                            <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                                        </div>
                                    </button>
                                </h2>
                                <div
                                    id={item.id}
                                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <p className='p-text-1'>{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest P2E Marketing Insights' />
            </section>
            <section>
                <FAQblockchain faqData={faqData} />
            </section>

            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default P2e

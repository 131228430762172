import React from 'react'
import Tabs from '../../../Pages/DigitalMarketing/Tabs'
import '../../../assets/css/style.css'

const DAOSection4 = () => {
    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Deep Understanding of the DAO Ecosystem',
            sections: [
                {
                    content: "XDMinds begins with a comprehensive understanding of the DAO ecosystem. We stay abreast of the latest developments, trends, and technologies in the decentralized space. This deep knowledge allows us to tailor our strategies specifically to the unique requirements and dynamics of DAOs, ensuring that your project is positioned effectively in the market."
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Personalized Strategy Development',
            sections: [
                {
                    content: "We believe that every DAO project is unique, with its own set of goals, challenges, and audience. XDMinds takes a personalized approach to strategy development, crafting bespoke marketing plans that align with your project's vision and objectives. Our team works closely with you to understand your project's core values, mission, and target audience, creating a customized roadmap for success."
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Expert Team with Diverse Skills',
            sections: [
                {
                    content: 'Our team comprises experts with diverse skills and backgrounds, all dedicated to the success of your DAO project. From blockchain enthusiasts to marketing strategists and content creators, our multidisciplinary team brings a wealth of knowledge and experience to the table. This diverse expertise ensures that every aspect of your marketing campaign is handled with precision and creativity.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: ' Innovative and Adaptive Marketing Tactics',
            sections: [
                {
                    content: "The decentralized landscape is dynamic and ever-changing. At XDMinds, we employ innovative and adaptive marketing tactics that keep your DAO project ahead of the curve. We continually explore new methodologies and tools to enhance your marketing efforts, ensuring that your project remains relevant and engaging to your target audience."
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Strong Emphasis on Community Building',
            sections: [
                {
                    content: "Community is at the heart of every successful DAO. XDMinds places a strong emphasis on community building, understanding that a vibrant and engaged community is key to your project's success. We implement strategies that foster genuine connections, active participation, and long-term loyalty among your community members, creating a solid foundation for your DAO."
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Transparent and Collaborative Process',
            sections: [
                {
                    content: "Transparency and collaboration are cornerstones of our approach at XDMinds. We maintain open lines of communication with our clients, providing regular updates and insights into the progress of your marketing campaigns. Our collaborative process ensures that you are involved at every step, making informed decisions and contributing to the overall strategy."
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Data-Driven Decision Making',
            sections: [
                {
                    content: "In the decentralized world, data is invaluable. XDMinds leverages data-driven decision-making to optimize your marketing efforts. We use advanced analytics and metrics to measure the effectiveness of our strategies, identify areas for improvement, and adjust our approach accordingly. This commitment to data ensures that your DAO project benefits from informed and impactful marketing decisions."
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Long-Term Partnership Focus',
            sections: [
                {
                    content: "At XDMinds, we are committed to building long-term partnerships with our clients. We understand that the journey of a DAO project extends beyond the initial launch. Our support and expertise continue throughout the lifecycle of your project, helping you navigate challenges, capitalize on opportunities, and achieve sustained growth and success."
                }
            ]
        },


    ]

    return (
        <div className='digital-marketing ppc'>

            <section className='digital-marketing-section-2 l-bg'>
                <h2 className='h-text-2'>
                    Why XDMinds Stands Out as the Trusted<br />DAO Marketing Agency for Your Project's Triumph
                </h2>
                <p className='p-text-2'>
                    In the rapidly evolving world of Decentralized Autonomous Organizations (DAOs), finding a marketing agency that truly understands the nuances of this landscape is crucial. XDMinds stands out as the trusted partner for your DAO project's triumph. Here’s a step-by-step look at why we are the best choice for your DAO marketing needs.
                </p>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>

        </div>
    );
}
export default DAOSection4
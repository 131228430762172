import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const StickyStackingCards = ({ stickyCardData }) => {
    const [cardsToShow, setCardsToShow] = useState(9);
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsMobileView(true);
                setCardsToShow(3);
            } else {
                setIsMobileView(false);
                setCardsToShow(9);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const loadMoreCards = () => {
        setCardsToShow(prevCount => prevCount + (isMobileView ? 3 : 9));
    };

    const hasMoreCardsToShow = cardsToShow < stickyCardData.length;

    return (
        <div className='sticky-card'>
            <div className='row g-4'>
                {stickyCardData.slice(0, cardsToShow).map((card, index) => (
                    <div key={index} className='col-md-6 col-lg-4 col-12'>
                        <div className='custom-sticky-card card h-100 w-100'>
                            <div className='custom-sticky-card-header'>
                                <h5 className='h-text-11'>{card.title}</h5>
                            </div>
                            <div className='custom-sticky-card-body'>
                                {card.sections.map((section, idx) => (
                                    <React.Fragment key={idx}>
                                        <h6 className='h-text-12'>{section.subtitle}</h6>
                                        <p className='p-text-8'>{section.content}</p>
                                        <Link reloadDocument to={card.link} className={`custom-sticky-card-link ${card.link ? 'l-show' : 'd-none'}`} />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {hasMoreCardsToShow && (
                <div className='text-center mt-4'>
                    <button onClick={loadMoreCards} className='show-more-btn btn-l-bg'>
                        Load More
                    </button>
                </div>
            )}
        </div>
    );
};

export default StickyStackingCards;

import React from 'react'

const CustomFaqSection = ({ customFaqData }) => {
    return (
        <div className='custom-faq-section'>
            <div className="accordion" id="accordionExample">
                {customFaqData.map((item, index) => (
                    <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button className="accordion-button collapsed px-0" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                <div style={{ width: '90%' }}>
                                    {item.title}
                                </div>
                            </button>
                        </h2>
                        <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {item.content}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CustomFaqSection
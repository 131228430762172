import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BottomFaq from '../DigitalMarketing/BottomFaq'
import ModalForm from '../../Components/ModalForm/ModalForm'
import { GetBlogDetailsApi, GetPageApi } from '../../Helper/Redux/ReduxThunk/Homepage'
import { useDispatch } from 'react-redux'




const BlogInnerPage = ({ getCHildpageinfo }) => {
    const [modalShow, setModalShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [particularBlog, setParticularBlog] = useState()
    const params = useParams();
    const [headings, setHeadings] = useState([]);
    const [tagDet, setTagDet] = useState()
    const [activeHeading, setActiveHeading] = useState(null);
    const navigate=useNavigate();


    getCHildpageinfo("BlogDynamicPage")

    useEffect(() => {
        if (particularBlog && particularBlog.blog_text) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(particularBlog.blog_text, 'text/html');
            const headingsList = Array.from(doc.querySelectorAll('h1, h2, h3, h4, h5, h6')).map((heading, index) => {
                const id = `heading-${index}`;
                heading.id = id;
                return {
                    id,
                    text: heading.textContent,
                    tag: heading.tagName,
                };
            });
            setHeadings(headingsList);

            const updatedHTML = doc.body.innerHTML;
            document.getElementById("blog-content").innerHTML = updatedHTML;

            const handleIntersection = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveHeading(entry.target.id);
                    }
                });
            };

            const observerOptions = {
                root: null,
                rootMargin: '0px 0px -50% 0px',
                threshold: 0.1
            };

            const observer = new IntersectionObserver(handleIntersection, observerOptions);

            headingsList.forEach((heading) => {
                const element = document.getElementById(heading.id);
                if (element) {
                    observer.observe(element);
                }
            });

            return () => {
                observer.disconnect();
            };
        }
    }, [particularBlog]);



    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const offset = 80;
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        getBlogDetails();

    }, [])
    const dispatch = useDispatch()
    const getBlogDetails = async () => {
        let datasss = {
            blog_url: params.id,
        }
        await dispatch(GetBlogDetailsApi(datasss, (resp) => {

            if (resp.status === true) {
                setParticularBlog(resp?.data)
                setTagDet(resp?.data?.blog_tags)
            }
            else {
                navigate('/404')
            }
        }))
    }

    const twitterIntentUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`;
    const facebookShareUrl = `https://www.facebook.com/share_channel/?link=${encodeURIComponent(window.location.href)}`;
    const linkedInShareUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(window.location.href)}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(window.location.href)}`;


    return (
        <div className='blog-inner-page'>
            <section className='seperate-blog-banner min-100vh'>
                <BreadCrumbs childPageLink='blog' childPageName='Blog' childPage={particularBlog && particularBlog?.breadcrumb_title} />
                <div className='row g-3 m-0 w-100'>
                    <div className='col-lg-6 col-12'>
                        <div className='seperate-blog-head w-100'>
                            <h1 className='h-text-1 t-center'>{particularBlog && particularBlog?.blog_title}</h1>
                        </div>
                    </div>
                    <div className='col-lg-6 lg-12'>
                        <div className='seperate-blog-img w-100'>
                            <LazyLoadImage src={particularBlog && particularBlog?.blog_image} alt='blog-1' loading='lazy' />
                        </div>
                    </div>
                </div>
            </section>
            <section className='seperate-blog-section-2'>
                <div className='row m-0'>
                    <div className='col-lg-2 col-12'>
                        <div className='r-side-div w-100'>
                            <div className='table-of-content mobile-hide tab-hide'>
                                <h5 className='h-text-4'>Table Of Contents</h5>
                                <div className='scroll-links mt-3'>
                                    {headings.map(({ id, text, tag }) => (
                                        <a href={`#${id}`} className={`scroll-link p-text-2 ${activeHeading === id ? 'active' : ''}`} key={id} onClick={(e) => { e.preventDefault(); scrollToSection(id); }}>
                                            {text}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className='accordion desktop-hide w-100 mt-3'>
                                <div className="b-accordion-head">
                                    <h5 className='h-text-4 mb-0'>
                                        Table Of Contents
                                    </h5>
                                    <button className='toc-btn' onClick={toggleAccordion} />
                                </div>
                                {isOpen && (
                                    <div className='scroll-links m-scroll-links mt-3'>
                                        {headings.map(({ id, text, tag }) => (
                                            <a href={`#${id}`} className={`scroll-link p-text-2 ${activeHeading === id ? 'active' : ''}`} key={id} onClick={(e) => { e.preventDefault(); scrollToSection(id); }}>
                                                {text}
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 col-12'>
                        <div className='blog-inner-page-content'>
                            <div id="blog-content" dangerouslySetInnerHTML={{ __html: particularBlog?.blog_text }} />
                            {tagDet !== "" &&
                                <div className='seperate-blog-tags'>
                                    <div className='seperate-blog-tags-head mb-3'>
                                        <h2 className='h-text-2'>Tags</h2>
                                    </div>
                                    <div className='blog-tags-div'>
                                        {tagDet && tagDet.split(',')?.map((tag, index) => (
                                            <span key={index} className={`tags-btn ${tag.active}`}>{tag.trim()}</span>
                                        ))}
                                    </div>
                                </div>
                            }
                            {particularBlog && particularBlog?.faqs?.length > 0 &&
                                <div className='blog-faq-section digital-marketing'>
                                    <h2 className='h-text-2'>
                                        Frequently Asked Questions
                                    </h2>
                                    <BottomFaq particularBlog={particularBlog} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-lg-3 col-12'>
                        <div className='r-side-div  w-100'>
                            <div className='blog-social w-100'>
                                <h5 className='h-text-4'>Share This Blog</h5>
                                <div className='blog-social-icons'>
                                    <a href={facebookShareUrl} className='blog-social-btn f-blog-btn' />
                                    <a href={twitterIntentUrl} className='blog-social-btn tw-blog-btn' />
                                    <a href={linkedInShareUrl} className='blog-social-btn l-blog-btn' />
                                    <a href={whatsappUrl} className='blog-social-btn w-blog-btn' />
                                    <a href={telegramShareUrl} className='blog-social-btn t-blog-btn' />
                                </div>
                            </div>

                            <div className='fix-banner'>
                                <div className='fix-banner-span-div'>
                                    Get Expert Consultation
                                </div>
                                <div className='fix-banner-content'>
                                    <h5 className='h-text-5'>Your Trusted Web3 & Digital Marketing Partner</h5>
                                    <p className='p-text-12'>
                                        Ready to elevate your digital presence and drive growth in the rapidly evolving Web3 landscape? At XDMinds, we specialize in delivering innovative digital marketing solutions tailored to your unique needs.
                                    </p>
                                </div>
                                <button className='fix-banner-btn' onClick={() => setModalShow(true)}>Let's Connect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalForm show={modalShow} onHide={() => setModalShow(false)} pageType={"Inner Blog"} />
        </div>
    )
}

export default BlogInnerPage
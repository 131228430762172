import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FaSkype, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const Section2 = () => {
    return (
        <div>
            <div className='blockchain-sec2'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>Power Up Your dApp Project Extensive XDMinds -<span className='metaverse-sec2-break'><br /></span> The Results-Driven dApp Marketing Agency
                                </h2>
                                <p>At XDMinds, we specialize in propelling dApp projects to new heights. Our results-driven approach combines cutting-edge marketing techniques with deep industry knowledge, ensuring your decentralized application stands out in the crowded market. Partner with us to power up your dApp project and achieve exceptional growth and engagement.
                                </p>
                            </div>
                            <div className='btn-mchallenge'>
                                <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your dApp marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/dapp-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                    <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                                </a>
                                <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                                </a>
                                <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Section2
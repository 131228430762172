import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What sets XDMinds apart as a Web3 marketing agency?",
    answer: `At XDMinds, we specialize in the unique dynamics of the Web3 ecosystem. Our team combines deep industry expertise with innovative marketing strategies tailored specifically for decentralized projects. We offer comprehensive, end-to-end marketing solutions that cover everything from SEO and paid advertising to community building and influencer partnerships, ensuring your Web3 project gains maximum visibility and engagement.`
  },
  {
    question: "How can XDMinds help my Web3 project stand out in a crowded market?",
    answer: `At XDMinds, we use a combination of innovative marketing strategies, comprehensive market analysis, and tailored go-to-market plans to ensure your Web3 project stands out. Our team focuses on creating a unique value proposition, optimizing your online presence, and engaging your target audience through various channels, helping you gain a competitive edge.`
  },
  {
    question: "How does XDMinds measure the success of Web3 marketing campaigns?",
    answer: `We use a variety of advanced analytics tools to monitor and evaluate the performance of our marketing campaigns. Key performance indicators (KPIs) such as engagement rates, conversion rates, website traffic, and ROI are tracked and analyzed regularly. Our data-driven approach allows us to make informed adjustments to optimize campaign performance continuously. We also provide detailed reports to keep you informed about the progress and effectiveness of your marketing efforts.`
  },
  {
    question: "What makes XDMinds different from other Web3 marketing agencies?",
    answer: `XDMinds stands out due to our deep understanding of the Web3 landscape, innovative marketing techniques, and a holistic approach to marketing. We provide end-to-end services tailored to the unique needs of Web3 projects, ensuring comprehensive support from strategy development to execution. Our commitment to transparency, data-driven optimization, and continuous improvement sets us apart as a leading Web3 marketing agency.`
  },
  {
    question: "How does XDMinds ensure that my Web3 project's marketing strategy is compliant with industry regulations?",
    answer: `At XDMinds, we stay updated with the latest industry regulations and best practices to ensure that your marketing strategy is fully compliant. We understand the unique challenges and legal considerations of the Web3 space, and we work diligently to ensure that all our marketing activities adhere to relevant guidelines. Our compliance-focused approach helps protect your brand's reputation and ensures a smooth and legally sound marketing process.`
  },

];

const Web3FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='blockchain-sec-6-faq'>
      <div className='faq'>
        <Container>
          <Row>
            <Col>
              <div>
                <h2>Frequently Asked Question
                </h2>
              </div>
              <div className='blockchain-faq-qa'>
                {faqData.map((faq, index) => (
                  <div key={index}>
                    <div
                      onClick={() => handleFAQClick(index)}
                      aria-controls={`faq${index}`}
                      aria-expanded={openFAQ === index}
                      className='faq-1st'
                    >
                      <div className='faq-content'>
                        <h6 className='faq-content-question'>{faq.question}</h6>
                      </div>
                      <div className='faq-icons'>
                        {openFAQ === index ? (
                          <IoIosArrowDropupCircle className='close-circle-faq' />
                        ) : (
                          <IoIosArrowDropdownCircle className='plus-circle-faq' />
                        )}
                      </div>
                    </div>
                    <Collapse in={openFAQ === index}>
                      <div id={`faq${index}`} className='faq-answer-content'>
                        {renderAnswer(faq.answer)}
                      </div>
                    </Collapse>
                  </div>
                ))}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Web3FAQ
import React, { useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import './Dmarketing.css'
import Card from 'react-bootstrap/Card';
import '../Industries/Industries.css';
import { HiArrowLongRight } from "react-icons/hi2";
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Dmarketing = () => {

    const cardData = [
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'AI Marketing',
            text: 'Leverage artificial intelligence to personalize and optimize your marketing strategies for maximum impact and efficiency.',
            link: '/ai-marketing-agency'
        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'AR/VR Marketing',
            text: 'Engage your audience with immersive augmented and virtual reality experiences that bring your brand to life.',
            link: '/ar-vr-marketing-agency'

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'IoT Marketing',
            text: 'Connect with your customers through smart devices and data-driven insights to deliver highly targeted marketing campaigns.',
            link: '/iot-marketing-agency'

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'Metaverse Marketing',
            text: 'Step into the future with metaverse marketing, creating interactive and dynamic virtual environments for your brand.',
            link: '/metaverse-marketing-agency'

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'Blockchain Marketing',
            text: 'Utilize blockchain technology to ensure transparency, security, and trust in your marketing efforts.',
            link: '/blockchain-marketing-agency'

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'DAO Marketing',
            text: 'Empower your decentralized autonomous organization with targeted marketing that drives community growth and participation.',
            link: '/dao-marketing-agency'

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'NFT Marketing',
            text: 'Drive innovation with NFT marketing, offering unique digital assets that enhance brand loyalty and engagement.',
            link: '/nft-marketing-agency'

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'DeFi Marketing',
            text: 'Promote decentralized finance solutions with tailored marketing strategies that highlight the benefits of DeFi.',
            link: '/defi-marketing-agency'

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'dApps Marketing',
            text: 'Boost your decentralized applications\' visibility and user adoption with specialized dApps marketing tactics.',
            link: '/dapp-marketing-agency'

        },
        {
            link: '/gaming-marketing-agency',
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'Gaming Marketing',
            text: 'From mobile to blockchain games, our marketing services cover all aspects to captivate and grow your gaming audience.',

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'Crypto Marketing',
            text: 'Skyrocket Your Crypto\'s Potential. We craft winning crypto marketing strategies - Let\'s grow your brand in the digital asset space.',
            link: '/crypto-marketing-agency'

        },
        {
            imgSrc: <FaChartLine className='img-home-industries-card' />,
            title: 'GameFi Marketing',
            text: 'Empower your GameFi project with tailored marketing strategies that drive growth and engagement, crafted by XDMinds',
            link: '/gamefi-marketing-agency'

        },
    ];
    const [countriesswiper, setCountriesSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (countriesswiper !== null) {
            countriesswiper.autoplay.stop();
        }
    };

    const handleMouseLeave = () => {
        if (countriesswiper !== null) {
            countriesswiper.autoplay.start();
        }
    };
    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };

    const chunkedCardData = chunkArray(cardData, 4);

    return (
        <div className='Dmarketing-home'>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h2>Elevate Your Brand with Our Advanced Web3 Marketing Solutions</h2>
                        </div>
                        <div className='industries-sub-head'>
                            <p>
                                Our advanced Web3 digital marketing services are tailored for the modern digital landscape, integrating blockchain technology and decentralized platforms to amplify your brand's reach and impact. From NFT campaigns to blockchain-based advertising strategies, we specialize in innovative solutions that redefine digital marketing in the Web3 era, ensuring your business stays ahead of the curve with measurable results.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className='desktop-industries-view'>
                {chunkedCardData.map((chunk, index) => (
                    <div className='home-industries-card-div' key={index}>
                        <Row>
                            {chunk.map((card, cardIndex) => (
                                <Col xl={3} sm={6} md={6} lg={3} key={cardIndex}>
                                    <Link to={card.link} reloadDocument className='industries-card-link'>
                                        <Card className='industries-card'>
                                            <Card.Body>
                                                <div className='dmarketing-card-head'>
                                                    <p className='img-home-industries-card'>{card.imgSrc} </p>
                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                </div>
                                                <Card.Text>
                                                    {card.text}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))}
            </div>
            <div className='mobile-view-industries'>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={3}
                    loop={true}
                    dots="true"
                    onSwiper={(swiper) => setCountriesSwiper(swiper)}
                    modules={[Scrollbar, Pagination, Autoplay]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true,
                    }}
                    pagination={{ clickable: true }}
                    className="ps-swiper"

                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 10,

                        },
                        460: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 50,
                        },
                    }}
                >

                    {cardData.map((item, index) => (
                        <SwiperSlide key={index}>
                            <Link to={item.link} reloadDocument className='industries-card-link'>

                                <Card className='industries-card' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    <Card.Body>
                                        <Image src={item.image} className='img-home-industries-card' />
                                        <Card.Title>
                                            <h4>{item.title}</h4>
                                        </Card.Title>
                                        <Card.Text>
                                            {item.text}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className='industries-navi-div'>
                <Link reloadDocument to={'/web3-marketing-services'}>
                    <Button className='navi-page-industries'><HiArrowLongRight /> </Button>
                </Link>
            </div>
        </div>
    )
}

export default Dmarketing
import React from 'react'
import './Section5.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaArrowRightLong } from "react-icons/fa6";
import smm from '../../../Images/seo-agency-xdminds1.svg';
import cm from '../../../Images/aso-marketing-agency-xdminds.svg';
import ppc from '../../../Images/ppc-advertising-agency-xdminds.svg';
import seo from '../../../Images/sec-5-card4.png';
import IM from '../../../Images/sec-5-card5.png';
import PR from '../../../Images/sec-5-card6.png';
import WD from '../../../Images/sec-5-card7.png';
import { Link } from 'react-router-dom';
import { HiArrowLongRight } from "react-icons/hi2";
import Accordion from 'react-bootstrap/Accordion';

const services = [
  { id: 'SMM', name: 'Search Engine Optimization (SEO)', image: smm, title: 'Search Engine Optimization (SEO)', link: '/seo-agency', description: "Dominate search results and become the trusted answer for your target audience's online queries. We'll craft a strategy that secures your rightful place at the top of search engine rankings." },
  { id: 'CM', name: 'App Store Optimization (ASO)', image: cm, title: 'App Store Optimization (ASO)', link: '/aso-marketing-agency', description: "Get your app discovered in a crowded marketplace. We'll optimize your app store listing to skyrocket visibility and drive organic downloads." },
  { id: 'PPC', name: 'Pay Per Click Advertising (PPC)', image: ppc, title: 'Pay Per Click Advertising(PPC)', link: '/ppc-advertising-agency', description: "Go beyond organic reach with targeted advertising campaigns. We'll develop data-driven SEM strategies to put your brand directly in front of high-intent users." },
  { id: 'SEO', name: 'Social Media Marketing (SMM)', image: seo, title: 'Social Media Marketing (SMM)', link: '/social-media-agency', description: 'Spark conversations and build a loyal community around your brand. Our SMM experts will craft engaging content and strategies to amplify your voice across social media platforms.' },
  { id: 'MAM', name: 'Mobile App Marketing', image: IM, title: 'Mobile App Marketing', link: '/mobile-app-marketing-agency', description: "Reach the mobile-first generation. We'll design targeted campaigns to drive app installs, user engagement, and retention." },
  { id: 'PR', name: 'Performance Marketing', image: PR, title: 'Performance Marketing', link: '/performance-marketing-agency', description: "Focus your marketing spend on what works.  We'll meticulously track campaign performance and optimize strategies to deliver measurable results that align with your business goals." },
  { id: 'WD', name: 'Programmatic Advertising', image: WD, title: 'Programmatic Advertising', link: '/programmatic-advertising-agency', description: 'Leverage the power of automation for efficient ad buying.  We\'\ll utilize programmatic platforms to secure the best placements at the most competitive rates.' }
];

const Section5 = () => {
  return (
    <div>
      <div className='sec-5-main-div'>
        <Container>
          <Row>
            <Col>
              <div className='sec-5'>
                <Row>
                  <Col lg={8} md={12} sm={12}>
                    <h2 className='sec-5-head'>Grow From Good to Great:<span><br /></span>Our Expert Digital Marketing Services</h2>
                  </Col>
                  <Col lg={4} md={12} sm={12} className='home-sec5-subhead'>
                    <p className='sec-5-head-p'>Fuel your brand's success with our comprehensive suite of digital marketing services designed to propel you to the forefront of your industry. We deliver strategic solutions that enhance your online presence and drive measurable results.</p>
                  </Col>
                </Row>
              </div>
              <div className='mobile-sec-5-div'>
                <Accordion defaultActiveKey="0">
                  {services.map((service, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div className='sec-5-tab-name'>
                          {service.name}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{service.description}</p>
                        <Link to={service.link} reloadDocument>
                          <Button className='mob-view-Sec-5-lm-btn'><FaArrowRightLong /></Button>
                        </Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='sec5-navi-div'>
        <Link reloadDocument to={'/digital-marketing-agency'}>
          <Button className='navi-page-sec5'><HiArrowLongRight /> </Button>
        </Link>
      </div>
    </div>
  )
}

export default Section5;

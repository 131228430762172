import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../Blockchain/Blockchain.css'

const Section1 = () => {
  const accordionData = [
    {
      id: 'Web3',
      title: 'What is Decentralized Finance (DeFi)?',
      heading: '',
      content: [
        'Decentralized Finance, commonly known as DeFi, is a revolutionary financial system that operates on blockchain technology, enabling peer-to-peer financial interactions without relying on traditional intermediaries like banks. DeFi leverages smart contracts to automate transactions, offering a more transparent, secure, and accessible financial ecosystem.',
      ],
    },
    {
      id: 'web3-marketing',
      title: "DeFi vs. Centralized Finance: What's the Difference?",
      heading: '',
      content: [
        "The primary difference between DeFi and centralized finance (CeFi) lies in their structure and operation. CeFi relies on centralized institutions to manage financial transactions, whereas DeFi eliminates intermediaries, allowing users to interact directly with financial services on a decentralized network. This decentralization enhances security, transparency, and user control.",
      ],
    },

    {
      id: 'wanuf',
      title: 'How Does Decentralized Finance (DeFi) Work?',
      heading: '',
      content: [
        'DeFi works by utilizing blockchain technology and smart contracts. Smart contracts are self-executing contracts with the terms of the agreement directly written into code. These contracts run on decentralized networks like Ethereum, enabling automated and trustless financial transactions such as lending, borrowing, trading, and staking.',
      ],
    },

    {
      id: 'comparison',
      title: 'What Are the Goals of DeFi?',
      heading: '',
      content: [
        'The primary goals of DeFi are to democratize access to financial services, reduce costs, increase transparency, and eliminate the need for traditional intermediaries. DeFi aims to create an open financial system accessible to anyone with an internet connection, fostering financial inclusion and innovation.',
      ],
    },


    {
      id: 'navigate',
      title: 'What Are the Current Examples of DeFi?',
      heading: '',
      content: [
        "There are numerous examples of DeFi platforms that offer various financial services:"
      ],
      benefits: [
        {
          title: 'Uniswap:',
          description: 'A decentralized exchange for trading cryptocurrencies.',
        },
        {
          title: 'Compound:',
          description: 'A lending and borrowing platform.',
        },
        {
          title: 'Aave:',
          description: ' A liquidity protocol for earning interest on deposits and borrowing assets.',
        },
        {
          title: 'MakerDAO: ',
          description: 'A platform for creating and managing decentralized stablecoins.',
        },
        {
          title: 'Yearn Finance:',
          description: ' A yield aggregator that optimizes returns on DeFi investments.',
        },
      ],
    },




    {
      id: 'blockchain-revolution',
      title: 'How to Get Involved in DeFi?',
      heading: '',
      content: [
        'Getting involved in DeFi is straightforward. Start by setting up a digital wallet like MetaMask, which allows you to interact with DeFi applications. Next, explore DeFi platforms and choose services such as lending, borrowing, or staking that align with your financial goals. Ensure you stay informed about the latest developments and risks in the DeFi space.',
      ],
    },

    {
      id: 'wianm',
      title: 'How Do You Benefit from DeFi?',
      heading: '',
      content: [
        'DeFi offers several benefits, including higher interest rates on savings, lower transaction fees, enhanced security through blockchain technology, and greater control over your financial assets. Additionally, DeFi provides innovative investment opportunities and financial products that are not available in traditional finance.',
      ],
    },
    {
      id: 'eonm',
      title: 'Why Is DeFi So Important?',
      heading: '',
      content: [
        'DeFi is important because it represents a paradigm shift in the financial industry. It democratizes access to financial services, promotes financial inclusion, and fosters innovation. By eliminating intermediaries, DeFi reduces costs and enhances transparency, making financial services more efficient and accessible.',
      ],
    },
    {
      id: 'winm',
      title: 'What is DeFi Marketing?',
      heading: '',
      content: [
        'DeFi marketing involves promoting decentralized finance projects using specialized strategies to increase visibility, attract users, and drive adoption. It encompasses various marketing techniques such as content creation, social media engagement, influencer partnerships, and community building to highlight the unique value proposition of DeFi projects.',
      ],
    },
    {
      id: 'project',
      title: 'Why Should You Do Marketing for DeFi Projects?',
      heading: '',
      content: [
        "Marketing is crucial for DeFi projects to build trust, educate potential users, and stand out in a competitive market. Effective marketing helps attract and retain users, fosters community engagement, and drives adoption, ultimately contributing to the project's long-term success and sustainability.",
      ],
    },
    {
      id: 'Anm',
      title: 'What Are the Advantages of DeFi Marketing?',
      heading: '',
      content: [
        'DeFi marketing offers several advantages, including enhanced project visibility, increased user acquisition, improved community engagement, and a stronger market presence. By effectively communicating the benefits and unique features of your DeFi project, marketing helps build credibility and trust among potential users and investors.',
      ],
    },


  ];

  return (
    <div className='blockchain-sec1'>
      <Container>
        <Row>
          <Col>
            <h2>DeFi Explained Understanding,<span className='metaverse-sec1-break'>
              <br /></span> Working with, and Marketing Your Project</h2>
            <div className='mobile-view'>
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {accordionData.map((section, index) => (
                  <div className="accordion-item" key={index}>
                    <p className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        {section.title}
                      </button>
                    </p>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <h4 className='content-head'>{section.heading}</h4>
                        {section.content && section.content.map((paragraph, idx) => (
                          <p className='content-sescription' key={idx}>{paragraph}</p>
                        ))}
                        {section.benefits && (
                          <div className="benefits">
                            {section.benefits.map((benefit, idx) => (
                              <React.Fragment key={idx}>
                                <p className="benefits-discript">{benefit.title}</p>
                                <p>{benefit.description}</p>
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Section1
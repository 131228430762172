import { createSlice } from "@reduxjs/toolkit";
import * as apiHelper from "../../apiHelper";
const initialState = {
  RegistrationSuccess: [],
  CountryList: [],
}
const slice = createSlice({
  name: "homepage",
  initialState: initialState,

  reducers: {
    LeadFormSubmit: (state, action) => {
      state.LeadFormSubmit = action.payload;
    },
    CareerFormSubmit: (state, action) => {
      state.CareerFormSubmit = action.payload;
    },
    SubscribeFormSubmit: (state, action) => {
      state.SubscribeFormSubmit = action.payload;
    },
    GetBlogDetails: (state, action) => {
      state.GetBlogDetails = action.payload;
    },
  }
});
export default slice.reducer;

const {
  LeadFormSubmit,
  CareerFormSubmit,
  SubscribeFormSubmit,
  GetBlogDetails,
  GetAllBlog,
  GetBlogCategory,
  GetAllJobs,
  GetPage,
  GetPageMeta
} = slice.actions;


export const LeadFormSubmitApi =
  (data, callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.postRequest("user/createLeads", data);
        callback(response);
        dispatch(LeadFormSubmit(response));
        if (response) {
        }
      } catch (e) {
      }
    };

export const CareerFormSubmitApi =
  (data, callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.postRequest("user/carear", data);
        callback(response);
        dispatch(CareerFormSubmit(response));
        if (response) {
        }
      } catch (e) {
      }
    };

export const SubscribeFormSubmitApi =
  (data, callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.postRequest("user/SubscribeUs", data);
        callback(response);
        dispatch(SubscribeFormSubmit(response));
        if (response) {
        }
      } catch (e) {
      }
    };

export const GetBlogDetailsApi =
  (data, callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.postRequest("user/getBlogs", data);
        callback(response);
        dispatch(GetBlogDetails(response));
        if (response) {
        }
      } catch (e) {
      }
    };

export const GetAllBlogApi =
  (callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.getRequest("user/getAllBlogs");
        callback(response);
        dispatch(GetAllBlog(response));
        if (response) {
        }
      } catch (e) {
      }
    };

export const GetBlogCategoryApi =
  (data, callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.postRequest("user/getBlogsCategory", data);
        callback(response);
        dispatch(GetBlogCategory(response));
        if (response) {
        }
      } catch (e) {
      }
    };

export const GetAllJobsApi =
  (callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.getRequest("user/getJob");
        callback(response);
        dispatch(GetAllJobs(response));
        if (response) {
        }
      } catch (e) {
      }
    };

export const GetPageApi =
  (data, callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.postRequest("user/getPage", data);
        callback(response);
        dispatch(GetPage(response));
        if (response) {
        }
      } catch (e) {
      }
    };

export const GetPageMetaApi =
  (callback = () => { }) =>
    async (dispatch) => {
      try {
        const response = await apiHelper.getRequest("admin/getAdminMeta");
        callback(response);
        dispatch(GetPageMeta(response));
      } catch (e) {
      }
    };


import React from 'react'
import Contact from '../../Components/Contact/Contact'
import '../../Components/Home/Banner/Banner.css'
const Contactpage = (props) => {
  const { countryCode } = props
  return (
    <div>
      <Contact countryCode={countryCode} />
    </div>
  )
}

export default Contactpage
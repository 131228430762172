import React from 'react';



const Tabs = ({ tabData }) => {
    return (
        <div className='d-tabs'>
            <div className='row'>
                <div className='col-12'>
                    <div className='tabs-navbar p-lg-2'>
                        <div className="nav nav-pills flex-nowrap justify-content-between" id="pills-tab" role="tablist">
                            {tabData.map((tab, index) => (
                                <button
                                    key={tab.id}
                                    className={`nav-link-dtabs ${index === 0 ? 'active' : ''}`}
                                    id={`${tab.id}-tab`}
                                    data-bs-toggle="pill"
                                    data-bs-target={`#${tab.id}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={tab.id}
                                    aria-selected={index === 0}
                                >
                                    {tab.label}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className='tabs-body mt-4 mt-md-5 mt-lg-5'>
                        <div className="tab-content">
                            {tabData.map((tab, index) => (
                                <div
                                    key={tab.id}
                                    className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                    id={tab.id}
                                    role="tabpanel"
                                    aria-labelledby={`${tab.id}-tab`}
                                    tabIndex="0"
                                >
                                    <h3 className='h-text-5'>{tab.title}</h3>
                                    {tab.sections.map((section, sectionIndex) => (
                                        <React.Fragment key={sectionIndex}>
                                            <h5 className='h-text-6'>{section.subtitle}</h5>
                                            <p className='p-text-5'>{section.content}</p>
                                        </React.Fragment>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tabs;

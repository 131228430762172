import React from 'react'
import DefiBanner from '../../Components/Services/Defi/DefiBanner'
import Section1 from '../../Components/Services/Defi/Section1'
import Section2 from '../../Components/Services/Defi/Section2'
import Section3 from '../../Components/Services/Defi/Section3'
import Section4 from '../../Components/Services/Defi/Section4'
import Section5 from '../../Components/Services/Defi/Section5'
import Section6 from '../../Components/Services/Defi/Section6'
import Latest from '../../Components/Home/Latest/Latest'



const Defi = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <DefiBanner countryCode={countryCode} />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Latest modifiedPath='Latest DeFi Marketing Insights' />
      <Section6 />


    </div>
  )
}

export default Defi
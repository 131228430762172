import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MdArrowCircleDown } from "react-icons/md";


const Section5 = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const data = [
    {
      number: 1,
      title: 'Expertise in DeFi Ecosystem',
      description: 'XDMinds has a deep understanding of the decentralized finance (DeFi) ecosystem, making us an ideal partner for your DeFi marketing needs. Our team is well-versed in the latest trends, technologies, and regulatory landscapes within the DeFi space. This expertise enables us to craft informed and effective marketing strategies that resonate with your target audience and align with industry standards.'
    },
    {
      number: 2,
      title: 'Targeted Audience Reach',
      description: 'We specialize in connecting your DeFi project with the right audience through targeted marketing efforts. Our data-driven approach allows us to identify and engage potential investors, users, and partners who are genuinely interested in decentralized finance. By leveraging advanced analytics and audience segmentation, we ensure your marketing message reaches those who matter most to your project’s success.'
    },
    {
      number: 3,
      title: 'Innovative Marketing Strategies',
      description: 'At XDMinds, we utilize innovative marketing strategies tailored specifically for the DeFi sector. From influencer partnerships and content marketing to social media campaigns and community engagement, we employ a multi-channel approach to maximize your project’s visibility. Our creative solutions are designed to highlight the unique value propositions of your DeFi offerings and generate buzz within the crypto community.'
    },
    {
      number: 4,
      title: ' Comprehensive Service Offering',
      description: 'We offer a comprehensive suite of DeFi marketing services, including content creation, social media management, influencer marketing, PR, and community building. Our holistic approach ensures that every aspect of your project’s marketing is covered, providing a consistent and cohesive brand message across all platforms.'
    },
    {
      number: 5,
      title: 'Deep Industry Connections',
      description: "XDMinds boasts extensive connections within the DeFi and broader cryptocurrency industry. Our strong relationships with key players, influencers, and media outlets enable us to amplify your project’s reach and credibility. These connections provide valuable opportunities for partnerships, collaborations, and media exposure, further enhancing your project's visibility and success."
    },
    {
      number: 6,
      title: 'Customized Solutions for Unique Needs',
      description: 'We understand that every DeFi project is unique, with its own goals, challenges, and target audience. That’s why we offer customized marketing solutions tailored to your specific needs. Whether you’re launching a new DeFi platform, promoting a token sale, or expanding your user base, we develop strategies that align with your objectives and drive measurable results.'
    },
    {
      number: 7,
      title: 'Focus on Compliance and Security',
      description: 'In the DeFi space, compliance and security are paramount. XDMinds is committed to maintaining the highest standards of integrity and transparency in all our marketing efforts. We ensure that our strategies adhere to relevant regulations and guidelines, protecting your project’s reputation and building trust with your audience.'
    },
    {
      number: 8,
      title: 'Long-Term Partnership and Support',
      description: 'Our relationship with clients goes beyond a single campaign. We aim to build long-term partnerships, offering continuous support and strategic guidance as your DeFi project evolves. Our team is dedicated to helping you navigate the dynamic DeFi landscape and achieve sustained growth and success.'
    },
    {
      number: 9,
      title: 'Insightful Analytics and Reporting',
      description: 'Transparency and accountability are core values at XDMinds. We provide detailed analytics and reporting on the performance of your marketing campaigns. Our insights help you understand the impact of our efforts, optimize your strategies, and make informed decisions for future marketing activities.'
    },
    {
      number: 10,
      title: 'Passion for Innovation',
      description: 'XDMinds is passionate about innovation and the transformative potential of DeFi. We are enthusiastic about collaborating with forward-thinking projects and contributing to the growth of the decentralized finance ecosystem. Our passion drives us to deliver exceptional results and help our clients make a meaningful impact in the DeFi space.'
    },
  ];

  return (
    <div>
      <div className='blockchain-sec5 '>
        <Container>
          <Row>
            <Col>
              <h2>Why XDMinds is the Best Choice for<span className='metaverse-sec2-break'><br /></span> Your DeFi Marketing Needs?
              </h2>
              <p className='subhead'>
                Partnering with XDMinds means leveraging our deep industry knowledge, innovative strategies, and results-driven approach. We are passionate about DeFi and committed to helping your project achieve its full potential. Our tailored marketing solutions are designed to meet the unique needs of your DeFi project, ensuring sustained growth and success.
              </p>
              <div className='metaverse-sec5 desktop-view mobile-hide'>
                <Row>
                  {data.map((item, index) => (
                    <Col
                      key={index}
                      lg={4}
                      xl={4}
                      sm={6}
                      md={6}
                      style={item.number % 3 === 0 ? { border: 'none' } : {}}
                    >
                      <div className='number-div'>
                        <div className='bc-sec5-carde-head'>
                          <p className='bc-sec5-carde-head-number'>{item.number}</p>
                          <p>{item.title}</p>
                        </div>
                        <p className='bc-sec5-card-discription'>{item.description}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='about'>
        <section className='about-section-5 p-0'>
          <div className='custom-accordion desktop-hide tab-hide'>
            <div className="accordion" id="accordionExample">
              {data.map((item, index) => (
                <div key={item.id} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                      onClick={() => handleClick(index)}
                      type="button"
                      aria-expanded={openIndex === index}
                      aria-controls={item.id}
                    >
                      {item.title}
                      <div className="arrow-icon-btn">
                        <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className='p-text-1'>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

    </div>

  );
};

export default Section5
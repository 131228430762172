import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './Defi.css'
import BreadCrumbs from '../../BreadCrumbs';

const DefiBanner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { countryCode } = props;

  return (

    <div className='web3-banner'>
      <Container>
        <Row>
          <Col>
            <BreadCrumbs />
            <div className='banner-left-sec  min-100vh'>
              <h1>High-performing<span className='changing-banner-content'>DeFi </span><span className='home-banner-break'><br /></span> Marketing Agency</h1>
              <p>At XDMinds, we specialize in driving exceptional results for your DeFi project. Our marketing team of experts leverages innovative strategies and data-driven insights to elevate your project and ensure its success in the competitive DeFi landscape.</p>
              <div className='btn-home-banner-request-div'>
                <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
              </div>
              <ModalForm
                countryCode={countryCode}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  )
}

export default DefiBanner
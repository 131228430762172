import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../Blockchain/Blockchain.css'

const Section1 = () => {
  const accordionData = [
    {
      id: 'Web3',
      title: 'What is an NFT?',
      heading: '',
      content: [
        'NFTs, or Non-Fungible Tokens, are unique digital assets that represent ownership of a specific item or piece of content, stored on a blockchain.',
      ],
    },
    {
      id: 'web3-marketing',
      title: 'How Do NFTs Work?',
      heading: '',
      content: [
        "NFTs function through blockchain technology, which ensures each token's uniqueness and provenance, making them distinct from other digital or physical assets.",
      ],
    },

    {
      id: 'wanuf',
      title: 'What are NFTs used for?',
      heading: '',
      content: [
        'NFTs are used for a variety of purposes, including digital art, collectibles, virtual real estate, gaming assets, and more, offering new avenues for creators and investors alike.',
      ],
    },

    {
      id: 'comparison',
      title: 'How does NFT make money?',
      heading: '',
      content: [
        'NFTs generate revenue through the sale of unique digital items, royalties for creators on secondary sales, and by creating exclusive experiences and content for buyers.',
      ],
    },


    {
      id: 'navigate',
      title: 'How do you buy NFTs?',
      heading: '',
      content: [
        'NFTs can be purchased on specialized marketplaces using cryptocurrency. Buyers need to set up a digital wallet, fund it with the required cryptocurrency, and then proceed to buy the NFT of their choice.',
      ],
    },



    {
      id: 'blockchain-revolution',
      title: 'How do I make my own NFT?',
      heading: '',
      content: [
        'To create your own NFT, you need to select a blockchain platform, create a digital wallet, and mint your digital asset using an NFT marketplace that supports the chosen blockchain.',
      ],
    },

    {
      id: 'wianm',
      title: 'What is an NFT marketplace?',
      heading: '',
      content: [
        'An NFT marketplace is a digital platform where NFTs are bought, sold, and traded. These marketplaces facilitate the transactions and display NFTs for potential buyers.',
      ],
    },
    {
      id: 'eonm',
      title: 'Examples of NFT marketplaces',
      heading: '',
      content: [
        'Popular NFT marketplaces include OpenSea, Rarible, Foundation, and SuperRare, each offering a unique environment for trading and showcasing NFTs.',
      ],
    },
    {
      id: 'winm',
      title: 'What is NFT Marketing?',
      heading: '',
      content: [
        'NFT marketing involves promoting NFT projects to increase visibility, engagement, and sales through various digital marketing strategies, ensuring they reach the right audience.',
      ],
    },
    {
      id: 'project',
      title: 'Why should you do Marketing for NFT projects?',
      heading: '',
      content: [
        'Marketing is essential for NFT projects to stand out in a crowded marketplace, attract potential buyers, and build a community around the project, ultimately driving sales and growth.',
      ],
    },
    {
      id: 'Anm',
      title: 'Advantages of NFT Marketing',
      heading: '',
      content: [
        'Effective NFT marketing can boost brand awareness, drive higher sales, create a loyal community, and establish a strong presence in the digital collectibles market.',
      ],
    },


  ];

  return (
    <div className='blockchain-sec1'>
      <Container>
        <Row>
          <Col>
            <h2>Understanding and Marketing NFTs: <span className='metaverse-sec1-break'>
              <br /></span>The Essential Guide </h2>
            <div className='mobile-view'>
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {accordionData.map((section, index) => (
                  <div className="accordion-item" key={index}>
                    <p className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        {section.title}
                      </button>
                    </p>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <h4 className='content-head'>{section.heading}</h4>
                        {section.content && section.content.map((paragraph, idx) => (
                          <p className='content-sescription' key={idx}>{paragraph}</p>
                        ))}
                        {section.benefits && (
                          <div className="benefits">
                            {section.benefits.map((benefit, idx) => (
                              <React.Fragment key={idx}>
                                <p className="benefits-discript">{benefit.title}</p>
                                <p>{benefit.description}</p>
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Section1
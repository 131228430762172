import React, { useRef } from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs';

const PrivacyPolicy = () => {
    const sectionHeadRef = useRef(null);
    const targetHeadingRef = useRef(null);

    const handleScrollToOpenPositions = () => {
        if (targetHeadingRef.current) {
            const targetPosition = targetHeadingRef.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: targetPosition - 100, behavior: 'smooth' });
        }
    };

    return (
        <div className='privacy-policy'>
            <div className='digital-marketing'>
                <section className='privacy-policy-banner min-100vh digital-marketing-banner'>
                    <BreadCrumbs />
                    <h1 className='h-text-1'>
                        Privacy Policy
                    </h1>
                    <p className='p-text-1'>
                        Welcome to XDMinds, your trusted partner in Web3 marketing solutions and comprehensive digital
                        marketing services. Protecting your privacy and ensuring the security of your personal information
                        is paramount to us. This Privacy Policy outlines how XDMinds collects, uses, and safeguards your
                        information when you visit our website or engage our services.
                    </p>
                    <button className='request-btn ' onClick={handleScrollToOpenPositions}>
                        Read Our Privacy Policy
                    </button>
                </section >
            </div>
            <div className='pp-section-head' ref={sectionHeadRef}>
                <section className='pp-section-2 ' >
                    <h2 className='h-text-2' ref={targetHeadingRef}> 1. Information Collection </h2>
                    <div className='pp-sec-head-2'>
                        <h2 className='h-text-3'>Personal Information: </h2>
                        <p className='p-text-2'>We may collect personal information such as your name, email address,
                            phone number, and company details when you voluntarily provide it to us through our website
                            contact forms, or when communicating with us via email or phone.</p>
                    </div>
                    <div className='pp-sec-head-2'>
                        <h2 className='h-text-3'>Automated Information:  </h2>
                        <p className='p-text-2'>
                            When you visit our website, we may automatically collect certain information such as your IP address,
                            browser type, operating system, and pages visited. This information helps us
                            improve our website's functionality and user experience.
                        </p>
                    </div>
                </section>
                <section className='pp-section-2'>
                    <h2 className='h-text-2'> 2. Use of Information </h2>
                    <div className='pp-sec-head-2'>
                        <h2 className='h-text-3'>Providing Services:  </h2>
                        <p className='p-text-2'>
                            We use your personal information to provide and customize our Web3 marketing services and digital marketing
                            solutions according to your business needs.
                        </p>
                    </div>
                    <div className='pp-sec-head-2'>
                        <h2 className='h-text-3'>Communication: </h2>
                        <p className='p-text-2'>
                            We may use your contact information to communicate with you regarding our services, updates, promotional offers,
                            and important notices related to your account.
                        </p>
                    </div>
                    <div className='pp-sec-head-2'>
                        <h2 className='h-text-3'>Analytics: </h2>
                        <p className='p-text-2'>
                            We may use aggregated and anonymized data for analytical purposes to understand trends, improve our services,
                            and enhance our marketing strategies.
                        </p>
                    </div>
                </section>
                <section className='pp-section-2'>
                    <h2 className='h-text-2'> 3. Information Sharing </h2>
                    <p className='p-text-3'>
                        Third-Party Service Providers: We may share your information with trusted
                        third-party service providers who assist us in delivering our services, such as
                        cloud hosting providers, analytics providers, and payment processors. These providers are contractually
                        obligated to safeguard your information.
                    </p>
                    <div className='pp-sec-head-2'>
                        <h2 className='h-text-3'>Legal Compliance: </h2>
                        <p className='p-text-2'>
                            We may disclose your information as required by law or if we believe that such disclosure is necessary
                            to comply with legal obligations, protect our rights or property,
                            or investigate fraud or illegal activities.
                        </p>
                    </div>
                </section>
                <section className='pp-section-2'>
                    <h2 className='h-text-2'> 4. Data Security</h2>
                    <p className='p-text-3'>
                        We implement industry-standard security measures to protect your
                        personal information from unauthorized access, alteration, disclosure, or destruction.
                        However, no method of transmission over the Internet or electronic storage is 100% secure,
                        and we cannot guarantee the absolute security of your information.
                    </p>
                </section>
                <section className='pp-section-2'>
                    <h2 className='h-text-2'>5. Your Choices</h2>
                    <p className='p-text-3 p-text-m'>
                        You have the right to access, update, or delete your personal information
                        held by us. You can do this by contacting us using the information provided below.
                    </p>
                    <p className='p-text-3 p-text-m '>
                        You may choose to opt out of receiving promotional emails from us by following the unsubscribe
                        instructions included in the
                        emails or by contacting us directly.
                    </p>
                </section>
                <section className='pp-section-2'>
                    <h2 className='h-text-2'> 6. Changes to this Privacy Policy</h2>
                    <p className='p-text-3'>
                        XDMinds reserves the right to update or modify this Privacy Policy
                        at any time. Any changes will be effective immediately upon posting
                        on our website. We encourage you to review this Privacy
                        Policy periodically to stay informed about
                        how we are protecting your information.
                    </p>
                </section>
                <section className='pp-section-2'>
                    <h2 className='h-text-2'>7. Contact Us</h2>

                    <p className='p-text-3'>
                        If you have any questions, concerns, or requests regarding this Privacy Policy
                        or the handling of your personal information,
                        please contact us a at <a className='mail-a' href="mailto:contact@xdminds.com">
                            contact@xdminds.com
                        </a>. By using XDMinds' website and services, you acknowledge that you have read, understood, and agree
                        to the terms of this Privacy Policy. If you do not agree with this Privacy Policy, please do not use our website
                        or provide us with your personal information.
                    </p>
                </section>

            </div>

        </div>
    )
}

export default PrivacyPolicy

import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';

const Section3 = () => {
    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'Marketing Strategy Creation',
            text: "Crafting customized marketing strategies that align with your dApp project's goals and target audience, ensuring optimal results.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'dApp Branding',
            text: "Developing a strong brand identity that resonates with your audience, creating a lasting impression and fostering trust.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Product Marketing',
            text: "Promoting your dApp's unique features and benefits to attract users and differentiate it from competitors.",
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'SEO',
            text: "Implementing SEO techniques to improve your dApp's search engine rankings, driving organic traffic and increasing visibility.",
        },
        {
            link: "/ppc-advertising-agency",
            imgSrc: <FaChartLine />,
            title: 'PPC Advertising',
            text: 'Running targeted pay-per-click campaigns to reach potential users and drive immediate traffic and conversions.',
        },
        {
            link: "/social-media-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Social Media Marketing',
            text: "Leveraging social media platforms to build a community, engage with users, and promote your dApp effectively.",
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Influencer Marketing',
            text: "Partnering with influencers to amplify your dApp's reach, credibility, and user engagement through authentic endorsements.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Email Marketing',
            text: 'Creating personalized email campaigns to nurture leads, keep users informed, and drive engagement and retention.'
        },
        {
            imgSrc: <FaChartLine />,
            title: 'PR and Media Outreach',
            text: "Amplify your dApp's visibility with strategic PR and media outreach. Our team secures top-tier coverage and builds a positive public image to drive engagement and credibility.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Community Marketing',
            text: 'Building and managing a vibrant user community to foster loyalty, engagement, and user-generated content.',
        },
        {
            link: "/gaming-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Gaming Marketing',
            text: 'Promoting gaming dApps through targeted campaigns that highlight unique gameplay features and attract gamers.',
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Content Marketing',
            text: "Producing high-quality content that educates, informs, and engages your audience, driving traffic and conversions.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Telegram Marketing',
            text: 'Utilizing Telegram to communicate with your audience, share updates, and foster a strong community presence.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Discord Marketing',
            text: 'Engaging with users on Discord through interactive channels, discussions, and community-building activities.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Video Marketing',
            text: "Creating compelling video content that showcases your dApp's features, benefits, and user experience.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Lead Generation',
            text: "Implementing strategies to attract and capture leads, converting prospects into loyal users and customers.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Omnichannel Marketing',
            text: "Coordinating dApp marketing efforts across multiple channels to provide a seamless and cohesive user experience.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Whitepaper / Litepaper',
            text: "Developing comprehensive whitepapers and litepapers that clearly explain your dApp's vision, technology, and value proposition.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Incentivization',
            text: "Boost user engagement and retention by designing effective token economic models that reward and incentivize participation within your dApp ecosystem.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Educational Content',
            text: "Empower your audience with comprehensive educational materials and interactive workshops that enhance understanding and adoption of your dApp..",
        },
    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (
        <div>
            <div className='blockchain-sec-4'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>Fueling the Future of dApps <span className='msec4-break'><br />
                                </span>XDMinds' Comprehensive dApp Marketing Services
                                </h2>
                            </div>
                            <div className='industries-sub-head'>
                                <p>
                                    At XDMinds, we fuel the future of dApps with our comprehensive dApp marketing services. Our expert team develops tailored strategies that drive user acquisition, engagement, and growth for your decentralized application. From branding to influencer marketing, we cover all aspects of dApp promotion, ensuring your project reaches its full potential.
                                </p>
                            </div>

                            <div className='desktop-industries-view'>
                                {chunkedCardData.map((chunk, index) => (
                                    <div className='home-industries-card-div' key={index}>
                                        <Row>
                                            {chunk.map((card, cardIndex) => (
                                                <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                    {card.link ? (
                                                        <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Link>
                                                    ) : (
                                                        <div className='c-default h-100'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                            </div>

                            <div className='mobile-view-industries'>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop={true}
                                    dots="true"
                                    onSwiper={(swiper) => setCardSwiper(swiper)}
                                    modules={[Scrollbar, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{ clickable: true }}
                                    className="ps-swiper"
                                    breakpoints={{
                                        0: { slidesPerView: 1, spaceBetween: 10 },
                                        460: { slidesPerView: 1, spaceBetween: 5 },
                                        768: { slidesPerView: 1, spaceBetween: 30 },
                                        1024: { slidesPerView: 6, spaceBetween: 50 },
                                    }}
                                >
                                    {cardData.map((card, index) => (
                                        <SwiperSlide key={index}>
                                            {card.link ? (
                                                <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            ) : (
                                                <div className='c-default h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Section3
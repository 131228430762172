import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import TopFaq from './TopFaq';
import GridCard from './GridCard';
import graph from '../../assets/images/graph.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import Tabs from './Tabs';
import BottomFaq from './BottomFaq';
import DSwiper from './DSwiper';
import Latest from '../../Components/Home/Latest/Latest';
import BreadCrumbs from '../../Components/BreadCrumbs';
import { Link } from 'react-router-dom';


const PayPerClick = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const topFaqData = [
        {
            question: "What is PPC?",
            answer: "PPC, or Pay Per Click, is an online advertising model where advertisers pay a fee each time their ad is clicked. It's a way to buy visits to your site rather than earning them organically."
        },
        {
            question: "What is the Difference Between SEO and PPC Advertising?",
            answer: "SEO (Search Engine Optimization) focuses on improving organic search results, while PPC advertising involves paying for ad placements in search results. SEO is a long-term strategy, whereas PPC provides immediate visibility and traffic."
        },
        {
            question: "How Does PPC Work?",
            answer: "PPC works by bidding on keywords relevant to your business. When users search for these keywords, your ad appears in search results. You only pay when someone clicks on your ad, making it a cost-effective way to drive targeted traffic."
        },
        {
            question: "What Are the Different Types of PPC Ads?",
            answer: "PPC ads come in various forms, including search ads, display ads, social media ads, video ads, and shopping ads. Each type serves different purposes and reaches audiences on different platforms."
        },
        {
            question: "What are PPC Management Services?",
            answer: "PPC management services involve the creation, monitoring, and optimization of PPC campaigns. This includes keyword research, ad copywriting, bid management, performance tracking, and continuous improvement to maximize ROI."
        },
        {
            question: "SEO vs. PPC: Which is the Right Strategy for Your Brand?",
            answer: "The choice between SEO and PPC depends on your business goals. SEO is ideal for long-term growth and building authority, while PPC is great for immediate results and targeting specific audiences. Often, a combination of both works best."
        },
        {
            question: "Why Does Pay-Per-Click Marketing Work for Your Business?",
            answer: "PPC marketing works because it provides immediate visibility, precise targeting, and measurable results. It allows businesses to reach their target audience quickly and effectively, driving high-quality traffic and conversions."
        },
        {
            question: "What is Search Engine Marketing (SEM)?",
            answer: "SEM is a broader term that encompasses both SEO and PPC. It involves promoting websites by increasing their visibility in search engine results pages through paid advertising and optimization techniques."
        },
        {
            question: "How Does SEM Work?",
            answer: "SEM works by leveraging both organic and paid strategies to improve a website's visibility in search engine results. This includes optimizing content for SEO and running PPC campaigns to drive traffic."
        },
        {
            question: "Why is SEM Important in Digital Marketing?",
            answer: "SEM is important because it combines the strengths of SEO and PPC to create a comprehensive approach to search engine visibility. It helps businesses capture both organic and paid traffic, increasing overall reach and effectiveness."
        },
        {
            question: "What are the Benefits of Using PPC Marketing Services?",
            answer: "PPC marketing services offer several benefits, including instant traffic, precise targeting, budget control, and measurable results. They help businesses reach their target audience quickly and efficiently, leading to higher ROI."
        },
        {
            question: "What is the Difference Between PPC and CPC?",
            answer: "PPC (Pay Per Click) is the overall advertising model, while CPC (Cost Per Click) refers to the actual price paid for each click on an ad. PPC campaigns are managed to optimize CPC and ensure cost-effective results."
        },
        {
            question: "Why Do You Need a Professional PPC Advertising Company?",
            answer: "A professional PPC advertising company provides expertise, tools, and resources to create and manage effective campaigns. They ensure your ads are optimized for performance, saving you time and maximizing your return on investment."
        },
    ]

    const cardData = [
        {
            src: graph,
            alt: 'PPC Ads Strategy Creation',
            title: 'PPC Ads Strategy Creation',
            text: 'We develop tailored PPC strategies that align with your business goals, target audience, and budget, ensuring maximum ROI and effective ad spend.'
        },
        {
            src: graph,
            alt: 'PPC Landing Page Creation & Optimization',
            title: 'PPC Landing Page Creation & Optimization',
            text: 'Maximize your PPC campaign success with our expertly crafted landing pages. Our team focuses on conversion-driven designs and seamless optimization to ensure you get the most out of every click.'
        },
        {
            src: graph,
            alt: 'PPC Keyword Research And Analysis',
            title: 'PPC Keyword Research And Analysis',
            text: 'Unlock the potential of your PPC campaigns with our comprehensive keyword research and analysis. We identify high-performing keywords that drive traffic and conversions, ensuring your ads reach the right audience.'
        },
        {
            src: graph,
            alt: 'PPC Competitor Analysis',
            title: 'PPC Competitor Analysis',
            text: 'Stay ahead of the competition with our in-depth PPC competitor analysis. We scrutinize your competitors’strategies to uncover opportunities and optimize your campaigns for superior performance.'
        },
        {
            src: graph,
            alt: 'PPC Bidding Strategy Creation',
            title: 'PPC Bidding Strategy Creation',
            text: 'Maximize your ad spending with our tailored PPC bidding strategy creation. We develop bidding strategies that align with your business goals, ensuring you get the best value for every dollar spent.'
        },
        {
            src: graph,
            alt: 'PPC Ad Copywriting',
            title: 'PPC Ad Copywriting',
            text: 'Capture attention and drive action with our compelling PPC ad copywriting. Our expert copywriters craft persuasive ad copy that resonates with your target audience, increasing click-through rates and conversions.'
        },
        {
            src: graph,
            alt: 'PPC Ads Conversion Tracking Setup',
            title: 'PPC Ads Conversion Tracking Setup',
            text: 'Track your success accurately with our PPC ads conversion tracking setup. We implement precise tracking mechanisms to monitor conversions, providing you with clear insights into your campaign performance.'
        },
        {
            src: graph,
            alt: 'PPC Ads A/B Split Testing',
            title: 'PPC Ads A/B Split Testing',
            text: 'Discover what works best for your audience with our PPC ads A/B split testing. We analyze different versions of your ads to find the most effective one, driving better results for your campaigns.'
        },
        {
            src: graph,
            alt: 'Mobile PPC Ads Management',
            title: 'Mobile PPC Ads Management',
            text: 'Reach your audience on the go with our mobile PPC ads management services. We create and optimize ads specifically for mobile devices, ensuring your brand stays connected with users anytime, anywhere.'
        },
        {
            src: graph,
            alt: 'Cost Per Conversion Optimization',
            title: 'Cost Per Conversion Optimization',
            text: 'Reduce your marketing expenses with our cost-per-conversion optimization strategies. We fine-tune your campaigns to deliver more conversions at a lower cost, boosting your ROI effectively.'
        },
        {
            src: graph,
            alt: 'PPC Campaign Review & Audit',
            title: 'PPC Campaign Review & Audit',
            text: 'A thorough PPC campaign review and audit by XDMinds identifies areas for improvement and optimization. We analyze your campaign’s performance, assess keyword effectiveness, and refine strategies to enhance ROI and achieve your advertising goals.'
        },
        {
            src: graph,
            alt: 'Search Engine Ads',
            title: 'Search Engine Ads',
            text: 'We manage your search engine ads across platforms like Google, Bing, Yahoo Ads, Yandex Ads, and Baidu Ads utilizing keyword research and ad optimization to drive relevant traffic.'
        },
        {
            src: graph,
            alt: 'Google Ads',
            title: 'Google Ads',
            text: 'We handle all aspects of Google Ads, including Local Service Ads, Gmail Sponsored Promotions (GSP), Search Ads, Display Ads, Performance Max Ads, Demand Gen Ads, Shopping Ads, Video Ads, Mobile App Ads, and Smart Campaign Ads.'
        },
        {
            src: graph,
            alt: 'Microsoft Ads',
            title: 'Microsoft Ads',
            text: 'XDMinds offers expert PPC management on Microsoft Advertising, leveraging the power of Bing to reach a unique and valuable audience. Our strategic campaigns are designed to boost your visibility and drive high-quality traffic. Partner with XDMinds for exceptional results on Microsoft Advertising.'
        },
        {
            src: graph,
            alt: 'Bing Ads',
            title: 'Bing Ads',
            text: 'We create impactful PPC campaigns on Bing Ads to tap into a unique audience segment. Our tailored strategies enhance your visibility on Bing, driving quality traffic and boosting conversions. Trust XDMinds to optimize your Bing Ads for exceptional results.'
        },
        {
            src: graph,
            alt: 'Yahoo Ads',
            title: 'Yahoo Ads',
            text: 'Harness the power of Yahoo Ads with XDMinds’expert PPC management services.We craft compelling campaigns that capture attention and drive engagement on Yahoo’s extensive network. Let XDMinds help you achieve your advertising goals with effective Yahoo Ads strategies.'
        },
        {
            src: graph,
            alt: 'Yandex Ads',
            title: 'Yandex Ads',
            text: 'Expand your reach in the Russian market with XDMinds’specialized PPC services on Yandex Ads.Our targeted campaigns are designed to resonate with local audiences, ensuring high engagement and conversions.Choose XDMinds to optimize your presence on Yandex and grow your business.'
        },
        {
            src: graph,
            alt: 'Social Media PPC Ads',
            title: 'Social Media PPC Ads',
            text: 'We create and manage PPC campaigns on social media platforms such as Facebook, X (Twitter), LinkedIn, Instagram, YouTube, and Pinterest to reach and engage your target audience.'
        },
        {
            src: graph,
            alt: 'Crypto PPC Ads',
            title: 'Crypto PPC Ads',
            text: 'Our specialized crypto PPC ads target niche audiences interested in cryptocurrency, ensuring your brand stands out in this competitive market.'
        },
        {
            src: graph,
            alt: 'PPC Native Advertising',
            title: 'PPC Native Advertising',
            text: 'XDMinds excels in PPC native advertising, seamlessly integrating your ads into relevant content to enhance user experience and engagement. Our targeted approach ensures your message reaches the right audience in a non-intrusive manner. Trust XDMinds to maximize your ROI with effective native ad campaigns.'
        },
        {
            src: graph,
            alt: 'Quora Advertising',
            title: 'Quora Advertising',
            text: 'XDMinds specializes in managing PPC campaigns on Quora Ads, connecting you with a curious and engaged audience. Our tailored strategies ensure your ads appear in relevant discussions, driving targeted traffic to your site. Choose XDMinds for impactful Quora Ads management and increased brand awareness.'
        },
        {
            src: graph,
            alt: 'Programmatic Advertising',
            title: 'Programmatic Advertising',
            text: 'At XDMinds, we use advanced programmatic advertising techniques to automate your ad buying process, reaching your target audience with precision. Our programmatic expertise ensures efficient ad spending and maximized ROI. Elevate your marketing with XDMinds’ programmatic advertising services.'
        },
        {
            src: graph,
            alt: 'Performance Max Ads',
            title: 'Performance Max Ads',
            text: 'XDMinds leverages Performance Max Ads to maximize your ad reach across Google’s entire inventory.Our data- driven approach ensures your campaigns are optimized for high performance and conversion rates.Partner with us to experience unparalleled results with Performance Max Ads.'
        },
        {
            src: graph,
            alt: 'Remarketing/Retargeting',
            title: 'Remarketing/Retargeting',
            text: 'Remarketing/retargeting helps re-engage users who have previously interacted with your site but didn’t convert.By displaying targeted ads to these users as they browse other sites, we increase the chances of bringing them back and driving conversions.'
        },
        {
            src: graph,
            alt: 'YouTube Ads',
            title: 'YouTube Ads',
            text: 'We produce and manage various YouTube ad formats, including TrueView Skippable In-stream Ads, Non-Skippable In-Stream Ads, In-feed Video Ads, Bumper Ads, Non-Video Banner Ads, Audio-based Ads, Video Discovery Ads, and Overlay Ads.'
        },
        {
            src: graph,
            alt: 'Amazon PPC Ads',
            title: 'Amazon PPC Ads',
            text: 'Our Amazon PPC services optimize your product listings and ad campaigns to increase visibility and sales on the world’s largest online marketplace.'
        },
        {
            src: graph,
            alt: 'PPC Campaign Review & Audit',
            title: 'PPC Campaign Review & Audit',
            text: 'A thorough PPC campaign review and audit by XDMinds identifies areas for improvement and optimization. We analyze your campaign’s performance, assess keyword effectiveness, and refine strategies to enhance ROI and achieve your advertising goals.'
        },
        {
            src: graph,
            alt: 'Quality Score Audit',
            title: 'Quality Score Audit',
            text: 'We conduct thorough audits of your PPC campaigns to improve quality scores, which enhance ad rankings, reduce costs, and increase campaign effectiveness.'
        },
        {
            src: graph,
            alt: 'PPC Conversion Rate Optimization',
            title: 'PPC Conversion Rate Optimization',
            text: 'Our PPC conversion rate optimization service enhances your ad campaigns by refining targeting, ad copy, and landing pages. We employ data-driven techniques to increase the percentage of visitors who complete desired actions, boosting your overall campaign effectiveness.'
        },
        {
            src: graph,
            alt: 'Hire a Dedicated PPC Expert',
            title: 'Hire a Dedicated PPC Expert',
            text: 'Hiring a dedicated PPC expert from XDMinds ensures personalized attention and strategic management of your ad campaigns. Our specialists bring expertise and focus to drive targeted traffic, optimize performance, and deliver measurable results tailored to your business.'
        },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Comprehensive Market Research',
            sections: [
                {
                    content: 'We start by conducting thorough market research to understand your industry, competitors, and target audience. This helps us identify the most effective keywords and strategies for your PPC campaigns.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Tailored PPC Strategy Development',
            sections: [
                {
                    content: 'Based on our research, we develop a customized PPC strategy that aligns with your business goals. This includes selecting the right platforms, ad types, and budget allocation to maximize ROI.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Keyword Optimization',
            sections: [
                {
                    content: 'We perform in-depth keyword analysis to select high-performing keywords that drive relevant traffic to your site. Our focus is on finding the perfect balance between high-traffic and long-tail keywords to optimize your ad spend.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Compelling Ad Copywriting',
            sections: [
                {
                    content: 'Our team of expert copywriters creates engaging and persuasive ad copy that resonates with your target audience. We highlight your unique selling points and use strong calls to action to drive conversions.'
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Compelling Ad Copywriting',
            sections: [
                {
                    content: 'We design visually appealing ads that capture attention and encourage clicks. Our designs are optimized for various platforms, ensuring your ads look great and perform well across all devices.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Strategic Bid Management',
            sections: [
                {
                    content: 'Our PPC specialists continuously monitor and adjust your bids to ensure optimal placement and cost-efficiency. We use advanced bidding strategies to maximize your ad visibility and minimize costs.'
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Landing Page Optimization',
            sections: [
                {
                    content: 'We create and optimize landing pages that align with your ads and provide a seamless user experience. Our goal is to increase engagement and conversion rates by ensuring your landing pages are relevant, fast-loading, and easy to navigate.'
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Performance Tracking and Analytics',
            sections: [
                {
                    content: 'We utilize advanced tracking tools to monitor the performance of your PPC campaigns. We analyze key metrics such as click-through rates, conversion rates, and cost per acquisition to make data-driven decisions.'
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Continuous Campaign Optimization',
            sections: [
                {
                    content: 'Our team continuously reviews and optimizes your PPC campaigns to improve performance. We test different ad variations, keywords, and targeting options to ensure your ads are always performing at their best.'
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Transparent Reporting',
            sections: [
                {
                    content: 'We provide regular, transparent reports that detail your campaign’s performance. Our reports include actionable insights and recommendations to help you understand the impact of your PPC efforts and make informed decisions.'
                }
            ]
        },
    ]

    const industries = [
        { name: 'Agriculture' }, { name: 'Automobiles' }, { link: '/b2b-marketing-agency', name: 'B2B' }, { link: '/b2c-marketing-agency', name: 'B2C' }, { name: 'Banking' }, { name: 'Crowd Funding' }, { name: 'Education' },
        { name: 'Electric Vehicle' }, { name: 'Electronics' }, { name: 'Energy' }, { name: 'FMCG' }, { name: 'FinTech' }, { name: 'Gaming' }, { name: 'Healthcare' },
        { name: 'Hospitality' }, { name: 'Information Technology' }, { name: 'Infrastructure' }, { name: 'Insurance' }, { name: 'Lifestyle' }, { name: 'Manufacturing' },
        { name: 'Media & Entertienment' }, { name: 'Oil & Gas' }, { name: 'Real Estate' }, { name: 'Retail' }, { link: '/saas-marketing-agency', name: 'SaaS' }, { name: 'Sports' },
        { name: 'Telecommunications' }, { name: 'Transportation & Logistics' }, { name: 'Travel & Tourism' }, { link: '/web3-marketing-services', name: 'Web3' },
    ]

    const faqData = [
        {
            question: "What is included in XDMinds' PPC management services?",
            answer: "Our PPC management services include strategy development, keyword research, ad creation, bid management, performance tracking, landing page optimization, and continuous campaign optimization to ensure the best results."
        },
        {
            question: "How does XDMinds ensure the success of PPC campaigns?",
            answer: "XDMinds ensures PPC campaign success through comprehensive market research, tailored strategies, keyword optimization, compelling ad copy, strategic bid management, and continuous performance tracking and optimization."
        },
        {
            question: "Why choose XDMinds for PPC management?",
            answer: "XDMinds offers expert PPC management backed by years of experience and a data-driven approach. We provide customized strategies, transparent reporting, dedicated support, and a proven track record of delivering exceptional results."
        },
        {
            question: "How does XDMinds measure the performance of PPC campaigns?",
            answer: "We use advanced analytics tools to track key metrics such as click-through rates, conversion rates, cost per click, and overall ROI. Regular reports are provided to keep you informed about your campaign's performance."
        },
        {
            question: "What industries does XDMinds serve with PPC marketing?",
            answer: "XDMinds serves a wide range of industries, including e-commerce, healthcare, finance, education, real estate, technology, and more. Our diverse sector expertise allows us to create effective PPC campaigns for any business."
        },
        {
            question: "How can I get started with XDMinds' PPC marketing services?",
            answer: "Getting started is easy. Contact us to schedule a consultation, where we’ll discuss your business goals, target audience, and budget. Our team will then develop a customized PPC strategy tailored to your needs."
        },
    ]

    const slideData = [
        {
            title: 'Expertise and Experience',
            content: `At XDMinds, we bring years of experience and a team of certified PPC experts to the table. Our deep understanding of PPC advertising ensures that your campaigns are managed by professionals who know how to achieve the best results.`,
        },
        {
            title: 'Customized Strategies',
            content: `We don’t believe in one-size-fits-all solutions. Our team takes the time to understand your business, goals, and target audience to create tailored PPC strategies that meet your specific needs and maximize your ROI.`,
        },
        {
            title: 'Advanced Technology and Tools',
            content: `We leverage the latest PPC tools and technologies to optimize your campaigns. From keyword research and bid management to performance tracking and analytics, we use advanced solutions to enhance your PPC efforts.`,
        },
        {
            title: 'Data-Driven Approach',
            content: `Our decisions are backed by data. We continuously analyze campaign performance and market trends to make informed adjustments, ensuring your PPC ads are always performing at their peak.`,
        },
        {
            title: 'Comprehensive Service Offering',
            content: `XDMinds offers a full spectrum of PPC services, including search engine ads, social media ads, crypto ads, and more. Our comprehensive approach ensures that all aspects of your PPC campaigns are covered, providing you with a seamless and effective advertising experience.`,
        },
        {
            title: 'Proven Track Record',
            content: `Our success stories speak for themselves. We have a proven track record of delivering exceptional results for clients across various industries, driving traffic, increasing conversions, and boosting sales.`,
        },
        {
            title: 'Transparent Reporting',
            content: `We believe in complete transparency. Our regular, detailed reports provide clear insights into your campaign performance, so you always know where your investment is going and the returns you are achieving.`,
        },
        {
            title: 'Continuous Optimization',
            content: `PPC is not a set-it-and-forget-it strategy. We continuously monitor and optimize your campaigns to ensure they remain effective and competitive. Our ongoing efforts help you stay ahead in the ever-changing digital landscape.`,
        },
        {
            title: 'Dedicated Support',
            content: `When you choose XDMinds, you get a dedicated team committed to your success. We provide personalized support and are always available to answer your questions, address your concerns, and adjust strategies as needed.`,
        },
        {
            title: 'Competitive Pricing',
            content: `We offer high-quality PPC services at competitive prices. Our goal is to provide excellent value, ensuring you get the best possible return on your investment without breaking the bank.`,
        },
    ]
    return (
        <div className='digital-marketing ppc'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Customer-Obsessed <br className='break' /> <span className='s-text-3'>PPC</span> Advertising Agency
                </h1>
                <p className='p-text-1'>
                    At XDMinds, we specialize in delivering tailored Pay Per Click (PPC) advertising strategies that drive measurable results. Our customer-obsessed approach ensures your campaigns are optimized for maximum impact across search engines, social media platforms, and niche markets like crypto. Partner with us to elevate your online presence and achieve unparalleled growth.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Top PPC Questions Answered: Benefits, Strategies, <br className='break' /> and More
                </h2>
                <TopFaq topFaqData={topFaqData} />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-3'>
                    XDMinds: Expert PPC Marketing Agency for <br className='break' /> Small, Medium, and Large Scale Businesses
                </h2>
                <div className='custom-div'>
                    <p className='p-text-3'>
                        XDMinds is a leading PPC marketing agency that excels in crafting customized pay-per-click campaigns for businesses of all sizes. Our expert team combines industry knowledge with cutting-edge technology to deliver PPC strategies that drive traffic, increase conversions, and maximize ROI. Whether you're a small startup or a large enterprise, we tailor our services to meet your specific needs, ensuring your online advertising success.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    XDMinds: Increase Leads and Conversions with <br className='break' /> Our Top-Notch PPC Advertising Services & Solutions
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we specialize in designing and executing high-performance PPC advertising campaigns that significantly boost your leads and conversions. Our top-notch services encompass a wide range of platforms and ad formats, ensuring your brand reaches the right audience at the right time. Partner with us to harness the full potential of PPC advertising and drive measurable business growth.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Let’s schedule a call and explore how our <br className='break' /> pay-per-click (PPC) advertising services can supercharge <br className='break' /> your business!
                </h2>
                <p className='p-text-2'>
                    Let’s schedule a call to unlock the full potential of your business with XDMinds' expert PPC advertising services. Our tailored strategies are designed to drive results and boost your growth. Discover how we can make a difference for your brand today!
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your ppc advertising services. Shall we start the discussion? Lead from https://www.xdminds.com/ppc-advertising-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-2 l-bg'>
                <h2 className='h-text-2'>
                    Creating High-Performing Pay-Per-Click (PPC) Ads: <br className='break' /> XDMinds' Proven Methodology
                </h2>
                <p className='p-text-2'>
                    At XDMinds, our proven methodology for creating high-performing Pay-Per-Click (PPC) ads ensures that your campaigns are optimized for success from start to finish. Here’s how we do it:
                </p>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Why Choose XDMinds As Your Trusted <br className='break' /> PPC Marketing Company?
                </h2>
                <div className='d-swiper-div'>
                    <DSwiper slideData={slideData} />
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    XDMinds: Expert PPC Management Services <br className='break' /> Across Diverse Sectors
                </h2>
                <p className='p-text-2'>
                    XDMinds excels in providing expert PPC management tailored to various industries, ensuring targeted reach and optimal ad performance. Our diverse sector expertise allows us to craft bespoke strategies that drive conversions and maximize ROI. Trust XDMinds to elevate your PPC campaigns with precision and innovation.
                </p>
                <div className='industry-div'>
                    {industries.map((industry, index) => (
                        industry.link ? (
                            <Link reloadDocument className='industry-btn c-pointer' to={industry.link} key={index}>
                                {industry.name}
                            </Link>
                        ) : (
                            <div className='industry-btn c-default' key={index}>
                                {industry.name}
                            </div>
                        )
                    ))}
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest Pay Per Click Advertising Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode } show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default PayPerClick
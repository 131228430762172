import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdArrowCircleDown } from "react-icons/md";


const Section5 = () => {

  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  const data = [
    {
      number: 1,
      title: 'Initial Consultation',
      description: ['We begin by understanding your brand, goals, and target audience through an in-depth consultation.', 'This stage involves gathering detailed information about your current marketing efforts and identifying areas for improvement.']
    },
    {
      number: 2,
      title: 'Research and Analysis',
      description: ['Conduct thorough market research to understand the competitive landscape within the metaverse.', 'Analyze your target audience’s behavior, preferences, and interactions in virtual environments.',
        'Identify key platforms and virtual spaces where your audience is most active.'
      ]
    },
    {
      number: 3,
      title: 'Marketing Strategy Development',
      description: ['Develop a comprehensive metaverse marketing strategy tailored to your brand’s goals and objectives.', 'Outline specific tactics, timelines, and key performance indicators (KPIs) to measure success.',
        'Ensure the strategy aligns with your overall marketing goals and leverages the unique features of the metaverse.'
      ]
    },
    {
      number: 4,
      title: 'Content Creation and Design',
      description: [
        'Create engaging and immersive content tailored to the metaverse, including 3D models, virtual events, AR/VR experiences, and interactive ads.',
        'Design compelling visuals and messages that resonate with your target audience in virtual environments.',
        'Ensure all content aligns with your brand’s identity and messaging.',
      ]
    },

    {
      number: 5,
      title: 'Platform Selection and Integration',
      description: [
        'Select the most suitable metaverse platforms for your campaign based on your target audience and marketing goals.',
        'Integrate your brand’s presence seamlessly into the chosen platforms, ensuring a cohesive and engaging user experience.',
        'Set up virtual storefronts, event spaces, and interactive elements as needed.'
      ]
    },
    {
      number: 6,
      title: 'Campaign Execution',
      description: [
        'Launch the metaverse marketing campaign, deploying all content and interactive elements across the chosen platforms.',
        'Monitor the campaign in real-time to ensure smooth execution and immediate response to any issues.',
        'Engage with users through virtual events, social media, and interactive experiences to drive engagement and brand loyalty.'
      ]
    },

    {
      number: 7,
      title: 'Data Collection and Analysis',
      description: ["Collect data on user interactions, engagement metrics, and overall campaign performance.",
        "Use advanced analytics tools to gain insights into user behavior, preferences, and campaign effectiveness.",
        "Identify trends, patterns, and areas for improvement based on the collected data."
      ]
    },
    {
      number: 8,
      title: 'Optimization and Refinement',
      description: [
        'Continuously optimize the campaign based on data-driven insights and user feedback.',
        'Adjust tactics, content, and strategies to enhance performance and achieve better results.',
        'Implement A/B testing and other optimization techniques to refine the campaign over time.'
      ]
    },
    {
      number: 9,
      title: 'Reporting and Evaluation',
      description: [
        'Provide comprehensive reports on campaign performance, including key metrics, achievements, and insights.',
        'Evaluate the overall success of the campaign against the initial goals and KPIs.',
        'Offer recommendations for future metaverse marketing initiatives based on the campaign’s outcomes.'
      ]
    },
    {
      number: 10,
      title: 'Ongoing Support and Maintenance',
      description: [
        'Offer ongoing support to ensure your brand continues to thrive in the metaverse.',
        'Regularly update and refresh content to keep your audience engaged and interested.',
        'Stay ahead of emerging trends and technologies to keep your metaverse marketing efforts cutting-edge.'
      ]
    },

  ];

  return (
    <div className='AI-sec4'>
      <div className='blockchain-sec5'>
        <Container>
          <Row>
            <Col>
              <h2>Building Your Metaverse Presence<span className='msec5-break'><br /></span> The XDMinds Marketing Process
              </h2>
              <p className='subhead'>
                At XDMinds, we follow a structured and strategic approach to deliver exceptional metaverse marketing results. Our process ensures that every campaign is tailored to meet the unique needs of your brand and maximizes the opportunities available in the metaverse. Here’s a step-by-step breakdown of our metaverse marketing process:
              </p>

              <div className='metaverse-sec5 desktop-view mobile-hide'>
                <Row>
                  {data.map((item, index) => (
                    <Col
                      key={index}
                      lg={4}
                      xl={4}
                      sm={6}
                      md={6}
                      style={item.number % 3 === 0 ? { border: 'none' } : {}}
                    >
                      <div className='number-div'>
                        <div className='bc-sec5-carde-head'>
                          <p className='bc-sec5-carde-head-number'>{item.number}</p>
                          <p>{item.title}</p>
                        </div>
                        {Array.isArray(item.description) ? item.description.map((desc, i) => (
                          <p key={i} className='bc-sec5-card-discription'><FaRegCheckCircle className='msec5-bullets' /> {desc}</p>
                        )) : null}
                      </div>
                    </Col>
                  ))}
                </Row>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='about'>
        <section className='about-section-5 p-0'>
          <div className='custom-accordion desktop-hide tab-hide'>
            <div className="accordion" id="accordionExample">
              {data.map((item, index) => (
                <div key={item.id} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                      onClick={() => handleClick(index)}
                      type="button"
                      aria-expanded={openIndex === index}
                      aria-controls={item.id}
                    >
                      {item.title}
                      <div className="arrow-icon-btn">
                        <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li className='p-text-1 '>{item.description}</li>
                        <li className='p-text-1 '>{item.description}</li>
                        <li className='p-text-1 '>{item.description}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
export default Section5;







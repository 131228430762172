import React, { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GetBlogCategoryApi } from '../../Helper/Redux/ReduxThunk/Homepage';
import { useDispatch } from 'react-redux';
import { HiArrowLongRight } from 'react-icons/hi2';



const BlogCategory = () => {
    const targetHeadingRef = useRef(null);
    const [visibleCards, setVisibleCards] = useState(9);
    const [categoryBlog, setCategoryBlog] = useState(null);
    const [categoryDet, setCategoryDet] = useState("")
    const params = useParams();
    const navigate = useNavigate();

    const handleScrollToOpenPositions = () => {
        if (targetHeadingRef.current) {
            const targetPosition = targetHeadingRef.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: targetPosition - 100, behavior: 'smooth' });
        }
    };

    const handleShowMore = () => {
        const increment = window.innerWidth < 768 ? 3 : 9;
        setVisibleCards(prevCount => prevCount + increment);
    };

    const updateVisibleCards = () => {
        const initialVisibleCards = window.innerWidth < 768 ? 3 : 9;
        setVisibleCards(initialVisibleCards);
    };

    useEffect(() => {
        getBlogCategory();
        updateVisibleCards();

        window.addEventListener('resize', updateVisibleCards);
        return () => window.removeEventListener('resize', updateVisibleCards);
    }, []);

    const dispatch = useDispatch()

    const getBlogCategory = async () => {
        let datas = {
            blog_category_url: params.id,
        }

        await dispatch(GetBlogCategoryApi(datas, (resp) => {
            if (resp.status === true) {
                setCategoryBlog(resp?.data)
                setCategoryDet(resp?.cat)
            }
            else {
                navigate('/404')
            }
        }))
    }
    let namedata = params.id
    const captalize = namedata.charAt(0).toUpperCase() + namedata.slice(1);

    return (
        <div className='digital-marketing blog-page'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs childPageLink='blog' childPageName='Blog' childPage={categoryDet[0]?.breadcrumb_title} />
                <h1 className='h-text-1 vw5'>
                    {categoryDet[0]?.categoryBannerTitle}
                </h1>
                <p className='p-text-1'>
                    {categoryDet[0]?.category_desc}
                </p>
                <button className='request-btn' onClick={handleScrollToOpenPositions}>Explore Blogs</button>
            </section>
            <section className='digital-marketing-section-2 pad-30' ref={targetHeadingRef}>
                <div className='blog-section'>
                    <div className='row g-4'>
                        {categoryBlog && categoryBlog?.slice(0, visibleCards)?.map(blog => (
                            <div key={blog._id} className='col-md-6 col-lg-4 col-12'>
                                <div className='custom-blog-card card h-100'>
                                    <Link reloadDocument to={`/${blog.blog_url}`} className='custom-blog-card-img'>
                                        <LazyLoadImage src={blog.blog_image} alt={blog.blog_image_alt_title} />
                                    </Link>
                                    <div className='custom-blog-card-body'>
                                        <h5 className='h-text-15'>{blog.blog_title}</h5>
                                        {/* <p className='p-text-11'>{blog.blog_shot}</p> */}
                                    </div>
                                    <div className='custom-blog-card-tags justify-content-end'>
                                        {blog.blog_tags && blog.blog_tags.split(',')?.map((tag, index) => (
                                            <span key={index} className='blog-tags-btn'>{tag.trim()}</span>
                                        ))}
                                        <div className='d-flex'>
                                            <div className='latest-arrow-incard-div '>
                                                <Link reloadDocument to={`/${blog.blog_url}`} className='btn-latest-arrow-incard'>
                                                    <HiArrowLongRight type='submit' className='latest-arrow-incard  float-end' />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {visibleCards < categoryBlog && categoryBlog?.length && (
                    <div className='text-center mt-4'>
                        <button className='show-more-btn' onClick={handleShowMore}>Show More</button>
                    </div>
                )}
            </section>
        </div>
    )
}

export default BlogCategory
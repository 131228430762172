import React from 'react'
import GameBanner from '../../Components/Services/Game/GameBanner'
import Section2 from '../../Components/Services/Game/Section2'
import Section4 from '../../Components/Services/Game/Section4'
import Latest from '../../Components/Home/Latest/Latest'
import GameFAQ from '../../Components/Services/Game/GameFAQ'
import Section1 from '../../Components/Services/Game/Section1'

const GamePage = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <GameBanner countryCode={countryCode} />
      <Section1 />
      <Section2 />
      <Section4 />
      <Latest modifiedPath='Latest Gaming Marketing Insights' />
      <GameFAQ />

    </div>
  )
}

export default GamePage
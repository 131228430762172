import React from 'react';
import Tabs from '../../../Pages/DigitalMarketing/Tabs'
import '../../../assets/css/style.css'

const Section4 = () => {
    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Initial Consultation and Goal Setting',
            sections: [
                {
                    content: "We begin with a thorough consultation to understand your blockchain project, its goals, target audience, and unique value propositions. This helps us tailor a marketing strategy that aligns with your objectives."
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Market Research and Analysis',
            sections: [
                {
                    content: 'Our team conducts extensive market research to identify trends, competitor strategies, and target audience behaviors. This data-driven approach allows us to create a marketing plan that resonates with your audience.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Strategy Development',
            sections: [
                {
                    content: 'Based on our research, we develop a comprehensive blockchain marketing strategy. This includes defining key performance indicators (KPIs), selecting appropriate marketing channels, and outlining the tactics to be used.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Content Creation and Optimization',
            sections: [
                {
                    content: "We create high-quality, engaging content tailored to your audience. This includes blog posts, videos, social media content, and more. Our team ensures that all content is optimized for search engines and tailored to the unique aspects of your blockchain project."
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Campaign Execution',
            sections: [
                {
                    content: 'With the strategy in place and content ready, we launch your marketing campaigns across selected channels. This involves social media marketing, SEO, PPC advertising, email marketing, and more.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Monitoring and Analysis',
            sections: [
                {
                    content: "We continuously monitor the performance of your marketing campaigns. Using advanced analytics tools, we track key metrics and analyze the data to understand what’s working and what needs improvement."
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Optimization and Adjustments',
            sections: [
                {
                    content: "Based on our analysis, we make necessary adjustments to the marketing strategy. This iterative process ensures that your campaigns are always optimized for the best possible results."
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Reporting and Communication',
            sections: [
                {
                    content: "We provide regular reports detailing the performance of your marketing campaigns. Our transparent communication ensures you are always informed about the progress and impact of our efforts."
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Ongoing Support and Maintenance',
            sections: [
                {
                    content: "Marketing is an ongoing process. We provide continuous support and maintenance to ensure your blockchain project remains relevant and competitive in the ever-evolving digital landscape."
                }
            ]
        },

    ]

    return (
        <div className='digital-marketing ppc'>

            <section className='digital-marketing-section-2 l-bg'>
                <h2 className='h-text-2 text-center'>
                    Maximizing ROI with Blockchain<br />Our Step-by-Step Marketing Approach
                </h2>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>

        </div>
    );
}
export default Section4
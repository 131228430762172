
import './App.css';
import './assets/css/style.css'
import './assets/css/style1.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import loadable from '@loadable/component'
import { Routes, Route, useLocation } from 'react-router-dom';
import Error from './Pages/404ErrorPage/404Error';
import { useEffect, useState } from 'react';
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import ipLocation from "iplocation";
// import Footer from './Components/Footer/Footer';
import LandingPage from './Pages/LandingPage/LandingPage';
import Blogpage from './Pages/BlogPage/Blogpage';
import Contactpage from './Pages/ContactPage/Contactpage';
import NewHeader from './Components/Header/Header';
import About from './Pages/About/About';
import Metaverse from './Pages/Web3ServicePage/Metaverse';
import Blockchain from './Pages/Web3ServicePage/Blockchain';
import NFT from './Pages/Web3ServicePage/NFT';
import Defi from './Pages/Web3ServicePage/Defi';
import Dapps from './Pages/Web3ServicePage/dapps';
import AIPage from './Pages/Web3ServicePage/AIPage';
import GamePage from './Pages/Web3ServicePage/GamePage';
import CryptoPage from './Pages/Web3ServicePage/CryptoPage';
import DigitalMarketing from './Pages/DigitalMarketing/DigitalMarketing'
import SearchEngineOptimization from './Pages/DigitalMarketing/SearchEngineOptimization'
import PayPerClick from './Pages/DigitalMarketing/PayPerClick'
import SocialMediaMarketing from './Pages/DigitalMarketing/SocialMediaMarketing'
import ContentMarketing from './Pages/DigitalMarketing/ContentMarketing'
import MobileAppMarketing from './Pages/DigitalMarketing/MobileAppMarketing'
import PerformanceMarketing from './Pages/DigitalMarketing/PerformanceMarketing'
import ProgrammaticAdvertising from './Pages/DigitalMarketing/ProgrammaticAdvertising'
import InfluencerMarketing from './Pages/DigitalMarketing/InfluencerMarketing'
import Web3Page from './Pages/Web3ServicePage/Web3Page';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy'
import TermsandCondition from './Pages/TermsAndCondition/TermsandCondition'
import ARVRMarketing from './Pages/Web3ServicePage/ARVRMarketing';
import Iot from './Pages/Web3ServicePage/Iot';
import SEMAgency from './Pages/DigitalMarketing/SEMAgency';
import DAOPage from './Pages/Web3ServicePage/DAOPage';
import GameFiPage from './Pages/Web3ServicePage/GameFiPage';
import Sto from './Pages/Web3ServicePage/Sto';
import Careers from './Pages/Careers/Careers';
import Ico from './Pages/Web3ServicePage/Ico';
import IDO from './Pages/Web3ServicePage/IDO';
import IEO from './Pages/Web3ServicePage/IEO';
import P2e from './Pages/Web3ServicePage/P2e';
import AsoMarketing from './Pages/DigitalMarketing/AsoMarketing';
import T2E from './Pages/Web3ServicePage/T2E';
import B2B from './Pages/Industries/B2B';
import B2C from './Pages/Industries/B2C';
import SAAS from './Pages/Industries/SAAS';
import Industries from './Pages/Industries/Industries';
import BlogInnerPage from './Pages/BlogPage/BlogInnerPage';
import BlogCategory from './Pages/BlogPage/BlogCategory';
import BlogTagPage from './Pages/BlogPage/BlogTagPage';
import GEO from './Pages/DigitalMarketing/GEO';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ORM from './Pages/DigitalMarketing/ORM';
import DubaiPages from './Pages/CountriesPages/Web3 Pages/DubaiPages';

const BookFrom = loadable(() => import('./Components/Home/BookForm/BookFrom'));
const WorkCountry = loadable(() => import('./Components/Home/WorkCountry/WorkCountry'));
const Scrollupbtn = loadable(() => import('./Components/Header/Scrollupbtn'));
const SocialMedia = loadable(() => import('./Components/MediaTooltip/SocialMedia'));
const Footer = loadable(() => import('./Components/Footer/Footer'));

function App() {
  const isValidRoute = (path) => {
    const validRoutes = ['/', '/about', '/ai-marketing-agency', '/ar-vr-marketing-agency', '/metaverse-marketing-agency', '/blockchain-marketing-agency', '/nft-marketing-agency', '/defi-marketing-agency', '/dapp-marketing-agency', '/gaming-marketing-agency', '/crypto-marketing-agency', '/dao-marketing-agency', '/gamefi-marketing-agency', '/web3-marketing-services', '/ico-marketing-agency', '/ido-marketing-agency', '/ieo-marketing-agency', '/p2e-game-marketing-agency', '/t2e-game-marketing-agency', '/iot-marketing-agency', '/digital-marketing-agency', '/seo-agency', '/aso-agency', '/ppc-advertising-agency', '/social-media-marketing-agency', '/mobile-app-marketing-agency', '/performance-marketing-agency', '/programmatic-advertising-agency', '/influencer-marketing-agency', '/content-marketing-agency', '/search-engine-marketing-agency', '/generative-engine-optimization-agency', '/online-reputation-management-agency', '/industries', '/sto-marketing-agency', '/b2b-marketing-agency', '/b2c-marketing-agency', '/saas-marketing-agency', '/blog', '/blog-title', '/blog-web3', '/tags/design', '/tags/fintech', '/tags/ux-design', '/tags/cross-platform', '/tags/mobile-application', '/careers', '/contact-us', '/privacy-policy', '/terms-and-conditions', '/:id', '/blog/:id',];
    return validRoutes.includes(path);
  };
  const location = useLocation();
  const isNotFound = !isValidRoute(location.pathname);

  const [childpageInfo, setChildpageInfo] = useState("")
  const handleCHildpage = (data) => {
    setChildpageInfo(data)

  };
  const [socialWhatsappLink, setSocialMediaWhatsappLink] = useState('')
  const getSocialWhatsappLink = (data) => {
    setSocialMediaWhatsappLink(data)

  };
  const [ipDatas, setIpDatas] = useState("")

  useEffect(() => {
    const getIpDetails = async () => {
      try {
        const ipDetails = await ipLocation(await publicIpv4());
        if (ipDetails && ipDetails.country && ipDetails.country.code) {
          setIpDatas(ipDetails.country.code);
        };
      } catch (error) {
        setIpDatas("");
      };
    };
    getIpDetails();
  }, []);

  const NotFound = location.pathname === '/404'
  return (
    <>
      <div className="App">
        <ToastContainer />
        {!NotFound &&
          <>
            <NewHeader countryCode={ipDatas} socialWhatsappLink={socialWhatsappLink} />
            <SocialMedia childpageInfo={childpageInfo} getSocialWhatsappLink={getSocialWhatsappLink} />
            <Scrollupbtn />
          </>
        }
        <Routes>
          <Route path='/' element={<LandingPage countryCode={ipDatas} />} />
          <Route path='/about' element={<About countryCode={ipDatas} />} />
          <Route path='/ai-marketing-agency' element={<AIPage countryCode={ipDatas} />} />
          <Route path='/ar-vr-marketing-agency' element={<ARVRMarketing countryCode={ipDatas} />} />
          <Route path='/metaverse-marketing-agency' element={<Metaverse countryCode={ipDatas} />} />
          <Route path='/blockchain-marketing-agency' element={<Blockchain countryCode={ipDatas} />} />
          <Route path='/nft-marketing-agency' element={<NFT countryCode={ipDatas} />} />
          <Route path='/defi-marketing-agency' element={<Defi countryCode={ipDatas} />} />
          <Route path='/dapp-marketing-agency' element={<Dapps countryCode={ipDatas} />} />
          <Route path='/gaming-marketing-agency' element={<GamePage countryCode={ipDatas} />} />
          <Route path='/crypto-marketing-agency' element={<CryptoPage countryCode={ipDatas} />} />
          <Route path='/dao-marketing-agency' element={<DAOPage countryCode={ipDatas} />} />
          <Route path='/gamefi-marketing-agency' element={<GameFiPage countryCode={ipDatas} />} />
          <Route path='/web3-marketing-services' element={<Web3Page countryCode={ipDatas} />} />
          <Route path='/ico-marketing-agency' element={<Ico countryCode={ipDatas} />} />
          <Route path='/ido-marketing-agency' element={<IDO countryCode={ipDatas} />} />
          <Route path='/ieo-marketing-agency' element={<IEO countryCode={ipDatas} />} />
          <Route path='/p2e-game-marketing-agency' element={<P2e countryCode={ipDatas} />} />
          <Route path='/t2e-game-marketing-agency' element={<T2E countryCode={ipDatas} />} />
          <Route path='/iot-marketing-agency' element={<Iot countryCode={ipDatas} />} />
          <Route path='/digital-marketing-agency' element={<DigitalMarketing countryCode={ipDatas} />} />
          <Route path='/seo-agency' element={<SearchEngineOptimization countryCode={ipDatas} />} />
          <Route path='/aso-agency' element={<AsoMarketing countryCode={ipDatas} />} />
          <Route path='/ppc-advertising-agency' element={<PayPerClick countryCode={ipDatas} />} />
          <Route path='/social-media-marketing-agency' element={<SocialMediaMarketing countryCode={ipDatas} />} />
          <Route path='/mobile-app-marketing-agency' element={<MobileAppMarketing countryCode={ipDatas} />} />
          <Route path='/performance-marketing-agency' element={<PerformanceMarketing countryCode={ipDatas} />} />
          <Route path='/programmatic-advertising-agency' element={<ProgrammaticAdvertising countryCode={ipDatas} />} />
          <Route path='/influencer-marketing-agency' element={<InfluencerMarketing countryCode={ipDatas} />} />
          <Route path='/content-marketing-agency' element={<ContentMarketing countryCode={ipDatas} />} />
          <Route path='/search-engine-marketing-agency' element={<SEMAgency countryCode={ipDatas} />} />
          <Route path='/generative-engine-optimization-agency' element={<GEO countryCode={ipDatas} />} />
          <Route path='/online-reputation-management-agency' element={<ORM countryCode={ipDatas} />} />
          <Route path='/industries' element={<Industries countryCode={ipDatas} />} />
          <Route path='/sto-marketing-agency' element={<Sto countryCode={ipDatas} />} />
          <Route path='/b2b-marketing-agency' element={<B2B countryCode={ipDatas} />} />
          <Route path='/b2c-marketing-agency' element={<B2C countryCode={ipDatas} />} />
          <Route path='/saas-marketing-agency' element={<SAAS countryCode={ipDatas} />} />
          <Route path='/blog' element={<Blogpage />} />
          <Route path='/:id' element={<BlogInnerPage getCHildpageinfo={handleCHildpage} />} />
          <Route path='/blog/:id' element={<BlogCategory />} />
          {/* <Route path='/tags/design' element={<BlogTagPage />} />
          <Route path='/tags/fintech' element={<BlogTagPage />} />
          <Route path='/tags/ux-design' element={<BlogTagPage />} />
          <Route path='/tags/cross-platform' element={<BlogTagPage />} />
          <Route path='/tags/mobile-application' element={<BlogTagPage />} /> */}
          <Route path='/careers' element={<Careers />} />
          <Route path='/contact-us' element={<Contactpage countryCode={ipDatas} />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsandCondition />} />

          <Route path='/web3-marketing-agency-in-dubai-uae' element={<DubaiPages/>} />



          <Route path='/404' element={<Error />} />
          <Route path='*' element={<Error />} />
        </Routes>
        {!NotFound &&
          <>
            <WorkCountry />
            <BookFrom countryCode={ipDatas} />
            <Footer />
          </>
        }
      </div>
    </>
  );
}

export default App;

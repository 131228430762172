import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button, Col, Container, Row } from 'react-bootstrap';
import BreadCrumbs from '../../BreadCrumbs';

const DAOBanner = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    return (

        <div className='web3-banner '>
            <Container>
                <Row>
                    <Col>
                        <BreadCrumbs />
                        <div className='banner-left-sec min-100vh'>
                            <h1>Your Go-To <span className='changing-banner-content'>DAO </span><span className='home-banner-break'><br /></span>Marketing Agency</h1>
                            <p>Maximize the potential of your Decentralized Autonomous Organization (DAO) with XDMinds, a leading DAO marketing agency. Our expert team is committed to driving substantial community growth through cutting-edge marketing strategies and deep industry knowledge. Collaborate with us to propel your DAO project to unprecedented success.</p>
                            <div className='btn-home-banner-request-div'>
                                <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
                            </div>
                            <ModalForm
                                countryCode={countryCode}
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}

export default DAOBanner
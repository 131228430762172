import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What exactly does a DeFi marketing agency do?",
    answer: `A DeFi marketing agency specializes in promoting decentralized finance projects through targeted marketing strategies. These strategies aim to increase visibility, attract users, build community engagement, and drive project adoption.`
  },
  {
    question: "Why should I choose XDMinds for my DeFi project's marketing needs?",
    answer: `XDMinds is a results-driven DeFi marketing agency with deep industry expertise, innovative strategies, and a commitment to delivering measurable results. Our tailored solutions are designed to meet the unique needs of your DeFi project.`
  },
  {
    question: "What types of marketing services does XDMinds offer for DeFi projects?",
    answer: `Our extensive services include strategy creation, website optimization, app store optimization, branding, community management, product marketing, SEO, PPC advertising, social media marketing, influencer marketing, email marketing, PR marketing, community marketing, and more. Our strategies are designed to highlight your project's unique value proposition and attract your target audience.`
  },
  {
    question: "How does XDMinds measure the success of marketing campaigns?",
    answer: `Success is measured through key performance indicators (KPIs) such as user acquisition, engagement rates, conversion rates, and return on investment (ROI). We provide detailed reports to track and analyze campaign performance.`
  },
  {
    question: "Can XDMinds help with the creation of a whitepaper or Litepaper for my DeFi project?",
    answer: `Yes, we offer comprehensive whitepaper and Litepaper creation services. Our team can help articulate your project's vision, technical details, and value proposition effectively.`
  },
  {
    question: "What is the process for starting a marketing campaign with XDMinds?",
    answer: `The process begins with a thorough analysis of your DeFi project. We then develop a customized marketing strategy, execute the plan, continuously optimize for best results, and provide detailed reporting on campaign performance.`
  },
  {
    question: "Does XDMinds offer community management services for DeFi projects?",
    answer: `Yes, we provide community management services to foster engagement, support, and real-time communication with your DeFi project's community on platforms like Telegram and Discord.`
  },
  {
    question: "How can XDMinds help improve my DeFi project's online visibility?",
    answer: `XDMinds enhances online visibility by implementing effective strategies tailored to your DeFi project. Our goal is to ensure your project reaches and resonates with its target audience, driving engagement and growth.`
  },
  {
    question: "What sets XDMinds apart from other DeFi marketing agencies?",
    answer: `XDMinds stands out due to our deep industry knowledge, innovative and data-driven strategies, and commitment to delivering measurable results. Our customized approach ensures that each DeFi project receives the attention and expertise it deserves.`
  },

];


const FAQblockchain = () => {
  const [openFAQ, setOpenFAQ] = useState(null);
  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };
  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='blockchain-sec-6-faq'>
      <div className='faq'>
        <Container>
          <Row>
            <Col>
              <div>
                <h2>Frequently Asked Questions
                </h2>
              </div>
              <div className='blockchain-faq-qa'>
                {faqData.map((faq, index) => (
                  <div key={index}>
                    <div
                      onClick={() => handleFAQClick(index)}
                      aria-controls={`faq${index}`}
                      aria-expanded={openFAQ === index}
                      className='faq-1st'
                    >
                      <div className='faq-content'>
                        <h6 className='faq-content-question'>{faq.question}</h6>
                      </div>
                      <div className='faq-icons'>
                        {openFAQ === index ? (
                          <IoIosArrowDropupCircle className='close-circle-faq' />
                        ) : (
                          <IoIosArrowDropdownCircle className='plus-circle-faq' />
                        )}
                      </div>
                    </div>
                    <Collapse in={openFAQ === index}>
                      <div id={`faq${index}`} className='faq-answer-content'>
                        {renderAnswer(faq.answer)}
                      </div>
                    </Collapse>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default FAQblockchain
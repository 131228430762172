import React, { useState } from 'react'
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What is XDMinds, and what services do you offer?",
    answer: `XDMinds is an industry-leading blockchain marketing agency offering a wide range of services, including blockchain SEO, PPC advertising, branding, content marketing, social media marketing, and more. We specialize in creating customized marketing strategies tailored to your blockchain project's needs.`
  },
  {
    question: "Why should I choose XDMinds for my blockchain marketing needs?",
    answer: `Choosing XDMinds ensures you benefit from our extensive experience in blockchain technology and digital marketing. We offer innovative,data-driven strategies, comprehensive services, and dedicated support to help your project achieve its goals.`
  },
  {
    question: "How does blockchain marketing differ from traditional marketing?",
    answer: `Blockchain marketing leverages the transparency, security, and decentralization of blockchain technology to enhance marketing efforts. It ensures greater trust, reduces fraud, and allows for more accurate targeting and measurement compared to traditional marketing.`
  },
  {
    question: "Can XDMinds help improve my blockchain project's visibility?",
    answer: `Absolutely. Our team uses advanced SEO techniques, targeted PPC campaigns, and engaging content marketing to boost your blockchain project's visibility and attract a wider audience.`
  },
  {
    question: "What is the process for starting a marketing campaign with XDMinds?",
    answer: `We begin with an initial consultation to understand your project and goals. This is followed by market research and strategy development. Once the strategy is in place, we execute the campaign and continuously monitor its performance, making adjustments as needed.`
  },
  {
    question: "How do you measure the success of a blockchain marketing campaign?",
    answer: `We set clear key performance indicators (KPIs) and use advanced analytics tools to track and measure the success of your marketing campaigns. Regular reports keep you informed about the progress and impact of our efforts.`
  },
  {
    question: "What makes XDMinds different from other blockchain marketing agencies?",
    answer: `XDMinds stands out due to our industry expertise, innovative strategies, data-driven approach, and comprehensive service offerings. Our commitment to transparency, personalized solutions, and delivering measurable results sets us apart from the competition.`
  },
  {
    question: "Can you help with community building for my blockchain project?",
    answer: `Yes, we offer specialized services for blockchain community building. Our strategies focus on engaging and nurturing a loyal community around your project, driving long-term success and growth.`
  },
  {
    question: "What kind of content marketing services do you provide?",
    answer: `We create high-quality, engaging content tailored to your audience. This includes blog posts, articles, videos, infographics, and social media content that educates, informs, and positions your project as an industry leader.`
  },
  {
    question: "How can I get started with XDMinds?",
    answer: `Getting started with XDMinds is simple. Contact us through our website or give us a call to schedule an initial consultation. We will discuss your project, goals, and how our services can help you achieve success.`
  }
];


const FAQblockchain = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {
    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };
  return (
    <div className='blockchain-sec-6-faq'>
      <div className='faq'>
        <Container>
          <Row>
            <Col>
              <div>
                <h2>Frequently Asked Question
                </h2>
              </div>
              <div className='blockchain-faq-qa'>
                {faqData.map((faq, index) => (
                  <div key={index}>
                    <div
                      onClick={() => handleFAQClick(index)}
                      aria-controls={`faq${index}`}
                      aria-expanded={openFAQ === index}
                      className='faq-1st'
                    >
                      <div className='faq-content'>
                        <h6 className='faq-content-question'>{faq.question}</h6>
                      </div>
                      <div className='faq-icons'>
                        {openFAQ === index ? (
                          <IoIosArrowDropupCircle className='close-circle-faq' />
                        ) : (
                          <IoIosArrowDropdownCircle className='plus-circle-faq' />
                        )}
                      </div>
                    </div>
                    <Collapse in={openFAQ === index}>
                      <div id={`faq${index}`} className='faq-answer-content'>
                        {renderAnswer(faq.answer)}
                      </div>
                    </Collapse>
                  </div>
                ))}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default FAQblockchain
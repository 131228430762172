import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button } from 'react-bootstrap';
import BreadCrumbs from '../../BreadCrumbs';

const DappBanner = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    return (

        <div className='web3-banner '>

            <BreadCrumbs />
            <div className='banner-left-sec min-100vh'>
                <h1>Performance-Focused <span className='changing-banner-content'>dApp</span><span className='home-banner-break'><br /></span> Marketing Agency</h1>
                <p>Unlock the true potential of your decentralized application with XDMinds, the performance-driven dApp marketing agency that delivers measurable results. Our expert team crafts innovative strategies tailored to your project’s unique needs, ensuring unparalleled success in the competitive dApp ecosystem.</p>

                <div className='btn-home-banner-request-div'>
                    <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
                </div>

                <ModalForm
                    countryCode={countryCode}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>

        </div>

    )
}

export default DappBanner
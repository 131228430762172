import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Web3GridCard = ({ web3CardData }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    return (
        <div className="row g-3">
            {web3CardData.map((card, index) => (
                <div className='col-md-6 col-lg-3 col-12' key={index}>
                    <div
                        className={`custom-web3-card card h-100 ${index === activeIndex ? 'active' : ''}`}
                        onClick={() => toggleClick(index)}
                    >
                        <LazyLoadImage src={card.src} alt={card.alt} effect="blur" />
                        <h5 className='h-text-4'>{card.title}</h5>
                        <p className={`p-text-4 ${index === activeIndex ? 'active' : ''}`}>{card.text}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Web3GridCard
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';


const Section2 = () => {
    const cardData = [
        {
            link: "/web3-marketing-services",
            imgSrc: <FaChartLine />,
            title: 'Web3 Game Marketing',
            text: "Harness the power of decentralized technology to create immersive and engaging Web3 gaming experiences. Our Web3 game marketing strategies ensure your game stands out in this innovative space, attracting dedicated players and investors.",
        },
        {
            link: "/metaverse-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Metaverse Game Marketing',
            text: "Step into the future with Metaverse game marketing. We help you navigate and thrive in the metaverse, building a robust presence and fostering a community of enthusiastic players who are eager to explore your virtual worlds.",
        },
        {
            link: "/blockchain-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Blockchain Game Marketing',
            text: "Capitalize on the growing trend of blockchain gaming. Our blockchain game marketing services leverage the unique aspects of blockchain technology to attract players who value transparency, security, and ownership in their gaming experiences.",
        },
        {
            link: "/nft-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'NFT Game Marketing',
            text: 'Tap into the NFT revolution with our specialized NFT game marketing. We create strategies that highlight the unique value of your NFT assets, driving interest and engagement from collectors and gamers who are passionate about digital ownership.',
        },
        {
            link: "/dapp-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'dApps Game Marketing',
            text: 'Promote your decentralized applications with our expert dApp game marketing. We focus on reaching the right audience, showcasing the benefits of decentralized gaming, and driving adoption through targeted campaigns',
        },
        {
            link: "/crypto-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Crypto Game Marketing',
            text: "Elevate your crypto game with our comprehensive marketing services. We connect you with the crypto community, utilizing innovative marketing tactics to increase visibility and drive user engagement.",
        },
        {
            link: "/p2e-game-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'P2E Game Marketing',
            text: 'Maximize the potential of play-to-earn (P2E) games with our tailored marketing strategies. We highlight the earning opportunities within your game, attracting players who are eager to play and earn.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'P2U Game Marketing',
            text: 'Promote play-to-utility (P2U) games by emphasizing the practical benefits and applications within the game. Our marketing strategies ensure your P2U game reaches the right audience and drives sustained engagement.'
        },
        {
            imgSrc: <FaChartLine />,
            title: 'M2E Game Marketing',
            text: 'Promote move-to-earn (M2E) games by highlighting the fitness and health benefits. Our marketing strategies attract health-conscious gamers who are motivated by the dual rewards of fitness and gaming.',
        },
        {
            link: '/t2e-game-marketing-agency',
            imgSrc: <FaChartLine />,
            title: 'T2E Game Marketing',
            text: 'Boost the popularity of your tap-to-earn (T2E) games with our targeted marketing strategies. We highlight the ease and excitement of earning rewards through simple gameplay, attracting a wide audience of casual and dedicated gamers. Let us help you turn taps into engagement and revenue.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Video Game Marketing',
            text: 'Enhance the reach and appeal of your video game with our comprehensive video game marketing services. We create compelling campaigns that engage players across platforms and drive downloads and sales.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Unity Game Marketing',
            text: 'Leverage the power of Unity with our specialized marketing services. We help you showcase the unique features and capabilities of your Unity game, attracting players and developers who appreciate high-quality gaming experiences.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Unreal Engine Game Marketing',
            text: 'Promote your Unreal Engine game with our expert marketing strategies. We emphasize the stunning visuals and immersive gameplay made possible by Unreal Engine, capturing the attention of discerning gamers.',
        },
        {
            link: "/ar-vr-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'VR Game Marketing',
            text: 'Step into the immersive world of virtual reality with our VR game marketing. We create engaging campaigns that highlight the unique experiences of your VR game, attracting players who seek cutting-edge entertainment.',
        },
        {
            link: "/ar-vr-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'AR Game Marketing',
            text: 'Bring augmented reality to life with our AR game marketing services. We focus on the interactive and innovative aspects of your AR game, appealing to players who love blending the virtual and real worlds.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Mobile Game Marketing',
            text: "Dominate the mobile gaming market with our tailored marketing strategies. We ensure your mobile game reaches the right audience, driving downloads and in-app engagement through targeted campaigns.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Console Game Marketing',
            text: "Promote your console game to a global audience with our expert marketing services. We create impactful campaigns that highlight the unique features of your console game, driving interest and sales.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'PC Game Marketing',
            text: "Maximize the reach of your PC game with our comprehensive marketing strategies. We target dedicated PC gamers, ensuring your game stands out in a crowded market and achieves success.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Betting Game Marketing',
            text: "Tap into the lucrative market of betting games with our specialized marketing services. We create strategies that emphasize the excitement and rewards of betting games, attracting a dedicated and engaged player base.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Game Marketing Consulting',
            text: "Elevate your game’s success with our expert marketing consulting services. XDMinds offers tailored strategies and insights to drive engagement, boost visibility, and maximize your game's market potential.",
        },

    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (
        <div className='game-sec-2'>
            <div className='blockchain-sec-4'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>Supercharge Your Game's Growth<span className='msec4-break'><br />
                                </span> XDMinds Extensive Gaming Marketing Services
                                </h2>
                            </div>
                            <div className='industries-sub-head'>
                                <p>
                                    XDMinds' vast marketing services will assist you in fulfilling your game's full potential. We combine cutting-edge techniques with industry knowledge to boost your game's growth. From crafting compelling narratives to executing strategic campaigns, we ensure your game reaches the right audience and achieves maximum impact.
                                </p>
                            </div>

                            <div className='desktop-industries-view'>
                                {chunkedCardData.map((chunk, index) => (
                                    <div className='home-industries-card-div' key={index}>
                                        <Row>
                                            {chunk.map((card, cardIndex) => (
                                                <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                    {card.link ? (
                                                        <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Link>
                                                    ) : (
                                                        <div className='c-default h-100'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                            </div>
                            <div className='mobile-view-industries'>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop={true}
                                    dots="true"
                                    onSwiper={(swiper) => setCardSwiper(swiper)}
                                    modules={[Scrollbar, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{ clickable: true }}
                                    className="ps-swiper"
                                    breakpoints={{
                                        0: { slidesPerView: 1, spaceBetween: 10 },
                                        460: { slidesPerView: 1, spaceBetween: 5 },
                                        768: { slidesPerView: 1, spaceBetween: 30 },
                                        1024: { slidesPerView: 6, spaceBetween: 50 },
                                    }}
                                >
                                    {cardData.map((card, index) => (
                                        <SwiperSlide key={index}>
                                            {card.link ? (
                                                <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            ) : (
                                                <div className='c-default h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Section2
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../Blockchain/Blockchain.css'

const Section1 = () => {
    const accordionData = [
        {
            id: 'Web3',
            title: ' What is a Decentralized Autonomous Organization (DAO)?',
            heading: '',
            content: [
                "A Decentralized Autonomous Organization, or DAO, is an organization represented by rules encoded as a computer program that is transparent, controlled by organization members, and not influenced by a central government. DAOs operate on blockchain technology, which allows for decentralized decision-making and automation of processes without the need for intermediaries. Members typically use tokens to vote on decisions and proposals, ensuring that the organization's governance is distributed among its stakeholders.",
            ],
        },
        {
            id: 'web3-marketing',
            title: 'How do DAOs make decisions?',
            heading: '',
            content: [
                'DAOs make decisions through a consensus mechanism, where members vote on proposals using governance tokens. The voting process is transparent and recorded on the blockchain, ensuring that all decisions are immutable and publicly accessible. The specific voting mechanisms can vary; some DAOs may require a simple majority, while others might use more complex systems like quadratic voting or token-weighted voting. The decentralized nature of the voting process helps prevent any single entity from having disproportionate control.',
            ],
        },

        {
            id: 'why-web3-marketing',
            title: 'What are the benefits of using a DAO?',
            heading: '',
            content: [
                'DAOs offer several benefits, including transparency, efficiency, and reduced costs. Because all transactions and decisions are recorded on the blockchain, DAOs provide a high level of transparency, making it easy for members and outsiders to verify actions and financial movements. The automation of processes through smart contracts can lead to increased efficiency and reduced operational costs. Additionally, DAOs allow for global participation, enabling anyone with internet access and governance tokens to participate in decision-making.',
            ],
        },

        {
            id: 'comparison',
            title: ' How are DAOs different from traditional organizations?',
            heading: '',
            content: [
                'The key difference between DAOs and traditional organizations lies in their governance and structure. Traditional organizations typically have a hierarchical structure with centralized control, while DAOs are decentralized and governed by their members. In traditional organizations, decisions are often made by a board of directors or executives, whereas in a DAO, decisions are made collectively by the members through a transparent voting process. Furthermore, DAOs rely on blockchain technology and smart contracts to automate and execute decisions, reducing the need for intermediaries and increasing operational efficiency.',
            ],
        },


        {
            id: 'navigate',
            title: 'What are some common use cases for DAOs?',
            heading: '',
            content: [
                'DAOs can be used for a wide range of applications, including investment funds, charity organizations, project management, and community governance. For example, a DAO can function as a decentralized investment fund where members pool their resources and vote on investment opportunities. In the charitable sector, DAOs can manage donations and distribute funds transparently, ensuring that the funds are used as intended. Additionally, DAOs can be used to govern decentralized projects or communities, allowing members to propose and vote on changes to protocols, policies, or community initiatives.',
            ],
        },


    ];

    return (
        <div className='blockchain-sec1'>
            <Container>
                <Row>
                    <Col>
                        <h2>Everything You Need to Know:The Top 5 FAQs <span className='metaverse-sec1-break'>
                            <br /></span> About Decentralized Autonomous Organizations (DAOs)</h2>
                        <div className='mobile-view'>

                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {accordionData.map((section, index) => (
                                    <div className="accordion-item" key={index}>
                                        <p className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#flush-collapse${index}`}
                                                aria-expanded="false"
                                                aria-controls={`flush-collapse${index}`}
                                            >
                                                {section.title}

                                            </button>

                                        </p>
                                        <div
                                            id={`flush-collapse${index}`}
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <h4 className='content-head'>{section.heading}</h4>
                                                {section.content && section.content.map((paragraph, idx) => (
                                                    <p className='content-sescription' key={idx}>{paragraph}</p>
                                                ))}
                                                {section.benefits && (
                                                    <div className="benefits">
                                                        {section.benefits.map((benefit, idx) => (
                                                            <React.Fragment key={idx}>
                                                                <p className="benefits-discript">{benefit.title}</p>
                                                                <p>{benefit.description}</p>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


export default Section1
import React, { useState } from 'react';

const EventCard = ({ eventCardData }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    return (
        <div className='event-card'>
            <div className='row g-4'>
                {eventCardData.map((card, index) => (
                    <div key={index} className='col-md-6 col-lg-4 col-12'>
                        <div
                            className={`custom-event-card card h-100 w-100 ${index === activeIndex ? 'active' : ''}`}
                        >
                            <div className='custom-event-card-header' onClick={() => toggleClick(index)}>
                                <h5 className='h-text-7'>{card.title}</h5>
                            </div>
                            <div className={`custom-event-card-body  ${index === activeIndex ? 'active' : ''}`}>
                                {card.sections.map((section, idx) => (
                                    <React.Fragment key={idx}>
                                        <h6 className='h-text-8'>{section.subtitle}</h6>
                                        <p className='p-text-6'>{section.content}</p>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default EventCard;

import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import graph from '../../assets/images/graph.svg';
import BottomFaq from '../DigitalMarketing/BottomFaq';
import Latest from '../../Components/Home/Latest/Latest';
import GridCard from '../DigitalMarketing/GridCard';
import Tabs from '../DigitalMarketing/Tabs';
import BreadCrumbs from '../../Components/BreadCrumbs';



const Iot = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;


    const cardData = [
        {
            src: graph,
            alt: 'IoT Go-to-Market Strategy',
            title: 'IoT Go-to-Market Strategy',
            text: 'Develop a robust go-to-market strategy tailored for your IoT products, ensuring a successful launch and strong market presence.'
        },
        {
            src: graph,
            alt: 'IoT Product Marketing',
            title: 'IoT Product Marketing',
            text: 'Boost your IoT product’s visibility and appeal with targeted marketing campaigns that highlight its unique features and benefits.'
        },
        {
            src: graph,
            alt: 'IoT Lead Generation',
            title: 'IoT Lead Generation',
            text: 'Generate high-quality leads with strategic marketing initiatives that attract and engage potential customers in the IoT space.'
        },
        {
            src: graph,
            alt: 'IoT Website SEO',
            title: 'IoT Website SEO',
            text: 'Optimize your website to improve search engine visibility, drive organic traffic, and increase conversions for your IoT offerings.'
        },
        {
            src: graph,
            alt: 'IoT App ASO',
            title: 'IoT App ASO',
            text: 'Enhance your IoT app’s discoverability and downloads with effective app store optimization techniques that improve visibility and user engagement.'
        },
        {
            src: graph,
            alt: 'Social Media Marketing',
            title: 'Social Media Marketing',
            text: 'Engage with your audience on their favorite platforms through tailored social media strategies that increase brand awareness and foster community.'
        },
        {
            src: graph,
            alt: 'IoT PPC Advertising',
            title: 'IoT PPC Advertising',
            text: 'Utilize pay-per-click advertising to quickly and efficiently reach your target audience, driving immediate traffic and leads.'
        },
        {
            src: graph,
            alt: 'IoT Social Media Ads',
            title: 'IoT Social Media Ads',
            text: 'Engage your audience on social media platforms with compelling ad campaigns that promote your IoT products and services.'
        },
        {
            src: graph,
            alt: 'Mobile App Advertising',
            title: 'Mobile App Advertising',
            text: 'Promote your mobile app through targeted advertising strategies that drive downloads and user engagement.'
        },
        {
            src: graph,
            alt: 'IoT Mobile App Marketing',
            title: 'IoT Mobile App Marketing',
            text: 'Maximize your mobile app’s reach and engagement with targeted marketing strategies designed for the IoT market.'
        },
        {
            src: graph,
            alt: 'IoT Gaming Marketing',
            title: 'IoT Gaming Marketing',
            text: 'Tap into the gaming community with innovative IoT marketing campaigns that resonate with gamers and tech enthusiasts.'
        },
        {
            src: graph,
            alt: 'IoT Community Marketing',
            title: 'IoT Community Marketing',
            text: 'Build a strong community around your IoT products with community-driven marketing efforts that foster loyalty and advocacy. '
        },
        {
            src: graph,
            alt: 'Omnichannel Marketing',
            title: 'Omnichannel Marketing',
            text: 'Seamlessly integrate your marketing efforts across all channels for a consistent brand experience. Our omnichannel strategies ensure cohesive communication and enhanced customer engagement.'
        },
        {
            src: graph,
            alt: 'IoT Influencer Marketing',
            title: 'IoT Influencer Marketing',
            text: 'Collaborate with influencers in the IoT space to amplify your brand’s message and reach a wider, more engaged audience.'
        },
        {
            src: graph,
            alt: 'IoT Inbound Marketing',
            title: 'IoT Inbound Marketing',
            text: 'Attract and nurture potential customers with inbound marketing strategies that provide valuable content and drive engagement.'
        },
        {
            src: graph,
            alt: 'IoT Display Ads',
            title: 'IoT Display Ads',
            text: 'Increase your brand’s visibility with eye-catching display ads that capture attention and drive traffic to your IoT products.'
        },
        {
            src: graph,
            alt: 'IoT PR Marketing',
            title: 'IoT PR Marketing',
            text: ' Enhance your brand’s reputation and visibility with strategic public relations campaigns that position your IoT solutions as industry leaders.'
        },
        {
            src: graph,
            alt: 'IoT Video Advertising',
            title: 'IoT Video Advertising',
            text: 'Engage your audience with compelling video content that showcases your IoT products’ features, benefits, and real-world applications.'
        },
        {
            src: graph,
            alt: 'IoT Email Marketing',
            title: 'IoT Email Marketing',
            text: 'Reach your audience directly with personalized email campaigns that drive engagement and conversions for your IoT offerings.'
        },
        {
            src: graph,
            alt: 'IoT Content Marketing',
            title: 'IoT Content Marketing',
            text: 'Create valuable and informative content that educates your audience, builds trust, and positions your brand as an IoT thought leader.'
        },
        {
            src: graph,
            alt: 'IoT Omnichannel Marketing',
            title: 'IoT Omnichannel Marketing',
            text: 'Deliver a seamless and consistent brand experience across all channels with comprehensive omnichannel marketing strategies.'
        },
        {
            src: graph,
            alt: 'IoT CRO',
            title: 'IoT CRO',
            text: 'Optimize your conversion rates with data-driven strategies that improve user experience and increase the likelihood of conversions.'
        },
        {
            src: graph,
            alt: 'IoT ORM',
            title: 'IoT ORM',
            text: 'Manage and enhance your online reputation with proactive reputation management strategies that build trust and credibility for your IoT brand.'
        },
    ];

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Tailored Strategy Development ',
            sections: [
                {
                    content: 'At XDMinds, we begin by crafting a customized IoT marketing strategy that aligns with your business goals and target audience. By analyzing market trends and consumer behavior, we develop a plan that focuses on high-impact channels and tactics to ensure every marketing dollar is spent effectively.'
                },
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Data-Driven Decision Making',
            sections: [
                {
                    content: 'We leverage advanced analytics and insights to track the performance of your IoT marketing campaigns. By continuously monitoring key metrics such as conversion rates, customer acquisition costs, and engagement levels, we make informed adjustments to optimize your ROI.'
                },
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Targeted Campaign Execution',
            sections: [
                {
                    content: 'Our team designs and implements targeted marketing campaigns across various channels, including social media, email, and PPC advertising. By focusing on specific customer segments and utilizing precise targeting, we maximize the efficiency of your marketing spend and enhance campaign effectiveness.'
                },
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Content Optimization',
            sections: [
                {
                    content: ' We create and refine high-quality content that resonates with your audience and drives conversions. From engaging blog posts and whitepapers to compelling video content, our content marketing strategies are designed to attract and retain potential customers, boosting your marketing ROI.'
                },
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Comprehensive Performance Analysis',
            sections: [
                {
                    content: 'Our approach includes in-depth performance analysis to evaluate the success of your campaigns. We provide detailed reports and actionable insights, helping you understand what’s working and where improvements can be made to continually enhance your marketing ROI. '
                },
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Continuous Improvement ',
            sections: [
                {
                    content: 'We adopt a cycle of continuous improvement, where we regularly review and refine your marketing strategies based on performance data and market changes. This iterative approach ensures that your IoT marketing efforts are always optimized for maximum return on investment. '
                },
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Expert Guidance and Support',
            sections: [
                {
                    content: "Throughout the process, XDMinds provides expert guidance and support to help you navigate the complexities of IoT marketing. Our team of specialists is dedicated to ensuring your marketing strategies are aligned with industry best practices and delivering the best possible ROI.",
                },
            ]
        },
    ];

    const faqData = [
        {
            question: "How will IoT marketing differentiate my business from competitors?",
            answer: " IoT marketing helps your business stand out by showcasing your unique IoT solutions through targeted and innovative campaigns. By leveraging advanced strategies like personalized content, data-driven insights, and engaging customer experiences, we highlight what sets your IoT products apart from the competition. Our approach ensures that your brand gains visibility, attracts the right audience, and establishes a strong presence in the market."
        },
        {
            question: "Are XDMinds' IoT marketing services suitable for all types of IoT businesses?",
            answer: "Yes, XDMinds' marketing services are designed to cater to a wide range of IoT businesses, from startups to established enterprises. Whether you specialize in smart home devices, industrial IoT solutions, healthcare technology, or any other IoT sector, our tailored marketing strategies address your specific needs and goals. We provide customized solutions that effectively promote your products and drive growth across various IoT industries.",
        },
        {
            question: "What metrics and tools does XDMinds use to evaluate the success of IoT marketing campaigns?",
            answer: "At XDMinds, we measure the success of IoT marketing campaigns using a combination of key performance indicators (KPIs) and analytics tools. Metrics such as engagement rates, lead generation, conversion rates, website traffic, and return on investment (ROI) are closely monitored. We provide detailed reports and insights that help you understand the impact of our marketing efforts and identify areas for optimization.",
        },
        {
            question: "How long does it usually take to launch an IoT marketing campaign with XDMinds?",
            answer: "The timeline for launching an IoT marketing campaign with XDMinds depends on the complexity and scope of the project. Generally, the process includes initial consultations, strategy development, creative design, and campaign execution. For a standard campaign, the typical timeline ranges from 4 to 8 weeks. We work closely with you to establish a timeline that aligns with your goals and ensures timely delivery of effective marketing solutions.",
        },
        {
            question: "Can XDMinds help small IoT businesses with marketing services if they have a limited budget?",
            answer: "Absolutely. XDMinds offers flexible marketing solutions that cater to small IoT businesses with varying budgets. We focus on cost-effective strategies such as targeted social media ads, content marketing, and inbound marketing that deliver high value without significant expenditure. By prioritizing key areas and optimizing resources, we help small businesses achieve impactful results and grow their presence in the IoT market.",
        }

    ];
    return (
        <div className='iot'>
            <div className='digital-marketing'>
                <div className='iot-banner min-100vh digital-marketing-banner'>
                    <BreadCrumbs />
                    <h1 className='h-text-1'>
                        Best <span className='s-text-1'>IoT</span> Marketing Agency <br className='break' /> for Startups & Enterprises
                    </h1>
                    <p className='p-text-1'>
                        At XDMinds, we specialize in IoT marketing that puts the customer at the heart of every strategy.
                        Our tailored solutions cater to both startups and enterprises, ensuring that your IoT products and
                        services reach the right audience and achieve maximum impact. From innovative campaigns to data-driven insights, we provide end-to-end IoT
                        marketing services designed to drive growth and foster lasting customer relationships.
                    </p>
                    <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
                </div>
            </div>
            <section className='iot-section-2 d-bg'>
                <h2 className='h-text-2'>
                    XDMinds: Your Trusted Marketing Agency for  <br />Internet of Things (IoT) Market Success
                </h2>
                <div className='border-div position-relative'>
                    <p className='p-text-2 w-100 span-icon'>
                        At XDMinds, we are dedicated to helping businesses navigate the complex landscape of IoT marketing.
                        As a trusted partner, we offer a comprehensive suite of marketing services tailored to the unique needs of the IoT industry.
                        Our team of experts combines deep industry knowledge with cutting-edge marketing techniques to deliver strategies that drive visibility, engagement, and growth. Whether you're launching a new IoT product or looking to enhance your existing offerings,
                        XDMinds provides the expertise and support you need to succeed.
                    </p>
                    <div className='social-buttons'>
                        <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your iot marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/iot-marketing-agency' className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </a>
                        <a href='https://t.me/xd_minds' className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </a>
                        <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </a>
                    </div>
                </div>
            </section>
            <section className='iot-section-2 digital-marketing'>
                <h2 className='h-text-2'>
                    Accelerate Your IoT Business with <br className='break' /> Our Expert IoT Marketing Services
                </h2>
                <p className='p-text-2'>
                    We specialize in creating tailored marketing strategies that drive growth, enhance visibility,
                    and boost customer engagement. From go-to-market planning to targeted advertising campaigns, our
                    comprehensive services are designed to position your IoT products and solutions at the forefront of the
                    industry. Partner with us to accelerate your
                    business and achieve unparalleled success in the dynamic world of IoT.
                </p>
                <div className='grid-cards '>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='iot-section-4 digital-marketing'>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        How Can XDMinds Help You Maximize Your IoT Marketing ROI?
                    </h2>
                    <div className='tabs-div'>
                        <Tabs tabData={tabData} />
                    </div>
                </section>
                <section>
                    <Latest modifiedPath='Latest IoT Marketing Insights' />
                </section>
                <section className='iot-section-4 iot-section-2 '>
                    <h2 className='h-text-2'>
                        Frequently Asked Questions
                    </h2>
                    <BottomFaq faqData={faqData} />
                </section>
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default Iot

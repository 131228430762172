import React, { useState } from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BreadCrumbs from '../../Components/BreadCrumbs';
import graph from '../../assets/images/graph.svg';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import Web3GridCard from '../DigitalMarketing/Web3GridCard';
import Latest from '../../Components/Home/Latest/Latest';
import FAQblockchain from '../../Components/Services/Blockchain/FAQblockchain';
import { MdArrowCircleDown } from "react-icons/md";



const T2E = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const web3CardData = [
        {
            src: graph,
            alt: 'T2E Marketing Strategy',
            title: 'T2E Marketing Strategy',
            text: "We craft tailored pre-launch, launch, and post-launch strategies to ensure your Tap-to-Earn (T2E) game achieves maximum impact and success."
        },
        {
            src: graph,
            alt: 'Keywords Research',
            title: 'Keywords Research',
            text: "Our in-depth keyword research identifies the most relevant and high-performing terms to boost your T2E game’s search visibility and attract the right audience."
        },
        {
            src: graph,
            alt: 'Competitor Analysis',
            title: 'Competitor Analysis',
            text: "We conduct thorough competitor analysis to understand market trends, identify opportunities, and position your T2E game advantageously."
        },
        {
            src: graph,
            alt: 'T2E Brand Development',
            title: 'T2E Brand Development',
            text: "We craft compelling brand narratives and visual identities that resonate with players, building strong brand recognition and loyalty."
        },
        {
            src: graph,
            alt: 'T2E SEO',
            title: 'T2E SEO',
            text: "Our SEO strategies enhance your T2E game’s online visibility, driving organic traffic and improving search engine rankings."
        },
        {
            src: graph,
            alt: 'T2E ASO',
            title: 'T2E ASO',
            text: "We optimize your T2E game’s presence on app stores to increase downloads and improve user engagement."
        },
        {
            src: graph,
            alt: 'Paid Advertising',
            title: 'Paid Advertising',
            text: "Our targeted paid advertising campaigns boost your T2E game’s exposure, reaching a broader and more relevant audience."
        },
        {
            src: graph,
            alt: 'T2E App Advertising',
            title: 'T2E App Advertising',
            text: "We utilize app advertising techniques to promote your T2E game within other apps, increasing visibility and attracting new players."
        },
        {
            src: graph,
            alt: 'Social Media Marketing',
            title: 'Social Media Marketing',
            text: "Our social media strategies engage players across platforms, fostering a vibrant and active community around your T2E game."
        },
        {
            src: graph,
            alt: 'Community Building',
            title: 'Community Building',
            text: "We build and manage online communities that support player interaction and engagement, enhancing player loyalty."
        },
        {
            src: graph,
            alt: 'Content Marketing',
            title: 'Content Marketing',
            text: "Our content marketing initiatives provide valuable information and insights to players, driving engagement and retention."
        },
        {
            src: graph,
            alt: 'Email Marketing',
            title: 'Email Marketing',
            text: "We design effective email campaigns that keep players informed and engaged, driving ongoing interaction with your game."
        },
        {
            src: graph,
            alt: 'Public Relations (PR)',
            title: 'Public Relations (PR)',
            text: "Boost your Tap-to-Earn (T2E) game’s exposure with our targeted PR strategies, securing impactful media coverage and press releases to build credibility and excitement."
        },
        {
            src: graph,
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: "We collaborate with influencers to promote your game, leveraging their reach and credibility to attract new players."
        },
        {
            src: graph,
            alt: 'Retention and Loyalty Programs',
            title: 'Retention and Loyalty Programs',
            text: "We design programs that reward and retain players, fostering long-term engagement and loyalty."
        },
        {
            src: graph,
            alt: 'Cross-Promotion and Partnerships',
            title: 'Cross-Promotion and Partnerships',
            text: "We identify and establish cross-promotion and partnership opportunities to expand your T2E game’s reach."
        },
        {
            src: graph,
            alt: 'Omnichannel Marketing',
            title: 'Omnichannel Marketing',
            text: "Our omnichannel approach ensures a consistent and cohesive marketing message across all platforms."
        },
        {
            src: graph,
            alt: 'In-Game Events and Promotions',
            title: 'In-Game Events and Promotions',
            text: "Boost player engagement and retention with XDMinds' expertly crafted Tap-to-Earn in-game events and promotions."
        },
        {
            src: graph,
            alt: 'Performance Tracking and Analytics',
            title: 'Performance Tracking and Analytics',
            text: "We monitor and analyze campaign performance, providing insights to optimize strategies and improve results."
        },
        {
            src: graph,
            alt: 'T2E Marketing Consulting',
            title: 'T2E Marketing Consulting',
            text: "Our expert marketing consulting services offer guidance and support to help you navigate the T2E game marketing landscape effectively."
        },

    ];

    const cardData = [
        {
            id: 1,
            title: 'Tailored Strategies',
            description: "We develop customized marketing strategies specifically designed for Tap-to-Earn games, ensuring that your unique goals and challenges are addressed effectively."
        },
        {
            id: 2,
            title: 'Innovative Campaigns',
            description: "We create cutting-edge marketing campaigns that captivate and engage your target audience, leveraging the latest trends and technologies in the Tap-to-Earn space."
        },
        {
            id: 3,
            title: 'Data-Driven Insights',
            description: "Our approach is backed by comprehensive data analysis, allowing us to optimize campaigns for maximum impact and adjust strategies based on real-time performance metrics."
        },
        {
            id: 4,
            title: 'Expertise in T2E Dynamics',
            description: "Our team is well-versed in the intricacies of Tap-to-Earn mechanics, allowing us to create campaigns that resonate with your target audience and drive player engagement."
        },
        {
            id: 5,
            title: 'Creative Solutions',
            description: "We employ creative and impactful promotional tactics, including in-game events and interactive experiences, to enhance player engagement and drive growth."
        },
        {
            id: 6,
            title: 'Comprehensive Support',
            description: "From strategic planning to execution and monitoring, XDMinds offers end-to-end support, ensuring that every aspect of your Tap-to-Earn marketing is managed with precision and care."
        },
        {
            id: 7,
            title: 'Results-Oriented Approach',
            description: "Our focus is on delivering tangible results, including increased player acquisition, improved retention rates, and higher revenue generation, to help you achieve your business objectives."
        },
        {
            id: 8,
            title: 'Focused User Acquisition',
            description: "We use targeted marketing tactics to reach your ideal players, driving high-quality installations and boosting your game's growth."
        },


    ];

    const faqData = [
        {
            question: "What is Tap-to-Earn marketing, and how can XDMinds help? ",
            answer: "Tap-to-Earn marketing focuses on encouraging players to engage with your game by offering rewards for their activity. XDMinds provides tailored strategies to maximize player engagement and drive game growth through targeted promotions and in-game incentives."
        },
        {
            question: "How does XDMinds design effective Tap-to-Earn promotions? ",
            answer: "Our team at XDMinds analyzes your game’s audience and gameplay mechanics to create customized promotions that resonate with players. We leverage data-driven insights to ensure that promotions are engaging and drive the desired results."
        },
        {
            question: "What types of in-game events does XDMinds offer for Tap-to-Earn games? ",
            answer: "XDMinds organizes a variety of in-game events, including challenges, tournaments, and seasonal promotions, all designed to boost player participation and retention. Each event is tailored to fit your game’s unique theme and objectives."
        },
        {
            question: "How can XDMinds help increase user acquisition for Tap-to-Earn games?  ",
            answer: "We utilize a mix of targeted advertising, influencer partnerships, and social media campaigns to attract new players to your Tap-to-Earn game. Our approach ensures that your game reaches a broad yet relevant audience."
        },
        {
            question: "What role does analytics play in XDMinds’ Tap-to-Earn marketing strategy?",
            answer: " Analytics are crucial for understanding player behavior and measuring the success of marketing efforts. XDMinds uses advanced analytics to track performance, optimize campaigns, and refine strategies for better results."
        },
        {
            question: "Can XDMinds assist with monetization strategies for Tap-to-Earn games? ",
            answer: "Yes, XDMinds offers expert guidance on monetization strategies, including in-game purchases and ad placements, to help you maximize revenue while maintaining a positive player experience."
        },
        {
            question: "How does XDMinds ensure that Tap-to-Earn promotions are aligned with my game’s brand?",
            answer: " We collaborate closely with you to understand your game’s branding and objectives. Our team ensures that all promotions are consistent with your game’s theme and enhance its overall brand identity."
        },
        {
            question: "What are the benefits of partnering with XDMinds for Tap-to-Earn marketing?  ",
            answer: "Partnering with XDMinds means leveraging our expertise in game marketing, innovative promotional strategies, and data-driven insights to drive player engagement and game success effectively."
        },
        {
            question: "How does XDMinds handle the implementation of Tap-to-Earn campaigns?  ",
            answer: "XDMinds manages the end-to-end process, from conceptualizing and designing campaigns to executing and monitoring them. We ensure smooth implementation and timely delivery of all marketing activities."
        },
        {
            question: "What kind of results can I expect from XDMinds’ Tap-to-Earn marketing services?  ",
            answer: "XDMinds aims to deliver measurable results, including increased player engagement, higher retention rates, and improved game visibility. Our goal is to drive meaningful growth and success for your Tap-to-Earn game."
        },



    ];

    return (
        <div className='t2e'>
            <div className='digital-marketing'>
                <section className='digital-marketing-banner min-100vh'>
                    <BreadCrumbs />
                    <h1 className='h-text-1'>
                        Unparalleled Tap-To-Earn   <br />
                        <span className='s-text-3'>T2E</span>
                        Marketing Agency
                    </h1>
                    <p className='p-text-1'>
                        Tap into the future of gaming with XDMinds, the leading Tap-To-Earn (T2E) game marketing agency.
                        We specialize in crafting innovative strategies that elevate your T2E game, ensuring maximum engagement and growth. Our comprehensive marketing solutions are tailored to meet
                        the unique demands of T2E gaming, helping you to create a loyal and active player base.
                    </p>
                    <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
                </section>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        XDMinds: The Ultimate Tap-To-Earn <br className='break' /> (T2E) Game Marketing Agency Partner for Maximizing Your Game's Reach

                    </h2>
                    <p className='p-text-2'>
                        XDMinds stands at the forefront of the T2E game marketing industry, offering unmatched expertise and
                        a track record of success in promoting Tap-To-Earn games. Our team of dedicated professionals understands the nuances of the T2E model and leverages this knowledge to deliver exceptional marketing solutions. We focus on maximizing your game’s reach and impact, ensuring it stands out in the competitive gaming landscape. By partnering with XDMinds, you gain access to innovative strategies,
                        cutting-edge technologies, and a passionate team committed to your game’s success.
                    </p>
                    <div className='social-buttons'>
                        <button className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </button>
                    </div>
                </section>
                <section className='digital-marketing-section-2 '>
                    <h2 className='h-text-2'>
                        Achieve Unmatched Growth for Your Tap-To-Earn <br className='break' />(T2E) Game with XDMinds' Comprehensive T2E Marketing Services

                    </h2>
                    <div className='grid-cards'>
                        <Web3GridCard web3CardData={web3CardData} />
                    </div>
                </section>
            </div>
            <div className='about'>
                <section className='about-section-5'>
                    <h2 className='h-text-2'>
                        Achieve Unmatched Growth for Your Tap-To-Earn<br className='break' /> (T2E) Game with XDMinds' Comprehensive T2E Marketing Services
                    </h2>
                    <p className='p-text-2'>
                        Choose XDMinds as your preferred Tap-to-Earn (T2E) marketing agency for innovative strategies that
                        drive exceptional results.
                        Our tailored approach ensures your game stands out and thrives in a competitive market.
                    </p>
                    <div className='custom-div-5 mobile-hide'>
                        <div className='row g-3'>
                            {cardData.map((card) => (
                                <div key={card.id} className='col-lg-4 col-md-6 col-12'>
                                    <div className='card custom-card-5 h-100'>
                                        <div className='custom-card-head'>
                                            <h1 className='h-text-3'>{card.id}</h1>
                                            <h2 className='h-text-4'>{card.title}</h2>
                                        </div>
                                        <div className='card-body'>
                                            {card.description && <p className='p-text-4'>{card.description}</p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='custom-accordion desktop-hide tab-hide'>
                        <div className="accordion" id="accordionExample">
                            {cardData.map((item, index) => (
                                <div key={item.id} className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                            onClick={() => handleClick(index)}
                                            type="button"
                                            aria-expanded={openIndex === index}
                                            aria-controls={item.id}
                                        >
                                            {item.title}
                                            <div className="arrow-icon-btn">
                                                <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id={item.id}
                                        className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p className='p-text-1'>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
            <section>
                <Latest modifiedPath='Latest T2E Marketing Insights' />
            </section>
            <section>
                <FAQblockchain faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default T2E

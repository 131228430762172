import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';

const GameFiSec2 = () => {
    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Marketing Strategy',
            text: "Developing comprehensive marketing campaigns tailored to your GameFi project's unique needs, ensuring maximum impact and engagement.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Keywords Research',
            text: "Conducting in-depth keyword research to identify high-traffic terms and optimize your content for better search engine rankings and visibility.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Competitor Analysis',
            text: "Analyzing competitor strategies to uncover opportunities and develop a competitive edge for your GameFi project."
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Branding Development',
            text: "Creating a distinctive brand identity and compelling narrative that resonates with your target audience and sets your game apart.",
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'GameFi SEO',
            text: "Optimizing your website and content for search engines to enhance organic visibility and drive targeted traffic to your GameFi project.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Paid Advertising',
            text: "Implementing targeted paid advertising campaigns to boost your game's reach and attract a highly engaged audience.",
        },
        {
            link: "/social-media-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Social Media Marketing',
            text: "Leveraging social media platforms to build a strong online presence, engage with your community, and drive user acquisition.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Community Building',
            text: "Fostering a vibrant and loyal community around your game through strategic community building and management efforts.",
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'GameFi Content Marketing',
            text: "Creating and distributing valuable, relevant content to attract and retain a clearly defined audience, driving profitable customer action.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Email Marketing',
            text: "Designing and executing email marketing campaigns that nurture leads, engage users, and drive conversions for your GameFi project.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Public Relations',
            text: "Managing public relations to enhance your game's reputation, secure media coverage, and build credibility in the blockchain gaming space.",
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'GameFi Influencer Marketing',
            text: "Partnering with influential figures in the gaming community to amplify your game's reach and drive engagement.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Strategic GameFi Partnerships',
            text: "Establishing strategic partnerships to enhance your game's development, distribution, and marketing efforts.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Omnichannel Marketing',
            text: "Implementing integrated marketing strategies across multiple channels to ensure a consistent and cohesive brand experience.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Performance Tracking',
            text: "Utilizing advanced analytics to track the performance of your marketing campaigns and make data-driven decisions for continuous improvement.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'GameFi Marketing Consulting',
            text: "Providing expert consulting services to guide your GameFi project's marketing efforts and ensure long-term success.",
        },

    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (

        <div className='blockchain-sec-4'>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h2>Expert-Driven GameFi Marketing Services<span className='msec4-break'><br />
                            </span> to Propel Your Blockchain Game Forward by XDMinds
                            </h2>
                        </div>
                        <div className='industries-sub-head'>
                            <p>
                                XDMinds offers a comprehensive suite of GameFi marketing services designed to propel your blockchain game forward. Our expert-driven approach ensures that every aspect of your marketing strategy is meticulously planned and executed. From market research to performance analytics, we provide end-to-end solutions that guarantee the success of your GameFi project. Let XDMinds be the catalyst that accelerates your game's journey to success.
                            </p>
                        </div>

                        <div className='desktop-industries-view'>
                            {chunkedCardData.map((chunk, index) => (
                                <div className='home-industries-card-div' key={index}>
                                    <Row>
                                        {chunk.map((card, cardIndex) => (
                                            <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                {card.link ? (
                                                    <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Link>
                                                ) : (
                                                    <div className='c-default h-100'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                        </div>

                        <div className='mobile-view-industries'>
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={3}
                                loop={true}
                                dots="true"
                                onSwiper={(swiper) => setCardSwiper(swiper)}
                                modules={[Scrollbar, Pagination, Autoplay]}
                                autoplay={{
                                    delay: 1000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}
                                className="ps-swiper"
                                breakpoints={{
                                    0: { slidesPerView: 1, spaceBetween: 10 },
                                    460: { slidesPerView: 1, spaceBetween: 5 },
                                    768: { slidesPerView: 1, spaceBetween: 30 },
                                    1024: { slidesPerView: 6, spaceBetween: 50 },
                                }}
                            >
                                {cardData.map((card, index) => (
                                    <SwiperSlide key={index}>
                                        {card.link ? (
                                            <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        ) : (
                                            <div className='c-default h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}


export default GameFiSec2
import { useState } from 'react';
import './FAQ.css';
import { Col, Row, Container, Collapse } from 'react-bootstrap';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

const faqData = [
  {
    question: "What is Web3 digital marketing?",
    answer: `Web3 marketing focuses on promoting products, services, and communities built on decentralized technologies like blockchain, cryptocurrency, and the metaverse. It leverages innovative strategies to reach crypto-native audiences and build strong communities within the Web3 space.`
  },
  {
    question: "Why choose XDMinds for my Web3 project?",
    answer: `XDMinds is a forward-thinking marketing agency with a deep understanding of the Web3 landscape. We stay ahead of the curve on emerging trends and technologies, allowing us to craft unique and effective marketing strategies specifically tailored to your Web3 project. Our team is passionate about the potential of Web3 and committed to helping your project achieve its full potential.`
  },
  {
    question: "What kind of Web3 marketing services do you offer?",
    answer: `We offer a comprehensive suite of Web3 marketing services, including:

Community building and management

Content marketing (articles, blog posts, social media content)

Influencer marketing within the Web3 space

Public relations for Web3 projects

Paid advertising campaigns on relevant platforms

SEO optimization for improved discoverability within Web3 search engines`
  },
  {
    question: "Can you help me create a marketing strategy for my NFT project?",
    answer: `Absolutely! We have extensive experience developing successful marketing campaigns for NFT projects. Our approach involves understanding your target audience, crafting compelling narratives, and leveraging the power of community building to drive sales and engagement for your NFTs.`
  },
  {
    question: "Do you offer any consulting services related to Web3 marketing?",
    answer: `Yes, we do. We offer consulting services to help businesses understand the Web3 marketing landscape and develop a strategic roadmap for achieving their goals. Our consultations can cover topics such as identifying target audiences, selecting the right marketing channels, and measuring campaign success within the Web3 ecosystem.`
  },
  {
    question: "What is your process for working with clients?",
    answer: `We take a collaborative approach to working with clients. First, we'll schedule a consultation to understand your project, target audience, and marketing goals. Then, we'll develop a customized strategy with specific tactics and timelines. Throughout the project, we maintain open communication and provide regular progress reports to ensure you're informed and involved in the process.`
  },
  {
    question: "How much do your services cost?",
    answer: `Our pricing structure is flexible and depends on the specific needs of your project. We offer hourly rates, project-based pricing, and retainer packages. During the consultation, we'll discuss your budget and create a proposal that outlines the scope of work and associated costs.`
  },
  {
    question: "Do you offer any guarantees on the results of your marketing campaigns?",
    answer: `The ever-evolving nature of the Web3 space makes it difficult to guarantee specific outcomes. However, we are confident in our ability to develop and execute effective marketing strategies that will drive positive results for your project. We focus on measurable tactics and transparent reporting, allowing you to track progress and the impact of our efforts.`
  }
];


const FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQClick = (faqIndex) => {
    setOpenFAQ(openFAQ === faqIndex ? null : faqIndex);
  };

  const renderAnswer = (answer) => {

    const lines = answer.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 1) {
      return (
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>;
  };

  return (
    <div className='faq'>
      <Container>
        <Row>
          <Col>
            <div>
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className='home-faq-content'>
              {faqData.map((faq, index) => (
                <div key={index}>
                  <div
                    onClick={() => handleFAQClick(index)}
                    aria-controls={`faq${index}`}
                    aria-expanded={openFAQ === index}
                    className='faq-1st'
                  >
                    <div className='faq-content'>
                      <h6 className='faq-content-question'>{faq.question}</h6>
                    </div>
                    <div className='faq-icons'>
                      {openFAQ === index ? (
                        <IoIosArrowDropupCircle className='close-circle-faq' />
                      ) : (
                        <IoIosArrowDropdownCircle className='plus-circle-faq' />
                      )}
                    </div>
                  </div>
                  <Collapse in={openFAQ === index}>
                    <div id={`faq${index}`} className='faq-answer-content'>
                      {renderAnswer(faq.answer)}
                    </div>
                  </Collapse>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQ;
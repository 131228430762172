import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './AI.css'
import { FaSkype, FaWhatsapp } from 'react-icons/fa6'
import { FaTelegramPlane } from 'react-icons/fa'

const AISection2 = () => {
    return (
        <div className='AI-sec2'>
            <Container>
                <Row>
                    <Col>
                        <div className='defi-sec-2'>
                            <div className='blockchain-sec2'>

                                <div>
                                    <h2>  XDMinds: Your Trusted Partner for <span className='metaverse-sec2-break'><br /></span>AI-Driven Marketing Transformation
                                    </h2>
                                    <p>At XDMinds, we are committed to transforming your marketing strategies through advanced AI technologies. Our team of experts combines deep industry knowledge with cutting-edge AI tools to deliver customized solutions that drive measurable results. As your trusted partner, we work closely with you to understand your business objectives and design AI-driven marketing strategies that align with your goals. From enhancing customer experiences to optimizing campaign performance, XDMinds is dedicated to helping you achieve marketing excellence in the AI era.
                                    </p>
                                </div>
                                <div className='btn-mchallenge'>
                                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your AI marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/ai-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                        <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                                    </a>
                                    <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                        <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                                    </a>
                                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                        <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AISection2
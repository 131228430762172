import React from 'react'
import BlockchainBanner from '../../Components/Services/Blockchain/BlockchainBanner'
import Section1 from '../../Components/Services/Blockchain/Section1'
import Section2 from '../../Components/Services/Blockchain/Section2'
import Section3 from '../../Components/Services/Blockchain/Section3'
import FAQblockchain from '../../Components/Services/Blockchain/FAQblockchain'
import Latest from '../../Components/Home/Latest/Latest'
import Section4 from '../../Components/Services/Blockchain/Section4'
import Section5 from '../../Components/Services/Blockchain/Section5'

const Blockchain = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <BlockchainBanner countryCode={countryCode} />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Latest modifiedPath='Latest Blockchain Marketing Insights' />
      <FAQblockchain />


    </div>
  )
}

export default Blockchain
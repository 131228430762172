import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import TopFaq from './TopFaq';
import StickyStackingCards from './StickyStackingCards';
import GridCard from './GridCard';
import graph from '../../assets/images/graph.svg';
import DSwiper from './DSwiper';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import BreadCrumbs from '../../Components/BreadCrumbs';
import { Link } from 'react-router-dom';


const SocialMediaMarketing = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const topFaqData = [
        {
            question: "What is Social Media Marketing?",
            answer: "Social media marketing involves using social media platforms to connect with your audience, build your brand, increase sales, and drive website traffic. This includes creating and sharing content on social media networks, engaging with followers, and analyzing results to improve strategy."
        },
        {
            question: "What is Social Media Advertising?",
            answer: "Social media advertising refers to paid efforts to promote your brand and content on social media platforms. This includes running targeted ad campaigns to reach specific demographics, increasing brand visibility, and driving conversions through sponsored posts, video ads, and more."
        },
        {
            question: "What is Social Media Management?",
            answer: "Social media management encompasses the day-to-day tasks of managing your social media profiles, including content creation, scheduling posts, responding to comments, and monitoring analytics. Effective management ensures your social media presence remains active and engaging."
        },
        {
            question: "What is Social Community Management?",
            answer: "Social community management involves nurturing and growing your online community by interacting with your audience, responding to their queries, and fostering a sense of community. This helps build brand loyalty and encourages positive engagement."
        },
        {
            question: "How Does Social Media Advertising Differ from SMM?",
            answer: "Social media advertising focuses on paid promotional efforts to increase visibility and drive specific actions, while social media marketing (SMM) includes both organic and paid strategies to build a strong social presence and engage with your audience."
        },
        {
            question: "Why is Social Media Marketing Important?",
            answer: "Social media marketing is crucial for building brand awareness, driving website traffic, and fostering customer relationships. It helps you reach a broader audience, engage with customers, and promote your products or services effectively."
        },
        {
            question: "Why is Social Media Advertising Important?",
            answer: "Social media advertising is essential for reaching targeted audiences quickly and efficiently. It allows you to promote your brand to specific demographics, increase conversions, and achieve measurable results with precise targeting and analytics."
        },
        {
            question: "What are the top 5 Benefits of Social Media Advertising?",
            title1: "Increased Brand Awareness:",
            answer1: "Boost your brand's visibility by reaching a large and targeted audience.",
            title2: "Higher Engagement:",
            answer2: "Drive more interactions with your content through likes, shares, comments, and clicks.",
            title3: "Cost-Effective:",
            answer3: "Achieve significant results with relatively low advertising costs compared to traditional media.",
            title4: "Precise Targeting:",
            answer4: "Use advanced targeting options to reach specific demographics and interests.",
            title5: "Measurable Results:",
            answer5: "Track and analyze campaign performance to optimize strategies and improve ROI.",
            cname: "min-h180"
        },
        {
            question: "How Does Social Media Advertising Work?",
            answer: "Social media advertising works by creating targeted ad campaigns on platforms like Facebook, Instagram, Twitter, and LinkedIn. These campaigns use data and algorithms to display your ads to users who are most likely to be interested in your products or services, driving traffic and conversions."
        },
        {
            question: "What are the Benefits of Social Media Marketing Services?",
            answer: "Social media marketing services help you build a strong online presence, engage with your audience, and drive organic growth. They provide expertise in content creation, strategy development, and performance analysis to ensure your social media efforts are effective and aligned with your business goals."
        },
        {
            question: "What are the Benefits of Social Media Advertising Services?",
            answer: "Social media advertising services offer targeted and efficient ways to promote your brand. They provide expert management of ad campaigns, ensuring your ads reach the right audience, achieve high engagement, and deliver measurable results, ultimately boosting your brand's visibility and ROI."
        },
    ]

    const stickyCardData = [
        {
            title: "Social Media Strategy & Planning",
            sections: [
                {
                    content: "Our team collaborates with you to create a tailored social media strategy that aligns with your business goals. We identify key objectives, target audiences, and the most effective platforms to ensure your social media efforts yield maximum impact."
                },
            ]
        },
        {
            title: "Social Media Profile Setup and Optimization",
            sections: [
                {
                    content: "We help you establish a professional and cohesive presence across all relevant social media platforms. Our optimization services ensure your profiles are complete, visually appealing, and optimized for search engines."
                },
            ]
        },
        {
            title: "Social Media Content Creation and Curation",
            sections: [
                {
                    content: "Content is king, and our team excels at creating engaging, high-quality content that resonates with your audience. We also curate relevant content from trusted sources to keep your profiles active and informative."
                },
            ]
        },
        {
            title: "Social Media Content Calendar Management",
            sections: [
                {
                    content: "Consistency is key to social media success. We develop and manage a detailed content calendar that ensures a steady stream of posts, keeping your audience engaged and your brand top-of-mind."
                },
            ]
        },
        {
            title: "Social Media Audience Engagement",
            sections: [
                {
                    content: "Building a loyal following requires more than just posting content. We actively engage with your audience by responding to comments, messages, and mentions, fostering a sense of community around your brand."
                },
            ]
        },
        {
            title: "Social Media Contests and Campaigns",
            sections: [
                {
                    content: "Contests and campaigns are powerful tools for boosting engagement and expanding your reach. We design and manage creative social media campaigns that captivate your audience and drive participation."
                },
            ]
        },
        {
            title: "Social Media Community Management",
            sections: [
                {
                    content: "A thriving community can be a brand’s biggest advocate. We monitor and manage your social media communities, ensuring a positive and vibrant space for your audience to connect and engage."
                },
            ]
        },
        {
            title: "Social Listening",
            sections: [
                {
                    content: "Understanding what your audience is saying about your brand is crucial. Our social listening services track and analyze conversations, providing valuable insights to inform your strategy and improve your offerings."
                },
            ]
        },
        {
            title: "Social Media Analytics and Reporting",
            sections: [
                {
                    content: "Data-driven decisions lead to better results. We provide comprehensive analytics and reporting, tracking key metrics and offering insights to continuously refine your social media strategy."
                },
            ]
        },
    ]

    const cardData = [
        {
            src: graph,
            alt: 'Social Media Ad Strategy & Planning',
            title: 'Social Media Ad Strategy & Planning',
            text: 'We develop strategic ad campaigns tailored to your business goals, ensuring that every dollar spent contributes to achieving your desired outcomes. Our plans are based on thorough market research and audience analysis.'
        },
        {
            src: graph,
            alt: 'Social Media Ad Setup & Management',
            title: 'Social Media Ad Setup & Management',
            text: 'From ad creation to launch, our team handles every aspect of your social media advertising. We ensure your ads are set up correctly, targeting the right audience with the right message for optimal performance.'
        },
        {
            src: graph,
            alt: 'Social Media Ads Conversion Tracking Setup',
            title: 'Social Media Ads Conversion Tracking Setup',
            text: 'Measuring the success of your ads is crucial. We implement conversion tracking to monitor and analyze the performance of your campaigns, providing valuable data to inform future strategies.'
        },
        {
            src: graph,
            alt: 'Social Media Ads A/B Testing',
            title: 'Social Media Ads A/B Testing',
            text: 'We conduct A/B testing to identify the most effective ad variations. By experimenting with different elements such as headlines, images, and calls to action, we refine your campaigns for the best possible results.'
        },
        {
            src: graph,
            alt: 'Social Media Ads Content Calendar Management',
            title: 'Social Media Ads Content Calendar Management',
            text: 'Our team creates and manages a content calendar for your paid campaigns, ensuring timely and consistent ad delivery that keeps your audience engaged and drives conversions.'
        },
        {
            src: graph,
            alt: 'Facebook Advertising',
            title: 'Facebook Advertising',
            text: 'Leverage the power of Facebook’s vast user base with our expert advertising services. We create targeted ad campaigns that reach your ideal audience and drive meaningful engagement and conversions.'
        },
        {
            src: graph,
            alt: 'X (Twitter) Advertising',
            title: 'X (Twitter) Advertising',
            text: 'Tap into the real-time conversations happening on Twitter. Our advertising services on X (formerly Twitter) are designed to boost your brand’s visibility and connect with users in impactful ways.'
        },
        {
            src: graph,
            alt: 'LinkedIn Advertising',
            title: 'LinkedIn Advertising',
            text: 'Reach decision-makers and professionals with our LinkedIn advertising services. We craft campaigns that resonate with a professional audience, driving leads and business growth.'
        },
        {
            src: graph,
            alt: 'YouTube Advertising',
            title: 'YouTube Advertising',
            text: 'Harness the power of video with our YouTube advertising services. We create compelling video ads that capture attention and drive action, expanding your brand’s reach on the world’s largest video platform.'
        },
        {
            src: graph,
            alt: 'Instagram Advertising',
            title: 'Instagram Advertising',
            text: 'Engage with a highly visual audience on Instagram. Our advertising services include creating visually appealing ads that tell your brand’s story and drive engagement and conversions.'
        },
        {
            src: graph,
            alt: 'Pinterest Advertising',
            title: 'Pinterest Advertising',
            text: 'Inspire and convert with Pinterest advertising. We develop ads that resonate with Pinterest’s creative and discovery-driven users, driving traffic and sales for your business.'
        },
        {
            src: graph,
            alt: 'WhatsApp Advertising',
            title: 'WhatsApp Advertising',
            text: 'We create personalized ad campaigns that engage your audience, deliver timely offers, and foster meaningful customer interactions, driving higher conversions and brand loyalty.'
        },
        {
            src: graph,
            alt: 'TikTok Advertising',
            title: 'TikTok Advertising',
            text: 'Reach a younger, dynamic audience with our TikTok advertising services. We create engaging and innovative ads that capture the spirit of TikTok, driving brand awareness and engagement.'
        },
        {
            src: graph,
            alt: 'Snapchat Advertising',
            title: 'Snapchat Advertising',
            text: 'Connect with a highly engaged audience on Snapchat. Our advertising services are designed to create immersive ad experiences that resonate with Snapchat users and drive results.'
        },
        {
            src: graph,
            alt: 'Social Media Ads Analytics and Reporting',
            title: 'Social Media Ads Analytics and Reporting',
            text: 'We provide detailed analytics and reporting for your social media ad campaigns. Our insights help you understand performance, optimize strategies, and achieve better results over time.'
        },
    ]

    const industries = [
        { name: 'Agriculture' }, { name: 'Automobiles' }, { link: '/b2b-marketing-agency', name: 'B2B' }, { link: '/b2c-marketing-agency', name: 'B2C' }, { name: 'Banking' }, { name: 'Crowd Funding' }, { name: 'Education' },
        { name: 'Electric Vehicle' }, { name: 'Electronics' }, { name: 'Energy' }, { name: 'FMCG' }, { name: 'FinTech' }, { name: 'Gaming' }, { name: 'Healthcare' },
        { name: 'Hospitality' }, { name: 'Information Technology' }, { name: 'Infrastructure' }, { name: 'Insurance' }, { name: 'Lifestyle' }, { name: 'Manufacturing' },
        { name: 'Media & Entertienment' }, { name: 'Oil & Gas' }, { name: 'Real Estate' }, { name: 'Retail' }, { link: '/saas-marketing-agency', name: 'SaaS' }, { name: 'Sports' },
        { name: 'Telecommunications' }, { name: 'Transportation & Logistics' }, { name: 'Travel & Tourism' }, { link: '/web3-marketing-services', name: 'Web3' },
    ]

    const slideData = [
        {
            title: 'Customized Strategies',
            content: `We develop tailored social media strategies that align with your specific business goals, ensuring maximum impact and ROI.`,
        },
        {
            title: 'Comprehensive Services',
            content: `From organic content creation to paid advertising, we offer a full suite of social media services to meet all your marketing needs.`,
        },
        {
            title: 'Data-Driven Approach',
            content: `Our campaigns are driven by data and analytics, allowing us to continually optimize and improve your social media performance.`,
        },
        {
            title: 'Creative Excellence',
            content: `Our team of creative experts crafts compelling content and eye-catching visuals that capture your audience's attention and drive interaction.`,
        },
        {
            title: 'Audience Engagement',
            content: `We prioritize building genuine connections with your audience through active engagement, fostering a loyal and active community around your brand.`,
        },
        {
            title: 'Transparent Reporting',
            content: `We provide detailed analytics and reports, keeping you informed about your campaign's performance and the return on your investment.`,
        },
        {
            title: 'Industry Expertise',
            content: `Our deep understanding of various industries allows us to create effective and compliant social media strategies that resonate with your target audience.`,
        },
        {
            title: 'Innovative Solutions',
            content: `We stay ahead of the curve by incorporating the latest trends and technologies into our social media strategies, ensuring your brand remains relevant and competitive.`,
        },
        {
            title: 'Expertise and Experience',
            content: `With years of experience in social media marketing, XDMinds has a proven track record of creating successful campaigns that drive engagement and conversions.`,
        },
        {
            title: 'Dedicated Support',
            content: `Our client-focused approach means you have a dedicated team of social media experts supporting you every step of the way, ensuring your success.`,
        },
    ]

    const faqData = [
        {
            question: "What services does XDMinds offer in social media marketing?",
            answer: "XDMinds provides a comprehensive range of services, including organic social media strategy, content creation, audience engagement, paid social media advertising, and detailed analytics and reporting to maximize your ROI."
        },
        {
            question: "How does XDMinds ensure a high ROI for social media campaigns?",
            answer: "We use data-driven strategies, continuous optimization, and targeted advertising to ensure our social media campaigns deliver maximum return on investment. Our approach involves regular monitoring and adjusting based on performance metrics."
        },
        {
            question: "Can XDMinds handle social media advertising across multiple platforms?",
            answer: "Yes, XDMinds manages advertising campaigns on various platforms, including Facebook, Instagram, LinkedIn, Twitter, YouTube, Pinterest, TikTok, and Snapchat, tailoring strategies to each platform's unique audience and capabilities."
        },
        {
            question: "What makes XDMinds different from other social media agencies?",
            answer: "Our customized strategies, industry-specific expertise, creative excellence, and commitment to data-driven results set us apart. We focus on delivering personalized and effective social media solutions that align with your business goals."
        },
        {
            question: "How does XDMinds develop a social media strategy for my business?",
            answer: "We start with a thorough analysis of your business goals, target audience, and current social media presence. Based on this, we create a tailored strategy that includes content planning, audience engagement tactics, and paid advertising plans."
        },
        {
            question: "What kind of businesses does XDMinds work with?",
            answer: "XDMinds works with a diverse range of industries, including B2B, B2C, Web3, retail, healthcare, SaaS, technology, education, finance, real estate, hospitality, automotive, entertainment, nonprofits, and more. We tailor our services to meet the specific needs of each industry."
        },
        {
            question: "How does XDMinds measure the success of social media campaigns?",
            answer: "We use comprehensive analytics and reporting tools to track key performance indicators such as engagement rates, click-through rates, conversions, and ROI. Regular reports keep you informed about the campaign's progress and success."
        },
        {
            question: "Can XDMinds help with social media community management?",
            answer: "Yes, our social media community management services include monitoring and engaging with your audience, fostering a positive and active community, and responding to comments and messages to build strong customer relationships."
        },
        {
            question: "Does XDMinds offer content creation services for social media?",
            answer: "Absolutely. Our team of creative experts produces high-quality, engaging content tailored to your brand and audience, including posts, images, videos, and stories that resonate and drive engagement."
        },
        {
            question: "How can I get started with XDMinds for my social media needs?",
            answer: "To get started, simply contact us through our website or give us a call. We'll schedule a consultation to understand your needs and develop a customized social media strategy to help achieve your business goals."
        },
    ]
    return (
        <div className='digital-marketing smm'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Full-Suit <span className='s-text-3'>Social Media</span> <br className='break' /> Marketing Agency
                </h1>
                <p className='p-text-1'>
                    XDMinds is a full-suit social media agency specializing in both organic and paid social media advertising. Our holistic approach ensures that your brand leverages the full potential of social media platforms to engage with your audience effectively. Our organic social media strategies focus on creating authentic content that resonates with your followers and fosters meaningful interactions.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Social Media Marketing vs. Advertising: Key Differences
                </h2>
                <TopFaq topFaqData={topFaqData} />
            </section>
            <section className='digital-marketing-section-3'>
                <h2 className='h-text-3'>
                    Top-Rated Social Media Advertising Agency: <br className='break' /> Growth, Engagement, and ROI
                </h2>
                <div className='custom-div'>
                    <p className='p-text-3'>
                        At XDMinds, we pride ourselves on being a top-rated social media advertising agency that delivers exceptional growth, engagement, and return on investment (ROI) for our clients. Our team of experts understands the ever-evolving landscape of social media and leverages advanced techniques to craft impactful advertising campaigns. We focus on creating compelling ad content, precise targeting, and continuous optimization to ensure your brand reaches the right audience and drives meaningful interactions. With XDMinds, you can expect data-driven strategies that enhance your brand's visibility and generate measurable results, fostering long-term business success.
                    </p>
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Boost Your Online Presence with XDMinds' Expert <br className='break' /> Organic Social Media Marketing Services
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we understand that a robust social media presence is crucial for any business aiming to thrive in today’s digital landscape. Our expert organic social media marketing services are designed to enhance your brand visibility, engage your audience, and drive meaningful interactions without relying on paid promotions.
                </p>
                <div className='event-card-div'>
                    <StickyStackingCards stickyCardData={stickyCardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    XDMinds: Precision Paid Social Media Advertising <br className='break' /> Services for Maximum ROI
                </h2>
                <p className='p-text-2'>
                    XDMinds' precision-focused paid social media advertising services can help you realize the full potential of your social media advertising. Our strategies are designed to deliver maximum return on investment (ROI), helping you reach and convert your target audience efficiently and effectively.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Maximizing Social Media ROI: Tailored Organic and <br className='break' /> Paid Advertising Services For Industries
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we recognize that each industry has unique needs and challenges when it comes to social media marketing. Our industry-specific organic and paid social media services are tailored to address these nuances, ensuring that your campaigns are effective, targeted, and deliver maximum ROI.
                </p>
                <div className='industry-div'>
                    {industries.map((industry, index) => (
                        industry.link ? (
                            <Link reloadDocument className='industry-btn c-pointer' to={industry.link} key={index}>
                                {industry.name}
                            </Link>
                        ) : (
                            <div className='industry-btn c-default' key={index}>
                                {industry.name}
                            </div>
                        )
                    ))}
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Top Reasons to Choose XDMinds as Your <br className='break' /> Social Media Agency
                </h2>
                <div className='d-swiper-div'>
                    <DSwiper name='d-bg' slideData={slideData} />
                </div>
            </section>
            <section className=''>
                <Latest modifiedPath='Latest Social Media Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default SocialMediaMarketing
import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import GridCard from './GridCard';
import graph from '../../assets/images/graph.svg';
import Tabs from './Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import CustomFaqSection from './CustomFaqSection';
import BreadCrumbs from '../../Components/BreadCrumbs';


const InfluencerMarketing = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const cardData = [
        {
            src: graph,
            alt: 'Mega Influencers Marketing',
            title: 'Mega Influencers Marketing',
            text: 'Harness the power of mega-influencers with vast followings to amplify your brand’s message and reach a broad audience. Our strategic partnerships ensure maximum impact and visibility for your campaigns.'
        },
        {
            src: graph,
            alt: 'Macro Influencer Marketing',
            title: 'Macro Influencer Marketing',
            text: 'Engage with macro-influencers who have substantial reach and influence in specific niches. We create targeted campaigns that drive high engagement and align with your brand’s goals and audience.'
        },
        {
            src: graph,
            alt: 'Micro Influencers Marketing',
            title: 'Micro Influencers Marketing',
            text: 'Leverage the authentic connections of micro-influencers to build trust and drive engagement with niche audiences. Our approach ensures personalized and effective campaign execution.'
        },
        {
            src: graph,
            alt: 'Nano Influencers Marketing',
            title: 'Nano Influencers Marketing',
            text: 'Leverage the authentic connections of micro-influencers to build trust and drive engagement with niche audiences. Our approach ensures personalized and effective campaign execution.'
        },
        {
            src: graph,
            alt: 'Web3 Influencer Marketing',
            title: 'Web3 Influencer Marketing',
            text: 'Navigate the evolving Web3 landscape with influencer marketing strategies tailored to decentralized platforms. We connect your brand with influencers who excel in Web3 spaces to boost engagement.'
        },
        {
            src: graph,
            alt: 'Metaverse Influencer Marketing',
            title: 'Metaverse Influencer Marketing',
            text: 'Engage users in virtual worlds with metaverse influencer marketing. Our strategies target influential figures in digital realms to enhance brand presence and foster interactive experiences.'
        },
        {
            src: graph,
            alt: 'NFT Influencer Marketing',
            title: 'NFT Influencer Marketing',
            text: 'Promote your NFTs effectively by collaborating with influencers who specialize in digital assets. We craft campaigns that highlight your NFT offerings and reach collectors and enthusiasts.'
        },
        {
            src: graph,
            alt: 'Blockchain Influencer Marketing',
            title: 'Blockchain Influencer Marketing',
            text: 'Leverage blockchain influencers to build credibility and awareness for your blockchain projects. Our targeted approach ensures that your message resonates within the blockchain community.'
        },
        {
            src: graph,
            alt: 'Crypto Influencer Marketing',
            title: 'Crypto Influencer Marketing',
            text: 'Connect with crypto enthusiasts through influencers who have a strong presence in the cryptocurrency space. Our campaigns drive awareness and trust for your crypto-related initiatives.'
        },
        {
            src: graph,
            alt: 'Game Influencer Marketing',
            title: 'Game Influencer Marketing',
            text: 'Boost your game’s visibility by partnering with influencers in the gaming industry.We design campaigns that engage gamers and generate buzz around your game’s launch and updates.'
        },
        {
            src: graph,
            alt: 'YouTube Influencer Marketing',
            title: 'YouTube Influencer Marketing',
            text: 'Harness the power of YouTube influencers to create engaging video content that drives brand awareness and viewer interaction. Our strategies ensure your brand gets noticed on this dynamic platform.'
        },
        {
            src: graph,
            alt: 'Instagram Influencer Marketing',
            title: 'Instagram Influencer Marketing',
            text: 'Utilize Instagram influencers to showcase your brand through captivating visuals and stories. Our approach maximizes engagement and brand exposure on this popular social media platform.'
        },
        {
            src: graph,
            alt: 'LinkedIn Influencer Marketing',
            title: 'LinkedIn Influencer Marketing',
            text: 'Leverage LinkedIn influencers to enhance your brand’s professional presence. We craft strategies that position your brand as a thought leader and connect with key industry professionals.'
        },
        {
            src: graph,
            alt: 'Facebook Influencer Marketing',
            title: 'Facebook Influencer Marketing',
            text: 'Engage with a diverse audience through Facebook influencers. Our campaigns utilize the platform’s reach and targeting capabilities to drive meaningful interactions and brand awareness.'
        },
        {
            src: graph,
            alt: 'X (Twitter) Influencer Marketing',
            title: 'X (Twitter) Influencer Marketing',
            text: 'Tap into the real-time conversations on X (formerly Twitter) with influencers who can amplify your brand’s message. Our strategies ensure that your content stands out in the fast-paced Twitter environment.'
        },
        {
            src: graph,
            alt: 'TikTok Influencer Marketing',
            title: 'TikTok Influencer Marketing',
            text: 'Reach a younger, trend-focused audience with TikTok influencers. Our campaigns leverage creative content and viral trends to drive engagement and brand recognition on this dynamic platform.'
        },
        {
            src: graph,
            alt: 'Podcast Influencer Marketing',
            title: 'Podcast Influencer Marketing',
            text: 'Promote your brand through influential podcast hosts who can deliver targeted messages to engaged listeners. We develop campaigns that integrate seamlessly into popular podcast content.'
        },
        {
            src: graph,
            alt: 'Celebrity Influencer Marketing',
            title: 'Celebrity Influencer Marketing',
            text: 'Partner with celebrities to boost your brand’s visibility and credibility. Our strategies leverage high-profile endorsements to create a strong impact and attract a broad audience.'
        },
        {
            src: graph,
            alt: 'Regional Influencer Marketing',
            title: 'Regional Influencer Marketing',
            text: 'Target specific geographic areas with regional influencers who understand local nuances. Our campaigns drive localized engagement and enhance your brand’s presence in key markets.'
        },
        {
            src: graph,
            alt: 'International Influencer Marketing',
            title: 'International Influencer Marketing',
            text: 'Expand your global reach with international influencers who connect with audiences across different countries. We create strategies that cater to diverse markets and cultures for global impact.'
        },
        {
            src: graph,
            alt: 'Blog Influencer Marketing',
            title: 'Blog Influencer Marketing',
            text: 'Collaborate with influential bloggers to create compelling content that drives traffic and engagement. Our approach ensures your brand is featured in relevant and high-traffic blogs.'
        },
        {
            src: graph,
            alt: 'Meme Marketing',
            title: 'Meme Marketing',
            text: 'Utilize meme marketing to capture attention and engage audiences with humorous and relatable content. Our strategies leverage viral trends to enhance brand visibility and connection.'
        },
        {
            src: graph,
            alt: 'Brand Placement',
            title: 'Brand Placement',
            text: 'Integrate your brand seamlessly into content through strategic placements that enhance visibility and relevance. Our approach ensures that your brand is featured in contextually appropriate environments.'
        },
        {
            src: graph,
            alt: 'User-Generated Content (UGC)',
            title: 'User-Generated Content (UGC)',
            text: 'Encourage and leverage user-generated content to build authentic connections with your audience. Our strategies drive engagement and trust by showcasing real customer experiences and testimonials.'
        },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Strategic Influencer Selection',
            sections: [
                {
                    content: 'At XDMinds, we start by identifying the perfect influencers for your brand. Our process involves analyzing their audience demographics, engagement rates, and content relevance to ensure they align with your campaign goals. This strategic selection helps create authentic and impactful connections with your target audience.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Customized Campaign Development',
            sections: [
                {
                    content: 'We design tailored influencer marketing campaigns that cater specifically to your brand’s objectives and audience. By incorporating your unique brand voice and values, we craft creative strategies that resonate deeply with your audience, ensuring a memorable and effective campaign.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Data-Driven Insights',
            sections: [
                {
                    content: 'Our approach relies on comprehensive data analysis to drive campaign success. We utilize advanced analytics to track performance metrics such as engagement rates, reach, and conversions. These insights allow us to refine strategies in real-time and ensure that your campaign delivers optimal results.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Seamless Execution and Management',
            sections: [
                {
                    content: 'XDMinds takes pride in managing every aspect of your influencer marketing campaign with precision. From coordinating with influencers to overseeing content creation and ensuring timely execution, we handle all logistical details to ensure a smooth and successful campaign launch.'
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Transparent Reporting and Optimization',
            sections: [
                {
                    content: 'We believe in transparency and provide detailed reports on campaign performance, including key metrics and insights. Our team continuously monitors and optimizes the campaign based on real-time data, making necessary adjustments to maximize impact and achieve your marketing goals.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Proven Creative Excellence',
            sections: [
                {
                    content: 'Our creative team excels in producing compelling content that captures attention and drives engagement. By blending creativity with strategic goals, we develop content that not only aligns with your brand but also stands out in the influencer’s content stream, ensuring maximum visibility and impact.'
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Long-Term Partnership Focus',
            sections: [
                {
                    content: 'At XDMinds, we view influencer marketing as a long-term strategy rather than a one-off campaign. We focus on building lasting relationships with influencers and continuously nurturing these partnerships to create ongoing brand advocacy and sustained campaign success.'
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Comprehensive Industry Knowledge',
            sections: [
                {
                    content: 'At XDMinds, we view influencer marketing as a long-term strategy rather than a one-off campaign. We focus on building lasting relationships with influencers and continuously nurturing these partnerships to create ongoing brand advocacy and sustained campaign success.'
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Ethical and Authentic Practices',
            sections: [
                {
                    content: 'We are committed to maintaining the highest standards of ethical practices in influencer marketing. Our approach prioritizes authenticity and transparency, ensuring that all influencer collaborations are genuine and aligned with your brand’s values.'
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Dedicated Support and Collaboration',
            sections: [
                {
                    content: 'Our team offers dedicated support throughout the campaign process, collaborating closely with you to understand your needs and objectives. We work as an extension of your marketing team, providing expert advice, creative input, and proactive solutions to achieve your desired outcomes.'
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What makes XDMinds stand out as an influencer marketing agency?",
            answer: "XDMinds stands out due to our strategic approach, which includes meticulously selecting the right influencers, crafting customized campaigns, and leveraging data-driven insights to optimize results. Our commitment to transparency, creativity, and long-term partnership development ensures that your brand achieves impactful and sustainable growth."
        },
        {
            question: "How does XDMinds choose influencers for a campaign?",
            answer: "We use a detailed selection process to identify influencers who align with your brand's values and target audience. This includes analyzing their engagement metrics, audience demographics, content relevance, and past campaign performance to ensure a perfect match for your marketing goals."
        },
        {
            question: "What types of influencer marketing campaigns does XDMinds manage?",
            answer: "XDMinds manages a wide range of influencer marketing campaigns, including those focused on mega, macro, micro, and nano-influencers. We also specialize in niche areas such as Web3, metaverse, NFT, and crypto influencers, tailoring our approach to meet the specific needs of your brand."
        },
        {
            question: "How does XDMinds measure the success of influencer marketing campaigns?",
            answer: "We measure the success of influencer marketing campaigns through comprehensive analytics, tracking key metrics such as reach, engagement rates, conversions, and return on investment (ROI). Our transparent reporting provides detailed insights and performance data to help you understand the impact of your campaign."
        },
        {
            question: "What is the typical timeline for launching an influencer marketing campaign with XDMinds?",
            answer: "The timeline for launching an influencer marketing campaign with XDMinds varies depending on the complexity and scope of the campaign. Generally, the process involves influencer selection, campaign development, content creation, and execution, which can take anywhere from a few weeks to a couple of months. We work closely with you to ensure a timely and effective campaign launch."
        },
    ]

    const customFaqData = [
        {
            title: "The Power of Trust and Authenticity",
            content: "Consumers are increasingly looking to influencers for product recommendations and lifestyle inspiration. This is because influencers often share personal stories and experiences, making their endorsements feel more trustworthy and relatable. When an influencer genuinely believes in a product, their followers are more likely to trust their recommendation, which can significantly boost a brand's credibility and visibility."
        },
        {
            title: "Leveraging Authentic Connections",
            content: "Influencers have built strong, authentic relationships with their followers based on trust and credibility. When these influencers endorse your brand, their audience perceives the recommendation as genuine and trustworthy. This trust can significantly boost brand credibility and encourage potential customers to engage with your business."
        },
        {
            title: "Targeted Reach to Niche Audiences",
            content: "Influencers often cater to specific niches or communities, allowing brands to reach highly targeted audiences. By partnering with influencers who align with your industry or market, you can directly connect with individuals who are most likely to be interested in your products or services. This targeted approach ensures that your marketing efforts are focused and efficient."
        },
        {
            title: "Enhanced Engagement and Visibility",
            content: "Influencers have the ability to create engaging and shareable content that resonates with their audience. Their followers actively engage with their posts, providing a platform for your brand to gain visibility and interact with potential customers. The increased engagement can lead to higher brand recall and a more significant online presence."
        },
        {
            title: "Increased Content Diversity",
            content: "Influencer collaborations offer a variety of content formats, from blog posts and social media updates to videos and podcasts. This diversity allows your brand to be presented in different ways, appealing to various audience preferences and platforms. Fresh and varied content can keep your brand top-of-mind and attract a wider audience."
        },
        {
            title: "Cost-Effective Marketing Strategy",
            content: "Compared to traditional advertising methods, influencer marketing can be more cost-effective, especially when working with micro or nano-influencers. These influencers often offer high engagement rates at lower costs, providing excellent value for your marketing budget. Additionally, the potential for organic reach and user-generated content can further amplify your investment."
        },
        {
            title: "Accelerated Brand Growth and Loyalty",
            content: "Influencer marketing can drive rapid brand growth by reaching new audiences and generating buzz. Positive endorsements from respected figures can foster brand loyalty and encourage repeat business. By consistently engaging with influencers and their communities, you can build a loyal customer base that champions your brand."
        },
        {
            title: "Real-Time Feedback and Insights",
            content: "Collaborating with influencers provides valuable insights into your target audience’s preferences and behaviors. Influencers can offer real-time feedback on your products or services, helping you understand market trends and adjust your strategies accordingly. This feedback loop enables you to refine your offerings and improve customer satisfaction."
        },
        {
            title: "Boosted SEO and Online Presence",
            content: "Influencer marketing can enhance your brand’s online presence and contribute to improved search engine rankings. When influencers mention or link to your brand, it generates high-quality backlinks and social signals, which can positively impact your SEO efforts. Increased online visibility can drive more traffic to your website and improve overall digital performance."
        },
    ]
    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Top-Notch <br className='break' /> <span className='s-text-3'>Influencer</span> Marketing Agency
                </h1>
                <p className='p-text-1'>
                    XDMinds is recognized as a top influencer marketing agency, dedicated to driving exceptional brand growth through strategic influencer partnerships. Our expertise in connecting brands with the right influencers ensures enhanced visibility, engagement, and business success.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <seciton className='digital-marketing-section-2 bg-black'>
                <div className='custom-section'>
                    <div className='row m-0'>
                        <div className='col-lg-6 col-12 '>
                            <div className='custom-content-left'>
                                <h2 className='h-text-14'>
                                    Why <span className='s-text-2'>Influencer Marketing</span> is Crucial for Business Growth and Brand Awareness?
                                </h2>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 '>
                            <div className='custom-content-right'>
                                <p className='p-text-10'>
                                    Influencer marketing has become an essential strategy for driving business growth and enhancing brand awareness. Here’s why it is crucial:
                                </p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='mt-md-3 mt-lg-5'>
                                <CustomFaqSection customFaqData={customFaqData} />
                            </div>
                        </div>
                    </div>
                </div>
            </seciton>
            <section className='digital-marketing-section-2 l-bg'>
                <div className='contact-div'>
                    <h2 className='h-text-2'>
                        XDMinds: Top Influencer Marketing Agency for <br className='break' /> Brand Growth and Engagement
                    </h2>
                    <p className='p-text-2'>
                        XDMinds stands out as the leading influencer marketing agency, specializing in creating impactful brand growth and engagement strategies. We connect your brand with the most relevant influencers, crafting campaigns that resonate with your target audience and drive measurable results.
                    </p>
                    <div className='social-buttons'>
                        <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your influencer marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/influencer-marketing-agency' className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </a>
                        <a href='https://t.me/xd_minds' className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </a>
                        <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </a>
                    </div>
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Elevate Your Business with XDMinds' Comprehensive Influencer Marketing Services
                </h2>
                <p className='p-text-2'>
                    Unleash the power of influencer marketing with XDMinds' all-encompassing services. Our customized strategies and expert implementation guarantee that your brand connects with the ideal audience, driving substantial growth and enhanced engagement.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 l-bg'>
                <h2 className='h-text-2'>
                    XDMinds: Your Ideal Partner for High-Impact <br className='break' /> Influencer Marketing Campaigns
                </h2>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest Influencer Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default InfluencerMarketing
import React, { useState } from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BreadCrumbs from '../../Components/BreadCrumbs';
import graph from '../../assets/images/graph.svg';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import Latest from '../../Components/Home/Latest/Latest';
import FAQblockchain from '../../Components/Services/Blockchain/FAQblockchain';
import { Link } from 'react-router-dom';
import rightarrow from "../../assets/images/arrowright.svg"
import { BsArrowDownSquareFill } from "react-icons/bs";
import Web3GridCard from '../DigitalMarketing/Web3GridCard';
import { MdArrowCircleDown } from "react-icons/md";


const IDO = (props) => {
    const { countryCode } = props;

    const [openIndex, setOpenIndex] = useState(null);

    const handleClick1 = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const [isRotated, setIsRotated] = useState({
        one: false,
        two: false,
        three: false,
        four: false,
        five: false
    });


    const handleClick = (item) => {
        setIsRotated(prevState => ({
            ...prevState,
            [item]: !prevState[item]
        }));
    };


    const [modalShow, setModalShow] = React.useState(false);

    const web3CardData = [
        {
            src: graph,
            alt: 'IDO GTM Strategy',
            title: 'IDO GTM Strategy',
            text: "We develop a customized campaign strategy that outlines clear objectives, target audience, and key performance indicators to ensure your IDO’s success."
        },
        {
            src: graph,
            alt: 'IDO Market Research',
            title: 'IDO Market Research',
            text: " Our team conducts thorough market research to identify trends, opportunities, and competitive insights that inform your marketing strategy."
        },
        {
            src: graph,
            alt: 'Brand Development',
            title: 'Brand Development',
            text: "We help you create a strong brand identity that resonates with your target audience and positions your IDO project as a leader in the market."
        },
        {
            src: graph,
            alt: 'Pre & Post IDO Marketing',
            title: 'Pre & Post IDO Marketing',
            text: "Our marketing efforts extend beyond the token sale, ensuring sustained interest and engagement through pre-IDO buzz and post-IDO support."
        },
        {
            src: graph,
            alt: 'IDO Tokenomics',
            title: 'IDO Tokenomics',
            text: "We provide expert guidance on tokenomics and incentive structures to maximize investor appeal and project sustainability."
        },
        {
            src: graph,
            alt: 'Website Development',
            title: 'Website Development',
            text: "We create a professional, user-friendly website that effectively communicates your IDO project’s vision and facilitates investor participation."
        },
        {
            src: graph,
            alt: 'Whitepaper/Litepaper',
            title: 'Whitepaper/Litepaper',
            text: "Our team crafts comprehensive and compelling whitepapers and litepapers that provide detailed information about your IDO project."
        },
        {
            src: graph,
            alt: 'Social Media Campaigns',
            title: 'Social Media Campaigns',
            text: "We leverage social media platforms to build a strong online presence, engage with your audience, and drive traffic to your IDO."
        },
        {
            src: graph,
            alt: 'Content Marketing',
            title: 'Content Marketing',
            text: "Our content marketing strategies include blog posts, articles, and multimedia content that educate and inform potential investors."
        },
        {
            src: graph,
            alt: 'Community Building',
            title: 'Community Building',
            text: "We focus on building and managing a vibrant community around your project, fostering loyalty and ongoing engagement."
        },

        {
            src: graph,
            alt: 'Email Marketing',
            title: 'Email Marketing',
            text: "We design effective email campaigns that keep players informed and engaged, driving ongoing interaction with your game."
        },
        {
            src: graph,
            alt: 'Public Relations (PR)',
            title: 'Public Relations (PR)',
            text: "We craft and distribute press releases to major media outlets, ensuring your IDO project gains the attention and coverage it deserves."
        },
        {
            src: graph,
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: " We collaborate with key influencers in the crypto space to amplify your IDO project reach and credibility."
        },
        {
            src: graph,
            alt: 'IDO SEO',
            title: 'IDO SEO',
            text: " Our SEO strategies improve your online visibility, driving organic traffic to your IDO website."
        },
        {
            src: graph,
            alt: 'Paid Advertising',
            title: 'Paid Advertising',
            text: "We design and implement targeted paid advertising campaigns to reach a wider audience and attract more investors to your IDO project."
        },
        {
            src: graph,
            alt: 'Airdrop & Bounty Campaigns',
            title: 'Airdrop & Bounty Campaigns',
            text: "Our airdrop and bounty campaigns incentivize participation and spread awareness of your IDO project."
        },
        {
            src: graph,
            alt: 'Event Promotion',
            title: 'Event Promotion',
            text: "We organize and promote events that showcase your project, providing opportunities for networking and investor engagement."
        },
        {
            src: graph,
            alt: 'Omnichannel Marketing',
            title: 'Omnichannel Marketing',
            text: " Our omnichannel approach ensures a consistent and cohesive marketing message across all platforms and channels."
        },
        {
            src: graph,
            alt: 'Analytics and Reporting',
            title: 'Analytics and Reporting',
            text: " We provide detailed analytics and reporting to track the performance of your marketing efforts and optimize strategies"
        },
        {
            src: graph,
            alt: 'IDO Marketing Consulting',
            title: 'IDO Marketing Consulting',
            text: "Our IDO consulting services offer expert advice and insights to guide your marketing strategy and decision-making process."
        },

    ];
    const cardData = [
        {
            id: 1,
            title: 'Customized Strategies Tailored to Your Project',
            description: "At XDMinds, we understand that every IDO project is unique. We go beyond generic approaches by developing customized marketing strategies that align with your project's specific goals, target audience, and market dynamics. Our team invests time in understanding your vision, ensuring that our strategies are finely tuned to meet your individual needs and achieve your desired outcomes."
        },
        {
            id: 2,
            title: 'Innovative and Cutting-Edge Techniques',
            description: "We pride ourselves on staying ahead of industry trends and leveraging innovative marketing techniques. Our approach incorporates the latest tools and technologies to create impactful campaigns that resonate with modern investors. From utilizing advanced data analytics to harnessing emerging digital platforms, we ensure that your IDO benefits from the most effective and forward-thinking marketing solutions."
        },
        {
            id: 3,
            title: 'Deep Industry Knowledge and Expertise',
            description: "Our team at XDMinds consists of seasoned professionals with extensive experience in the blockchain and cryptocurrency space. This deep industry knowledge allows us to navigate the complexities of IDO marketing with a nuanced understanding of market trends, investor behavior, and regulatory considerations. Our expertise ensures that your project is positioned for success in a competitive landscape."
        },
        {
            id: 4,
            title: 'Focus on Building Genuine Engagement',
            description: "We believe in fostering authentic relationships between your project and its audience. Our marketing approach emphasizes genuine engagement rather than just superficial interactions. By focusing on building a strong and loyal community, we help create lasting connections that drive sustained interest and participation in your IDO."
        },
        {
            id: 5,
            title: 'Transparent Communication and Collaboration',
            description: "Transparency and collaboration are at the heart of our approach. We maintain open lines of communication throughout the campaign, keeping you informed and involved in every step of the process. This collaborative approach ensures that you are always aligned with our strategies and can provide valuable input, making the marketing efforts more effective and responsive to your needs."
        },
        {
            id: 6,
            title: 'Commitment to Delivering Results',
            description: "Our commitment to delivering measurable results sets us apart. We focus on achieving tangible outcomes that contribute to the success of your IDO project. By setting clear objectives and utilizing performance metrics, we continuously evaluate and optimize our strategies to ensure that your project reaches its full potential."
        },
        {
            id: 7,
            title: 'Holistic Approach to Marketing',
            description: "Rather than focusing solely on individual tactics, we take a holistic approach to marketing your IDO. Our strategies integrate various elements, including branding, content creation, community management, and investor relations, to create a cohesive and comprehensive marketing campaign. This ensures that all aspects of your IDO are effectively covered and contribute to the overall success of the project."
        },


    ];
    const faqData = [
        {
            question: "How can XDMinds help us stand out in a crowded IDO market? ",
            answer: "XDMinds leverages a deep understanding of the IDO landscape and a creative approach to make your project stand out. We craft customized marketing strategies that highlight your unique selling points, engage your target audience, and build a strong brand presence. Through innovative campaigns and targeted outreach, we ensure your IDO captures attention and drives participation."
        },
        {
            question: "What strategies does XDMinds use to build and engage a community for our IDO?",
            answer: "Building and engaging a community is central to a successful IDO. XDMinds employs a multifaceted approach, including interactive social media campaigns, engaging content, and active participation in relevant forums and groups. Our goal is to foster a loyal and enthusiastic community that is not only informed about your project but also motivated to support and advocate for it."
        },
        {
            question: "How does XDMinds ensure our IDO reaches the right audience? ",
            answer: "We utilize a combination of market research and data analytics to identify and target the right audience for your IDO. By understanding the preferences and behaviors of potential investors, we tailor our marketing efforts to reach those most likely to be interested in your project. This targeted approach maximizes the effectiveness of our campaigns and enhances the overall success of your IDO."
        },
        {
            question: "What kind of content does XDMinds create for IDO promotion? ",
            answer: "XDMinds produces a range of high-quality content designed to engage and inform your audience. This includes compelling blog posts, detailed whitepapers, eye-catching graphics, and engaging videos. Our content is crafted to clearly communicate your project's value proposition, address potential investor questions, and generate excitement about your IDO."
        },
        {
            question: "How does XDMinds track and report the success of our IDO marketing efforts?",
            answer: "Tracking and reporting are crucial for optimizing marketing strategies. XDMinds provides detailed analytics on key performance indicators such as audience engagement, campaign reach, and conversion rates. We regularly review these metrics and share insights with you, allowing for data-driven adjustments and continuous improvement of your IDO marketing strategy."
        },




    ];



    return (
        <div className='ido'>
            <div className='digital-marketing'>
                <section className='digital-marketing-banner min-100vh'>
                    <BreadCrumbs />
                    <h1 className='h-text-1'>
                        Industry-Leading <br />
                        <span className='s-text-3'>IDO</span>
                        Marketing Agency

                    </h1>
                    <p className='p-text-1'>
                        In the fast-paced world of decentralized finance (DeFi), launching a successful Initial DEX Offering (IDO)
                        requires more than just a great idea. It demands strategic planning, targeted marketing, and expert execution. At XDMinds, we specialize in providing comprehensive IDO marketing services that elevate your token sale strategies and ensure effective promotion. Our tailored approach
                        and industry expertise make us the premier choice for your IDO marketing needs.
                    </p>
                    <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
                </section>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        What is IDO and How Does It Differ from ICO, STO, and IEO?
                    </h2>
                    <div className="accordion ido-content" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    onClick={() => handleClick('one')}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                >
                                    <h6 className='h-text-3'>Initial DEX Offering (IDO)</h6>
                                    <BsArrowDownSquareFill
                                        className={`icon-up ${isRotated.one ? 'rotate-icon' : 'rotate1-icon'}`}
                                    />
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p className='p-text-3'>
                                        Initial DEX Offering (IDO) is a fundraising method used by blockchain projects to raise capital through the issuance of tokens on a decentralized exchange (DEX). Unlike other fundraising mechanisms, IDOs provide immediate liquidity and access to trading for investors. Here’s a detailed look at how IDOs differ from Initial Coin Offerings (ICOs), Security Token Offerings (STOs), and Initial Exchange Offerings (IEOs):
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    onClick={() => handleClick('two')}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    <h6 className='h-text-3'>Initial Coin Offering (ICO)</h6>
                                    <BsArrowDownSquareFill
                                        className={`icon-up ${isRotated.two ? 'rotate-icon' : 'rotate1-icon'}`}
                                    />
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p className='p-text-3'>
                                        An ICO is a popular fundraising model where a project sells its tokens directly to investors, typically before the token is listed on any exchange. ICOs offer a way to raise funds quickly and often at a lower cost compared to traditional funding methods. However, they are less regulated, which can lead to increased risks and potential scams. ICOs rely heavily on the project's whitepaper and marketing efforts to attract investors, and liquidity is not guaranteed until the token is listed on an exchange.
                                    </p>
                                    <div className='d-flex justify-content-end'>
                                        <Link to="/ico-marketing-agency">
                                            <LazyLoadImage src={rightarrow} alt='rightarrow' className='right-arrow' />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    onClick={() => handleClick('three')}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    <h6 className='h-text-3'>Security Token Offering (STO)</h6>
                                    <BsArrowDownSquareFill
                                        className={`icon-up ${isRotated.three ? 'rotate-icon' : 'rotate1-icon'}`}
                                    />
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p className='p-text-3'>
                                        An STO is a more regulated form of fundraising where tokens issued represent ownership in an underlying asset, such as equity, bonds, or real estate. These tokens comply with regulatory standards and offer greater investor protection. STOs are designed to provide legal and financial transparency, making them a more secure option for both issuers and investors. Unlike ICOs, which are often unregulated, STOs adhere to strict regulatory frameworks, offering a higher degree of credibility and investor confidence.
                                    </p>
                                    <div className='d-flex justify-content-end'>
                                        <Link to="/sto-marketing-agency">
                                            <LazyLoadImage src={rightarrow} alt='rightarrow' className='right-arrow' />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    onClick={() => handleClick('four')}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                >
                                    <h6 className='h-text-3'>Initial Exchange Offering (IEO)</h6>
                                    <BsArrowDownSquareFill
                                        className={`icon-up ${isRotated.four ? 'rotate-icon' : 'rotate1-icon'}`}
                                    />
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p className='p-text-3'>
                                        An IEO is conducted on a centralized exchange that acts as an intermediary between the project and investors. In this model, the exchange performs due diligence on the project and manages the sale of tokens, which adds a layer of trust and security. IEOs benefit from the exchange’s existing user base and marketing resources, which can significantly enhance the project's visibility and credibility. However, IEOs often come with higher listing fees and the project’s success is closely tied to the reputation of the exchange.
                                    </p>
                                    <div className='d-flex justify-content-end'>
                                        <Link to="/ieo-marketing-agency">
                                            <LazyLoadImage src={rightarrow} alt='rightarrow' className='right-arrow' />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    onClick={() => handleClick('five')}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                >
                                    <h6 className='h-text-3'>Initial DEX Offering (IDO)</h6>
                                    <BsArrowDownSquareFill
                                        className={`icon-up ${isRotated.five ? 'rotate-icon' : 'rotate1-icon'}`}
                                    />
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p className='p-text-3'>
                                        An IDO stands out by utilizing decentralized exchanges (DEXs) for the token sale. This method allows for immediate liquidity and trading, providing investors with the opportunity to buy and sell tokens as soon as they are issued. IDOs are characterized by their decentralized nature, which means they do not rely on a central authority or intermediary. This can lead to more transparent and equitable access for a wider range of investors. IDOs also often feature automated market makers (AMMs) and liquidity pools, enhancing the trading experience and providing greater flexibility in terms of token liquidity.
                                    </p>
                                    <p className='p-text-3 mt-2'>
                                        In summary, while ICOs, STOs, and IEOs each offer distinct advantages and cater to different aspects of the fundraising process, IDOs leverage the benefits of decentralized exchanges to provide immediate liquidity and trading opportunities. This makes IDOs an attractive option for projects looking to capitalize on the growing trend of decentralized finance and offer a more open and transparent investment opportunity.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='digital-marketing-section-2 ido-section-2'>
                    <h2 className='h-text-2'>
                        Elevate Your IDO Campaign with a Comprehensive Full-Service IDO Marketing Agency for Token Launch Excellence
                    </h2>
                    <p className='p-text-2'>
                        At XDMinds, we understand that the success of your IDO campaign hinges on a well-executed marketing strategy.
                        As a comprehensive full-service IDO marketing agency, we specialize in delivering tailored solutions that elevate
                        your token launch to new heights. Our team of experts combines industry knowledge, creative thinking, and cutting-edge
                        technologies to ensure your IDO stands out in the competitive market. From initial strategy development to post-launch support,
                        XDMinds is committed to driving excellence at every stage of your IDO campaign.
                    </p>
                    <div className='social-buttons'>
                        <button className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </button>
                    </div>
                </section>
                <div className='digital-marketing'>
                    <section className='digital-marketing-section-2 '>
                        <h2 className='h-text-2'>
                            Comprehensive IDO Marketing Services by XDMinds: <br className='break' />Strategies to Elevate Your Initial DEX Offering (IDO) Projects
                        </h2>
                        <p className='p-text-2'>
                            XDMinds offers a complete suite of IDO marketing services designed to elevate your Initial DEX Offering projects.
                            Our strategies are tailored to meet the unique needs of each client,
                            ensuring maximum visibility, engagement, and success.
                        </p>
                        <div className='grid-cards'>
                            <Web3GridCard web3CardData={web3CardData} />
                        </div>
                    </section>
                </div>
            </div>


            <div className='about'>
                <section className='about-section-5'>
                    <h2 className='h-text-2'>
                        Achieve Unmatched Growth for Your Tap-To-Earn<br className='break' /> (T2E) Game with XDMinds' Comprehensive T2E Marketing Services
                    </h2>
                    <p className='p-text-2'>
                        Choose XDMinds as your preferred Tap-to-Earn (T2E) marketing agency for innovative strategies that
                        drive exceptional results.
                        Our tailored approach ensures your game stands out and thrives in a competitive market.
                    </p>
                    <div className='custom-div-5 mobile-hide '>
                        <div className='row g-3'>
                            {cardData.map((card) => (
                                <div key={card.id} className='col-lg-4 col-md-6 col-12'>
                                    <div className='card custom-card-5 h-100'>
                                        <div className='custom-card-head'>
                                            <h1 className='h-text-3'>{card.id}</h1>
                                            <h2 className='h-text-4'>{card.title}</h2>
                                        </div>
                                        <div className='card-body'>
                                            {card.description && <p className='p-text-4'>{card.description}</p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='custom-accordion desktop-hide tab-hide'>
                        <div className="accordion " id="accordionExample">
                            {cardData.map((item, index) => (
                                <div key={item.id} className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                            onClick={() => handleClick1(index)}
                                            type="button"
                                            aria-expanded={openIndex === index}
                                            aria-controls={item.id}
                                        >
                                            {item.title}
                                            <div className="arrow-icon-btn">
                                                <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id={item.id}
                                        className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p className='p-text-1'>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
            <section>
                <Latest modifiedPath='Latest IDO Marketing Insights' />
            </section>
            <section>
                <FAQblockchain faqData={faqData} />
            </section>


            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />

        </div>
    )
}

export default IDO

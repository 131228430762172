import React, { useState } from "react";
import './PeopleSaying.css'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';

const slides = [
  {
    id: 1,
    className: 'ps1',
    title: 'Exceptional Digital Marketing Expertise',
    text: 'XDMinds transformed our digital marketing strategy with their exceptional expertise and innovative approach. Their tailored campaigns and in-depth analysis significantly boosted our online presence and ROI. We highly recommend XDMinds for any business looking to excel in the digital space.',
    person: 'Alex., CTO of Crypto Startups'
  },
  {
    id: 2,
    className: 'ps2',
    title: 'Outstanding Results and Professionalism',
    text: 'Working with XDMinds has been a game-changer for us. Their professionalism and dedication to delivering outstanding results are truly commendable. The team\'s strategic insights and creative solutions have driven substantial growth for our brand. We couldn\'t be happier with the partnership.',
    person: 'Emily Johnson., COO of Healthcare Startups'
  },
  {
    id: 3,
    className: 'ps3',
    title: 'Impressive Growth in Online Engagement',
    text: 'XDMinds helped us achieve impressive growth in our online engagement and conversions. Their deep understanding of Web3 digital marketing and personalized approach set them apart. The team\'s commitment to our success has been evident in every campaign, and we look forward to continuing our collaboration.',
    person: 'Sarah Davis., Founder & CEO of Web3 Startups'
  },
  {
    id: 4,
    className: 'ps1',
    title: 'Innovative and Effective Strategies',
    text: 'The innovative and effective strategies implemented by XDMinds have significantly elevated our digital marketing efforts. Their expertise in the Web3 space is unmatched, and their team\'s responsiveness and creativity have been instrumental in our success. We highly recommend XDMinds for any company seeking top-notch digital marketing services.',
    person: 'David Wilson., Project Lead of Metaverse Startups'
  },
  {
    id: 5,
    className: 'ps1',
    title: 'Exceptional Support and Guidance',
    text: 'The support and guidance we received from XDMinds have been exceptional. Their team\'s deep knowledge of digital marketing and the Web3 landscape provided us with the tools and strategies needed to thrive in a competitive market. XDMinds\' commitment to excellence is truly impressive.',
    person: 'David M., CMO of FinTech Innovations'
  },
  {
    id: 6,
    className: 'ps1',
    title: 'Transformative Impact on Our Business',
    text: 'XDMinds has had a transformative impact on our business. Their customized digital marketing solutions and strategic insights have driven significant growth and enhanced our brand\'s visibility. The team\'s professionalism and dedication make them a trusted partner, and we are thrilled with the results they\'ve delivered.',
    person: 'Linda T., Head of Marketing at Smart Home Solutions'
  },
];


const PeopleSaying = () => {
  const [countriesswiper, setCountriesSwiper] = useState(null);

  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const handleMouseEnter = () => {
    if (countriesswiper !== null) {
      countriesswiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (countriesswiper !== null) {
      countriesswiper.autoplay.start();
    }
  };
  return (
    <div className='people-saying'>
      <Container>
        <Row>
          <Col>
            <div className="people-saying-content">
              <div className="ps-header">
                <p className="ps-content-head">What People Are Saying</p>
              </div>
              {/* <Container className="ps-inner-container"> */}

              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                loop={true}
                dots="true"
                onSwiper={(swiper) => setCountriesSwiper(swiper)}
                modules={[Scrollbar, Pagination, Autoplay]}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                className="ps-swiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
              >
                {slides.map((slide) => (
                  <SwiperSlide key={slide.id}>
                    <div className={slide.className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <p className="ps-head">{slide.title}</p>
                      <p>{slide.text}</p>
                      <div className="person-details">
                        <p className={`${slide.className}-admin`}>{slide.person}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* </Container> */}
              <div className="btn-case-navi">
                <div className="navi-div-ps">
                  <Button className="btn-navi-prev" onClick={goPrev}><FaArrowLeftLong /></Button>
                  <Button className="btn-navi-next" onClick={goNext}><FaArrowRightLong /></Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PeopleSaying
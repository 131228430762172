import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Section4 = () => {

    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'Go-To-Market Strategy',
            text: "XDMinds crafts tailored go-to-market strategies to ensure your metaverse project launches successfully and captures your target audience's attention.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Metaverse Branding',
            text: "Establish a strong, memorable brand presence in the metaverse with XDMinds' expert branding services, designed to resonate with virtual audiences.",
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'Metaverse SEO',
            text: "Optimize your metaverse content for search engines with XDMinds' SEO strategies, driving organic traffic and enhancing your online visibility.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Paid Advertising',
            text: "Leverage XDMinds' metaverse paid advertising services to reach your ideal audience with targeted ads that maximize your ROI.",
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Content Marketing',
            text: 'Engage and inform your audience with high-quality content created by XDMinds, tailored to the unique needs of the metaverse environment.',
        },
        {
            link: "/social-media-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Social Media Marketing',
            text: "Boost your metaverse presence on social media platforms with XDMinds' effective marketing strategies that drive engagement and growth.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'PR Marketing',
            text: "Enhance your brand's reputation and visibility in the metaverse with XDMinds' strategic PR campaigns, designed to generate buzz and media coverage.",
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Influencer Marketing',
            text: "Partner with key influencers in the metaverse to amplify your brand's reach and credibility through authentic endorsements by XDMinds.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Community Marketing',
            text: "Build and nurture a loyal community around your metaverse project with XDMinds' community marketing services, fostering engagement and advocacy.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Omnichannel Marketing',
            text: "Create a seamless marketing experience across multiple channels in the metaverse with XDMinds' omnichannel marketing strategies.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Old School Advertising',
            text: "Combine traditional advertising methods with modern metaverse marketing techniques for a comprehensive approach with XDMinds.",
        },
        {
            link: "/gaming-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Gaming Marketing',
            text: "Promote your metaverse gaming project with XDMinds' specialized gaming marketing services, designed to attract and retain players.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Metaverse App Marketing',
            text: "Drive downloads and engagement for your metaverse app with XDMinds' targeted app marketing strategies, ensuring maximum visibility.",
        },
        {
            link: "/social-media-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Social Media App Marketing',
            text: "Enhance your social media app's presence in the metaverse with XDMinds' expert marketing services, tailored to your audience.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Token Marketing',
            text: "Boost the visibility and adoption of your metaverse token with XDMinds' comprehensive token marketing strategies.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Marketplace Marketing',
            text: "Promote your metaverse marketplace effectively with XDMinds' marketing services, designed to attract buyers and sellers.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Metaverse Ecosystem Marketing',
            text: "Expand your metaverse ecosystem's reach with XDMinds' integrated marketing strategies, ensuring cohesive growth and engagement.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Real Estate Marketing',
            text: "Market your metaverse real estate projects successfully with XDMinds' tailored marketing services, attracting potential buyers and investors.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Enterprise Marketing',
            text: "Position your enterprise solutions effectively in the metaverse with XDMinds' strategic marketing services, designed for business success.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Land Marketing',
            text: "Promote your metaverse land offerings with XDMinds' expert marketing strategies, ensuring maximum exposure and interest.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Store Marketing',
            text: "Drive traffic and sales for your metaverse store with XDMinds' targeted marketing campaigns, designed to enhance visibility and engagement.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Virtual Events Marketing',
            text: "Maximize attendance and engagement for your metaverse virtual events with XDMinds' specialized marketing services.",
        },
        {
            link: "/industries",
            imgSrc: <FaChartLine />,
            title: 'Industries',
            text: "Tailor your metaverse marketing efforts to specific industries with XDMinds' customized strategies, ensuring targeted success.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Marketing Consulting',
            text: "Gain valuable insights and guidance for your metaverse marketing efforts with XDMinds' expert consulting services, tailored to your needs.",
        },
    ];
    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };

    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (
        <div className='metaverse-sec-4'>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h2>Experience Immersive Brand Growth with<span className='msec4-break'><br /></span>XDMinds' Metaverse Marketing Services</h2>
                        </div>
                        <div className='industries-sub-head'>
                            <p>
                                Our metaverse marketing services are designed to help your brand navigate and succeed in this dynamic digital space. From strategy creation to execution, we offer comprehensive solutions tailored to your unique needs.
                            </p>
                        </div>

                        <div className='desktop-industries-view'>
                            {chunkedCardData.map((chunk, index) => (
                                <div className='home-industries-card-div' key={index}>
                                    <Row>
                                        {chunk.map((card, cardIndex) => (
                                            <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                {card.link ? (
                                                    <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Link>
                                                ) : (
                                                    <div className='c-default h-100'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <div className='mobile-view-industries'>
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={3}
                                loop={true}
                                onSwiper={(swiper) => setCardSwiper(swiper)}
                                modules={[Scrollbar, Pagination, Autoplay]}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}
                                className="ps-swiper"
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    460: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 6,
                                        spaceBetween: 50,
                                    },
                                }}
                            >
                                {cardData.map((card, index) => (
                                    <SwiperSlide key={index}>
                                        {card.link ? (
                                            <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        ) : (
                                            <div className='c-default h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Section4
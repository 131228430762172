import React from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import graph from '../../assets/images/graph.svg';
import Tabs from '../DigitalMarketing/Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import Web3GridCard from '../DigitalMarketing/Web3GridCard';
import BottomFaq from '../DigitalMarketing/BottomFaq';


const Sto = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const web3CardData = [
        {
            src: graph,
            alt: 'Campaign Strategy and Planning',
            title: 'Campaign Strategy and Planning',
            text: 'We develop a robust campaign strategy and plan tailored to your STO goals, ensuring every aspect of the marketing effort is meticulously executed.'
        },
        {
            src: graph,
            alt: 'STO Brand Development',
            title: 'STO Brand Development',
            text: 'Our brand development services help establish a strong and memorable brand identity for your STO, creating a lasting impression on potential investors.'
        },
        {
            src: graph,
            alt: 'Pre-STO Promotion',
            title: 'Pre-STO Promotion',
            text: 'We create buzz and anticipation before the STO launch through targeted promotional activities, ensuring a strong start for your campaign.'
        },
        {
            src: graph,
            alt: 'Post-STO Promotion',
            title: 'Post-STO Promotion',
            text: 'Our post-STO promotion strategies maintain investor engagement and support ongoing project development and updates.'
        },
        {
            src: graph,
            alt: 'STO Tokenomics Consultation',
            title: 'STO Tokenomics Consultation',
            text: 'We provide expert consultation on tokenomics, helping you design a fair and attractive token distribution model.',
        },
        {
            src: graph,
            alt: 'STO Website Development',
            title: 'STO Website Development',
            text: 'Our team develops a professional and user-friendly website that effectively communicates your STO’s value proposition.',
        },
        {
            src: graph,
            alt: 'Whitepaper/Litepaper Creation',
            title: 'Whitepaper/Litepaper Creation',
            text: 'We create comprehensive and persuasive whitepapers/litepapers that detail your STO project and its potential benefits.',
        },
        {
            src: graph,
            alt: 'Social Media Campaigns',
            title: 'Social Media Campaigns',
            text: 'Our social media campaigns leverage various platforms to engage with your target audience and build a strong online presence.',
        },
        {
            src: graph,
            alt: 'Content Marketing',
            title: 'Content Marketing',
            text: 'We produce high-quality content that educates and informs potential investors, driving interest and engagement in your STO.',
        },
        {
            src: graph,
            alt: 'Community Building and Management',
            title: 'Community Building and Management',
            text: 'Our community building services foster a loyal and engaged investor base through platforms like Telegram and Discord.',
        },
        {
            src: graph,
            alt: 'Email Marketing Campaign',
            title: 'Email Marketing Campaign',
            text: 'We design and execute targeted email marketing campaigns to keep potential investors informed and engaged.',
        },
        {
            src: graph,
            alt: 'Press Releases and Media Coverage',
            title: 'Press Releases and Media Coverage',
            text: 'We generate media coverage and distribute press releases to enhance your STO’s visibility and credibility.',
        },
        {
            src: graph,
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: 'Our influencer marketing services leverage industry influencers to promote your STO and build trust with potential investors.',
        },
        {
            src: graph,
            alt: 'Search Engine Optimization',
            title: 'Search Engine Optimization',
            text: 'We optimize your online content to improve search engine rankings and drive organic traffic to your STO website.',
        },
        {
            src: graph,
            alt: 'Paid Advertising',
            title: 'Paid Advertising',
            text: 'Our targeted paid advertising campaigns reach a broader audience and attract potential investors to your STO.',
        },
        {
            src: graph,
            alt: 'Omnichannel Marketing',
            title: 'Omnichannel Marketing',
            text: 'We integrate multiple marketing channels to create a cohesive and effective marketing strategy for your STO.',
        },
        {
            src: graph,
            alt: 'STO Listing Services',
            title: 'STO Listing Services',
            text: 'We help list your security token on reputable exchanges and platforms, increasing its visibility and accessibility.',
        },
        {
            src: graph,
            alt: 'Airdrop Campaigns',
            title: 'Airdrop Campaigns',
            text: 'Our airdrop campaigns generate interest and attract potential investors by distributing free tokens.',
        },
        {
            src: graph,
            alt: 'Analytics and Reporting',
            title: 'Analytics and Reporting',
            text: 'We provide detailed analytics and reporting on the performance of your STO marketing campaigns, allowing for data-driven optimization.',
        },
        {
            src: graph,
            alt: 'STO Consulting',
            title: 'STO Consulting',
            text: 'Our consulting services offer expert advice and guidance throughout your STO journey, ensuring your project’s success.',
        },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Tailored Marketing Strategies',
            sections: [
                {
                    content: 'At XDMinds, we recognize that every STO project is unique, requiring a personalized approach. Our team meticulously develops tailored marketing strategies that align with your project’s specific goals and target audience.We analyze your project’s strengths and market position to craft a plan that maximizes visibility and engagement.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Expertise in Security Token Offerings',
            sections: [
                {
                    content: 'Our deep understanding of the intricacies of Security Token Offerings sets us apart. We are well-versed in the regulatory requirements, market dynamics, and investor expectations specific to STOs. This expertise allows us to navigate the complexities of the STO landscape effectively and provide strategic guidance throughout the campaign.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Comprehensive Pre- and Post-STO Support',
            sections: [
                {
                    content: 'XDMinds provides extensive support both before and after your STO launch. Our pre-STO efforts focus on building momentum, creating awareness, and generating investor interest through targeted campaigns and strategic outreach. Post-STO, we continue to engage with investors, maintain project visibility, and support ongoing marketing efforts to sustain interest and support future developments.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Advanced Digital Marketing Techniques',
            sections: [
                {
                    content: 'We employ cutting-edge digital marketing techniques to ensure your STO stands out in a crowded market. From leveraging advanced SEO strategies to executing data-driven ad campaigns, our methods are designed to drive high-quality traffic and attract potential investors. Our use of analytics and real-time optimization ensures that your marketing efforts are continuously refined for optimal results.'
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Strong Community Engagement',
            sections: [
                {
                    content: 'Building and nurturing a community around your STO is crucial for its success. XDMinds excels in community management, creating vibrant, engaged groups on platforms like Telegram and Discord. Our community-building strategies foster trust, encourage active participation, and create a supportive environment for your investors.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Innovative Content Creation',
            sections: [
                {
                    content: 'Effective communication is key to a successful STO. Our team of content creators develops compelling and informative materials that clearly articulate your project’s value proposition. From whitepapers and litepapers to blog posts and press releases, we craft content that captures investor interest and communicates your project’s unique selling points.'
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Personalized Consultation and Support',
            sections: [
                {
                    content: 'Our commitment to your STO project includes personalized consultation and support. We work closely with you to understand your needs, address concerns, and provide expert advice throughout the campaign. This collaborative approach ensures that your project receives the attention and expertise it deserves.'
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Efficient Project Management',
            sections: [
                {
                    content: 'XDMinds excels in efficient project management, ensuring that all aspects of your STO marketing campaign are executed smoothly and on schedule. Our structured approach and attention to detail help streamline the process, reducing potential challenges and ensuring timely delivery of results.'
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What makes XDMinds the best choice for marketing my STO project?",
            answer: "We specialize in crafting tailored strategies to ensure the success of your STO project. Our approach combines deep market insights, innovative tactics, and a proven track record of delivering results. By leveraging our expertise, we can help you effectively reach your target audience and achieve your project goals."
        },
        {
            question: "How can XDMinds help increase visibility for my STO offering?",
            answer: "We employ a comprehensive range of tactics designed to boost your offering's visibility. This includes targeted advertising, strategic partnerships, and engaging content that highlights your project’s unique value. Our goal is to ensure your STO stands out in a competitive market."
        },
        {
            question: "What strategies does XDMinds use to attract investors to my STO?",
            answer: "Our strategies are designed to capture the attention of potential investors by showcasing the strengths and benefits of your project. We use advanced targeting techniques, compelling narratives, and data-driven campaigns to engage and persuade investors to participate in your STO."
        },
        {
            question: "Can XDMinds assist with regulatory compliance for my STO marketing?",
            answer: "Yes, we understand the importance of adhering to regulatory requirements. Our team is knowledgeable about industry regulations and works closely with you to ensure that all marketing materials and strategies comply with relevant legal standards."
        },
        {
            question: "How does XDMinds measure the success of an STO marketing campaign?",
            answer: "We use a combination of metrics and analytics to evaluate the effectiveness of our campaigns. Key performance indicators include engagement rates, conversion rates, and overall reach. Regular reporting and analysis allow us to refine strategies and ensure that your campaign is achieving its objectives."
        },
        {
            question: "What kind of support can I expect from XDMinds throughout my STO campaign?",
            answer: "Our support is comprehensive and ongoing. From initial planning to execution and optimization, we provide continuous guidance and assistance. Our team is always available to address your questions, offer insights, and make adjustments to ensure the success of your campaign."
        },
        {
            question: "How does XDMinds tailor its marketing approach to fit my specific STO project?",
            answer: "We take a personalized approach to each project by thoroughly understanding your goals, target audience, and unique selling points. This allows us to develop and implement strategies that are specifically designed to meet the needs of your STO and maximize its impact."
        },
        {
            question: "What is the typical timeline for seeing results from your marketing efforts?",
            answer: "The timeline can vary depending on the complexity of your project and the scope of the marketing campaign. Generally, you can expect to start seeing initial results within a few weeks, with more substantial outcomes emerging as the campaign progresses. We focus on delivering measurable results as efficiently as possible."
        },
    ]
    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    The Premier <br className='break' /> <span className='s-text-3'>STO</span> Marketing Agency
                </h1>
                <p className='p-text-1'>
                    Welcome to XDMinds, the premier STO marketing agency specializing in comprehensive security token offering (STO) strategies. Our expertise in Web3 marketing services positions us as the best choice for driving the success of your STO project. With innovative approaches and data-driven tactics, we ensure your STO reaches the right audience and achieves your fundraising goals.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    XDMinds: Your Ultimate Partner in STO Marketing for <br className='break' /> Superior Security Token Offering Campaign Management
                </h2>
                <p className='p-text-2'>
                    XDMinds stands out as your ultimate partner in STO marketing, offering superior campaign management for your security token offering. Our dedicated team of experts combines deep industry knowledge with innovative marketing strategies to deliver exceptional results. We focus on creating tailored marketing plans that align with your project’s goals, ensuring a successful STO campaign from start to finish.
                    Our approach is comprehensive and client-focused, providing end-to-end solutions that cover every aspect of your STO marketing needs. From pre-STO preparation to post-STO support, XDMinds is committed to driving your project’s success and maximizing investor engagement.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your sto marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/sto-marketing-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Strategic STO Marketing Services by XDMinds<br className='break' /> Comprehensive Approaches to Enhance Your <br className='break' /> STO Project and Gain Potential Investors
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we offer strategic STO marketing services designed to enhance your security token offering and drive tangible results. Our comprehensive approaches integrate the latest marketing trends and technologies to ensure your STO stands out in the competitive market. We provide customized marketing solutions that cater to your specific needs, helping you achieve your fundraising objectives efficiently and effectively.
                </p>
                <div className='grid-cards'>
                    <Web3GridCard web3CardData={web3CardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Why Trust XDMinds for Your <br className='break' /> Performance Marketing Needs?
                </h2>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest STO Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default Sto
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './Section4.css';
import { Link } from 'react-router-dom';



const Section4 = () => {
  return (
    <div className='home-sec-4' id='aboutsec'>
      <Container >
        <Row>
          <Col>
            <div className='home-sec4-content'>
              <h2>Beyond the Blockchain<span className='sec4-break'><br /></span> XDMinds - Your Web3 Marketing Powerhouse </h2>
              <p>At XDMinds, we are your ultimate Web3 marketing powerhouse, driving innovation and success in the digital landscape. Our expertise spans across blockchain, NFTs, and decentralized applications, ensuring your brand harnesses the full potential of Web3 technologies. We blend cutting-edge strategies with data-driven insights to create tailored marketing campaigns that elevate your project. With a commitment to excellence and a passion for the future, XDMinds stands at the forefront of the Web3 revolution, empowering businesses to thrive in the decentralized era. Partner with us and unlock unparalleled growth and engagement for your Web3 ventures.</p>
              <div className='btn-mchallenge'>
                <Link reloadDocument to={'/about'} className='footer-links'>
                  <Button className='challenge-tele'><span className='challenge-tele-span'>KNOW MORE</span></Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Section4;

import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import closeicon from "../../assets/images/closeimg.svg";
import Select from 'react-select';
import ReactFlagsSelect from "react-flags-select";
import { State, City } from 'country-state-city';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import { CareerFormSubmitApi } from '../../Helper/Redux/ReduxThunk/Homepage';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';
import ipLocation from "iplocation";


const ApplyModal = ({ allJobs, selectedJobTitle }) => {
    const [loading, setLoading] = useState(false);
    const [resumeFileName, setResumeFileName] = useState('');
    const dispatch = useDispatch();

    const [selectedPosition, setSelectedPosition] = useState(null);
    const [experienceLevel, setExperienceLevel] = useState(null)
    const [availability, setAvailability] = useState(null)
    const [socialMedia, setSocialMedia] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [selectedcountryfromapi, setSelectedCountryfromapi] = useState('');
    const [countryData, setCountryData] = useState({
        countryCode: '',
        countryName: '',
        dialCode: '',
        phoneNumber: ''
    });



    useEffect(() => {
        const fetchCountryCode = async () => {
            const ipDetails = await ipLocation(await publicIpv4());
            try {
                if (ipDetails && ipDetails.country && ipDetails.country.code) {
                    setSelectedCountryfromapi(ipDetails.country.code);
                    setSelectedCountry(ipDetails.country.code);
                };
            } catch (error) {
                console.error('Error fetching country code:', error);
            }
        };

        fetchCountryCode();
    }, []);



    useEffect(() => {
        if (selectedCountry) {
            const statesOfCountry = State.getStatesOfCountry(selectedCountry);
            const options = statesOfCountry.map(state => ({
                value: state.isoCode,
                label: state.name,
            }));
            setStateOptions(options);
        }
    }, [selectedCountry]);


    useEffect(() => {
        if (selectedState) {
            const citiesOfState = City.getCitiesOfState(selectedCountry, selectedState.value);

            const options = citiesOfState.map(city => ({
                value: city.name,
                label: city.name
            }));
            setCityOptions(options);

        } else {
            setCityOptions([]);
        }
    }, [selectedState, selectedCountry]);



    const positionOptions = allJobs?.map((d) => ({
        label: d?.Job_Title,
        value: d?.Job_Title,
    })) || [];



    const availabilityOptions = [
        { value: 'Immediate Joiner', label: 'Immediate Joiner' },
        { value: 'Notice Period', label: 'Notice Period' },
        { value: 'Availability for Interview', label: 'Availability for Interview' },
    ];
    const socialOptions = [
        { value: 'Through LinkedIn', label: 'Through LinkedIn' },
        { value: 'Through WhatsApp', label: 'Through WhatsApp' },
        { value: 'Social Media', label: 'Social Media' },
        { value: 'Refer by a Friend/Colleague/colleague', label: 'Refer by a Friend/Colleague' },
        { value: 'other', label: 'Other' },
    ];
    const expOptions = [
        { value: 'Intern', label: 'Intern' },
        { value: 'Fresher', label: 'Fresher' },
        { value: 'Less than 1 Year', label: 'Less than 1 Year' },
        { value: '1 years', label: '1 Year' },
        { value: '2 years', label: '2 Years' },
        { value: 'More than 2 Years', label: 'More than 2 Years' },
    ];

    const schema = yup.object().shape({
        position: yup.string().required('Position is required!'),
        fullName: yup.string().required('Full Name is required!'),
        email: yup.string().email('Invalid email format').required('Email is required'),
        mobileNumber: yup.string().required('Mobile Number is required!'),
        state: yup.string().required('State is required!'),
        city: yup.string().required('City is required!'),
        employer: yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Special characters are not allowed!').required('Employer is required!'),
        experienceLevel: yup.string().required('Experience Level is required!'),
        availability: yup.string().required('Availability is required!'),
        linkedInProfile: yup.string().matches(
            /((https?):\/\/)?([a-z0-9]+[.])?(linkedin\.com)\/(in|pub|profile)\/[a-zA-Z0-9-_]+(\/)?$/,
            'Enter correct LinkedIn profile URL!'
        )        
            .required('Linkedin Profile is required!'),
        hearAboutUs: yup.string().required('Here About Us is required!'),
        resumeFile: yup.string().required('Resume is required!').url('Please enter a valid URL!'),
        flexCheckDefault: yup
            .bool()
            .oneOf([true], 'You must accept the terms and conditions'),
    })

    const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            position: selectedPosition
        }
    });

    useEffect(() => {
        if (selectedJobTitle) {
            setSelectedPosition(selectedJobTitle);
            setValue("position", selectedJobTitle)
        }
    }, [selectedJobTitle]);

    const onSubmit = async (data) => {
        setLoading(true)

        let params = {
            position: data.position,
            fullName: data.fullName,
            email: data.email,
            mobileNumber: countryData?.phoneNumber,
            country: countryData?.countryName,
            state: data.state,
            city: data.city,
            experienceLevel: data.experienceLevel,
            availability: data.availability,
            linkedInProfile: data.linkedInProfile,
            hearAboutUs: data.hearAboutUs,
            resumeFile: data.resumeFile,
            flexCheckDefault: data.flexCheckDefault,
        }

        await dispatch(CareerFormSubmitApi(params, (resp) => {

            if (resp.status === true) {
                setLoading(false)
                toast.success("Form Submitted Successfully")
                resetForm();
                closeModal()
            }
            else {
                setLoading(false)
                toast.error(resp.message)
            }
        }))
    };

    const resetForm = () => {
        reset({
            position: '',
            fullName: '',
            email: '',
            mobileNumber: '',
            country: '',
            state: '',
            city: '',
            experienceLevel: '',
            availability: '',
            linkedInProfile: '',
            hearAboutUs: '',
            resumeFile: '',
            flexCheckDefault: false,
        });
        setResumeFileName('');
        setSelectedPosition(null);
        setSelectedState(null);
        setSelectedCity(null);
        setExperienceLevel(null);
        setAvailability(null);
        setSocialMedia(null);
        setCountryData({
            countryCode: '',
            countryName: '',
            dialCode: '',
            phoneNumber: ''
        })
    }

    const closeModal = () => {
        const closeModalBtn = document.getElementById('closeModalBtn');
        if (closeModalBtn) {
            closeModalBtn.click();
        }
    };

    return (
        <div className='apply-modal'>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Apply Now</h1>
                            <button type="button" className='btn-close-modal' id="closeModalBtn" data-bs-dismiss="modal" aria-label="Close" onClick={resetForm}>
                                <LazyLoadImage src={closeicon} alt='close' />
                            </button>
                        </div>
                        <div className="modal-body">
                            <Form className='apply-form-body' onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-6 col-12 col-form pack-formselect'>
                                        <label htmlFor="position" className="form-label">
                                            Position Applied For <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="position"
                                            control={control}
                                            defaultValue={selectedPosition}
                                            rules={{ required: "Position selection is required" }}
                                            render={({ field }) => (
                                                <Select
                                                    className="form-select form-select-new no-focus-shadow"
                                                    value={positionOptions.find(option => option.value === selectedPosition)}
                                                    onChange={(option) => {
                                                        field.onChange(option.value);
                                                        setSelectedPosition(option.value);
                                                    }}
                                                    options={positionOptions}
                                                    placeholder="Position Applied for"
                                                    isSearchable={false}
                                                />
                                            )}
                                        />
                                        {errors.position && <p className="alert-msg">{errors.position.message}</p>}
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form'>
                                        <label htmlFor="fullName" className="form-label">
                                            Full Name
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="fullName"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    {...field}
                                                    id="fullName"
                                                    placeholder="Enter Full Name"
                                                />
                                            )}
                                        />
                                        {errors.fullName && <p className="alert-msg">{errors.fullName.message}</p>}
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form'>
                                        <label htmlFor="email" className="form-label">
                                            Email Address
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    {...field}
                                                    id="email"
                                                    placeholder="Enter Email Address"
                                                />
                                            )}
                                        />
                                        {errors.email && <p className="alert-msg">{errors.email.message}</p>}
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form'>
                                        <label htmlFor="country" className="form-label">
                                            Country
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="country"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Country selection is required" }}
                                            render={({ field }) => (
                                                <ReactFlagsSelect
                                                    value={selectedcountryfromapi}
                                                    selected={selectedcountryfromapi}
                                                    disabled
                                                    onSelect={(code) => setSelectedCountry(code)}
                                                    id="countrySelect"
                                                    className="form-select"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form'>
                                        <div className='row g-1'>
                                            <div className='col-lg-6 col-md-6 col-6 pack-formselect'>
                                                <label htmlFor="state" className="form-label">
                                                    Current State
                                                    <span className='span-label'>*</span>
                                                </label>
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "State selection is required" }}
                                                    render={({ field }) => (
                                                        <Select
                                                            className="form-select form-select-new no-focus-shadow"
                                                            value={selectedState}
                                                            onChange={(option) => {
                                                                field.onChange(option.label);
                                                                setSelectedState(option);
                                                                setSelectedCity(null);
                                                            }}
                                                            options={stateOptions}
                                                            placeholder="State"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />
                                                {errors.state && <p className="alert-msg">{errors.state.message}</p>}
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-6 pack-formselect'>
                                                <label htmlFor="city" className="form-label">
                                                    Current City
                                                    <span className='span-label'>*</span>
                                                </label>
                                                <Controller
                                                    name="city"
                                                    control={control}
                                                    rules={{ required: "City selection is required" }}
                                                    render={({ field }) => (
                                                        <Select
                                                            className="form-select form-select-new no-focus-shadow"
                                                            value={selectedCity}
                                                            onChange={(option) => {
                                                                field.onChange(option.value);
                                                                setSelectedCity(option);
                                                            }}
                                                            options={cityOptions}
                                                            placeholder="City"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />
                                                {errors.city && <p className="alert-msg">{errors.city.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form'>
                                        <label htmlFor="mobileNumber" className="form-label">
                                            Mobile Number
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="mobileNumber"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <PhoneInput
                                                    country={selectedcountryfromapi ? selectedcountryfromapi.toLowerCase() : 'in'}
                                                    value={field.value}
                                                    countryCodeEditable={false}
                                                    onChange={(value, country) => {
                                                        // Set the phone number in the form state
                                                        const formattedNumber = `+${country.dialCode} ${value.slice(country.dialCode.length)}`;
                                                        field.onChange(formattedNumber);

                                                        // Extract and set country details
                                                        setCountryData({
                                                            countryCode: country.countryCode,
                                                            countryName: country.name,
                                                            dialCode: country.dialCode,
                                                            phoneNumber: formattedNumber
                                                        });

                                                        // Set the country dynamically
                                                        setSelectedCountry(country.countryCode.toLowerCase());
                                                    }}
                                                    inputProps={{
                                                        name: 'mobileNumber',
                                                        id: 'mobileNumber',
                                                        // required: true,
                                                        className: 'form-control',
                                                        placeholder: 'Enter Mobile Number',
                                                        autoFocus: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.mobileNumber && <p className="alert-msg">{errors.mobileNumber.message}</p>}
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form'>
                                        <label htmlFor="employer" className="form-label">Current/Most Recent Employer</label>
                                        <Controller
                                            name="employer"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    {...field}
                                                    id="employer"
                                                    placeholder="Enter Current/Most Recent Employer"
                                                />
                                            )}
                                        />
                                        {errors.employer && <p className="alert-msg">{errors.employer.message}</p>}
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form pack-formselect'>
                                        <label htmlFor="experienceLevel" className="form-label">
                                            What’s Your Experience Level?
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="experienceLevel"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Experience Level selection is required" }}
                                            render={({ field }) => (
                                                <Select
                                                    className="form-select form-select-new no-focus-shadow"
                                                    value={experienceLevel}
                                                    onChange={(option) => {
                                                        field.onChange(option.value);
                                                        setExperienceLevel(option)
                                                    }}
                                                    options={expOptions}
                                                    placeholder="Choose Your Experience Level"
                                                    isSearchable={true}
                                                />
                                            )}
                                        />
                                        {errors.experienceLevel && <p className="alert-msg">{errors.experienceLevel.message}</p>}
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form pack-formselect'>
                                        <label htmlFor="availability" className="form-label">
                                            Availability
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="availability"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Availability selection is required" }}
                                            render={({ field }) => (
                                                <Select
                                                    className="form-select form-select-new no-focus-shadow"
                                                    value={availability}
                                                    onChange={(option) => {
                                                        field.onChange(option.value);
                                                        setAvailability(option)
                                                    }}
                                                    options={availabilityOptions}
                                                    placeholder="Choose Your Availability"
                                                    isSearchable={true}
                                                />
                                            )}
                                        />
                                        {errors.availability && <p className="alert-msg">{errors.availability.message}</p>}
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 col-form'>
                                        <label htmlFor="linkedInProfile" className="form-label">
                                            LinkedIn Profile URL
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="linkedInProfile"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    {...field}
                                                    id="linkedInProfile"
                                                    placeholder="LinkedIn Profile Link"
                                                />
                                            )}
                                        />
                                        {errors.linkedInProfile && <p className="alert-msg">{errors.linkedInProfile.message}</p>}
                                    </div>

                                    <div className='col-lg-4 col-md-6 col-12 col-form'>
                                        <label htmlFor="resumeLink" className="form-label">
                                            Your Resume/CV URL
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="resumeFile"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    {...field}
                                                    id="resumeFile"
                                                    placeholder="Resume URL"
                                                />
                                            )}
                                        />
                                        {errors.resumeFile && <p className="alert-msg">{errors.resumeFile.message}</p>}
                                    </div>

                                    <div className='col-lg-4 col-md-6 col-12 col-form pack-formselect'>
                                        <label htmlFor="hearAboutUs" className="form-label">
                                            How Did You Hear About Us?
                                            <span className='span-label'>*</span>
                                        </label>
                                        <Controller
                                            name="hearAboutUs"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Hear About Us selection is required" }}
                                            render={({ field }) => (
                                                <Select
                                                    className="form-select form-select-new no-focus-shadow"
                                                    value={socialMedia}
                                                    onChange={(option) => {
                                                        field.onChange(option.value);
                                                        setSocialMedia(option)
                                                    }}
                                                    options={socialOptions}
                                                    placeholder="How Did You Hear About Us?"
                                                    isSearchable={true}
                                                />
                                            )}
                                        />
                                        {errors.hearAboutUs && <p className="alert-msg">{errors.hearAboutUs.message}</p>}
                                    </div>


                                    <div className='col-lg-12 col-md-12 col-12 mt-2'>
                                        <div className="form-check">
                                            <Controller
                                                name="flexCheckDefault"
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="flexCheckDefault"
                                                        checked={field.value}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.checked)
                                                        }}
                                                    />
                                                )}
                                            />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                I hereby declare that the information provided above is true, complete, and accurate to the best of my knowledge and belief.
                                            </label>
                                        </div>
                                        {errors.flexCheckDefault && <p className="alert-msg">{errors.flexCheckDefault.message}</p>}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {!loading && <button className="s-btn" type="submit">Submit</button>}
                                    {loading && <button className="s-btn" type="" disabled>Loading...<Spinner size='sm' /></button>}
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplyModal;

import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';


const Section2 = () => {
    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'Exchange Marketing',
            text: "Elevate your crypto exchange's profile with tailored marketing strategies for both web and mobile platforms. We focus on user acquisition, brand awareness, and engagement to drive traffic and increase trading volume.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Wallet Marketing',
            text: "Promote your crypto wallet effectively with our targeted marketing solutions. We highlight key features, security benefits, and user-friendly aspects to attract and retain a loyal user base.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Token Marketing',
            text: "Launch and grow your crypto token with our strategic marketing campaigns. We emphasize your token’s unique value propositions and market potential to attract investors and build community support.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Launchpad Marketing',
            text: 'Gain visibility and traction for your crypto launchpad with our specialized marketing services. We focus on attracting high-quality projects and investors to your platform, ensuring a successful launch.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Bot Marketing',
            text: 'Drive adoption and engagement for your crypto bot solutions. Our marketing strategies highlight the efficiency, reliability, and advanced features of your bots to attract users and boost utilization.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'MLM Marketing',
            text: "Unlock the potential of multi-level marketing with our crypto MLM strategies. We design compelling referral programs and incentives that drive growth and expand your user base. Our targeted approach ensures optimal engagement and conversion rates.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'HYIP Marketing',
            text: 'Maximize the visibility and success of your High-Yield Investment Program with our expert marketing solutions. We craft strategies to attract investors and highlight the unique advantages of your HYIP, ensuring sustained interest and growth.',
        },
        {
            link: "/ico-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'ICO Marketing',
            text: 'Maximize the success of your Initial Coin Offering with our comprehensive ICO marketing services. We manage everything from campaign planning to execution, focusing on generating interest and securing investments.'
        },
        {
            link: "/sto-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'STO Marketing',
            text: 'Leverage our expertise in Security Token Offerings to attract institutional investors and regulatory compliance. Our strategies are designed to build trust and highlight the security features of your STO.',
        },
        {
            link: "/ieo-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'IEO Marketing',
            text: 'Ensure a successful Initial Exchange Offering with targeted marketing strategies that drive awareness and participation. We help position your project to stand out in the competitive IEO landscape.',
        },
        {
            link: "/ido-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'IDO Marketing',
            text: 'Promote your Initial DEX Offering with our strategic IDO marketing services. We focus on building hype and engaging the decentralized community to drive participation and support.',
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Influencer Marketing',
            text: "Partner with influential voices in the crypto space to amplify your project's message. We connect you with key influencers who can authentically promote your brand to their engaged audiences.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'PR Marketing',
            text: 'Enhance your project’s credibility with strategic PR campaigns. We craft compelling narratives and secure media coverage to build trust and boost visibility.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Telegram / Discord Marketing',
            text: 'Build and engage with your community on Telegram and Discord. Our strategies include targeted outreach, community management, and interactive campaigns to foster loyalty and support.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Reddit Marketing',
            text: 'Utilize Reddit’s vibrant crypto communities to promote your project. We create engaging content and participate in discussions to drive interest and increase awareness.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Community Building',
            text: "Develop a strong and active community around your crypto project. Our approach includes engagement strategies, community events, and ongoing support to cultivate a loyal user base.",
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'SEO',
            text: "Improve your project's online visibility with targeted SEO strategies. We optimize your website and content to rank higher in search engine results and attract organic traffic.",
        },
        {
            link: "/ppc-advertising-agency",
            imgSrc: <FaChartLine />,
            title: 'PPC Advertising',
            text: "Drive immediate traffic and conversions with pay-per-click advertising. We create effective ad campaigns that target your ideal audience and maximize your marketing budget.",
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Content Marketing',
            text: "Engage your audience with high-quality content that educates and informs. Our content marketing strategies include blog posts, articles, and whitepapers tailored to your project’s goals.",
        },
        {
            link: "/social-media-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Social Media Marketing',
            text: "Boost your crypto project's presence on social media with tailored marketing strategies. Our approach includes engaging content, targeted ads, and community management to build brand awareness and drive user engagement across platforms.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Email Marketing',
            text: "Enhance your communication with targeted crypto email campaigns. We design personalized and compelling email content that drives user actions, keeps your audience informed, and nurtures relationships with potential investors and users.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Video Advertising',
            text: "Capture attention and convey your message through impactful video ads. We produce and distribute engaging video content that highlights your project’s features and benefits..",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Bounty Campaign Marketing',
            text: "Incentivize community participation with targeted bounty campaigns. We design and manage campaigns that reward users for promoting and supporting your project.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Token Airdrops and Giveaways',
            text: "Generate buzz and attract attention with strategic crypto token airdrops and giveaways. Our campaigns are designed to increase visibility, reward engagement, and grow your project's community by incentivizing participation.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Whitepaper / Litepaper',
            text: "Craft a professional and persuasive whitepaper or litepaper with our expert writing services. We ensure your document effectively communicates your project's vision, technology, and value proposition to potential investors and stakeholders.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Online Reputation Management',
            text: "Protect and enhance your crypto project's online reputation with our proactive management strategies. We monitor brand mentions, address negative feedback, and build a positive image to foster trust and credibility in the crypto community.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Token Listing',
            text: "Facilitate a successful token listing with our comprehensive marketing support. We handle the intricacies of exchange requirements, promote your token's benefits, and ensure a smooth listing process to maximize exposure and trading opportunities.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Marketing Consulting',
            text: "Partner with XDMinds for expert guidance in crypto marketing, designed to boost your project’s visibility and growth. Our tailored strategies drive impactful results in the dynamic crypto landscape.",
        },
    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);
    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (
        <div className='crypto-sec-2'>
            <div className='blockchain-sec-4'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>Accelerate Your Crypto Project's Growth with<span className='msec4-break'><br />
                                </span>XDMinds' Cryptocurrency Marketing Services
                                </h2>
                            </div>
                            <div className='industries-sub-head'>
                                <p>
                                    Supercharge your crypto project's growth with XDMinds' comprehensive cryptocurrency marketing services. Our expert team utilizes advanced tactics and industry insights to enhance your project's visibility, engage your target audience, and drive measurable results. From strategic planning to execution, we ensure that every aspect of your marketing campaign is optimized for maximum impact and ROI.
                                </p>
                            </div>

                            <div className='desktop-industries-view'>
                                {chunkedCardData.map((chunk, index) => (
                                    <div className='home-industries-card-div' key={index}>
                                        <Row>
                                            {chunk.map((card, cardIndex) => (
                                                <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                    {card.link ? (
                                                        <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Link>
                                                    ) : (
                                                        <div className='c-default h-100'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                            </div>

                            <div className='mobile-view-industries'>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop={true}
                                    dots="true"
                                    onSwiper={(swiper) => setCardSwiper(swiper)}
                                    modules={[Scrollbar, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{ clickable: true }}
                                    className="ps-swiper"
                                    breakpoints={{
                                        0: { slidesPerView: 1, spaceBetween: 10 },
                                        460: { slidesPerView: 1, spaceBetween: 5 },
                                        768: { slidesPerView: 1, spaceBetween: 30 },
                                        1024: { slidesPerView: 6, spaceBetween: 50 },
                                    }}
                                >
                                    {cardData.map((card, index) => (
                                        <SwiperSlide key={index}>
                                            {card.link ? (
                                                <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            ) : (
                                                <div className='c-default h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Section2
import React from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import GridCard from '../DigitalMarketing/GridCard';
import graph from '../../assets/images/graph.svg';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from '../DigitalMarketing/BottomFaq';
import StickyStackingCards from '../DigitalMarketing/StickyStackingCards';
import ServiceAccordion from './ServiceAccordion';


const Industries = (props) => {

    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const cardData = [
        {
            src: graph,
            alt: 'Industry GTM Strategy ',
            title: 'Industry GTM Strategy ',
            text: "Develop industry-specific digital marketing campaigns with strategic planning tailored to your unique market needs, ensuring targeted outreach and optimal engagement."
        },
        {
            src: graph,
            alt: 'Keywords Research',
            title: 'Keywords Research',
            text: "Leverage comprehensive keyword research to identify and target high-impact industry-specific terms that drive traffic and improve search engine rankings."
        },
        {
            src: graph,
            alt: 'Competitor Analysis',
            title: 'Competitor Analysis',
            text: "Gain a competitive edge with in-depth competitor analysis, helping you understand market positioning and refine your strategies to outshine industry rivals."
        },
        {
            src: graph,
            alt: 'Branding Development',
            title: 'Branding Development',
            text: "Elevate your brand with custom branding strategies that resonate with your target audience and establish a strong industry presence."
        },
        {
            src: graph,
            alt: 'Website/Web Portal Development',
            title: 'Website/Web Portal Development',
            text: "Create a user-centric, industry-optimized website or web portal that enhances user experience and drives conversions, tailored to your specific sector needs."
        },
        {
            src: graph,
            alt: 'Mobile App Development',
            title: 'Mobile App Development',
            text: "Engage customers on the go with industry-specific mobile apps that deliver seamless user experiences and strengthen brand loyalty."
        },
        {
            link: '/seo-agency',
            src: graph,
            alt: 'Search Engine Optimization',
            title: 'Search Engine Optimization',
            text: "Boost your industry’s online presence with tailored SEO strategies, covering local, national, and international markets for maximum reach and visibility."
        },
        {
            link: '/aso-agency',
            src: graph,
            alt: 'App Store Optimization',
            title: 'App Store Optimization',
            text: "Optimize your app’s visibility in app stores with industry-focused ASO strategies, driving downloads and increasing user engagement."
        },
        {
            link: '/ppc-advertising-agency',
            src: graph,
            alt: 'Paid Advertising ',
            title: 'Paid Advertising ',
            text: "Maximize ROI with targeted paid advertising campaigns across search engines, display networks, and social media, tailored to your industry’s audience."
        },
        {
            link: '/social-media-marketing-agency',
            src: graph,
            alt: 'Social Media Marketing',
            title: 'Social Media Marketing',
            text: "Build a strong social media presence with customized strategies that engage your industry’s target audience and foster meaningful connections."
        },
        {
            src: graph,
            alt: 'Lead Generation',
            title: 'Lead Generation',
            text: "Drive high-quality leads with industry-specific lead generation strategies that convert potential customers into loyal clients."
        },
        {
            src: graph,
            alt: 'Demand Generation',
            title: 'Demand Generation',
            text: "Fuel your sales pipeline with demand generation strategies designed to create awareness, interest, and desire within your industry’s target market."
        },
        {
            link: '/content-marketing-agency',
            src: graph,
            alt: 'Content Marketing',
            title: 'Content Marketing',
            text: "Deliver valuable, industry-relevant content that educates, informs, and engages your audience, positioning your brand as a thought leader."
        },
        {
            src: graph,
            alt: 'Email Marketing',
            title: 'Email Marketing',
            text: "Craft personalized email campaigns that resonate with your industry’s audience, driving engagement, and nurturing customer relationships."
        },
        {
            src: graph,
            alt: 'PR & Media Outreach',
            title: 'PR & Media Outreach',
            text: "Enhance your brand’s reputation with strategic public relations and media outreach tailored to your industry’s specific audience and goals."
        },
        {
            link: '/influencer-marketing-agency',
            src: graph,
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: "Leverage the power of influencers within your industry to amplify your brand message and reach a broader, more engaged audience."
        },
        {
            link: '/performance-marketing-agency',
            src: graph,
            alt: 'Performance Marketing',
            title: 'Performance Marketing',
            text: "Achieve measurable results with performance marketing strategies focused on delivering high-impact outcomes specific to your industry."
        },
        {
            link: '/programmatic-advertising-agency',
            src: graph,
            alt: 'Programmatic Advertising',
            title: 'Programmatic Advertising',
            text: "Utilize advanced programmatic advertising to automate and optimize your industry’s ad campaigns, ensuring efficient and targeted ad placements."
        },
        {
            src: graph,
            alt: 'Conversion Rate Optimization',
            title: 'Conversion Rate Optimization',
            text: "Increase your website’s conversion rates with industry-specific CRO techniques that turn visitors into customers."
        },
        {
            link: '/online-reputation-management-agency',
            src: graph,
            alt: 'Online Reputation Management ',
            title: 'Online Reputation Management ',
            text: "Manage and improve your online reputation with tailored ORM strategies that reflect positively on your brand within your industry."
        },
        {
            src: graph,
            alt: 'Performance Tracking',
            title: 'Performance Tracking',
            text: "Stay informed with detailed performance tracking and analytics, providing insights into your industry’s marketing efforts and guiding strategic adjustments."
        },
        {
            src: graph,
            alt: 'Growth Marketing ',
            title: 'Growth Marketing ',
            text: "XDMinds drives industry-specific growth with targeted marketing strategies designed to accelerate your business’s expansion. Our data-driven approach focuses on scaling your success through strategic insights and effective growth tactics."
        },
        {
            src: graph,
            alt: 'Marketing Automation',
            title: 'Marketing Automation',
            text: "Streamline your marketing efforts with our advanced automation solutions, designed to enhance efficiency and drive personalized engagement across various industries."
        },
        {
            src: graph,
            alt: 'Marketing Consulting',
            title: 'Marketing Consulting',
            text: "Benefit from expert marketing consulting services that provide industry-specific insights, strategies, and advice to help you achieve your business goals."
        },
    ]



    const stickyCardData = [
        {
            title: "Agriculture",
            sections: [
                {
                    content: "Our digital marketing strategies for agriculture focus on promoting sustainable practices and connecting with target audiences through innovative online campaigns. We help agribusinesses enhance visibility and drive engagement in a competitive market."
                },
            ]
        },
        {
            title: "Automative",
            sections: [
                {
                    content: "Boost your brand's visibility and drive more leads with our tailored digital marketing strategies designed specifically for the automotive industry."
                },
            ]
        },
        {
            title: "Automobiles",
            sections: [
                {
                    content: "We specialize in automotive marketing solutions that accelerate brand growth and drive customer engagement. Our targeted campaigns help automotive companies stand out in a crowded marketplace and boost sales."
                },
            ]
        },
        {
            title: "Banking",
            sections: [
                {
                    content: "Our digital marketing for banking institutions focuses on building trust and increasing customer acquisition. We leverage innovative strategies to enhance online presence and drive financial growth in the competitive banking sector."
                },
            ]
        },
        {
            title: "Beauty & Personal Care",
            sections: [
                {
                    content: "Capture the attention of beauty enthusiasts with targeted digital marketing solutions that elevate your brand and increase sales in the beauty and personal care industry."
                },
            ]
        },
        {
            link: '/b2b-marketing-agency',
            title: "B2B",
            sections: [
                {
                    content: "Our B2B digital marketing services are crafted to generate high-quality leads and foster meaningful business relationships. We employ strategies that enhance brand authority and drive business growth in the B2B sector."
                },
            ]
        },
        {
            link: '/b2c-marketing-agency',
            title: "B2C",
            sections: [
                {
                    content: "We create compelling B2C marketing campaigns that captivate audiences and drive consumer action. Our strategies are designed to enhance brand loyalty and maximize ROI through effective customer engagement."
                },
            ]
        },
        {
            title: "Consumer Durables",
            sections: [
                {
                    content: "Drive consumer engagement and sales for your durable goods with innovative marketing campaigns that resonate with your target audience."
                },
            ]
        },
        {
            title: "Crowdfunding",
            sections: [
                {
                    content: "We craft targeted marketing campaigns that amplify your crowdfunding efforts and attract potential backers. Our approach ensures your project gains the visibility and support needed to achieve funding goals."
                },
            ]
        },
        {
            title: "E-commerce",
            sections: [
                {
                    content: "Maximize your online sales and customer retention with data-driven marketing strategies that cater to the dynamic needs of the e-commerce industry."
                },
            ]
        },
        {
            title: "Education",
            sections: [
                {
                    content: "Our marketing strategies for education institutions are designed to attract and engage students, showcasing educational programs and services effectively. We help educational organizations build a strong online presence and drive enrollment."
                },
            ]
        },
        {
            title: "Electric Vehicle",
            sections: [
                {
                    content: "We provide targeted marketing services for the electric vehicle sector, focusing on promoting eco-friendly innovations and increasing brand visibility. Our strategies are designed to drive adoption and growth in the EV market."
                },
            ]
        },
        {
            title: "Electronics",
            sections: [
                {
                    content: "Our digital marketing services for electronics companies emphasize product innovation and market differentiation. We help brands connect with tech-savvy consumers and drive sales through strategic online campaigns."
                },
            ]
        },
        {
            title: "Energy",
            sections: [
                {
                    content: "We offer specialized marketing strategies for the energy sector that highlight sustainable solutions and industry advancements. Our approach aims to increase brand awareness and foster growth in the energy market."
                },
            ]
        },
        {
            title: "FMCG",
            sections: [
                {
                    content: "Our FMCG marketing services focus on driving brand visibility and consumer engagement through dynamic digital strategies. We help fast-moving consumer goods brands achieve their sales and marketing objectives."
                },
            ]
        },
        {
            title: "FinTech",
            sections: [
                {
                    content: "We deliver digital marketing solutions tailored to the FinTech industry, focusing on enhancing brand credibility and attracting new clients. Our strategies are designed to drive growth and innovation in the financial technology sector."
                },
            ]
        },
        {
            title: "Gaming",
            sections: [
                {
                    content: "Our marketing strategies for the gaming industry are crafted to boost game visibility and player engagement. We use creative campaigns to drive interest and increase downloads or sales in a competitive gaming market."
                },
            ]
        },
        {
            title: "Gems and Jewellery Industry",
            sections: [
                {
                    content: "Showcase your exquisite designs and attract high-value customers with our luxury-focused digital marketing strategies tailored for the gems and jewelry industry."
                },
            ]
        },
        {
            title: "Healthcare",
            sections: [
                {
                    content: "We provide digital marketing solutions for healthcare providers that focus on patient engagement and service promotion. Our strategies aim to enhance online presence and drive growth in the healthcare sector."
                },
            ]
        },
        {
            title: "Health & Wellness",
            sections: [
                {
                    content: "Promote your health and wellness brand with strategic marketing initiatives that connect you with health-conscious audiences and drive measurable growth."
                },
            ]
        },
        {
            title: "Home & Decor",
            sections: [
                {
                    content: "Enhance your online presence and engage design-savvy consumers with creative and effective marketing campaigns for the home and decor sector."
                },
            ]
        },
        {
            title: "Home Services",
            sections: [
                {
                    content: "Attract more clients and grow your home services business with our customized digital marketing services that deliver real results."
                },
            ]
        },
        {
            title: "HVAC",
            sections: [
                {
                    content: "Increase leads and customer retention for your HVAC business with our specialized marketing services that focus on driving ROI."
                },
            ]
        },
        {
            title: "Infrastructure",
            sections: [
                {
                    content: "Our marketing strategies for infrastructure projects focus on showcasing developments and attracting investment. We help infrastructure companies build a strong online presence and engage with key stakeholders."
                },
            ]
        },
        {
            title: "Information Technology",
            sections: [
                {
                    content: "We offer tailored digital marketing solutions for IT companies that highlight innovation and expertise. Our strategies are designed to enhance visibility and generate leads in the competitive IT sector."
                },
            ]
        },
        {
            title: "Insurance",
            sections: [
                {
                    content: "We provide marketing solutions for the insurance industry that build trust and drive customer acquisition. Our strategies are designed to enhance brand visibility and support growth in a competitive insurance market."
                },
            ]
        },
        {
            title: "Large Scale Enterprises",
            sections: [
                {
                    content: "Enhance your brand's global presence and market share with comprehensive digital marketing solutions tailored for large-scale enterprises."
                },
            ]
        },
        {
            title: "Legal and Law",
            sections: [
                {
                    content: "Strengthen your law firm's online presence and generate quality leads with our specialized marketing strategies tailored for the legal industry."
                },
            ]
        },
        {
            title: "Lifestyle",
            sections: [
                {
                    content: "Our lifestyle marketing services are crafted to engage consumers and build brand loyalty through personalized campaigns. We help lifestyle brands connect with their target audience and drive engagement."
                },
            ]
        },
        {
            title: "Manufacturing",
            sections: [
                {
                    content: "We deliver digital marketing solutions for manufacturing companies that highlight product innovations and drive industry leadership. Our strategies are designed to enhance online presence and attract business opportunities."
                },
            ]
        },
        {
            title: "Medium Scale Enterprises",
            sections: [
                {
                    content: "Expand your market reach and drive sustainable growth with customized marketing strategies designed for medium-scale enterprises."
                },
            ]
        },
        {
            title: "Micro Scale Enterprises",
            sections: [
                {
                    content: "Elevate your micro-enterprise with personalized marketing campaigns that maximize reach and return on investment, even with a limited budget."
                },
            ]
        },
        {
            title: "Non-Profit Organization",
            sections: [
                {
                    content: "Our digital marketing solutions for non-profit organizations are designed to amplify your cause and drive donations. We use compelling strategies to increase visibility, engage supporters, and achieve your mission-driven goals."
                },
            ]
        },
        {
            title: "Oil and Gas",
            sections: [
                {
                    content: "We provide specialized marketing strategies for the oil and gas industry, focusing on enhancing brand reputation and attracting industry partners. Our approach helps drive growth and visibility in the energy sector."
                },
            ]
        },
        {
            title: "On Demand",
            sections: [
                {
                    content: "We deliver ROI-driven digital marketing strategies for on-demand businesses, ensuring your services reach the right audience at the right time."
                },
            ]
        },
        {
            title: "Pharmaceuticals",
            sections: [
                {
                    content: "Navigate the complexities of the pharmaceutical industry with compliant and effective digital marketing strategies that boost brand awareness and patient outreach."
                },
            ]
        },
        {
            title: "Professional Services",
            sections: [
                {
                    content: "Build trust and authority in your industry with our targeted marketing strategies that position your professional services firm for success."
                },
            ]
        },
        {
            title: "Real Estate",
            sections: [
                {
                    content: "Our real estate marketing solutions are designed to showcase properties and attract potential buyers or tenants. We utilize targeted online strategies to increase visibility and drive sales or leases."
                },
            ]
        },
        {
            title: "Retail",
            sections: [
                {
                    content: "We offer digital marketing services for retail brands that drive in-store traffic and online sales. Our strategies are crafted to enhance brand presence and connect with consumers effectively."
                },
            ]
        },
        {
            title: "Small Scale Enterprises",
            sections: [
                {
                    content: "Accelerate growth and brand recognition for your small-scale enterprise through strategic digital marketing that delivers tangible outcomes."
                },
            ]
        },
        {
            link: '/saas-marketing-agency',
            title: "Software-as-a-Service (SaaS)",
            sections: [
                {
                    content: "Our marketing solutions for SaaS companies focus on driving user acquisition and enhancing subscription growth. We create targeted campaigns that showcase your software’s value and attract ideal customers."
                },
            ]
        },
        {
            title: "Sports",
            sections: [
                {
                    content: "We deliver marketing strategies for the sports industry that boost brand visibility and fan engagement. Our approach helps sports teams and organizations connect with audiences and drive growth."
                },
            ]
        },
        {
            title: "Telecommunication",
            sections: [
                {
                    content: "Our digital marketing services for telecommunications companies focus on promoting network services and innovations. We help telecom brands enhance their online presence and attract new customers."
                },
            ]
        },
        {
            title: "Textiles",
            sections: [
                {
                    content: "Promote your textile products to a global audience with our industry-specific marketing solutions highlighting your unique offerings and driving sales."
                },
            ]
        },
        {
            title: "Transportation and Logistics",
            sections: [
                {
                    content: "We provide marketing solutions for the transportation and logistics sector that highlight efficiency and reliability. Our strategies are designed to improve visibility and attract business opportunities."
                },
            ]
        },
        {
            title: "Travel and Tourism",
            sections: [
                {
                    content: "Our marketing services for the travel and tourism industry are crafted to inspire travel and boost bookings. We use engaging campaigns to enhance destination visibility and drive tourism growth."
                },
            ]
        },
        {
            link: '/web3-marketing-services',
            title: "Web3",
            sections: [
                {
                    content: "Our marketing strategies for Web3 projects are designed to navigate the complexities of decentralized technologies and drive innovation. We help projects in the metaverse, blockchain, and beyond gain traction and achieve success."
                },
            ]
        },

    ];


    const faqData = [
        {
            question: "What makes XDMinds different from other digital marketing agencies?",
            answer: "XDMinds stands out due to our tailored approach to each industry’s unique needs. We dive deep into understanding your specific market, challenges, and goals to create customized strategies that deliver the best results. Our team combines industry expertise with innovative tactics to ensure your business achieves its objectives."
        },
        {
            question: "How do you develop a digital marketing strategy for a new industry?",
            answer: "When approaching a new industry, we start with thorough research to understand the market dynamics, target audience, and competitive landscape. We then craft a strategy that aligns with industry-specific goals and trends, using data-driven insights to refine our approach and optimize performance."
        },
        {
            question: "How can XDMinds help my business improve its online visibility?",
            answer: "We employ a range of strategies tailored to enhance online visibility, including SEO, content marketing, and targeted advertising. By optimizing your website, creating engaging content, and running effective ad campaigns, we help increase your presence across search engines and social media platforms."
        },
        {
            question: "What types of businesses can benefit from your services?",
            answer: "XDMinds provides digital marketing solutions for a wide range of industries, including but not limited to technology, healthcare, finance, retail, and education. Our versatile approach ensures that businesses of all types can leverage our expertise to drive growth and reach their target audience effectively."
        },
        {
            question: "How do you measure the success of your digital marketing campaigns?",
            answer: "We use a variety of metrics to measure the success of our campaigns, including traffic growth, conversion rates, lead generation, and engagement levels. We provide detailed reports that track performance against your goals, allowing us to make data-driven adjustments for continuous improvement."
        },
        {
            question: "Can you handle both B2B and B2C marketing needs?",
            answer: "Yes, XDMinds has experience in both B2B (business-to-business) and B2C (business-to-consumer) marketing. We understand the different strategies and approaches required for each type of market and tailor our campaigns accordingly to meet the specific needs and objectives of your business."
        },
        {
            question: "How do you stay updated with the latest digital marketing trends and technologies?",
            answer: "Our team is committed to staying at the forefront of the digital marketing landscape. We continually invest in professional development, and engage with thought leaders to keep up with the latest trends and technologies, ensuring that our strategies remain cutting-edge."
        },
        {
            question: "What should I expect during the onboarding process with XDMinds?",
            answer: "During onboarding, we conduct a comprehensive assessment of your business, including current marketing efforts, industry position, and goals. We then collaborate with you to outline a strategic plan, set clear objectives, and establish key performance indicators. This process ensures alignment and sets the stage for a successful partnership."
        },
        {
            question: "How does XDMinds ensure that my marketing budget is used efficiently?",
            answer: "We prioritize budget efficiency by focusing on high-impact strategies and continuously optimizing campaigns based on performance data. By allocating resources to the most effective channels and tactics, we maximize your return on investment and ensure that your budget is used effectively."
        },
        {
            question: "What level of involvement is required from my team?",
            answer: "While we handle the majority of the marketing activities, your involvement is crucial for providing insights into your business, reviewing strategies, and approving key decisions. We maintain open communication and work closely with your team to ensure that our efforts align with your vision and goals."
        },
    ]

    return (
        <div className='dm-service digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Digital Marketing Services <br className='break ' />  for  Every  <span className='s-text-3'>Industry</span>
                </h1>
                <p className='p-text-1'>
                    Unlock your business potential with XDMinds’ all-encompassing digital
                    marketing services, tailored to meet the unique needs of every industry. Whether you’re in retail,
                    tech, or any other sector, our customized strategies drive growth, enhance brand visibility, and ensure your business stands out in a competitive marketplace.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Experience Industry Growth with XDMinds: The First-Grade  <br className='break' />Digital Marketing Agency Offering ROI-Driven Marketing Solutions
                </h2>
                <p className='p-text-2'>
                    XDMinds is your go-to partner for driving industry-specific growth through innovative digital marketing
                    solutions. As a first-rate digital marketing agency, we specialize in creating ROI-driven campaigns that
                    are tailored to your industry’s unique needs. Our team combines expertise in cutting-edge technologies with a deep understanding of market trends to deliver impactful strategies that elevate your brand, increase customer engagement, and drive measurable results.
                    Partner with XDMinds and experience unparalleled growth across any industry landscape.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your b2b marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/b2b-marketing-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Comprehensive Digital Marketing Solutions Designed <br className='break' /> for Success Across All Industries
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we offer comprehensive digital marketing solutions meticulously designed to ensure success across all industries.
                    Our services are not one-size-fits-all; instead, they are crafted with precision to meet the distinct challenges and opportunities of your specific sector. From strategic planning to execution, we employ a holistic approach that incorporates SEO, content marketing, social media, and more, ensuring your brand achieves maximum visibility, engagement, and conversion.
                    With XDMinds, your industry’s digital transformation is not just a possibility—it’s  a guarantee.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    XDMinds: Your All-in-One Digital Marketing Partner <br className='break' />Across Every Industry
                </h2>
                <p className='p-text-2'>
                    Our comprehensive digital marketing solutions are designed to address the unique needs of every industry.
                    Whether you’re in agriculture or the latest tech, we provide tailored strategies that drive growth and
                    enhance your online presence.
                    Partner with us to unlock your industry's full potential with innovative marketing approaches.
                </p>
                <div className='event-card-div mobile-hide'>
                    <StickyStackingCards stickyCardData={stickyCardData} />
                </div>
                <div className='event-accordion'>
                    <ServiceAccordion />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest Industries Marketing Insights For Industries' />
            </section>
            <section className='digital-marketing-section-2'>
                <h5 className='h-text-2'>
                    Frequently Asked Questions
                </h5>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default Industries

import React from 'react';
import './404Error.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import tick from "../../assets/images/tick.svg";

const Error = () => {
    return (
        <div className='error-page'>
            <h1>404</h1>

            <div className='cloak'>
                <div className='cloak__wrapper'>
                    <div className='cloak__container'></div>
                </div>
            </div>
            <div className='info'>
                <h2>Oops! This Page Got Lost in the Digital World.</h2>
                <p className='error-sub-head'>It seems the page you're looking for has taken a wrong turn or simply doesn't exist anymore. But don’t worry, we’re here to help you get back on track!</p>


                <div className='error-route'>
                    <a href="/" target="_blank" rel="noreferrer noopener"><p><LazyLoadImage src={tick} alt='tick' className='error-page-img' /> Return to the <span className='error-route-key'>Home</span> and start fresh.</p></a>
                    <a href="/web3-marketing-services" target="_blank" rel="noreferrer noopener"><p><LazyLoadImage src={tick} alt='tick' className='error-page-img' />Explore our <span className='error-route-key'> Services</span> to see what we offer.</p></a>

                    <a href="/contact-us" target="_blank" rel="noreferrer noopener"><p><LazyLoadImage src={tick} alt='tick' className='error-page-img' /><span className='error-route-key'> Contact</span> Us if you need any assistance.</p></a>
                </div>
            </div>
        </div>
    );
};

export default Error;

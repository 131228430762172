import React from 'react'
import { Button } from 'react-bootstrap';
import './Metaverse.css'
import ModalForm from '../../ModalForm/ModalForm';
import BreadCrumbs from '../../BreadCrumbs';

const MBanner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { countryCode } = props;

  
  return (
    <div className='web3-banner'>

      <BreadCrumbs />
      <div className='banner-left-sec min-100vh'>
        <h1>Industry Leading <span className='changing-banner-content'>Metaverse</span><span className='home-banner-break'><br /></span> Marketing Agency</h1>
        <p>Step into the future with our Metaverse Marketing Agency. As the digital landscape evolves, the metaverse presents limitless opportunities for innovative marketing strategies. We specialize in creating immersive experiences that captivate audiences and elevate your brand in this new digital frontier.</p>
        <div className='btn-home-banner-request-div'>
          <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
        </div>
        <ModalForm
          countryCode={countryCode}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>

    </div>
  )
}

export default MBanner
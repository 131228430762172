import React from 'react';
import Tabs from '../../../Pages/DigitalMarketing/Tabs'
import '../../../assets/css/style.css'
import '../Blockchain/Blockchain.css'
const Section4 = () => {
    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Define Your Unique Selling Proposition (USP)',
            sections: [
                {
                    subtitle: "Identify Your Project's Strengths",
                    content: "Before diving into marketing tactics, we first identify what makes your NFT project unique. Whether it's the artwork, the utility, or the story behind the project, pinpointing your USP helps in crafting a compelling narrative that resonates with your target audience.",

                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Conduct Market Research',
            sections: [
                {
                    subtitle: "Understand Your Audience",
                    content: "We conduct thorough market research to understand your target audience's preferences, behaviors, and needs. This involves analyzing market trends, competitor strategies, and audience demographics to tailor our approach effectively."
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Create a Brand Identity',
            sections: [
                {
                    subtitle: "Develop a Strong Visual and Verbal Identity",
                    content: "A cohesive brand identity is crucial for establishing trust and recognition. We work on creating a memorable logo, engaging visuals, and a consistent tone of voice that reflects your project's values and vision."
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Build a Professional and Responsive Website',
            sections: [
                {
                    subtitle: "Optimize for User Experience and SEO",
                    content: "Your website serves as the hub for your NFT project. We ensure it's professionally designed, easy to navigate, and optimized for search engines to drive organic traffic. This includes creating informative and engaging content that highlights your project's features and benefits."
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Utilize Social Media Platforms',
            sections: [
                {
                    subtitle: "Engage and Grow Your Audience",
                    content: "Social media is a powerful tool for building a community around your NFT project. We create and manage profiles on key platforms like Twitter, Instagram, and TikTok, posting regular updates, engaging with followers, and leveraging hashtags to increase visibility."
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Leverage Influencer Partnerships',
            sections: [
                {
                    subtitle: "Tap Into Established Audiences",
                    content: "Partnering with influencers can significantly boost your project's reach. We identify and collaborate with influencers in the NFT and crypto space who can authentically promote your project to their followers."
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Implement Content Marketing',
            sections: [
                {
                    subtitle: "Educate and Engage Through Valuable Content",
                    content: "We develop a content marketing strategy that includes blog posts, videos, infographics, and more. This content educates your audience about NFTs, your project, and the broader market, positioning you as a thought leader in the space."
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Launch Paid Advertising Campaigns',
            sections: [
                {
                    subtitle: "Drive Targeted Traffic and Sales",
                    content: "Paid advertising can accelerate your project's visibility and sales. We create targeted campaigns using platforms like Google Ads, Facebook Ads, and Twitter Ads, ensuring we reach potential buyers who are most likely to engage with your project."
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Foster a Community',
            sections: [
                {
                    subtitle: "Create and Nurture Community Spaces",
                    content: "Building a community is essential for long-term success. We set up and manage community spaces on platforms like Discord and Telegram, where fans and investors can interact, share ideas, and stay updated on your project's progress."
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Monitor and Optimize',
            sections: [
                {
                    subtitle: "Track Performance and Refine Strategies",
                    content: "Marketing is an ongoing process. We continuously monitor the performance of our strategies, using analytics to track key metrics like engagement, traffic, and sales. This data-driven approach allows us to refine and optimize our tactics for maximum impact."
                }
            ]
        },
    ]
    return (
        <div className='nft-sec-4'>
            <div className='blockchain-sec4'>
                <div className='digital-marketing ppc'>
                    <section className='digital-marketing-section-2 l-bg'>
                        <h2 className='h-text-2 text-center'>
                            Fuel Your NFT Project <br />Our Step-by-Step Marketing Strategy
                        </h2>
                        <p className='p-text-2 text-center'>
                            Launching a successful NFT project requires more than just minting digital assets and listing them on a marketplace. It demands a strategic marketing plan that can drive visibility, engagement, and sales. At XDMinds, we've perfected a step-by-step marketing strategy designed to fuel your NFT project's success. Here's how we do it:
                        </p>
                        <div className='tabs-div'>
                            <Tabs tabData={tabData} />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Section4
import React from 'react'
import ModalForm from '../../ModalForm/ModalForm';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../Blockchain/Blockchain.css'
import BreadCrumbs from '../../BreadCrumbs';

const GameFIBanner = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { countryCode } = props;

  return (

    <div className='web3-banner'>
      <Container>
        <Row>
          <Col>
            <BreadCrumbs />
            <div className='banner-left-sec min-100vh mt-2'>
              <h1><span className='changing-banner-content'>GameFi</span>Marketing Agency<span className='home-banner-break'><br /></span>for Blockchain Gaming Growth</h1>
              <p>At XDMinds, we specialize in providing top-notch GameFi marketing services designed to drive growth and success in the blockchain gaming industry. As a leading GameFi marketing agency, we harness the power of innovative strategies and deep industry insights to elevate your blockchain game to new heights. Partner with us to transform your gaming project into a thriving success.</p>
              <div className='btn-home-banner-request-div'>
                <Button className='btn-banner-request' onClick={() => setModalShow(true)}>Request A Free Proposal</Button>
              </div>
              <ModalForm
                countryCode={countryCode}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GameFIBanner
import React from 'react';

const BottomFaq = ({ faqData, particularBlog }) => {

    return (
        <div className='faq-section'>
            <div className="accordion" id="accordionExample">
                {particularBlog && particularBlog.faqs?.length > 0 ? (
                    particularBlog.faqs?.map((item, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                    <div style={{ width: '90%' }}>
                                        {item.question}
                                    </div>
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: item.answer }} />
                            </div>
                        </div>
                    ))
                ) : (
                    faqData?.map((item, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                    <div style={{ width: '90%' }}>
                                        {item.question}
                                    </div>
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body" dangerouslySetInnerHTML={{ __html: item.answer }} />
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default BottomFaq;

import React from 'react'
import { Button } from 'react-bootstrap'
import { FaSkype, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const AboutDubai = () => {
    return (
        <div>
            <div className='blockchain-sec2'>

                <div>
                    <h2>XDMinds: Pioneering Web3 Marketing Excellence  <span className='metaverse-sec2-break'><br /></span>in Dubai, United Arab Emirates – Transforming Brands<span className='metaverse-sec2-break'><br /></span> in the Next Digital Frontier
                    </h2>
                    <p>XDMinds is at the forefront of Web3 marketing innovation, redefining how brands connect with audiences in the blockchain era. Based in Dubai, United Arab Emirates, our team combines cutting-edge strategies with deep expertise in decentralized technologies to help businesses navigate the complexities of Web3. Whether you're launching an NFT, building a metaverse presence, or creating a dApp, we ensure your brand stands out in the highly competitive Web3 space. Our mission is to accelerate your growth and create meaningful connections in the next generation of the digital economy.
                    </p>
                </div>
                <div className='btn-mchallenge'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Web3 marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/web3-marketing-agency-in-dubai-uae' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                        <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                    </a>
                    <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                        <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                        <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AboutDubai
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';

const DAOSection3 = () => {
    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'DAO GTM Strategy',
            text: "Develop comprehensive marketing strategies and detailed planning to effectively launch and promote your DAO project.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Market Research',
            text: "Conduct thorough market research and analysis to identify trends, opportunities, and challenges in the DAO space.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Brand Development',
            text: "Craft a strong and unique brand identity for your DAO to stand out and attract your target audience.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Pre-DAO Promotion',
            text: "Create buzz and anticipation for your DAO launch with targeted pre-launch promotion strategies.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Post-DAO Promotion',
            text: 'Maintain momentum and continue to grow your community with ongoing promotional efforts post-launch.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Tokenomics',
            text: "Design effective tokenomics and incentive structures to ensure the sustainability and attractiveness of your DAO.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Website Development',
            text: "Build a professional, user-friendly, and engaging website to showcase your DAO project and its value proposition.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Whitepaper/Litepaper',
            text: "Draft detailed and informative whitepapers and litepapers that clearly communicate your DAO's vision, goals, and mechanics."
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Social Media Campaigns',
            text: "Leverage social media platforms to build awareness, engage with your community, and drive participation in your DAO.",
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Content Marketing',
            text: 'Create high-quality content that educates, informs, and engages your audience about your DAO and its benefits.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Community Building',
            text: 'Develop and manage a vibrant and active community around your DAO through strategic community-building initiatives.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Email Marketing',
            text: "Utilize targeted email campaigns to keep your community informed and engaged with the latest updates and developments.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Press Releases',
            text: 'Distribute compelling press releases to major publications and platforms to gain media coverage and visibility for your DAO.',
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Influencer Marketing',
            text: "Collaborate with key influencers in the Web3 space to amplify your DAO's reach and credibility.",
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'DAO SEO',
            text: "Optimize your online presence to improve search engine rankings and attract organic traffic to your DAO project.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Paid Advertising',
            text: "Implement targeted paid advertising campaigns to reach a wider audience and drive traffic to your DAO.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Event Promotion',
            text: "Plan, promote, and manage events to engage your community and showcase your DAO project.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Omnichannel Marketing',
            text: "Adopt a holistic marketing approach that integrates multiple channels to deliver a consistent and engaging message across all platforms.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Analytics and Reporting',
            text: "Utilize advanced analytics and reporting tools to track performance, measure success, and make data-driven decisions.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Consulting',
            text: "Receive expert advice and consultation to navigate the complexities of DAO marketing and achieve your project's goals.",
        },
    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (
        <div>
            <div className='blockchain-sec-4'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>Comprehensive DAO Marketing Services by XDMinds: <span className='msec4-break'><br />
                                </span>Your Partner in Decentralized Autonomous Organization Growth
                                </h2>
                            </div>
                            <div className='industries-sub-head'>
                                <p>
                                    XDMinds offers a full suite of DAO marketing services designed to support the growth and success of your decentralized autonomous organization. From strategic planning to execution, our expert team provides tailored solutions to meet your unique needs. With our extensive experience and cutting-edge techniques, we ensure that your DAO achieves its full potential in the competitive Web3 ecosystem.
                                </p>
                            </div>

                            <div className='desktop-industries-view'>
                                {chunkedCardData.map((chunk, index) => (
                                    <div className='home-industries-card-div' key={index}>
                                        <Row>
                                            {chunk.map((card, cardIndex) => (
                                                <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                    {card.link ? (
                                                        <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Link>
                                                    ) : (
                                                        <div className='c-default h-100'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                            </div>



                            <div className='mobile-view-industries'>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop={true}
                                    dots="true"
                                    onSwiper={(swiper) => setCardSwiper(swiper)}
                                    modules={[Scrollbar, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 1000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{ clickable: true }}
                                    className="ps-swiper"
                                    breakpoints={{
                                        0: { slidesPerView: 1, spaceBetween: 10 },
                                        460: { slidesPerView: 1, spaceBetween: 5 },
                                        768: { slidesPerView: 1, spaceBetween: 30 },
                                        1024: { slidesPerView: 6, spaceBetween: 50 },
                                    }}
                                >
                                    {cardData.map((card, index) => (
                                        <SwiperSlide key={index}>
                                            {card.link ? (
                                                <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            ) : (
                                                <div className='c-default h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default DAOSection3
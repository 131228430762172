import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../Blockchain/Blockchain.css'
import { Link } from 'react-router-dom';

const Section3 = () => {

    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'DeFi Go-To-Market Strategy',
            text: " Our tailored DeFi go-to-market strategy ensures your project achieves maximum visibility and impact from launch.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Website Optimization',
            text: "Our experts optimize your DeFi website for speed, usability, and search engines, driving more traffic and conversions.",
        },
        {
            link: "/aso-agency",
            imgSrc: <FaChartLine />,
            title: 'App Store Optimization',
            text: "Boost your DeFi app’s visibility and downloads with our comprehensive app store optimization services.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DeFi Product Marketing ',
            text: "Our strategic DeFi product marketing services highlight your project's unique value, driving engagement and adoption.",
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'DeFi SEO',
            text: 'Our DeFi SEO services improve your search engine rankings, making your DeFi project more discoverable to potential users.',
        },
        {
            link: "/ppc-advertising-agency",
            imgSrc: <FaChartLine />,
            title: 'PPC Advertising',
            text: "Our targeted DeFi PPC advertising campaigns maximize your return on investment by reaching the right audience.",
        },
        {
            link: "/social-media-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Social Media Marketing',
            text: ' Our social media strategies amplify your DeFi project’s presence across key platforms, driving community growth.',
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'DeFi Influencer Marketing',
            text: ' Our DeFi influencer marketing connects you with industry leaders to boost credibility and expand your reach.'
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'DeFi Content Marketing',
            text: 'Our DeFi content marketing services create engaging, informative content that attracts and retains your target audience.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DeFi Email Marketing ',
            text: 'Our effective DeFi email marketing campaigns keep your audience informed and engaged, driving conversions',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DeFi PR Marketing',
            text: 'Our DeFi PR marketing secures top-tier media coverage, enhancing your project’s visibility and reputation.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Community Marketing',
            text: 'Our DeFi community marketing builds a loyal and active user base, fostering long-term engagement and growth.',
        },

        {
            imgSrc: <FaChartLine />,
            title: 'Telegram And Discord Marketing',
            text: 'Our expert DeFi Telegram and Discord marketing services engage users and drive community interaction.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Video Marketing',
            text: 'Our compelling DeFi video marketing content captivates your audience, boosting engagement and brand awareness.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Omnichannel Marketing',
            text: " Our omnichannel marketing approach ensures your DeFi project reaches audiences across multiple platforms seamlessly",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Whitepaper Creation',
            text: "Our DeFi whitepaper and litepaper creation services clearly articulate your project’s value and technical details.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Bounty Campaign Management',
            text: "Our DeFi bounty campaign management drives user participation and rewards engagement effectively.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DeFi Exchange Marketing',
            text: "Our exchange marketing services increase your DeFi token's visibility and trading volume on key exchanges.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DeFi Token Marketing',
            text: "Our strategic DeFi token marketing enhances your token's appeal and adoption in the market.",
        },
        {
            link: "/gaming-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'DeFi Gaming Marketing',
            text: "Our DeFi gaming marketing services attract gamers and drive engagement through targeted strategies",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Crowdfunding Marketing',
            text: "Our crowdfunding platform marketing boosts visibility and support for your DeFi project’s fundraising efforts.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DeFi Wallet Marketing',
            text: " Our DeFi wallet marketing services drive adoption and trust in your wallet solution through targeted campaigns.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Lending and Borrowing Marketing',
            text: "Our tailored marketing for DeFi lending and borrowing platforms drives user acquisition and engagement.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DeFi Staking Marketing',
            text: "Our staking platform marketing strategies attract users, increasing staking participation and platform growth",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Yield Farming Marketing',
            text: "Our marketing services for yield farming platforms attract yield farmers and enhance platform liquidity.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Smart Contract Marketing',
            text: "Our smart contract marketing services highlight your project’s security and innovation, driving user confidence.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Insurance Marketing',
            text: "Our DeFi insurance marketing services communicate the value and security of your insurance solutions effectively.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Marketing Consulting',
            text: "Our DeFi marketing consulting provides expert insights and strategies to elevate your project’s marketing efforts.",
        },

    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (

        <div className='blockchain-sec-4'>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h2>Dominate the DeFi Landscape<span className='msec4-break'><br />
                            </span> XDMinds' Comprehensive DeFi Marketing Services
                            </h2>
                        </div>
                        <div className='industries-sub-head'>
                            <p>
                                Unlock the full potential of your DeFi project with XDMinds' comprehensive DeFi marketing services. Our expert team is dedicated to helping you dominate the DeFi landscape through strategic, data-driven approaches that deliver measurable results.
                            </p>
                        </div>
                        <div className='desktop-industries-view'>
                            {chunkedCardData.map((chunk, index) => (
                                <div className='home-industries-card-div' key={index}>
                                    <Row>
                                        {chunk.map((card, cardIndex) => (
                                            <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                {card.link ? (
                                                    <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Link>
                                                ) : (
                                                    <div className='c-default h-100'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <div className='mobile-view-industries'>
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={3}
                                loop={true}
                                dots="true"
                                onSwiper={(swiper) => setCardSwiper(swiper)}
                                modules={[Scrollbar, Pagination, Autoplay]}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}
                                className="ps-swiper"
                                breakpoints={{
                                    0: { slidesPerView: 1, spaceBetween: 10 },
                                    460: { slidesPerView: 1, spaceBetween: 5 },
                                    768: { slidesPerView: 1, spaceBetween: 30 },
                                    1024: { slidesPerView: 6, spaceBetween: 50 },
                                }}
                            >
                                {cardData.map((card, index) => (
                                    <SwiperSlide key={index}>
                                        {card.link ? (
                                            <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        ) : (
                                            <div className='c-default h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Section3
import React from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import GridCard from '../DigitalMarketing/GridCard';
import graph from '../../assets/images/graph.svg';
import Tabs from '../DigitalMarketing/Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from '../DigitalMarketing/BottomFaq';


const B2B = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const cardData = [
        {
            src: graph,
            alt: 'B2B GTM Strategy',
            title: 'B2B GTM Strategy',
            text: 'Our team develops comprehensive B2B marketing strategies that align with your business goals. From initial planning to execution, we ensure every aspect of your campaign is optimized for maximum impact and success.'
        },
        {
            src: graph,
            alt: 'B2B Keywords Research',
            title: 'B2B Keywords Research',
            text: 'We conduct in-depth keyword research tailored to your B2B industry, identifying high-value terms that drive traffic and conversions. Our keyword strategies are designed to boost your search engine rankings and visibility.'
        },
        {
            src: graph,
            alt: 'B2B Competitor Analysis',
            title: 'B2B Competitor Analysis',
            text: 'Gain a competitive edge with our thorough competitor analysis. We assess your rivals’ strengths and weaknesses, allowing us to develop strategies that position your B2B business ahead of the competition.'
        },
        {
            src: graph,
            alt: 'B2B Branding Building',
            title: 'B2B Branding Building',
            text: 'Establish a strong, memorable brand with our B2B branding services. We craft brand identities that resonate with your target audience and differentiate you in the marketplace.'
        },
        {
            src: graph,
            alt: 'B2B Web Portal Development',
            title: 'B2B Web Portal Development',
            text: 'Empower your business with our custom B2B web portal development services. We create secure, user-friendly portals that streamline communication and enhance collaboration, enabling you to manage complex business processes efficiently.'
        },
        {
            src: graph,
            alt: 'B2B Mobile App Development',
            title: 'B2B Mobile App Development',
            text: 'Stay connected with your clients on the go through our B2B mobile app development services. We design robust, intuitive mobile applications that cater to your business needs, enhancing customer engagement and driving operational efficiency.'
        },
        {
            src: graph,
            alt: 'B2B SEO',
            title: 'B2B SEO',
            text: 'Boost your online visibility with our tailored SEO services. Whether you need local, national, or international SEO, we optimize your website to rank higher and attract more qualified leads.'
        },
        {
            src: graph,
            alt: 'B2B ASO',
            title: 'B2B ASO',
            text: 'Boost your B2B business app’s visibility with our unparelled ASO services, ensuring higher rankings and increased downloads.Tailor your app’s success with our expert optimization strategies.'
        },
        {
            src: graph,
            alt: 'B2B Paid Advertising',
            title: 'B2B Paid Advertising',
            text: 'Drive targeted traffic to your business with our B2B paid advertising services. We create and manage campaigns across search engines and social media platforms to maximize your ROI.'
        },
        {
            src: graph,
            alt: 'Social Media Marketing',
            title: 'Social Media Marketing',
            text: 'Leverage the power of social media to engage with your audience and build lasting relationships. Our B2B social media marketing strategies are designed to increase brand awareness and foster customer loyalty.'
        },
        {
            src: graph,
            alt: 'B2B Lead Generation',
            title: 'B2B Lead Generation',
            text: 'Generate high-quality leads with our targeted lead generation strategies. We identify and engage with potential clients who are most likely to convert, driving your business growth.'
        },
        {
            src: graph,
            alt: 'B2B Demand Generation',
            title: 'B2B Demand Generation',
            text: 'Fuel your sales pipeline with our demand generation services. We create integrated marketing campaigns that increase demand for your products or services, helping you achieve sustained growth.'
        },
        {
            src: graph,
            alt: 'B2B Content Marketing',
            title: 'B2B Content Marketing',
            text: 'Engage your audience with compelling content that informs, educates, and persuades. Our content marketing services are tailored to your business needs, driving traffic and conversions.'
        },
        {
            src: graph,
            alt: 'B2B Email Marketing',
            title: 'B2B Email Marketing',
            text: 'Reach your target audience directly with personalized email marketing campaigns. We craft messages that resonate with your prospects, driving engagement and increasing conversions.'
        },
        {
            src: graph,
            alt: 'B2B Public Relations',
            title: 'B2B Public Relations',
            text: 'Build and maintain a positive public image with our B2B public relations services. We develop PR strategies that enhance your reputation and position your business as a leader in your industry.'
        },
        {
            src: graph,
            alt: 'B2B Influencer Marketing',
            title: 'B2B Influencer Marketing',
            text: 'Partner with industry influencers to amplify your brand’s reach. Our B2B influencer marketing services connect you with key figures who can boost your credibility and visibility.'
        },
        {
            src: graph,
            alt: 'B2B Performance Marketing',
            title: 'B2B Performance Marketing',
            text: 'Achieve measurable results with our performance marketing services. We focus on data-driven strategies that deliver high ROI, optimizing campaigns for continuous improvement.'
        },
        {
            src: graph,
            alt: 'B2B Programmatic Advertising',
            title: 'B2B Programmatic Advertising',
            text: 'Reach your target audience with precision through our programmatic advertising services. We use automated technology to deliver the right message to the right people at the right time.'
        },
        {
            src: graph,
            alt: 'Account Based Marketing (ABM)',
            title: 'Account Based Marketing (ABM)',
            text: 'Elevate your marketing efforts with our B2B account-based marketing (ABM) services, designed to target high-value accounts and deliver personalized, impactful campaigns.'
        },
        {
            src: graph,
            alt: 'B2B CRO',
            title: 'B2B CRO',
            text: 'Maximize your website’s performance with our CRO services. We analyze user behavior and make data-driven changes to increase your conversion rates and drive business growth.'
        },
        {
            src: graph,
            alt: 'B2B ORM',
            title: 'B2B ORM',
            text: 'Protect and enhance your brand’s online reputation with our ORM services. We monitor and manage your online presence to ensure a positive image across digital channels.'
        },
        {
            src: graph,
            alt: 'B2B Growth Marketing',
            title: 'B2B Growth Marketing',
            text: 'Accelerate your business growth with our tailored B2B marketing strategies, crafted to boost lead generation and maximize ROI.'
        },
        {
            src: graph,
            alt: 'B2B Marketing Automation',
            title: 'B2B Marketing Automation',
            text: 'Streamline your B2B marketing processes with our cutting-edge automation solutions to enhance efficiency and drive targeted engagement.'
        },
        {
            src: graph,
            alt: 'B2B Marketing Consulting',
            title: 'B2B Marketing Consulting',
            text: 'Leverage our expertise with tailored marketing consulting services. We provide strategic advice and actionable insights to help you overcome challenges and achieve your business goals.'
        },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Strategic Vision for Growth',
            sections: [
                {
                    content: 'At XDMinds, we start with a clear strategic vision that aligns with your long-term business goals. Our approach is designed to build a strong foundation for sustained growth, ensuring that your marketing efforts contribute to your overall success.'
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Tailored Solutions for Your Business',
            sections: [
                {
                    content: 'We understand that every business is unique. Our team crafts bespoke marketing strategies tailored to your specific needs, target audience, and market conditions, ensuring that our solutions drive meaningful and long-lasting results.'
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Comprehensive Market Understanding',
            sections: [
                {
                    content: 'Our deep understanding of the B2B landscape allows us to anticipate market trends and adapt strategies accordingly. We provide insights and recommendations based on thorough research and analysis, positioning your business for long-term success.'
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Focus on Relationship Building',
            sections: [
                {
                    content: 'Long-term success is built on strong relationships. We prioritize building and nurturing relationships with your clients, partners, and stakeholders, creating a loyal customer base that supports your business over the years.'
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Data-Driven Insights and Optimization',
            sections: [
                {
                    content: 'We utilize data-driven insights to refine and optimize our strategies continuously. By analyzing performance metrics and market data, we make informed adjustments to keep your marketing efforts effective and aligned with your long-term objectives.'
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Innovative Strategies and Techniques',
            sections: [
                {
                    content: 'Innovation is at the heart of our approach. We implement the latest marketing strategies and technologies to keep your business ahead of the competition, ensuring that your marketing remains relevant and impactful.'
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Long-Term Partnership Approach',
            sections: [
                {
                    content: 'We view our relationship with clients as a long-term partnership. Our commitment extends beyond individual campaigns, focusing on your overall growth and success. We work collaboratively to achieve your long-term business goals.'
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Consistent Performance Tracking',
            sections: [
                {
                    content: 'Regular performance tracking is essential for sustained success. We provide detailed reports and insights into your marketing efforts, allowing us to measure progress, identify areas for improvement, and adjust strategies as needed.'
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Commitment to Your Success',
            sections: [
                {
                    content: 'Your success is our top priority. We are dedicated to delivering results that not only meet but exceed your expectations. Our team’s commitment to your business ensures that we remain focused on achieving and maintaining long-term success.'
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What makes XDMinds' approach to B2B marketing unique?",
            answer: "XDMinds' approach is unique because we tailor our strategies to align with your specific business goals and market dynamics. We combine deep industry expertise with innovative techniques to create customized marketing solutions that drive measurable results and long-term success."
        },
        {
            question: "How does XDMinds ensure the success of its B2B marketing strategies?",
            answer: "We ensure success by employing a data-driven approach. Our team conducts thorough market research and performance analysis to inform our strategies. By continuously monitoring and optimizing campaigns, we adapt to changes and maximize the effectiveness of our marketing efforts."
        },
        {
            question: "What kind of results can I expect from working with XDMinds?",
            answer: "Working with XDMinds, you can expect improved brand visibility, increased lead generation, and higher ROI. Our strategies are designed to drive meaningful results, including enhanced customer engagement and long-term business growth."
        },
        {
            question: "How does XDMinds tailor its marketing solutions to fit my business?",
            answer: "We tailor our solutions by starting with a deep understanding of your business, target audience, and industry. Our team conducts detailed assessments and collaborates closely with you to develop customized marketing strategies that address your unique needs and objectives."
        },
        {
            question: "Can XDMinds help with both local and international B2B marketing?",
            answer: "Yes, XDMinds offers expertise in both local and international B2B marketing. We develop strategies that are effective in various geographic markets, ensuring that your business can reach and engage with your target audience regardless of location."
        },
        {
            question: "What role does data play in XDMinds' marketing strategies?",
            answer: "Data plays a crucial role in our marketing strategies. We use data to guide decision-making, measure campaign performance, and optimize our approaches. This ensures that our strategies are based on insights and trends that drive the best results for your business."
        },
        {
            question: "How often will I receive updates on my marketing campaign's performance?",
            answer: "We provide regular updates on your campaign’s performance through detailed reports and analysis. These updates include insights into key metrics, progress towards goals, and recommendations for ongoing optimization to keep your marketing efforts on track."
        },
        {
            question: "What is the process for getting started with XDMinds?",
            answer: "Getting started with XDMinds involves an initial consultation to understand your business needs and goals. From there, we develop a customized marketing strategy and collaborate with you throughout the implementation and optimization phases to ensure success."
        },
        {
            question: "How does XDMinds measure the ROI of its marketing campaigns?",
            answer: "We measure ROI by analyzing various performance metrics, such as lead generation, conversion rates, and overall campaign effectiveness. By comparing these metrics to the investment made, we assess the return on investment and ensure that our strategies deliver value for your business."
        },
    ]
    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    The Premier <br className='break' /> <span className='s-text-3'>B2B</span> Digital Marketing Agency
                </h1>
                <p className='p-text-1'>
                    XDMinds is the leading force in B2B marketing, driving innovative strategies that fuel business growth. As the premier B2B digital marketing agency, we understand the intricacies of the B2B landscape and craft customized marketing solutions designed to propel your business to new heights. With a focus on maximizing ROI and delivering measurable results, XDMinds stands as your trusted partner in achieving long-term success in an ever-evolving marketplace.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    XDMinds: Your Top-Rated B2B Digital Marketing Agency <br className='break' /> Specializing in Tailored Solutions for Maximum ROI
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we believe that every B2B business has unique needs and goals, which is why we specialize in creating tailored marketing solutions that drive maximum ROI. As a top-rated B2B digital marketing agency, we combine deep industry expertise with innovative strategies to deliver exceptional results for our clients. Our approach is data-driven, ensuring that every campaign is optimized for success. Whether you’re looking to enhance brand visibility, generate high-quality leads, or improve customer retention, XDMinds offers the expertise and resources to meet your business objectives. Partner with us to unlock your business’s full potential and achieve sustainable growth in today’s competitive market.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your b2b marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/b2b-marketing-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Accelerate Your Business with XDMinds' Result-Driven B2B Digital Marketing Services and Innovative Strategies
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we specialize in propelling businesses forward with our result-driven B2B digital marketing services. Our innovative strategies are designed to meet the unique challenges of the B2B landscape, ensuring that your business not only keeps pace with the competition but leads the way. Whether you're looking to enhance brand visibility, generate high-quality leads, or achieve sustained growth, XDMinds offers the expertise and tailored solutions needed to accelerate your business to new heights. Partner with us to unlock your business's full potential and achieve measurable success.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Why XDMinds Is the Best Choice as Your B2B Marketing Agency <br className='break' /> for Building Long-Term Business Success?
                </h2>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest B2B Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default B2B
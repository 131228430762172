import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import '../../../Services/Blockchain/Blockchain.css';
import { Link } from 'react-router-dom';


const DubaiWebServices = () => {
    const cardData = [
        {
            
            imgSrc: <FaChartLine />,
            title: 'Web3 Marketing Strategy Development',
            text: "We develop tailored Web3 marketing strategies that align with your brand’s objectives, utilizing decentralized technologies to reach new audiences and build communities.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'SEO For Web3 Projects',
            text: "Boost your Web3 project’s visibility with targeted SEO tactics that improve your rankings on decentralized and traditional search engines.",
        },
        {
            
            imgSrc: <FaChartLine />,
            title: ' Generative Engine Optimization (GEO) For Web3 Projects',
            text: "We optimize your Web3 content for generative engines, ensuring that your project stays relevant in the rapidly evolving decentralized search landscape.",
        },
        {
            imgSrc: <FaChartLine />,
            title: ' SEM For Web3 Projects',
            text: 'Our SEM strategies combine paid search techniques with Web3 technologies to drive traffic and maximize ROI for your decentralized projects.',
        },
        {
            imgSrc: <FaChartLine />,
            title: ' Crypto Ads & Web3 Advertising Platforms',
            text: 'Leverage crypto-specific ad platforms and targeted campaigns to reach niche audiences in the Web3 ecosystem, boosting brand awareness and conversions.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Social Media Marketing For Web3 Projects',
            text: "We craft tailored social media strategies for Web3 projects, fostering engagement and community growth on platforms like Twitter, Discord, and Telegram.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Decentralized Social Media Marketing',
            text: 'Harness the power of decentralized social networks to promote your Web3 brand, building trust and authenticity in the blockchain community.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'ASO For Web3 Projects',
            text: 'Our App Store Optimization (ASO) services ensure your Web3 apps get maximum visibility and downloads in decentralized and traditional app stores.'
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Content Creation & Storytelling',
            text: 'We create compelling, SEO-optimized content that tells your Web3 brand’s story, helping to engage audiences and drive long-term loyalty.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'DAO Marketing & Governance Solutions',
            text: 'We offer marketing strategies tailored for Decentralized Autonomous Organizations (DAOs), helping you build communities and engage members in governance.',
        },
        {
            imgSrc: <FaChartLine />,
            title: ' Metaverse Marketing',
            text: 'Tap into the rapidly growing metaverse economy with innovative marketing strategies that position your brand within virtual worlds and immersive experiences.',
        },
        {
            imgSrc: <FaChartLine />,
            title: ' NFT Marketing',
            text: 'Our NFT marketing services are designed to create buzz, build communities, and drive sales for your non-fungible token projects across global platforms.',
        },
        {
            imgSrc: <FaChartLine />,
            title: ' Blockchain Marketing',
            text: 'We specialize in blockchain marketing, helping businesses showcase the benefits of their technology and build trust in the decentralized ecosystem.',
        },
        {
            imgSrc: <FaChartLine />,
            title: ' DeFi Marketing',
            text: 'Promote your decentralized finance (DeFi) projects with tailored marketing campaigns that educate, engage, and convert potential users.',
        },
        {
            imgSrc: <FaChartLine />,
            title: ' dApp Marketing',
            text: 'Our marketing solutions for decentralized applications (dApps) focus on user acquisition, retention, and growth, ensuring your app reaches its full potential.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'ICO Marketing',
            text: "Launch your Initial Coin Offering (ICO) with a robust marketing strategy designed to build trust, attract investors, and drive project success.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'STO Marketing',
            text: "We provide expert marketing services for Security Token Offerings (STOs), helping you navigate compliance while reaching the right investors.",
        },
        {
            imgSrc: <FaChartLine />,
            title: ' IDO Marketing',
            text: "Our Initial DEX Offering (IDO) marketing strategies ensure your token sale is a success, maximizing visibility and attracting liquidity on decentralized exchanges.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'IEO Marketing',
            text: "We support your Initial Exchange Offering (IEO) with targeted marketing campaigns that generate awareness and encourage participation on exchange platforms.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Crypto Exchange Website and App Marketing',
            text: "We offer comprehensive marketing services for crypto exchange websites and apps, focusing on user acquisition and engagement to boost trading volumes.",
        },
        {
            imgSrc: <FaChartLine />,
            title: ' Crypto Wallet App Marketing',
            text: "Promote your crypto wallet app with strategies designed to attract users and enhance their experience, driving downloads and active usage.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Community Building & Management',
            text: "Build and manage thriving communities around your Web3 projects with our expert strategies, fostering engagement, loyalty, and advocacy.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Influencer & KOL (Key Opinion Leader) Marketing',
            text: "Leverage the power of influencers and key opinion leaders to build credibility and trust for your Web3 brand in the digital and blockchain spaces.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Paid Advertising & Campaigns',
            text: "We create data-driven paid advertising campaigns tailored to the Web3 landscape, delivering measurable results across multiple channels.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Decentralized Paid Advertising',
            text: "Advertise your Web3 project on decentralized platforms, utilizing blockchain-powered ads to reach a global audience while ensuring transparency.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 PR & Media Outreach',
            text: "Our PR and media outreach services help position your Web3 brand as an industry leader, gaining coverage on top blockchain and crypto media outlets.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Cross-chain Marketing Solutions',
            text: "We offer cross-chain marketing strategies that help your project gain visibility across multiple blockchain ecosystems, enhancing reach and user engagement.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Event Marketing & Sponsorships',
            text: "Boost your presence at Web3 events and conferences with tailored marketing strategies, including sponsorships, to increase visibility and connect with key industry players.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Web3 Analytics & Performance Tracking',
            text: "We provide in-depth analytics and performance tracking for all Web3 marketing campaigns, allowing you to measure success and make data-driven decisions.",
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Reputation Management in the Web3 Space',
            text: "Protect and enhance your brand’s reputation in the Web3 space with proactive strategies that address issues, amplify positive sentiment, and build trust.",
        },
       

    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (
        <div className='game-sec-2'>
            <div className='blockchain-sec-4'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>XDMinds: Comprehensive Web3 Marketing Services<span className='msec4-break'><br />
                                </span>Tailored for Businesses in Dubai, United Arab Emirates
                                <span className='msec4-break'><br /></span>
                                Driving Innovation in the Digital Economy
                                </h2>
                            </div>
                            <div className='industries-sub-head'>
                                <p>
                                At XDMinds, we understand the unique challenges and opportunities in the Web3 ecosystem. Our comprehensive Web3 marketing solutions are designed to address the specific needs of businesses in Dubai and the global decentralized economy. From blockchain and NFT marketing to DeFi and DAO strategies, we offer an integrated approach that combines SEO, SEM, and social media tactics with innovative Web3 technologies. With a focus on delivering measurable results, we help businesses achieve growth and visibility in the new digital frontier.
                                </p>
                            </div>

                            <div className='desktop-industries-view'>
                                {chunkedCardData.map((chunk, index) => (
                                    <div className='home-industries-card-div' key={index}>
                                        <Row>
                                            {chunk.map((card, cardIndex) => (
                                                <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                    {card.link ? (
                                                        <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Link>
                                                    ) : (
                                                        <div className='c-default h-100'>
                                                            <Card className='industries-card'>
                                                                <Card.Body>
                                                                    <div className='dmarketing-card-head'>
                                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                    </div>
                                                                    <Card.Text>
                                                                        {card.text}
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                            </div>
                            <div className='mobile-view-industries'>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    loop={true}
                                    dots="true"
                                    onSwiper={(swiper) => setCardSwiper(swiper)}
                                    modules={[Scrollbar, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{ clickable: true }}
                                    className="ps-swiper"
                                    breakpoints={{
                                        0: { slidesPerView: 1, spaceBetween: 10 },
                                        460: { slidesPerView: 1, spaceBetween: 5 },
                                        768: { slidesPerView: 1, spaceBetween: 30 },
                                        1024: { slidesPerView: 6, spaceBetween: 50 },
                                    }}
                                >
                                    {cardData.map((card, index) => (
                                        <SwiperSlide key={index}>
                                            {card.link ? (
                                                <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            ) : (
                                                <div className='c-default h-100'>
                                                    <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Card.Body>
                                                            <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                            <Card.Title><h4>{card.title}</h4></Card.Title>
                                                            <Card.Text>{card.text}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default DubaiWebServices
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MdArrowCircleDown } from "react-icons/md";

const Section5 = () => {

  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  const data = [
    {
      number: 1,
      title: 'Expertise in NFT Marketing',
      description: 'At XDMinds, we are pioneers in the NFT marketing landscape, equipped with a deep understanding of the unique dynamics of the NFT market. Our team of seasoned professionals brings extensive experience in digital marketing, blockchain technology, and the evolving world of NFTs, ensuring your project receives top-tier strategic guidance and execution.'
    },
    {
      number: 2,
      title: 'Customized Marketing Strategies',
      description: "We recognize that every NFT project is unique. Our approach involves crafting tailored marketing strategies that align with your specific goals and audience. From creating compelling narratives to selecting the most effective channels, we develop bespoke plans that maximize your project's visibility and impact."
    },
    {
      number: 3,
      title: 'Data-Driven Approach',
      description: 'In the rapidly changing NFT space, data is paramount. We utilize advanced analytics to track performance, understand audience behavior, and refine our strategies. Our data-driven approach ensures that your marketing efforts are continuously optimized for better results and higher ROI.'
    },
    {
      number: 4,
      title: 'Comprehensive Service Offering',
      description: "XDMinds offers a full suite of marketing services designed to cover all aspects of NFT promotion. Whether it's SEO, PPC advertising, social media marketing, influencer partnerships, or community building, we have the expertise to handle every facet of your campaign. Our holistic approach ensures a cohesive and effective marketing strategy."
    },
    {
      number: 5,
      title: 'Proven Track Record',
      description: 'Our portfolio speaks volumes about our capabilities. We have successfully launched and promoted numerous NFT projects, helping them achieve significant milestones in terms of visibility, engagement, and sales. Our proven track record demonstrates our ability to deliver results and drive success for our clients.'
    },
    {
      number: 6,
      title: 'Cutting-Edge Technology',
      description: "Staying ahead of the curve is crucial in the NFT industry. We leverage the latest technologies and tools to enhance your marketing efforts. From utilizing AI for personalized marketing to blockchain analytics for deeper insights, we ensure your project benefits from the best technology available."
    },
    {
      number: 7,
      title: 'Strong Industry Connections',
      description: "Our extensive network within the NFT and blockchain community provides us with valuable connections and opportunities. We collaborate with key influencers, marketplaces, and platforms to give your project the needed exposure. These relationships enable us to open doors that might otherwise remain closed."
    },
    {
      number: 8,
      title: 'Transparent Communication',
      description: "We believe in maintaining transparency with our clients. Regular updates, detailed reports, and open lines of communication ensure that you are always informed about the progress of your campaign. We value your input and work collaboratively to achieve your desired outcomes."
    },
    {
      number: 9,
      title: 'Commitment to Excellence',
      description: "At XDMinds, excellence is not just a goal but a standard. We are committed to delivering the highest quality of service and achieving outstanding results for your NFT project. Our dedication to excellence drives us to go above and beyond in every campaign we undertake."
    },
    {
      number: 10,
      title: 'Passion for Innovation',
      description: "The NFT space is driven by creativity and innovation, and so are we. Our passion for exploring new ideas and pushing boundaries ensures that your marketing campaign is not only effective but also pioneering. We strive to create groundbreaking strategies that set your project apart from the competition.Choosing XDMinds as your NFT marketing partner means aligning with a team that is as invested in your success as you are. Let us help you navigate the complexities of the NFT market and achieve unparalleled growth and recognition for your project."
    },

  ];

  return (
    <div>
      <div className='blockchain-sec5'>
        <Container>
          <Row>
            <Col>
              <h2>Why Choose Us As an NFT Marketing Partner?
              </h2>
              <p className='subhead'>
                Choosing XDMinds as your blockchain marketing partner offers numerous advantages that can significantly elevate your project's success. Here's why we stand out:
              </p>

              <div className='metaverse-sec5 desktop-view mobile-hide'>
                <Row>
                  {data.map((item, index) => (
                    <Col
                      key={index}
                      lg={4}
                      xl={4}
                      sm={6}
                      md={6}
                      style={item.number % 3 === 0 ? { border: 'none' } : {}}
                    >
                      <div className='number-div'>
                        <div className='bc-sec5-carde-head'>
                          <p className='bc-sec5-carde-head-number'>{item.number}</p>
                          <p>{item.title}</p>
                        </div>
                        <p className='bc-sec5-card-discription'>{item.description}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='about'>
        <section className='about-section-5 p-0'>
          <div className='custom-accordion desktop-hide tab-hide'>
            <div className="accordion" id="accordionExample">
              {data.map((item, index) => (
                <div key={item.id} className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                      onClick={() => handleClick(index)}
                      type="button"
                      aria-expanded={openIndex === index}
                      aria-controls={item.id}
                    >
                      {item.title}
                      <div className="arrow-icon-btn">
                        <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                      </div>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className='p-text-1'>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Section5
import React from 'react'
import DappBanner from '../../Components/Services/Dapps/DappBanner'
import Section1 from '../../Components/Services/Dapps/Section1'
import Section2 from '../../Components/Services/Dapps/Section2'
import Section3 from '../../Components/Services/Dapps/Section3'
import Section4 from '../../Components/Services/Dapps/Section4'
import Section5 from '../../Components/Services/Dapps/Section5'
import Latest from '../../Components/Home/Latest/Latest'
import DappsFAQ from '../../Components/Services/Dapps/DappsFAQ'


const Dapps = (props) => {
  const { countryCode } = props;
  return (
    <div>
      <DappBanner countryCode={countryCode} />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Latest modifiedPath='Latest dApps Marketing Insights' />
      <DappsFAQ />


    </div>
  )
}

export default Dapps
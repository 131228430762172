import React, { useRef } from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs';

const TermsandCondition = () => {
    const targetHeadingRef = useRef(null);

    const handleScrollToOpenPositions = () => {
        if (targetHeadingRef.current) {
            const targetPosition = targetHeadingRef.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: targetPosition - 100, behavior: 'smooth' });
        }
    };
    return (
        <div className='terms-and-condition'>
            <div className='digital-marketing'>
                <section className='tc-banner min-100vh digital-marketing-banner'>
                    <BreadCrumbs />
                    <h1 className='h-text-1'>
                        Terms and Conditions
                    </h1>
                    <p className='p-text-1'>
                        Welcome to XDMinds, your premier Web3 marketing agency specializing in
                        cutting-edge web3 digital marketing solutions. By accessing our website and engaging our services,
                        you agree to comply with the following terms and conditions
                    </p>
                    <button className='request-btn ' onClick={handleScrollToOpenPositions}>
                        Read Terms and Conditions
                    </button>
                </section >
            </div>
            <section className='tc-section-2' ref={targetHeadingRef}>
                <h2 className='h-text-2'>Services Offered: </h2>
                <p className='p-text-3'>
                    XDMinds provides comprehensive Web3 marketing services alongside traditional digital marketing solutions.
                    Our services include but are not limited to search engine optimization (SEO), social media marketing, content creation, pay-per-click (PPC) advertising,
                    AI marketing, metaverse marketing, crypto marketing, DeFi marketing, dApp marketing, blockchain marketing, and more. These services are
                    tailored to meet the unique needs of businesses of all sizes and industries.
                </p>
            </section>
            <section className='tc-section-2'>
                <h2 className='h-text-2'>Client Responsibilities:  </h2>
                <p className='p-text-3'>
                    Clients are responsible for providing accurate and current information necessary for XDMinds to deliver services effectively.
                    We reserve the right to suspend or terminate services if
                    clients fail to fulfill their obligations or provide misleading information.
                </p>
            </section>
            <section className='tc-section-2'>
                <h2 className='h-text-2'>Intellectual Property:</h2>
                <p className='p-text-3'>
                    All content, designs, logos, and intellectual property provided by XDMinds remain our exclusive property
                    unless otherwise agreed upon in writing. Clients may not reproduce, modify, distribute,
                    or display our intellectual property without prior consent.
                </p>
            </section>
            <section className='tc-section-2'>
                <h2 className='h-text-2'>Payment Terms:</h2>
                <p className='p-text-3'>
                    Payment terms are outlined in individual service agreements and invoices. Clients agree to adhere to the payment schedule specified therein.
                    XDMinds reserves the right to suspend services for overdue accounts or charge interest on late payments.
                    Refunds are subject to the terms detailed in our refund policy.
                </p>
            </section>
            <section className='tc-section-2'>
                <h2 className='h-text-2'>Confidentiality:</h2>
                <p className='p-text-3'>
                    XDMinds treats all client information as confidential and implements robust measures to protect data privacy. Clients agree to maintain the confidentiality of
                    information exchanged during our business engagement.
                </p>
            </section>
            <section className='tc-section-2'>
                <h2 className='h-text-2'>Limitation of Liability: </h2>
                <p className='p-text-3'>
                    While XDMinds strives to deliver high-quality and effective services, we do not guarantee specific outcomes or results.
                    Clients acknowledge that XDMinds shall not be liable for any direct, indirect, incidental,
                    or consequential damages arising from the use of our services.
                </p>
            </section>
            <section className='tc-section-2'>
                <h2 className='h-text-2'>Modification of Terms: </h2>
                <p className='p-text-3'>
                    XDMinds reserves the right to modify these terms and conditions at any time.
                    Updates will be effective upon posting on our website.
                    Continued use of our services constitutes acceptance of the revised terms.
                </p>
            </section>
            <section className='tc-section-2'>
                <h2 className='h-text-2'>Governing Law:  </h2>
                <p className='p-text-3 p-text-m'>
                    These terms and conditions are governed by the laws of [The high courts of India],
                    without regard to its conflict of law provisions.
                </p>

                <p className='p-text-3 p-text-m'>
                    By engaging with XDMinds' services, you acknowledge that you have read, understood, and agreed to abide by these terms and conditions. If you have any questions or concerns
                    regarding our terms, please contact us at <a className='mail-a' href="mailto:contact@xdminds.com">
                        contact@xdminds.com
                    </a>.
                </p>
            </section>
        </div>
    )
}

export default TermsandCondition

import React, { useEffect, useState } from 'react'
import './Latest.css'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import { HiArrowLongRight } from "react-icons/hi2";
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GetAllBlogApi } from '../../../Helper/Redux/ReduxThunk/Homepage';


const Latest = ({ modifiedPath }) => {

  const [latestCategoryBlog, setLatestCategoryBLog] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    getLatestBlogCategory();
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const getLatestBlogCategory = async () => {
    // let datas = {
    //   blog_category_url: params.id,
    // }

    if (window.location.pathname === "/") {
      await dispatch(GetAllBlogApi((resp) => {
        if (resp.status === true) {
          setLatestCategoryBLog(resp?.data)
        }
      }))
    }
  }

  const getVisibleCards = () => {
    if (!latestCategoryBlog) return [];

    return windowWidth < 767 ? latestCategoryBlog.slice(0, 1) : latestCategoryBlog.slice(0, 3);
  };

  return (
    <>
      {latestCategoryBlog && latestCategoryBlog?.length > 0 ? (
        <div className='latest'>
          <Container>
            <Row>
              <Col>
                <div className='latest-content'>
                  <h3>{modifiedPath}</h3>
                </div>
                <div className='desktop-view-latest'>
                  <Row>
                    {getVisibleCards().map((card) => (
                      <Col xl={4} lg={4} md={6} sm={12} key={card._id}>
                        <Link reloadDocument to={`/${card.blog_url}`} className='text-decoration-none'>
                          <Card className={`latest-card`}>
                            <Card.Img variant="top" src={card.blog_image} alt={card.blog_image_alt_title} className='latest-card-img' />
                            <Card.Body>
                              <Card.Title className='latest-card-title'>
                                {card.blog_title}
                                <span>
                                  <div className='latest-arrow-incard-div'>
                                    <Link reloadDocument to={`/${card.blog_url}`} className='btn-latest-arrow-incard'>
                                      <HiArrowLongRight type='submit' className='latest-arrow-incard' />
                                    </Link>
                                  </div>
                                </span>
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Link>
                        <span className='latest-break'><br /></span>
                      </Col>
                    ))}
                  </Row>
                  <div className='latest-navi-div'>
                    <h4 className='l-navi-text'>
                      <Link reloadDocument to='/blog' className='arrow-navi-btn-latest'>
                        <HiArrowLongRight className='latest-navi-icon' />
                      </Link>
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : ('')
      }
    </>
  )
}

export default Latest
import React, { useState } from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import EventCard from '../DigitalMarketing/EventCard';
import Mchallenge from '../../Components/Home/MarketingChallenge/Mchallenge';
import BreadCrumbs from '../../Components/BreadCrumbs';
import { MdArrowCircleDown } from "react-icons/md";



const About = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const eventCardData = [
        {
            title: "Vision",
            sections: [
                {
                    content: "Our vision is to revolutionize the digital marketing landscape by leading the transition to a decentralized internet. We aim to empower brands with the tools and strategies needed to succeed in the Web3 ecosystem, fostering a more transparent, secure, and user-centric digital world."
                },
            ]
        },
        {
            title: "Mission",
            sections: [
                {
                    content: "Our mission is to provide unparalleled Web3 marketing services that drive growth, engagement, and brand loyalty. We strive to deliver innovative solutions that harness the power of blockchain technology, ensuring our clients remain at the cutting edge of digital marketing."
                },
            ]
        },
        {
            title: "Core Values",
            sections: [
                {
                    subtitle: "Innovation:",
                    content: " We continuously explore and integrate the latest advancements in Web3 and blockchain technology to deliver innovative marketing solutions."
                },
                {
                    subtitle: "Transparency:",
                    content: "We prioritize open and honest communication, ensuring our clients have complete visibility into our processes and results."
                },
                {
                    subtitle: "Integrity:",
                    content: "We uphold the highest ethical standards, fostering trust and reliability in all our client interactions."
                },
                {
                    subtitle: "Excellence:",
                    content: "We are committed to delivering exceptional results, driven by a passion for excellence in every project we undertake."
                },
                {
                    subtitle: "Collaboration:",
                    content: "We believe in the power of teamwork, working closely with our clients to achieve shared goals and mutual success."
                },
            ]
        },
    ]
    const cardData = [
        {
            id: 1,
            title: 'Expertise in Web3 Technologies',
            description: 'XDMinds boasts a team of experts who are well-versed in the latest Web3 technologies, including blockchain, decentralized applications (dApps), and smart contracts. Our deep technical knowledge ensures that we can develop and implement cutting-edge marketing strategies tailored to the unique needs of your Web3 project.'
        },
        {
            id: 2,
            title: 'Tailored Marketing Strategies',
            description: 'We understand that no two brands are the same. XDMinds provides customized marketing solutions that are designed to meet the specific goals and challenges of your business. Our data-driven approach ensures that our strategies are not only innovative but also highly effective.'
        },
        {
            id: 3,
            title: 'Transparent and Ethical Practices',
            description: 'Transparency and integrity are at the core of our operations. We maintain open lines of communication with our clients, providing regular updates and detailed reports on campaign performance. Our ethical practices build trust and long-term relationships with our clients.'
        },
        {
            id: 4,
            title: 'Proven Track Record',
            description: 'XDMinds has a proven track record of success in the Web3 marketing space. Our portfolio includes a diverse range of successful campaigns that have driven significant growth and engagement for our clients. We leverage our experience to deliver results that exceed expectations.'
        },
        {
            id: 5,
            title: 'Community-Centric Approach',
            description: 'In the Web3 world, community is everything. XDMinds focuses on building and nurturing vibrant, engaged communities around your brand. Our strategies include community management, social media engagement, and incentivized participation, ensuring that your brand has a loyal and active following.'
        },
        {
            id: 6,
            title: 'Innovative Tools and Technologies',
            description: 'We utilize the latest digital marketing tools and technologies to enhance our services. From advanced keyword research tools like Ahrefs and SEMrush to performance tracking platforms like Google Analytics and Mixpanel, our tech stack ensures precise and impactful marketing efforts.'
        },
        {
            id: 7,
            title: 'Comprehensive Service Offering',
            description: 'XDMinds provides a full suite of marketing services, including SEO, content marketing, social media management, influencer partnerships, and more. Our holistic approach ensures that all aspects of your marketing are seamlessly integrated and aligned with your overall business objectives.'
        },
        {
            id: 8,
            title: 'Focus on ROI',
            description: 'We prioritize return on investment (ROI) in all our campaigns. By closely monitoring key performance indicators (KPIs) and continuously optimizing our strategies, we ensure that your marketing budget is spent efficiently and delivers maximum value.'
        },
        {
            id: 9,
            title: 'Adaptability and Agility',
            description: 'The Web3 landscape is constantly evolving, and so are we. XDMinds stays ahead of industry trends and adapts quickly to changes in the market. Our agile approach allows us to pivot strategies as needed to keep your brand at the forefront of innovation.'
        },
        {
            id: 10,
            title: 'Client-Centric Philosophy',
            description: 'At XDMinds, our clients come first. We take the time to understand your vision, goals, and challenges. Our dedicated team works collaboratively with you to achieve your objectives and ensure your satisfaction at every stage of the partnership.'
        }

    ];
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <div className='about'>

            <section className='about-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Industry-Leading<br /><span className='s-text-1'>Web3</span> Marketing Agency
                </h1>
                <p className='p-text-1'>
                    At XDMinds, we are at the forefront of the Web3 revolution, dedicated to transforming your brand with cutting-edge marketing strategies. Our expertise in decentralized technologies and blockchain-based solutions ensures that your brand not only stands out in the digital landscape but also thrives in the new era of the internet. Join us on a journey to elevate your brand with innovative and impactful Web3 marketing..
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section >
            <section className='about-section-2 a-bg'>
                <h2 className='h-text-2'>
                    XDMinds: Your Trusted Web3 Marketing Advisor
                </h2>
                <p className='p-text-2'>
                    At XDMinds, we pride ourselves on being your reliable partner in navigating the
                    complexities of Web3 marketing. With a team of seasoned professionals passionate
                    about decentralization and blockchain technology, we offer tailored strategies that
                    align with your unique business goals. Our commitment to transparency, innovation,
                    and client success sets us apart as the go-to advisor for brands looking to leverage the full
                    potential of Web3.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Web3 marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/about ' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='about-section-3'>
                <h2 className='h-text-2'>
                    Who We Are?
                </h2>
                <p className='p-text-1'>
                    XDMinds is more than just a marketing agency; we are your strategic partners in navigating
                    the complexities of Web3. Our team of experts is passionate about the potential of decentralized
                    technologies to revolutionize industries and create new opportunities. We stay ahead of trends,
                    leveraging our extensive knowledge and innovative
                    approaches to deliver exceptional results for our clients.
                </p>
                <div className='sec-3-card'>
                    <EventCard eventCardData={eventCardData} />
                </div>
            </section>
            <section className='about-section-4 a-bg'>
                <h2 className='h-text-2'>
                    How XDMinds Differs from Other <br className='break' />Web3 Marketing Agencies?
                </h2>
                <div className='custom-div-sec-4'>
                    <p className='p-text-3'>
                        XDMinds stands out in the Web3 marketing landscape due to our deep understanding of
                        decentralized technologies and our commitment to client success. Unlike other agencies,
                        we offer a holistic approach that combines traditional digital marketing expertise with
                        innovative Web3 strategies. Our personalized, data-driven solutions ensure
                        that your brand not only adapts to but thrives in the Web3 era.
                    </p>
                </div>
            </section>
            <section className='about-section-5'>
                <h2 className='h-text-2'>
                    Why Choose XDMinds as Your <br className='break' />Web3 Marketing Partner?
                </h2>
                <div className='custom-div-5 mobile-hide'>
                    <div className='row g-3'>
                        {cardData.map((card) => (
                            <div key={card.id} className='col-lg-4 col-md-6 col-12'>
                                <div className='card custom-card-5 h-100'>
                                    <div className='custom-card-head'>
                                        <h1 className='h-text-3'>{card.id}</h1>
                                        <h2 className='h-text-4'>{card.title}</h2>
                                    </div>
                                    <div className='card-body'>
                                        {card.description && <p className='p-text-4'>{card.description}</p>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='custom-accordion desktop-hide tab-hide'>
                    <div className="accordion" id="accordionExample">
                        {cardData.map((item, index) => (
                            <div key={item.id} className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                        onClick={() => handleClick(index)}
                                        type="button"
                                        aria-expanded={openIndex === index}
                                        aria-controls={item.id}
                                    >
                                        {item.title}
                                        <div className="arrow-icon-btn">
                                            <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                                        </div>
                                    </button>
                                </h2>
                                <div
                                    id={item.id}
                                    className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <p className='p-text-1'>{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Mchallenge />
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default About

import React from 'react'
import MBanner from '../../Components/Services/Metaverse/MBanner'
import Section1 from '../../Components/Services/Metaverse/Section1'
import Section3 from '../../Components/Services/Metaverse/Section3'
import Section4 from '../../Components/Services/Metaverse/Section4'
import Section5 from '../../Components/Services/Metaverse/Section5'
import Latest from '../../Components/Home/Latest/Latest'
import Section6FAQ from '../../Components/Services/Metaverse/Section6FAQ'



const Metaverse = (props) => {
  const { countryCode } = props;

  return (
    <div>
      <MBanner countryCode={countryCode} />
      <Section1 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Latest modifiedPath='Latest Metaverse Marketing Insights' />
      <Section6FAQ />

    </div>
  )
}

export default Metaverse
import React, { useState } from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BreadCrumbs from '../../Components/BreadCrumbs';
import graph from '../../assets/images/graph.svg';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import Web3GridCard from '../DigitalMarketing/Web3GridCard';
import Latest from '../../Components/Home/Latest/Latest';
import FAQblockchain from '../../Components/Services/Blockchain/FAQblockchain';
import { MdArrowCircleDown } from "react-icons/md";


const Ico = (props) => {
    const [openIndex, setOpenIndex] = useState(null);

    const { countryCode } = props;

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const cardData = [
        {
            id: 1,
            title: 'In-Depth Industry Knowledge',
            description: "XDMinds brings extensive knowledge of the blockchain and cryptocurrency industry. Our team stays updated with the latest trends, regulations, and technologies, ensuring that your ICO marketing strategy is both current and compliant. We understand the unique challenges and opportunities within the ICO landscape, allowing us to craft tailored solutions that drive success."
        },
        {
            id: 2,
            title: 'Comprehensive Service Offering',
            description: "We offer a full suite of ICO marketing services that cover every stage of your project. From pre-ICO promotion and tokenomics consultation to post-ICO support and community management, XDMinds provides end-to-end solutions. Our holistic approach ensures that all aspects of your ICO are strategically planned and executed for maximum impact."
        },
        {
            id: 3,
            title: 'Customized Marketing Strategies',
            description: "At XDMinds, we recognize that each ICO is unique. We take the time to understand your project’s goals, target audience, and competitive landscape. This allows us to develop customized marketing strategies that align with your specific needs and objectives. Our personalized approach ensures that your ICO stands out in a crowded market."
        },
        {
            id: 4,
            title: 'Proven Success Methodologies',
            description: "We employ proven methodologies and best practices to ensure the success of your ICO. Our strategies are data-driven and performance-oriented, focusing on achieving measurable results. Whether it’s through targeted social media campaigns, influencer partnerships, or search engine optimization, our techniques are designed to maximize visibility, engagement, and investment."
        },
        {
            id: 5,
            title: 'Transparent Communication',
            description: "Transparency is a cornerstone of our service. We believe in keeping our clients informed at every step of the process. Our regular updates, detailed reports, and open lines of communication ensure that you are always aware of the progress and performance of your marketing campaigns. This transparency builds trust and fosters a collaborative partnership."
        },
        {
            id: 6,
            title: 'Strong Community Engagement',
            description: "Building and maintaining a strong community is vital for ICO success. XDMinds excels at community building and management, leveraging platforms like Telegram and Discord to foster engagement and loyalty. Our strategies focus on creating a vibrant community of supporters and investors who are actively involved in your project’s journey."
        },
        {
            id: 7,
            title: 'Expert Content Creation',
            description: "High-quality content is key to educating and engaging potential investors. Our team of expert content creators develops compelling whitepapers, blog posts, press releases, and social media content that effectively communicates your project’s value proposition. This content not only informs but also builds credibility and trust."
        },
        {
            id: 8,
            title: 'Strategic Partnerships and Influencer Marketing',
            description: "We leverage our network of strategic partners and influencers to amplify your ICO’s reach. By collaborating with respected figures and platforms in the blockchain space, we enhance your project’s visibility and credibility. Influencer endorsements and partnerships can significantly boost investor interest and confidence."
        },
        {
            id: 9,
            title: 'Data-Driven Optimization',
            description: "Continuous improvement is essential for effective marketing. XDMinds uses advanced analytics and performance metrics to monitor and optimize your campaigns in real-time. We make data-driven adjustments to ensure that your marketing efforts are always aligned with your goals and deliver the best possible results."
        },
        {
            id: 10,
            title: 'Dedicated Support and Consultation',
            description: "Our commitment to your success extends beyond marketing execution. We provide ongoing support and consultation throughout your ICO journey. Our experts are always available to offer advice, address concerns, and adapt strategies as needed to ensure that your project stays on track and achieves its fundraising goals."
        },
        {
            id: 11,
            title: ' Ethical and Transparent Practices',
            description: "Trustworthiness is at the core of our operations. XDMinds adheres to ethical marketing practices and operates with full transparency. We prioritize your project's integrity and the trust of your investors, ensuring that all marketing activities are conducted responsibly and ethically."
        }

    ];


    const web3CardData = [
        {
            src: graph,
            alt: 'ICO Campaign Strategy and Planning',
            title: 'ICO Campaign Strategy and Planning',
            text: "Develop a robust ICO campaign strategy that outlines your goals, target audience, and promotional tactics, ensuring a well-coordinated and effective marketing effort."
        },
        {
            src: graph,
            alt: 'Pre-ICO Promotion',
            title: 'Pre-ICO Promotion',
            text: "Generate buzz and build anticipation for your ICO with targeted pre-ICO promotion activities, including social media teasers, content marketing, and influencer outreach."
        },
        {
            src: graph,
            alt: 'Post-ICO Promotion',
            title: 'Post-ICO Promotion',
            text: "Maintain momentum and investor engagement after your ICO with ongoing marketing efforts, updates, and community interactions to sustain interest and support."
        },
        {
            src: graph,
            alt: 'Tokenomics Consultation',
            title: 'Tokenomics Consultation',
            text: "Receive expert advice on designing and structuring your tokenomics to ensure a fair and attractive token distribution model that appeals to investors."
        },
        {
            src: graph,
            alt: 'Landing Page Development',
            title: 'Landing Page Development',
            text: "Create a compelling and informative landing page that highlights your ICO’s value proposition, key features, and benefits, optimizing for conversions and investor interest."
        },
        {
            src: graph,
            alt: 'Whitepaper Creation',
            title: 'Whitepaper Creation',
            text: "Craft a comprehensive and well-researched whitepaper that details your ICO project’s vision, technology, roadmap, and tokenomics, serving as a critical resource for potential investors."
        },
        {
            src: graph,
            alt: 'Social Media Campaigns',
            title: 'Social Media Campaigns',
            text: "Leverage social media platforms to reach a wide audience, engage with potential investors, and build a strong online presence through strategic content and interactions."
        },
        {
            src: graph,
            alt: 'ICO Content Marketing',
            title: 'ICO Content Marketing',
            text: " Develop and distribute high-quality content that educates and informs your audience about your ICO, fostering trust and credibility."
        },
        {
            src: graph,
            alt: 'Community Building and Management',
            title: 'Community Building and Management',
            text: "Cultivate a vibrant and engaged community on platforms like Telegram and Discord, providing support, updates, and fostering discussions around your ICO project."
        },
        {
            src: graph,
            alt: 'ICO Listing Services',
            title: 'ICO Listing Services',
            text: "Elevate your ICO’s visibility with XDMinds' expert listing services, designed to maximize exposure and attract top investors."
        },
        {
            src: graph,
            alt: 'Email Marketing Campaign',
            title: 'Email Marketing Campaign',
            text: "Utilize email marketing campaigns to keep your audience informed about your ICO’s progress, updates, and important milestones, nurturing investor relationships."
        },
        {
            src: graph,
            alt: 'Press Releases and Media Coverage',
            title: 'Press Releases and Media Coverage',
            text: "Distribute press releases and secure media coverage to enhance your ICO’s visibility and credibility within the blockchain and crypto community."
        },
        {
            src: graph,
            alt: 'Influencer Marketing',
            title: 'Influencer Marketing',
            text: "Partner with influential figures in the blockchain space to promote your ICO, leveraging their reach and authority to attract potential investors."
        },
        {
            src: graph,
            alt: 'Search Engine Optimization (SEO)',
            title: 'Search Engine Optimization (SEO)',
            text: "Optimize your online content to improve search engine rankings, driving organic traffic to your ICO’s website and landing pages."
        },
        {
            src: graph,
            alt: 'Paid Advertising',
            title: 'Paid Advertising',
            text: "Implement targeted paid advertising campaigns on platforms like Google Ads and social media to reach a broader audience and drive traffic to your ICO."
        },
        {
            src: graph,
            alt: 'Crypto Ads',
            title: 'Crypto Ads',
            text: "Elevate your ICO project's visibility with our targeted crypto ads. We strategically choose the best crypto ads platforms to drive maximum engagement and success for your ICO campaign."
        },
        {
            src: graph,
            alt: 'Airdrop Campaigns',
            title: 'Airdrop Campaigns',
            text: "Conduct airdrop campaigns to distribute tokens to a wider audience, increasing awareness and attracting potential investors to your ICO."
        },
        {
            src: graph,
            alt: 'Investor Relations Management',
            title: 'Investor Relations Management',
            text: "Elevate your ICO project's success with our expert investor relations management, ensuring seamless communication and trust-building with your investors."
        },
        {
            src: graph,
            alt: 'Analytics and Reporting',
            title: 'Analytics and Reporting',
            text: "Monitor and analyze the performance of your marketing campaigns with detailed analytics and reporting, making data-driven decisions for continuous improvement."
        },
        {
            src: graph,
            alt: 'ICO Consulting',
            title: 'ICO Consulting',
            text: "Receive expert consulting services throughout your ICO journey, from initial planning to post-ICO support, ensuring a smooth and successful fundraising process."
        },

    ];
    const faqData = [
        {
            question: " What is ICO marketing and why is it important? ",
            answer: "ICO marketing involves promoting an Initial Coin Offering to attract investors and build community support. It's crucial for creating awareness, generating interest, and securing the necessary funding for your project. Effective marketing can differentiate your ICO from competitors and drive its success."
        },
        {
            question: "How does XDMinds approach ICO marketing?",
            answer: " XDMinds uses a comprehensive, multi-channel strategy to market your ICO. We combine traditional digital marketing techniques with specialized blockchain promotion tactics, including social media campaigns, influencer partnerships, community management, and content marketing, to maximize visibility and engagement."
        },
        {
            question: "How can XDMinds help with pre-ICO and post-ICO promotion? ",
            answer: "For pre-ICO promotion, we build anticipation through targeted marketing campaigns, social media engagement, and influencer outreach. Post-ICO, we maintain momentum by keeping investors informed, continuing community engagement, and promoting ongoing project developments."
        },
        {
            question: "What makes XDMinds different from other ICO marketing agencies? ",
            answer: "XDMinds stands out due to our deep industry knowledge, customized marketing strategies, proven methodologies, transparent communication, and ethical practices. We provide end-to-end support, ensuring that all aspects of your ICO marketing are covered for optimal results."
        },
        {
            question: "How does XDMinds ensure the success of an ICO marketing campaign?",
            answer: " We use data-driven strategies and continuous optimization to ensure campaign effectiveness. By leveraging advanced analytics, we monitor performance and make necessary adjustments in real-time, ensuring your marketing efforts are always aligned with your goals and delivering the best possible outcomes."
        },
        {
            question: "Can XDMinds help with tokenomics consultation?",
            answer: "Yes, we provide expert tokenomics consultation to help you design a fair and attractive token distribution model. Our goal is to ensure your tokenomics appeal to investors and support the long-term sustainability of your project."
        },
        {
            question: "How does XDMinds handle community building and management?",
            answer: " We focus on creating and nurturing vibrant online communities on platforms like Telegram and Discord. Our strategies include regular updates, interactive discussions, and prompt responses to community queries, fostering a strong sense of engagement and loyalty among your supporters."
        },
        {
            question: "What role do influencers play in XDMinds' ICO marketing strategy? ",
            answer: "Influencers can significantly amplify your ICO’s reach and credibility. We collaborate with respected figures in the blockchain space to promote your ICO, leveraging their networks and influence to attract potential investors and boost confidence in your project."
        },
        {
            question: "How does XDMinds create and distribute content for ICO marketing? ",
            answer: "Our team of expert content creators develops high-quality, informative content such as whitepapers, blog posts, press releases, and social media updates. We ensure this content is widely distributed across relevant channels to educate and engage your target audience."
        },
        {
            question: " What kind of analytics and reporting does XDMinds provide? ",
            answer: "We offer detailed analytics and reporting on the performance of your marketing campaigns. This includes insights into traffic, engagement, conversion rates, and other key metrics. Our data-driven approach helps us optimize strategies for better results."
        },
        {
            question: "How does XDMinds handle paid advertising for ICOs? ",
            answer: "We implement targeted paid advertising campaigns on platforms like Google Ads, Crypto ad networks,  and social media to reach a broader audience. Our ads are designed to drive traffic to your ICO’s website and landing pages, enhancing visibility and investor interest."
        },
        {
            question: "Can XDMinds help with SEO for my ICO project?",
            answer: " Yes, we provide comprehensive SEO services to improve your online content’s search engine rankings. This drives organic traffic to your ICO’s website and increases the chances of attracting potential investors."
        },
        {
            question: "What is an airdrop campaign and how does XDMinds manage it? ",
            answer: "An airdrop campaign involves distributing free tokens to a wide audience to generate interest and attract potential investors. XDMinds designs and executes effective airdrop campaigns, ensuring they reach the right audience and maximize engagement."
        },
        {
            question: "How does XDMinds ensure ethical marketing practices? ",
            answer: "We adhere to strict ethical guidelines in all our marketing activities. Transparency, honesty, and integrity are at the core of our operations, ensuring that our marketing practices build trust and maintain the integrity of your ICO project."
        }
    ];

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className='ico  iot '>
            <div className='digital-marketing'>
                <section className='digital-marketing-banner min-100vh'>
                    <BreadCrumbs />
                    <h1 className='h-text-1'>
                        Your Go-To  <br /> ICO Marketing Agency
                    </h1>
                    <p className='p-text-1'>
                        Welcome to XDMinds, your premier ICO marketing agency specializing in innovative promotion strategies
                        for your Initial Coin Offering (ICO). We combine cutting-edge technology with proven marketing techniques to ensure your ICO stands out in the competitive blockchain landscape. Partner with us to maximize your reach, engage your audience,
                        and drive your ICO to success with our comprehensive Web3 marketing services.
                    </p>
                    <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
                </section>
                <section className='digital-marketing-section-2 d-bg'>
                    <h2 className='h-text-2'>
                        XDMinds: ROI-driven Digital Marketing Agency for <br className='break' /> Startups, Scale-Ups, Large-scale Enterprises, <br className='break' /> and Conglomerates
                    </h2>
                    <p className='p-text-2'>
                        XDMinds stands at the forefront of digital marketing innovation and is committed to delivering ROI-driven strategies
                        tailored for startups, scale-ups, large-scale enterprises, and conglomerates. Our holistic approach combines
                        creativity with data-driven insights to create compelling marketing campaigns that resonate with
                        your target audience. From increasing brand visibility to driving customer engagement and
                        conversions,we partner with you every step of the way to ensure your marketing
                        investment yields substantial returns. At XDMinds, our goal is to transform your digital presence into a powerful growth engine.
                    </p>
                    <div className='social-buttons w-80'>
                        <button className='social-btn'>
                            <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                            WRITE TO US IN WHATSAPP
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                            WRITE TO US IN TELEGRAM
                        </button>
                        <button className='social-btn'>
                            <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                            WRITE TO US IN SKYPE
                        </button>
                    </div>
                </section>
                <section className='digital-marketing-section-2 '>
                    <h2 className='h-text-2'>
                        Comprehensive ICO Marketing Services by XDMinds: <br className='break' />Elevate Your Initial Coin Offering with Expert Strategies

                    </h2>
                    <p className='p-text-2'>
                        Elevate your Initial Coin Offering with XDMinds' comprehensive ICO marketing services,
                        designed to provide expert strategies and unparalleled support throughout your ICO journey. Our holistic approach ensures every aspect of your
                        ICO is meticulously planned and executed for maximum impact and success.
                    </p>
                    <div className='grid-cards'>
                        <Web3GridCard web3CardData={web3CardData} />
                    </div>
                </section>
            </div>
            <div className='about'>
                <section className='about-section-5'>
                    <h2 className='h-text-2'>
                        Why Choose XDMinds as Your <br className='break' />Web3 Marketing Partner?
                    </h2>
                    <div className='custom-div-5 mobile-hide'>
                        <div className='row g-3'>
                            {cardData.map((card) => (
                                <div key={card.id} className='col-lg-4 col-md-6 col-12'>
                                    <div className='card custom-card-5 h-100'>
                                        <div className='custom-card-head'>
                                            <h1 className='h-text-3'>{card.id}</h1>
                                            <h2 className='h-text-4'>{card.title}</h2>
                                        </div>
                                        <div className='card-body'>
                                            {card.description && <p className='p-text-4'>{card.description}</p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='custom-accordion desktop-hide tab-hide'>
                        <div className="accordion" id="accordionExample">
                            {cardData.map((item, index) => (
                                <div key={item.id} className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                            onClick={() => handleClick(index)}
                                            type="button"
                                            aria-expanded={openIndex === index}
                                            aria-controls={item.id}
                                        >
                                            {item.title}
                                            <div className="arrow-icon-btn">
                                                <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id={item.id}
                                        className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p className='p-text-1'>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>

            <section>
                <Latest modifiedPath='Latest ICO Marketing Insights' />
            </section>
            <section>
                <FAQblockchain faqData={faqData} />
            </section>


            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />

        </div>
    )
}

export default Ico

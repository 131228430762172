import React from 'react'
import ModalForm from '../../Components/ModalForm/ModalForm';
import TopFaq from './TopFaq';
import EventCard from './EventCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import graph from '../../assets/images/graph.svg';
import GridCard from './GridCard';
import Tabs from './Tabs';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from './BottomFaq';
import BreadCrumbs from '../../Components/BreadCrumbs';



const ProgrammaticAdvertising = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const topFaqData = [
        {
            question: "What is programmatic advertising?",
            answer: "Programmatic advertising is the use of automated technology and data-driven algorithms to buy and optimize digital ad placements in real-time. It streamlines the ad buying process, making it more efficient and effective."
        },
        {
            question: "How does programmatic advertising differ from traditional advertising?",
            answer: "Unlike traditional advertising, which often relies on manual processes and broad targeting, programmatic advertising uses automated technology to target specific audiences with precision, optimizing ad spend in real-time."
        },
        {
            question: "Why is programmatic advertising becoming so popular?",
            answer: "Programmatic advertising is gaining popularity due to its efficiency, precision, and ability to deliver targeted ads at scale. Its data-driven approach enhances ROI and improves ad relevance, making it a preferred choice for modern marketers."
        },
        {
            question: "Who benefits from programmatic advertising?",
            answer: "Businesses of all sizes benefit from programmatic advertising, including e-commerce brands, service providers, and large enterprises. It allows them to reach their target audience more effectively and efficiently."
        },
        {
            question: "When should a business consider using programmatic advertising?",
            answer: "A business should consider programmatic advertising when it seeks to optimize ad spend, target specific audience segments, and leverage real-time data to enhance campaign performance and reach."
        },
        {
            question: "Where are programmatic ads typically displayed?",
            answer: "Programmatic ads can be displayed across various digital channels, including websites, mobile apps, social media platforms, and video streaming services, providing extensive reach and visibility."
        },
        {
            question: "Which types of businesses use programmatic advertising?",
            answer: "Businesses across diverse industries, including retail, travel, finance, and technology, use programmatic advertising to drive targeted traffic, increase brand awareness, and boost sales."
        },
        {
            question: "What kind of results can I expect from programmatic advertising?",
            answer: "With programmatic advertising, you can expect improved targeting accuracy, increased ad engagement, enhanced ROI, and better overall campaign performance through data-driven insights and optimization."
        },
        {
            question: "How much does programmatic advertising typically cost?",
            answer: "The cost of programmatic advertising varies based on factors like audience targeting, ad placements, and campaign objectives. It often operates on a bidding model, where costs are determined by competition and ad quality."
        },
        {
            question: "Who can help me with programmatic advertising?",
            answer: "For expert assistance with programmatic advertising, XDMinds is your go-to partner. Our experienced team provides comprehensive solutions to optimize your ad strategies and maximize results."
        },
    ]

    const eventCardData = [
        {
            title: "Real-Time Bidding (RTB)",
            sections: [
                {
                    content: "An open auction where ad impressions are bought and sold in real-time. Advertisers bid for available inventory, and the highest bid wins the ad placement. This type is cost-effective and provides extensive reach across various websites and platforms."
                }
            ]
        },
        {
            title: "Private Marketplace (PMP)",
            sections: [
                {
                    content: "A closed auction where select advertisers are invited to bid on premium ad inventory. PMPs offer more control and transparency, as publishers can choose who can participate in the auction, ensuring higher-quality placements."
                }
            ]
        },
        {
            title: "Preferred Deals",
            sections: [
                {
                    content: "A type of deal where advertisers have the opportunity to purchase ad inventory at a fixed price before it is made available in the open auction. This arrangement ensures premium placements and exclusivity, allowing advertisers to secure high-quality ad space."
                }
            ]
        },
        {
            title: "Programmatic Guaranteed (PG)",
            sections: [
                {
                    content: "Also known as programmatic direct, this type involves a direct deal between the advertiser and publisher. It guarantees specific ad placements at a fixed price and agreed-upon conditions, providing advertisers with assured ad inventory and greater control over where their ads appear."
                }
            ]
        },
        {
            title: "Programmatic Direct",
            sections: [
                {
                    content: "Similar to programmatic guaranteed, programmatic direct involves direct negotiations and fixed-price deals with publishers. However, it may not always guarantee specific placements but still offers premium inventory and greater control over ad placements."
                }
            ]
        },
        {
            title: "Sell-Side Platform (SSP)",
            sections: [
                {
                    content: "A Sell-Side Platform (SSP) or supply-side platform is a technology platform that enables publishers and website owners to manage, sell, and optimize their digital ad inventory. SSPs allow publishers to offer their ad space to multiple ad exchanges, demand-side platforms (DSPs), and potential buyers in an automated and efficient manner."
                }
            ]
        },
        {
            title: "Demand-Side Platform (DSP)",
            sections: [
                {
                    content: "Like SSPs, DSPs are not a type of programmatic ad but a technology platform used by advertisers to purchase ad impressions. DSPs allow advertisers to buy inventory from multiple ad exchanges and SSPs through a single interface, providing data-driven targeting and optimization capabilities."
                }
            ]
        },
    ]

    const cardData = [
        {
            src: graph,
            alt: 'Ads Strategy & Planning',
            title: 'Ads Strategy & Planning',
            text: 'Craft a precise and effective programmatic ad strategy with XDMinds, tailored to your unique goals and audience insights for optimal campaign performance.'
        },
        {
            src: graph,
            alt: 'Campaign Management & Optimization',
            title: 'Campaign Management & Optimization',
            text: 'Maximize your ad spend efficiency with our expert campaign management and continuous optimization, ensuring your ads perform at their best across all platforms.'
        },
        {
            src: graph,
            alt: 'Campaign Measurement & Reporting',
            title: 'Campaign Measurement & Reporting',
            text: 'Gain valuable insights into your campaign performance with our detailed measurement and reporting services, enabling data-driven decisions for future ad strategies.'
        },
        {
            src: graph,
            alt: 'Ad Operations & Technical Integrations',
            title: 'Ad Operations & Technical Integrations',
            text: 'Streamline your ad operations with our technical expertise, ensuring seamless integrations and smooth execution of your programmatic ad campaigns.'
        },
        {
            src: graph,
            alt: 'Omnichannel Solution',
            title: 'Omnichannel Solution',
            text: 'Achieve a unified ad experience across multiple channels with our omnichannel solutions, enhancing your reach and engagement through integrated programmatic advertising.'
        },
        {
            src: graph,
            alt: 'Audience Targeting',
            title: 'Audience Targeting',
            text: 'Reach your ideal customers with precision using our advanced audience targeting techniques, designed to connect your ads with the most relevant and engaged users.'
        },
        {
            src: graph,
            alt: 'Programmatic Display Ads',
            title: 'Programmatic Display Ads',
            text: 'Elevate your brand visibility with our programmatic display ads, delivering targeted banner ads across a wide network of sites to engage your ideal audience.'
        },
        {
            src: graph,
            alt: 'Programmatic Video Ads',
            title: 'Programmatic Video Ads',
            text: 'Captivate viewers with our programmatic video ads, providing immersive and dynamic video content that reaches audiences on popular streaming platforms and websites.'
        },
        {
            src: graph,
            alt: 'Programmatic Social Ads',
            title: 'Programmatic Social Ads',
            text: 'Boost your social media presence with our programmatic social ads, strategically placing your ads across major social networks to connect with your target demographics.'
        },
        {
            src: graph,
            alt: 'Programmatic Audio Ads',
            title: 'Programmatic Audio Ads',
            text: 'Reach listeners with our programmatic audio ads, delivering engaging audio content on music streaming services and podcasts to enhance your brand’s auditory impact'
        },
        {
            src: graph,
            alt: 'Programmatic Native Ads',
            title: 'Programmatic Native Ads',
            text: 'Blend seamlessly with content using our programmatic native ads, offering a non-intrusive ad experience that matches the look and feel of the surrounding content, enhancing user engagement.'
        },
        {
            src: graph,
            alt: 'Programmatic Ads Consulting',
            title: 'Programmatic Ads Consulting',
            text: 'Receive expert guidance and strategic insights with our Programmatic Ads Consulting, helping you navigate the complexities of programmatic advertising and optimize your campaigns for success.'
        },
    ]

    const tabData = [
        {
            id: 'pills-one',
            label: '1',
            title: 'Comprehensive Understanding of Your Brand and Goals',
            sections: [
                {
                    content: `Our journey begins with a deep dive into understanding your brand's unique identity, objectives, and target audience. We take the time to analyze your market position, competitive landscape, and business goals to craft a tailored programmatic advertising strategy. This foundation ensures that our efforts are perfectly aligned with your vision and desired outcomes.`,
                }
            ]
        },
        {
            id: 'pills-two',
            label: '2',
            title: 'Cutting-Edge Technology and Innovation',
            sections: [
                {
                    content: `At XDMinds, we leverage the latest advancements in programmatic technology to deliver exceptional results. Our use of sophisticated algorithms, AI-driven insights, and real-time data analytics allows us to optimize ad placements, enhance targeting precision, and maximize campaign effectiveness. We continuously innovate to stay ahead of industry trends and deliver cutting-edge solutions to our clients.`,
                }
            ]
        },
        {
            id: 'pills-three',
            label: '3',
            title: 'Advanced Audience Targeting and Segmentation',
            sections: [
                {
                    content: `We excel in identifying and reaching your most valuable audiences. Our advanced targeting capabilities include demographic, geographic, and behavioral segmentation, as well as interest-based and contextual targeting. By utilizing data-driven insights, we ensure your ads are displayed to the right people at the right time, increasing engagement and conversion rates.`,
                }
            ]
        },
        {
            id: 'pills-four',
            label: '4',
            title: 'Transparent and Ethical Practices',
            sections: [
                {
                    content: `Transparency and integrity are core to our operations. We provide our clients with full visibility into their ad campaigns, including detailed reports on performance metrics, budget allocation, and ad placements. Our ethical practices ensure that your brand is represented accurately and that all advertising activities comply with industry standards and regulations.`,
                }
            ]
        },
        {
            id: 'pills-five',
            label: '5',
            title: 'Transparent and Ethical Practices',
            sections: [
                {
                    content: `We understand that creative excellence is key to capturing audience attention. Our team of designers and copywriters work collaboratively to develop compelling ad creatives that resonate with your target audience. From eye-catching visuals to persuasive messaging, we tailor our creative solutions to reflect your brand's unique voice and values.`,
                }
            ]
        },
        {
            id: 'pills-six',
            label: '6',
            title: 'Multi-Channel and Omnichannel Expertise',
            sections: [
                {
                    content: `XDMinds offers expertise across multiple digital channels, including display, video, social, audio, and native advertising. We adopt an omnichannel approach, ensuring a consistent and cohesive brand experience across all platforms. This strategy helps in maximizing reach and engagement, providing a seamless journey for your audience.`,
                }
            ]
        },
        {
            id: 'pills-seven',
            label: '7',
            title: 'Real-Time Optimization and Performance Monitoring',
            sections: [
                {
                    content: `Our commitment to excellence includes continuous monitoring and optimization of your campaigns. We track key performance indicators (KPIs) in real time and make data-driven adjustments to enhance performance. Whether it's tweaking bidding strategies, refining audience targeting, or updating creatives, we ensure your campaigns are always optimized for the best results.`,
                }
            ]
        },
        {
            id: 'pills-eight',
            label: '8',
            title: 'Dedicated Account Management and Support',
            sections: [
                {
                    content: `We pride ourselves on offering exceptional customer service. Our dedicated account managers provide personalized support, keeping you informed and involved throughout the campaign lifecycle. We offer strategic advice, respond promptly to queries, and ensure that your experience with XDMinds is smooth and satisfying.`,
                }
            ]
        },
        {
            id: 'pills-nine',
            label: '9',
            title: 'Scalability and Flexibility',
            sections: [
                {
                    content: `XDMinds is equipped to handle campaigns of all sizes, from small-scale initiatives to large, complex projects. Our scalable solutions and flexible strategies allow us to adapt to your evolving needs, whether you're looking to expand into new markets, launch a new product, or increase brand awareness.`,
                }
            ]
        },
        {
            id: 'pills-ten',
            label: '10',
            title: 'Commitment to Ethical Practices ',
            sections: [
                {
                    content: `XDMinds adheres to the highest standards of ethical advertising practices. We prioritize brand safety, ensuring your ads appear in appropriate and relevant contexts. Our commitment to integrity and transparency builds trust and fosters long-term partnerships with our clients..`,
                }
            ]
        },
    ]

    const faqData = [
        {
            question: "What types of programmatic advertising services does XDMinds offer?",
            answer: "XDMinds provides a comprehensive range of programmatic advertising services, including programmatic display ads, video ads, social ads, audio ads, and native ads. We also offer advanced targeting, real-time bidding (RTB), private marketplace (PMP) deals, and more, ensuring a tailored and effective strategy for your brand."
        },
        {
            question: "How does XDMinds ensure effective targeting in programmatic campaigns?",
            answer: "We utilize sophisticated data analytics and machine learning algorithms to segment and target audiences based on demographics, behaviors, interests, and geographic locations. This precision targeting ensures that your ads reach the most relevant and engaged users, maximizing the efficiency and impact of your campaigns."
        },
        {
            question: "What kind of results can I expect from programmatic advertising with XDMinds?",
            answer: "Our programmatic advertising solutions are designed to drive measurable results, such as increased brand awareness, higher engagement rates, and improved conversions. By leveraging data-driven strategies and continuous optimization, we aim to maximize your ROI and help you achieve your specific business goals."
        },
        {
            question: "How does XDMinds measure the success of programmatic advertising campaigns?",
            answer: "We provide detailed performance reports that include key metrics such as impressions, click-through rates (CTR), conversion rates, and cost per acquisition (CPA). Our transparent reporting and insights allow you to track the success of your campaigns and make informed decisions for future strategies."
        },
        {
            question: "What makes XDMinds a trusted partner for programmatic advertising?",
            answer: "XDMinds is known for its deep industry expertise, innovative solutions, and commitment to ethical practices. We offer customized strategies, advanced technology, and a client-centric approach. Our dedication to transparency, continuous optimization, and long-term partnerships sets us apart as a reliable and effective programmatic advertising partner."
        },
    ]
    return (
        <div className='digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    Foremost <span className='s-text-3'>Programmatic</span> <br className='break' /> Advertising Agency
                </h1>
                <p className='p-text-1'>
                    At XDMinds, we pride ourselves on being a foremost programmatic advertising agency, leading the industry with cutting-edge technology and innovative strategies. Our expertise in programmatic ad solutions ensures precise targeting, efficient ad spend, and impactful results, setting the standard for excellence in digital advertising.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Understanding Programmatic Advertising: <br className='break' /> A Beginner's Guide
                </h2>
                <TopFaq topFaqData={topFaqData} />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Discover the Different Flavors of <br className='break' /> Programmatic Advertising
                </h2>
                <p className='p-text-2'>
                    Programmatic advertising encompasses several types, each catering to different needs and strategies. Here are the main types:
                </p>
                <div className='event-card-div'>
                    <EventCard eventCardData={eventCardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Expert Programmatic Advertising Agency: <br className='break' /> Maximize ROI and Reach
                </h2>
                <p className='p-text-2'>
                    XDMinds stands out as an expert programmatic advertising agency dedicated to maximizing your ROI and reach. We combine advanced technology with strategic insights to drive targeted ad campaigns that deliver measurable results and enhance your digital presence.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your programmatic advertising services. Shall we start the discussion? Lead from https://www.xdminds.com/programmatic-advertising-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Drive Exceptional Results with XDMinds' <br className='break' /> Programmatic Advertising Services
                </h2>
                <p className='p-text-2'>
                    Unlock unparalleled success with XDMinds' programmatic advertising services. Our tailored solutions and data-driven strategies ensure your ads reach the right audience, optimizing your campaign performance and delivering outstanding results.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Decoding Digital Success: The XDMinds’ <br className='break' /> Digital Marketing Methodology
                </h2>
                <p className='p-text-2'>
                    At XDMinds, our proven methodology for digital success is built on a foundation of innovation, data-driven insights, and client-centric strategies. Here’s a closer look at how we deliver exceptional results for our clients:
                </p>
                <div className='tabs-div'>
                    <Tabs tabData={tabData} />
                </div>
            </section>
            <section>
                <Latest modifiedPath='Latest Programmatic Advertising Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>
            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default ProgrammaticAdvertising
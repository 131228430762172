import React from 'react'
import Banner from '../../Components/Home/Banner/Banner'
// import Section3 from '../../Components/Home/Section3/Section3'
import Section5 from '../../Components/Home/Section5/Section5'
import Industries from '../../Components/Home/Industries/Industries'
import PeopleSaying from '../../Components/Home/PeopleSaying/PeopleSaying'
import Latest from '../../Components/Home/Latest/Latest'
import FAQ from '../../Components/Home/FAQ/FAQ'
import Mchallenge from '../../Components/Home/MarketingChallenge/Mchallenge'
import Section4 from '../../Components/Home/Section4/Section4'
import Dmarketing from '../../Components/Home/DigitalMarketingServices/Dmarketing'
import AnswerTab from '../../Components/Home/AnswerTab/AnswerTab'



const LandingPage = (props) => {

  const { countryCode } = props;
  return (
    <div>
      <Banner countryCode={countryCode} />
      {/* <Section3 /> */}
      <AnswerTab />
      <Section4 />
      <Dmarketing />
      <Section5 />
      <Industries />
      <PeopleSaying />
      <Latest modifiedPath='Latest Insights' />
      <FAQ />
      <Mchallenge />
    </div>
  )
}

export default LandingPage
import React, { useState } from 'react'
import BreadCrumbs from '../../Components/BreadCrumbs'
import ModalForm from '../../Components/ModalForm/ModalForm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import whatsapp from '../../assets/images/whatsapp.svg'
import telegram from '../../assets/images/telegram.svg'
import skype from '../../assets/images/skype.svg'
import GridCard from '../DigitalMarketing/GridCard';
import graph from '../../assets/images/graph.svg';
import Latest from '../../Components/Home/Latest/Latest';
import BottomFaq from '../DigitalMarketing/BottomFaq';
import { MdArrowCircleDown } from "react-icons/md";

const SAAS = (props) => {

    const [modalShow, setModalShow] = React.useState(false);
    const { countryCode } = props;

    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const cardData = [
        {
            src: graph,
            alt: 'SaaS GTM Marketing Strategy',
            title: 'SaaS GTM Marketing Strategy',
            text: "Our expert team develops comprehensive SaaS marketing campaign strategies that align with your business goals. We meticulously plan and execute campaigns that maximize your reach, engagement, and ROI, ensuring sustained growth."
        },
        {
            src: graph,
            alt: 'SaaS Keywords Research',
            title: 'SaaS Keywords Research',
            text: "We conduct in-depth keyword research to identify high-performing search terms specific to your SaaS niche. This ensures your content ranks highly in search engines, driving organic traffic to your site."
        },
        {
            src: graph,
            alt: 'SaaS Competitor Analysis',
            title: 'SaaS Competitor Analysis',
            text: "Gain a competitive edge with our thorough SaaS competitor analysis. We assess your competitors’ strategies, identify gaps, and uncover opportunities to position your SaaS product as a market leader."
        },
        {
            src: graph,
            alt: 'SaaS Branding Building',
            title: 'SaaS Branding Building',
            text: "We help you build a strong, recognizable SaaS brand that resonates with your target audience. Our branding strategies ensure consistency across all channels, enhancing brand loyalty and customer retention."
        },
        {
            src: graph,
            alt: 'SaaS Website Development',
            title: 'SaaS Website Development',
            text: "Our team designs and develops user-friendly SaaS websites that enhance the customer experience. We focus on functionality, performance, and security, ensuring your portal supports your business objectives."
        },
        {
            src: graph,
            alt: 'SaaS Mobile App Development',
            title: 'SaaS Mobile App Development',
            text: "We create intuitive and feature-rich SaaS mobile apps that provide seamless access to your services. Our mobile solutions are designed to improve user engagement and retention on all devices."
        },
        {
            src: graph,
            alt: 'SaaS SEO',
            title: 'SaaS SEO',
            text: "Boost your online visibility with our tailored SaaS SEO services. We optimize your website for local, national, and international searches, helping you reach a broader audience."
        },
        {
            src: graph,
            alt: 'SaaS Paid Advertising',
            title: 'SaaS Paid Advertising',
            text: "Maximize your ROI with our targeted SaaS paid advertising campaigns. We manage your ads across search engines, display networks, and social media platforms to drive qualified traffic and conversions."
        },
        {
            src: graph,
            alt: 'Social Media Marketing',
            title: 'Social Media Marketing',
            text: "Engage with your audience on their preferred platforms through our SaaS social media marketing services. We create and manage content that builds relationships, drives brand awareness, and fosters customer loyalty."
        },
        {
            src: graph,
            alt: 'SaaS Lead Generation',
            title: 'SaaS Lead Generation',
            text: "Our lead generation strategies are designed to attract high-quality leads that are likely to convert. We use data-driven tactics to target the right audience, ensuring a steady stream of potential customers."
        },
        {
            src: graph,
            alt: 'SaaS Demand Generation',
            title: 'SaaS Demand Generation',
            text: "We develop demand generation campaigns that increase awareness and interest in your SaaS product. By creating targeted content and leveraging multiple channels, we drive demand and accelerate the sales cycle."
        },
        {
            src: graph,
            alt: 'SaaS Content Marketing',
            title: 'SaaS Content Marketing',
            text: "We craft compelling content that educates, informs, and persuades your target audience. Our SaaS content marketing services focus on delivering value, establishing thought leadership, and driving conversions."
        },
        {
            src: graph,
            alt: 'SaaS Email Marketing',
            title: 'SaaS Email Marketing',
            text: "Our email marketing campaigns are designed to nurture leads, engage customers, and drive repeat business. We create personalized email content that resonates with your audience and encourages action."
        },
        {
            src: graph,
            alt: 'PR & Media Outreach',
            title: 'PR & Media Outreach',
            text: "Build your brand’s reputation with our SaaS public relations and media outreach services. We connect you with industry influencers, journalists, and media outlets to amplify your brand’s message."
        },
        {
            src: graph,
            alt: 'SaaS Influencer Marketing',
            title: 'SaaS Influencer Marketing',
            text: "Leverage the power of influencer marketing to reach a broader audience. We identify and collaborate with influencers who align with your brand to promote your SaaS product authentically."
        },
        {
            src: graph,
            alt: 'SaaS Performance Marketing',
            title: 'SaaS Performance Marketing',
            text: "Our performance marketing services focus on driving measurable results. We use data-driven strategies to optimize your campaigns for the best possible outcomes, ensuring your marketing efforts deliver value."
        },
        {
            src: graph,
            alt: 'SaaS Programmatic Advertising',
            title: 'SaaS Programmatic Advertising',
            text: "Automate your advertising efforts with our SaaS programmatic advertising services. We use advanced algorithms to target the right audience at the right time, maximizing your ad spend efficiency."
        },
        {
            src: graph,
            alt: 'Conversion Rate Optimization',
            title: 'Conversion Rate Optimization',
            text: "Increase your website’s conversion rates with our CRO services. We analyze user behavior and optimize your site to turn more visitors into customers, boosting your overall sales."
        },
        {
            src: graph,
            alt: 'Online Reputation Management',
            title: 'Online Reputation Management',
            text: "Protect and enhance your brand’s online reputation with our SaaS ORM services. We monitor and manage your online presence, ensuring positive customer perceptions and addressing negative feedback proactively."
        },
        {
            src: graph,
            alt: 'SaaS Performance Tracking',
            title: 'SaaS Performance Tracking',
            text: "Our performance tracking and analytics services provide you with actionable insights into your marketing campaigns. We help you understand what’s working and where improvements can be made for better results."
        },
        {
            src: graph,
            alt: 'SaaS Marketing Consulting',
            title: 'SaaS Marketing Consulting',
            text: "Get expert advice with our SaaS marketing consulting services. We offer tailored guidance to help you navigate the complexities of SaaS marketing, ensuring your strategies align with your business objectives."
        },

    ]
    const cardData1 = [
        {
            id: 1,
            title: 'Deep Understanding of SaaS Business Models',
            description: "XDMinds possesses a deep understanding of the unique challenges and opportunities within the SaaS industry. We know that SaaS businesses operate differently from traditional models, and our strategies are specifically tailored to address the nuances of subscription-based services, customer retention, and recurring revenue."
        },
        {
            id: 2,
            title: ' Comprehensive Marketing Solutions',
            description: "We offer a full spectrum of marketing services designed to cover every aspect of your SaaS business. From SEO and content marketing to paid advertising and social media management, XDMinds provides end-to-end solutions that drive growth and enhance brand visibility. Our holistic approach ensures all aspects of your marketing strategy work together seamlessly."
        },
        {
            id: 3,
            title: 'Data-Driven Strategies',
            description: "At XDMinds, data is at the heart of everything we do. We rely on in-depth analytics and performance metrics to guide our marketing decisions, ensuring that every campaign is optimized for maximum impact. By continuously monitoring and refining our strategies, we help you achieve sustainable growth."
        },
        {
            id: 4,
            title: 'Tailored Campaigns for Maximum ROI',
            description: "We understand that every SaaS business is unique, and a one-size-fits-all approach won’t cut it. That’s why we create customized marketing campaigns that align with your specific goals and target audience. Our tailored strategies are designed to maximize your return on investment, ensuring that every dollar spent delivers results."
        },
        {
            id: 5,
            title: 'Transparent Communication and Collaboration',
            description: "We believe that strong communication is key to a successful partnership. At XDMinds, we prioritize transparency and collaboration, keeping you informed at every stage of your marketing journey. You’ll have a dedicated team that’s always accessible, ready to provide insights, updates, and adjustments as needed."
        },
        {
            id: 6,
            title: 'Commitment to Innovation',
            description: "The digital landscape is constantly evolving, and so are our strategies. XDMinds is committed to staying ahead of the curve by adopting the latest marketing trends and technologies. We continuously innovate to ensure your SaaS business remains competitive and can capitalize on emerging opportunities."
        },
        {
            id: 7,
            title: 'Focus on Long-Term Success',
            description: "While we aim for quick wins, our primary focus is on achieving long-term success for your SaaS business. We build strategies that not only deliver immediate results but also set the foundation for sustained growth. With XDMinds, you’re not just getting a marketing partner—you’re gaining a long-term ally in your business journey."
        },
        {
            id: 8,
            title: 'Expertise Across Multiple Channels',
            description: "XDMinds’ expertise spans multiple digital marketing channels, ensuring that your SaaS business reaches its audience wherever they are. Whether it’s through search engines, social media, email, or content marketing, we create cohesive campaigns that engage users across all touchpoints."
        },
        {
            id: 9,
            title: ' Results You Can Measure',
            description: "Finally, XDMinds is committed to delivering measurable results. We provide detailed reports and insights that allow you to see the impact of our efforts on your business. Our goal is to ensure that every strategy we implement contributes directly to your growth and success."
        },

    ];

    const faqData = [
        {
            question: "How does XDMinds tailor its marketing strategies to fit my specific SaaS product?",
            answer: "At XDMinds, we begin by thoroughly understanding your SaaS product, target audience, and business goals. We then create a customized marketing strategy that aligns with your unique needs, focusing on the channels and tactics that will most effectively reach and engage your audience."
        },
        {
            question: "How can XDMinds help improve customer retention for my SaaS business?",
            answer: "Customer retention is critical in the SaaS industry. XDMinds employs strategies like personalized email campaigns, customer engagement initiatives, and ongoing content support to keep your users engaged and satisfied, reducing churn and increasing lifetime value."
        },
        {
            question: "What kind of analytics and reporting can I expect from XDMinds?",
            answer: "We provide comprehensive, data-driven insights that track the performance of your campaigns in real-time. You’ll receive regular reports that highlight key metrics, such as conversion rates, customer acquisition costs, and user engagement, allowing you to see the tangible impact of our efforts."
        },
        {
            question: "How does XDMinds approach lead generation for SaaS companies?",
            answer: "XDMinds focuses on attracting high-quality leads that are likely to convert. We use targeted campaigns, including content marketing, paid advertising, and social media strategies, to reach potential customers at the right stage of their buyer’s journey."
        },
        {
            question: "Can XDMinds assist with launching a new SaaS product?",
            answer: "Absolutely. We specialize in go-to-market strategies for new SaaS products, helping you build anticipation, generate buzz, and achieve a successful launch. From pre-launch campaigns to post-launch support, we’re with you every step of the way."
        },
        {
            question: "How flexible are XDMinds' marketing plans?",
            answer: "Our marketing plans are highly flexible and can be adjusted as your business grows or as market conditions change. We work closely with you to ensure that our strategies evolve alongside your needs and objectives."
        },
        {
            question: "How does XDMinds ensure that my SaaS product stands out in a competitive market?",
            answer: "We differentiate your SaaS product by highlighting its unique value proposition through targeted messaging, branding, and creative content. Our approach ensures that your product not only reaches the right audience but also resonates with them, making it stand out in a crowded market."
        },
        {
            question: "What support does XDMinds offer for international SaaS expansion?",
            answer: "If you’re looking to expand your SaaS product internationally, XDMinds can help by tailoring marketing strategies to different regions and cultures. We consider language preferences, local market trends, and regional regulations to ensure your expansion is successful."
        },
        {
            question: "How does XDMinds handle content creation for SaaS marketing?",
            answer: "Our team of experienced content creators develops high-quality, relevant content that speaks to your target audience. Whether it’s blog posts, whitepapers, case studies, or video content, we ensure that your content drives engagement and builds authority in your niche."
        },
        {
            question: "What role does customer feedback play in XDMinds' marketing strategy?",
            answer: "Customer feedback is invaluable to refining and improving your SaaS marketing efforts. We regularly gather and analyze feedback to adjust our strategies, ensuring that your marketing campaigns are aligned with customer needs and expectations."
        },
    ]

    return (
        <div className='saas digital-marketing'>
            <section className='digital-marketing-banner min-100vh'>
                <BreadCrumbs />
                <h1 className='h-text-1'>
                    First-Grade <br className='break' /> <span className='s-text-3'>SaaS</span>  Marketing Agency
                </h1>
                <p className='p-text-1'>
                    XDMinds is the leading force in B2B marketing, driving innovative strategies that fuel business growth. As the premier B2B digital marketing agency, we understand the intricacies of the B2B landscape and craft customized marketing solutions designed to propel your business to new heights. With a focus on maximizing ROI and delivering measurable results, XDMinds stands as your trusted partner in achieving long-term success in an ever-evolving marketplace.
                </p>
                <button className='request-btn' onClick={() => setModalShow(true)}>Request A Free Proposal</button>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    Drive SaaS Growth with XDMinds – Your Go-To  <br className='break' /> Software-as-a-Service (SaaS) Marketing and Advertising Agency
                </h2>
                <p className='p-text-2'>
                    XDMinds is a premier SaaS marketing and advertising agency that specializes in accelerating
                    the growth of software-as-a-service businesses. With deep industry knowledge and a passion for
                    innovation, we craft customized marketing strategies that resonate with your target audience and drive measurable results. Whether you're looking to launch a new SaaS product, expand your market reach, or enhance customer engagement, our team of experts is here to guide you every step of the way. At XDMinds, we understand the unique challenges of the SaaS landscape, and we're committed to helping you
                    overcome them with strategic marketing solutions that deliver real value.
                </p>
                <div className='social-buttons'>
                    <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your SaaS marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/saas-marketing-agency' className='social-btn'>
                        <LazyLoadImage src={whatsapp} alt='WRITE TO US IN WHATSAPP' />
                        WRITE TO US IN WHATSAPP
                    </a>
                    <a href='https://t.me/xd_minds' className='social-btn'>
                        <LazyLoadImage src={telegram} alt='WRITE TO US IN TELEGRAM' />
                        WRITE TO US IN TELEGRAM
                    </a>
                    <a href='skype:live:.cid.7361579f2e5c83ea?chat' className='social-btn'>
                        <LazyLoadImage src={skype} alt='WRITE TO US IN SKYPE' />
                        WRITE TO US IN SKYPE
                    </a>
                </div>
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Elevate Your SaaS Product with XDMinds' Cutting-Edge <br className='break' />SaaS Marketing Services and Extraordinary Strategies
                </h2>
                <p className='p-text-2'>
                    Elevate your SaaS product to new heights with XDMinds' cutting-edge marketing services and strategies.
                    Our comprehensive approach integrates the latest in digital marketing with a deep understanding of SaaS business models, ensuring your product stands out in a competitive market. We specialize in everything from SEO to paid advertising, all while focusing on data-driven insights to optimize performance. Whether you need to build brand awareness, generate leads, or drive conversions, our tailored strategies are designed to meet your specific goals.
                    Trust XDMinds to be your partner in success and watch your SaaS product thrive.
                </p>
                <div className='grid-cards'>
                    <GridCard cardData={cardData} />
                </div>
            </section>
            <section className='digital-marketing-section-2 d-bg'>
                <h2 className='h-text-2'>
                    EFuture-Proof Your SaaS Business with <br className='break' /> XDMinds’ Scalable Agile Marketing Process
                </h2>
                <div className='border-div position-relative '>
                    <p className='p-text-2 w-100 span-icon text-start'>
                        In the rapidly evolving world of SaaS, staying ahead of the curve is crucial for long-term success.
                        At XDMinds, we understand the unique challenges SaaS businesses face and offer a scalable, agile marketing process designed to future-proof your brand. Our approach is rooted in flexibility,
                        allowing us to adapt quickly to market changes, emerging trends, and shifting customer needs.
                    </p>
                    <p className='p-text-2 w-100 span-icon text-start'>
                        By implementing an agile marketing process, we ensure that your SaaS business remains responsive and
                        resilient in a dynamic environment. Our strategy involves continuous iteration and improvement, with regular feedback loops that keep your marketing efforts aligned with your business objectives.
                        This allows us to fine-tune campaigns in real-time, optimizing performance and maximizing ROI.
                    </p>
                    <p className='p-text-2 w-100 span-icon text-start'>
                        Our scalable solutions grow with your business, providing the support you need whether you're
                        just starting out or scaling to new heights. With XDMinds, you gain a marketing partner who is
                        committed to your success—delivering strategies that not only address today’s challenges but also
                        anticipate tomorrow’s opportunities. Trust our agile marketing process to future-proof your SaaS business,
                        ensuring sustained growth and a competitive edge in the marketplace.
                    </p>
                </div>
            </section>
            <div className='about'>
                <section className='about-section-5'>
                    <h2 className='h-text-2'>
                        Why XDMinds Should Be Your Top Choice for a <br className='break' />Trusted SaaS Digital Marketing Partnership?
                    </h2>
                    <p className='p-text-1'>
                        Choosing the right digital marketing partner is crucial for the success of your SaaS business.
                        XDMinds stands out as a top choice for SaaS companies seeking a trusted and effective marketing partnership.
                        Here’s a step-by-step look at why XDMinds should be your go-to partner:
                    </p>
                    <div className='custom-div-5 mobile-hide'>
                        <div className='row g-3'>
                            {cardData1.map((card) => (
                                <div key={card.id} className='col-lg-4 col-md-6 col-12'>
                                    <div className='card custom-card-5 h-100'>
                                        <div className='custom-card-head'>
                                            <h1 className='h-text-3'>{card.id}</h1>
                                            <h2 className='h-text-4'>{card.title}</h2>
                                        </div>
                                        <div className='card-body'>
                                            {card.description && <p className='p-text-4'>{card.description}</p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='custom-accordion desktop-hide tab-hide'>
                        <div className="accordion" id="accordionExample">
                            {cardData1.map((item, index) => (
                                <div key={item.id} className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                            onClick={() => handleClick(index)}
                                            type="button"
                                            aria-expanded={openIndex === index}
                                            aria-controls={item.id}
                                        >
                                            {item.title}
                                            <div className="arrow-icon-btn">
                                                <MdArrowCircleDown className={`arrow-icon ${openIndex === index ? 'rotated' : ''}`} />
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id={item.id}
                                        className={`accordion-collapse collapse  ${openIndex === index ? 'show' : ''}`}
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p className='p-text-1'>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
            <section>
                <Latest modifiedPath='Latest SaaS Marketing Insights' />
            </section>
            <section className='digital-marketing-section-2'>
                <h2 className='h-text-2'>
                    Frequently Asked Questions
                </h2>
                <BottomFaq faqData={faqData} />
            </section>


            <ModalForm countryCode={countryCode} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default SAAS

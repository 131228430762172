import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Scrollbar } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination } from 'swiper/modules';
import { FaChartLine } from 'react-icons/fa';
import './Blockchain.css'
import { Link } from 'react-router-dom';

const Section3 = () => {

    const cardData = [
        {
            imgSrc: <FaChartLine />,
            title: 'Marketing Strategy Creation',
            text: 'We develop comprehensive marketing strategies tailored to your blockchain project, ensuring effective audience engagement and brand growth.',
        },
        {
            link: "/seo-agency",
            imgSrc: <FaChartLine />,
            title: 'Search Engine Optimization',
            text: 'Optimize your blockchain project’s visibility with our specialized SEO strategies designed to boost search engine rankings and organic traffic.',
        },
        {
            link: "/ppc-advertising-agency",
            imgSrc: <FaChartLine />,
            title: 'PPC Advertising',
            text: 'Drive targeted traffic to your blockchain project with our pay-per-click advertising campaigns, maximizing ROI and brand exposure.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Branding',
            text: 'Create a strong and recognizable brand identity for your blockchain project, ensuring it stands out in a competitive market.',
        },
        {
            link: "/content-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Content Marketing',
            text: 'Engage your audience with high-quality, relevant content that educates and informs, positioning your blockchain project as an industry leader.',
        },
        {
            link: "/social-media-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Social Media Marketing',
            text: 'Leverage the power of social media to build a community around your blockchain project and engage with your audience effectively.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Email Marketing',
            text: 'Nurture leads and maintain communication with your audience through targeted and personalized email marketing campaigns.',
        },
        {
            link: "/programmatic-advertising-agency",
            imgSrc: <FaChartLine />,
            title: 'Programmatic Advertising',
            text: 'Utilize automated advertising technology to deliver highly targeted ads, ensuring maximum reach and efficiency.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Video Advertising',
            text: 'Create compelling video content that captures your audience’s attention and communicates your blockchain project’s value proposition.',
        },
        {
            link: "/performance-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Performance Marketing',
            text: 'Measure and optimize your marketing efforts to ensure maximum effectiveness and ROI for your blockchain project.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'PR Marketing',
            text: 'Enhance your blockchain project’s reputation and visibility through strategic public relations and media outreach.',
        },
        {
            link: "/influencer-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Influencer Marketing',
            text: 'Partner with industry influencers to amplify your blockchain project’s reach and credibility.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Community Building',
            text: 'Foster a loyal and engaged community around your blockchain project, driving long-term success and growth.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Omnichannel Marketing',
            text: 'Implement a cohesive marketing strategy across multiple channels, ensuring a seamless and consistent brand experience.',
        },
        {
            link: "/gaming-marketing-agency",
            imgSrc: <FaChartLine />,
            title: 'Gaming Marketing',
            text: 'Boost the reach and engagement of your blockchain game with tailored marketing strategies that attract gamers and investors alike.',
        },
        {
            imgSrc: <FaChartLine />,
            title: 'Whitepaper Creation',
            text: "Craft compelling whitepapers and Litepapers that clearly articulate your blockchain project's vision, technical details, and value proposition. ",
        },

    ]

    const chunkArray = (arr, size) => {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    };
    const chunkedCardData = chunkArray(cardData, 4);

    const [cardswiper, setCardSwiper] = useState(null)
    const handleMouseEnter = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.stop();
        }
    }
    const handleMouseLeave = () => {
        if (cardswiper !== null) {
            cardswiper.autoplay.start();
        }
    }
    return (

        <div className='blockchain-sec-4'>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h2> Fueling Blockchain Growth: <span className='msec4-break'><br />
                            </span>Performance-Driven Blockchain Marketing Services
                            </h2>
                        </div>
                        <div className='industries-sub-head'>
                            <p>
                                At XDMinds, we offer a range of blockchain marketing services designed to elevate your brand and maximize your project's potential.
                            </p>
                        </div>

                        <div className='desktop-industries-view'>
                            {chunkedCardData.map((chunk, index) => (
                                <div className='home-industries-card-div' key={index}>
                                    <Row>
                                        {chunk.map((card, cardIndex) => (
                                            <Col xl={3} sm={6} lg={3} key={cardIndex}>
                                                {card.link ? (
                                                    <Link reloadDocument to={card.link} className='industries-card-link text-decoration-none'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Link>
                                                ) : (
                                                    <div className='c-default h-100'>
                                                        <Card className='industries-card'>
                                                            <Card.Body>
                                                                <div className='dmarketing-card-head'>
                                                                    <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                                    <Card.Title><h4>{card.title}</h4></Card.Title>
                                                                </div>
                                                                <Card.Text>
                                                                    {card.text}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                        </div>



                        <div className='mobile-view-industries'>

                            <Swiper
                                spaceBetween={50}
                                slidesPerView={3}
                                loop={true}
                                dots="true"
                                onSwiper={(swiper) => setCardSwiper(swiper)}
                                modules={[Scrollbar, Pagination, Autoplay]}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{ clickable: true }}
                                className="ps-swiper"
                                breakpoints={{
                                    0: { slidesPerView: 1, spaceBetween: 10 },
                                    460: { slidesPerView: 1, spaceBetween: 5 },
                                    768: { slidesPerView: 1, spaceBetween: 30 },
                                    1024: { slidesPerView: 6, spaceBetween: 50 },
                                }}
                            >
                                {cardData.map((card, index) => (
                                    <SwiperSlide key={index}>
                                        {card.link ? (
                                            <Link reloadDocument to={card.link} className='text-decoration-none h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        ) : (
                                            <div className='c-default h-100'>
                                                <Card className='industries-card h-100' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Card.Body>
                                                        <p className='img-home-industries-card'>{card.imgSrc}</p>
                                                        <Card.Title><h4>{card.title}</h4></Card.Title>
                                                        <Card.Text>{card.text}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}

export default Section3
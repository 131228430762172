import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import './BookForm.css'
import { FaRegCheckCircle, FaSkype, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa'
import 'country-flag-icons/react/3x2';
import ReactFlagsSelect from "react-flags-select";
import '../../Contact/Contact.css'
import '../../Home/Banner/Banner.css'
import { useForm, Controller } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux';
import { LeadFormSubmitApi } from '../../../Helper/Redux/ReduxThunk/Homepage';
import useDeviceType from '../../useDeviceType/useDeviceType';



const BookFrom = (props) => {
    const { countryCode } = props;
    const [loading, setLoading] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState('WhatsApp');
    const [placeholder, setPlaceholder] = useState('WhatsApp Number');
    const [type, setType] = useState('number')
    const [selectedService, setSelectedService] = useState('');
    const [additionalContentOptions, setAdditionalContentOptions] = useState([]);
    const dispatch = useDispatch();
    const selectedcountryfromapi = countryCode;
    const isMobile = useDeviceType();
    const [selectedSubService, setSelectedSubService] = useState('');

    const [countryData, setCountryData] = useState({
        countryCode: '',
        countryName: '',
        dialCode: '',
        phoneNumber: ''
    });
    const handleSelect = (icon) => {
        setSelectedIcon(icon);
        setPlaceholder({
            WhatsApp: 'WhatsApp Number',
            telegram: 'Telegram Number',
            Skype: 'Skype ID'
        }[icon]);
        setType({
            WhatsApp: 'number',
            Telegram: 'text',
            Skype: 'text'
        }[icon])
    };

    const renderIcon = () => {
        return {
            WhatsApp: <FaWhatsapp className='modal-drop-wapp' />,
            telegram: <FaTelegramPlane className='modal-drop-tele' />,
            Skype: <FaSkype className='modal-drop-tele' />
        }[selectedIcon];
    };

    const handleServiceChange = (e) => {
        const service = e.target.value;
        setSelectedService(service);

        const options = {
            "Web3 Marketing": [
                'AI Marketing', 'AR/VR Marketing', 'IoT Marketing', 'Web3 Marketing', 'Metaverse Marketing', 'Blockchain Marketing', 'DAO Marketing',
                'NFT Marketing', 'DeFi Marketing', 'dApps Marketing', 'Gaming Marketing', 'Crypto Marketing', 'ICO Marketing', 'STO Marketing',
                'IDO Marketing', 'IEO Marketing', 'Others'
            ],
            "Digital Marketing": [
                'Full-stack Digital Marketing', 'Search Engine Optimization', 'Generative Engine Optimization', 'App Store Optimization', 'Search Engine Marketing',
                'PPC Advertising', 'Content Marketing', 'Social Media Marketing (Organic)', 'Social Media Advertising (Paid)', 'Mobile App Marketing', 'Email Marketing',
                'Performance Marketing', 'Programmatic Advertising', 'Influencer Marketing', 'Conversion Rate Optimization', 'Online Reputation Management', 'Others'
            ]
        }[service] || [];

        setAdditionalContentOptions(options);
        setSelectedSubService(options[0]);
    };

    const getValidationSchema = (selectedIcon) => {
        let contactValueSchema;

        if (selectedIcon === 'WhatsApp') {
            contactValueSchema = yup
                .string()
                .matches(/^\+?\d{10,14}$/, 'Invalid WhatsApp number')
                .required('WhatsApp number is required');
        } else if (selectedIcon === 'telegram') {
            contactValueSchema = yup
                .string()
                .matches(/^[a-z][a-z0-9\.,\-_]{5,32}$/, 'Invalid Telegram username')
                .required('Telegram username is required');
        } else if (selectedIcon === 'skype') {
            contactValueSchema = yup
                .string()
                .matches(/^[a-z][a-z0-9\.,\-_]{5,32}$/, 'Invalid Skype ID')
                .required('Skype ID is required');
        } else {
            contactValueSchema = yup.string().required('Contact information is required');
        }

        return yup.object().shape({
            name: yup.string().required('Name is required'),
            email: yup.string().email('Invalid email format').required('Email is required'),
            number: yup.string().required('Phone Number is required'),
            service: yup.string().required('Service is required'),
            budget: yup.string().required("Budget is required"),
            messages: yup.string().required("Message is required"),
            contactValue: contactValueSchema,
        });
    };


    const validationSchema = getValidationSchema(selectedIcon);


    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });


    const path = window.location.pathname;
    const cleanPath = path.startsWith('/') ? path.slice(1) : path;

    const onSubmit = async (data) => {
        setLoading(true)

        let params = {
            name: data.name,
            email: data.email,
            country: countryData?.countryName,
            number: countryData?.phoneNumber,
            social_media: selectedIcon,
            page_from: "Footer_Contactus_Form",
            page_from_url: window.location.href,
            service: data.service,
            sub_service: data.subService,
            budget: data.budget,
            message: data.messages,
            social_media_number: data.contactValue,
            device: isMobile ? "Mobile" : "Desktop",
        }

        await dispatch(LeadFormSubmitApi(params, (resp) => {
            if (resp.status == true) {
                setLoading(false)
                toast.success("Form Submitted Successfully")
                resetForm();
                props.onHide()
            }
            else {
                setLoading(false)
                toast.error(resp.message)
            }
        }))
    }

    const resetForm = () => {
        setValue("name", "")
        setValue("email", "")
        setValue("service", "")
        setValue("subService", "")
        setValue("budget", "")
        setValue("messages", "")
        setValue("contactValue", "")
        setValue("social_media_number", "")
        setValue("number", countryData?.dialCode)
        setCountryData({
            countryCode: '',
            countryName: '',
            dialCode: '',
            phoneNumber: ''
        })
        setSelectedService("")
    }

    const handleKeyDown = (e) => {
        if (type === 'number') {
            if (e.key === 'e' || e.key === 'E') {
                e.preventDefault();
            }
        }
    };

    const [selectedCountry, setSelectedCountry] = useState('');

    const handlerestrictnumbers = (event) => {
        const regex = /[0-9~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/;

        if (regex.test(event.key)) {
            event.preventDefault();
        }
    };
    const handlerestrictcharacters = (event) => {
        const regex = /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/;

        if (regex.test(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <div className='book-form'>
            <Container>
                <Row>
                    <Col>
                        <div className='bk-main-content'>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <div className='text-format-form'>
                                        <div className='book-form-content'>
                                            <p className='book-form-head'>
                                                Don't Settle for Average Marketing. Let XDMinds Elevate Your Brand. Contact us today!
                                            </p>
                                            <div className='mob-hide'>
                                                <p><span><FaRegCheckCircle className='bf-tick' /></span>Quantifiable improvements go beyond "average."</p>
                                                <p><span><FaRegCheckCircle className='bf-tick' /></span>We help your brand achieve differentiation.</p>
                                                <p><span><FaRegCheckCircle className='bf-tick' /></span>We offer more than just basic marketing.</p>
                                                <p><span><FaRegCheckCircle className='bf-tick' /></span>Get a customized marketing plan for your brand.</p>
                                                <p><span><FaRegCheckCircle className='bf-tick' /></span>We work collaboratively to elevate your brand.</p>
                                                <p><span><FaRegCheckCircle className='bf-tick' /></span>Our data-driven approach ensures success.</p>
                                                <p><span><FaRegCheckCircle className='bf-tick' /></span>The time to act is now. Let's discuss your marketing goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className='right-col'>
                                    <div className='form-div'>
                                        <p className='form-div-head'>BOOK A CALL WITH US</p>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <div className="col-12">
                                                    <div className="form-floating">
                                                        <Controller
                                                            name='name'
                                                            control={control}
                                                            render={({ field }) => <input type="text" onKeyDown={handlerestrictnumbers} className="form-control" {...field} aria-describedby="textHelp" placeholder='Enter Your Name' />}
                                                        />
                                                        {errors.name && <p className="alert-msg">{errors.name.message}</p>}
                                                        <label className="form-label">Name</label>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating">
                                                        <Controller
                                                            name='email'
                                                            control={control}
                                                            render={({ field }) => <input type='email' className="form-control" {...field} placeholder='Enter your email' aria-describedby="textHelp" />}
                                                        />
                                                        {errors.email && <p className="alert-msg">{errors.email.message}</p>}
                                                        <label className="form-label">Email</label>
                                                    </div>
                                                </div>

                                                <>
                                                    <div className="col-12 banner-contact-flag country-number">
                                                        <Controller
                                                            name="country"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: "Country selection is required" }}
                                                            render={({ field }) => (
                                                                <div className="modal-country">

                                                                    <div className="select-wrapper">
                                                                        <ReactFlagsSelect
                                                                            selected={selectedcountryfromapi}
                                                                            disabled
                                                                            onSelect={(code) => setSelectedCountry(code)}
                                                                            id="countrySelect"
                                                                            className={`form-select country-select-drop-book-form `}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="col-12 phone-number">
                                                        <Controller
                                                            name="number"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: "Phone number is required" }}
                                                            render={({ field }) => (
                                                                <PhoneInput
                                                                    country={selectedcountryfromapi ? selectedcountryfromapi?.toLowerCase() : "in"}
                                                                    value={field.value}
                                                                    countryCodeEditable={false}
                                                                    onChange={(value, country) => {
                                                                        // Set the phone number in the form state
                                                                        const formattedNumber = `+${country.dialCode} ${value.slice(country.dialCode.length)}`;
                                                                        field.onChange(formattedNumber);

                                                                        // Extract and set country details
                                                                        setCountryData({
                                                                            countryCode: country.countryCode,
                                                                            countryName: country.name,
                                                                            dialCode: country.dialCode,
                                                                            phoneNumber: formattedNumber
                                                                        });

                                                                        // Set the country dynamically
                                                                        setSelectedCountry(country.countryCode.toLowerCase());
                                                                    }}
                                                                    searchPlaceholder="Search Countries"
                                                                    enableSearch={false}
                                                                    disableCountryCode={false}
                                                                    className="phone-select"
                                                                />
                                                            )}
                                                        />
                                                        {errors.number && <p className="alert-msg">{errors.number.message}</p>}
                                                    </div>
                                                </>


                                                <div className="col-12 banner-contact-media">
                                                    <div className="input-group">
                                                        <button className="btn btn-outline-secondary dropdown-toggle modal-icon-drop" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {renderIcon()}
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" onClick={() => handleSelect('WhatsApp')}><FaWhatsapp className='modal-drop-wapp' /></a></li>
                                                            <li><a className="dropdown-item" onClick={() => handleSelect('telegram')}><FaTelegramPlane className='modal-drop-tele' /></a></li>
                                                            <li><a className="dropdown-item" onClick={() => handleSelect('Skype')}><FaSkype className='modal-drop-skype' /></a></li>
                                                        </ul>
                                                        <Controller
                                                            name="contactValue"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input
                                                                    onKeyDown={handleKeyDown}
                                                                    type={type}
                                                                    className="form-control"
                                                                    placeholder={placeholder}
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors.contactValue && <p className="alert-msg">{errors.contactValue.message}</p>}

                                                </div>
                                                <div className={`form-services-div ${selectedService ? 'col-md-6' : 'col-md-12'}`}>
                                                    <div className="select-wrapper">
                                                        <Controller
                                                            name='service'
                                                            control={control}
                                                            render={({ field }) =>
                                                                <>
                                                                    <select className="form-select contact-form-select" {...field} onChange={(e) => {
                                                                        field.onChange(e);
                                                                        handleServiceChange(e);
                                                                    }}>
                                                                        <option value="" selected hidden>Service you are looking for?</option>
                                                                        <option value="Web3 Marketing">Web3 Marketing</option>
                                                                        <option value="Digital Marketing">Digital Marketing</option>
                                                                    </select>
                                                                </>
                                                            }
                                                        />
                                                        {errors.service && <p className="alert-msg">{errors.service.message}</p>}
                                                    </div>
                                                </div>
                                                {selectedService && (
                                                    <div className="col-md-6 form-services-div">
                                                        <label className="form-label"></label>
                                                        <div className="select-wrapper subservice-wrapper">
                                                            <Controller
                                                                name='subService'
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <select
                                                                        className="form-select contact-form-select"
                                                                        value={selectedSubService}
                                                                        onChange={(e) => {
                                                                            field.onChange(e);
                                                                            setSelectedSubService(e.target.value);
                                                                        }}
                                                                        {...field}
                                                                    >
                                                                        {additionalContentOptions.map((option) => (
                                                                            <option key={option} value={option}>{option}</option>
                                                                        ))}
                                                                    </select>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col-12 budget-div">
                                                    <Controller
                                                        name="budget"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <>
                                                                <select className="form-select contact-form-select"   {...field}>
                                                                    <option value="" selected hidden>What is your monthly budget?*</option>
                                                                    <option value="&lt; $1,000">&lt; $1,000</option>
                                                                    <option value="$2,000 - $5,000">$2,000 - $5,000</option>
                                                                    <option value="$5,000 - $15,000">$5,000 - $15,000</option>
                                                                    <option value="$15,000 - $25,000">$15,000 - $25,000</option>
                                                                    <option value="$25,000 - $50,000">$25,000 - $50,000</option>
                                                                    <option value="&gt; 50,0000"><span className='gt-lt-symbol' style={{ fontSize: '54px' }}>&gt;</span>$50,000</option>
                                                                    <option value="Haven't Decided Yet">Haven't Decided Yet</option>
                                                                </select>
                                                            </>
                                                        }
                                                    />
                                                    {errors.budget && <p className="alert-msg">{errors.budget.message}</p>}

                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating mb-3">
                                                        <Controller
                                                            name="messages"
                                                            control={control}
                                                            render={({ field }) =>
                                                                <textarea className="form-control" onKeyDown={handlerestrictcharacters} placeholder="Write a Message" {...field}></textarea>
                                                            }
                                                        />
                                                        {errors.messages && <p className="alert-msg">{errors.messages.message}</p>}

                                                        <label>Message</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 text-end">
                                                    {!loading && <button className="btn banner-btn-submit-form" type="submit">Submit</button>}
                                                    {loading && <button className="btn banner-btn-submit-form" type="submit" disabled>Loading...<Spinner size='sm' /></button>}
                                                </div>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BookFrom
import React from 'react'

const TopFaq = ({ topFaqData }) => {
    return (
        <div className='faq-section top-faq-section'>
            <div className="accordion" id="accordionExample">
                {topFaqData.map((item, index) => (
                    <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                <div style={{ width: '90%' }}>
                                    {item.question}
                                </div>
                            </button>
                        </h2>
                        <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className={`accordion-body ${item.cname}`}>
                                {item.answer}

                                {item.title1 && item.answer1 && (
                                    <h5 className='h-text-10 mt-3'>
                                        {item.title1} <br /> <span className={`s-text-1 ${item.sclass}`}>{item.answer1}</span>
                                    </h5>
                                )}
                                {item.title2 && item.answer2 && (
                                    <h5 className='h-text-10'>
                                        {item.title2} <br /> <span className={`s-text-1 ${item.sclass}`}>{item.answer2}</span>
                                    </h5>
                                )}
                                {item.title3 && item.answer3 && (
                                    <h5 className='h-text-10'>
                                        {item.title3} <br /> <span className={`s-text-1 ${item.sclass}`}>{item.answer3}</span>
                                    </h5>
                                )}
                                {item.title4 && item.answer4 && (
                                    <h5 className='h-text-10'>
                                        {item.title4} <br /> <span className={`s-text-1 ${item.sclass}`}>{item.answer4}</span>
                                    </h5>
                                )}
                                {item.title5 && item.answer5 && (
                                    <h5 className='h-text-10'>
                                        {item.title5} <br /> <span className={`s-text-1 ${item.sclass}`}>{item.answer5}</span>
                                    </h5>
                                )}
                                {item.title6 && item.answer6 && (
                                    <h5 className='h-text-10'>
                                        {item.title6} <br /> <span className={`s-text-1 ${item.sclass}`}>{item.answer6}</span>
                                    </h5>
                                )}
                                {item.title7 && item.answer7 && (
                                    <h5 className='h-text-10'>
                                        {item.title7} <br /> <span className={`s-text-1 ${item.sclass}`}>{item.answer7}</span>
                                    </h5>
                                )}
                                {item.answer && (
                                    <h5 className='h-text-10'>
                                        {item.answerh}
                                    </h5>
                                )}
                                {item.title8 && item.answer8 && (
                                    <h5 className='h-text-10 mt-3'>
                                        {item.title8} <br /> <span className='s-text-1'>{item.answer8}</span>
                                    </h5>
                                )}
                                {item.title9 && item.answer9 && (
                                    <h5 className='h-text-10'>
                                        {item.title9} <br /> <span className='s-text-1'>{item.answer9}</span>
                                    </h5>
                                )}
                                {item.title10 && item.answer10 && (
                                    <h5 className='h-text-10'>
                                        {item.title10} <br /> <span className='s-text-1'>{item.answer10}</span>
                                    </h5>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TopFaq
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FaSkype, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const Section1 = () => {
    return (
        <div className='crypto-sec-1'>
            <div className='blockchain-sec2'>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h2>XDMinds: Innovative Crypto Marketing Solutions for <span className='metaverse-sec2-break'><br /></span>Forward-Thinking Cryptocurrency Projects
                                </h2>
                                <p>XDMinds is at the forefront of cryptocurrency marketing, delivering tailored solutions that empower projects to thrive. Our innovative approach combines deep industry knowledge with creative strategies to propel your cryptocurrency to new heights. Whether you're launching a new token or expanding an existing platform, XDMinds provides the expertise and tools needed to succeed in the rapidly evolving crypto space. </p>
                            </div>
                            <div className='btn-mchallenge'>
                                <a href='https://api.whatsapp.com/send?phone=917708215321&text=Hi, XDMinds! I’m interested in your Crypto marketing services. Shall we start the discussion? Lead from https://www.xdminds.com/crypto-marketing-agency' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                                    <Button className='challenge-tele'><FaWhatsapp className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN WHATSAPP</span></Button>
                                </a>
                                <a href='https://t.me/xd_minds' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-tele'><FaTelegramPlane className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN TELEGRAM </span></Button>
                                </a>
                                <a href='skype:live:.cid.7361579f2e5c83ea?chat' style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                                    <Button className='challenge-skype-btn'><FaSkype className='challenge-tele-icons' /><span className='challenge-tele-span'>WRITE TO US IN SKYPE</span></Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Section1
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { GetPageApi } from '../Helper/Redux/ReduxThunk/Homepage';

const BreadCrumbs = ({ childPage, childPageLink, childPageName }) => {
    const [breadCrumb, setBreadCrumb] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        getPageBreadcrumb();
    }, []);

    const getPageBreadcrumb = async () => {
        const data = {
            page_url: location.pathname.replace('/', ''),
        };
        await dispatch(GetPageApi(data, (resp) => {
            if (resp.status === true) {
                setBreadCrumb(resp.data);
            }
        }));
    };


    return (
        <div className='breadcrumbs'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item px-0">
                        <Link reloadDocument to='/' className='text-link bc-link'>Home</Link>
                    </li>

                    {breadCrumb && breadCrumb?.breadcrumbs_title && <li className="breadcrumb-item active px-0" aria-current="page">
                        {breadCrumb.breadcrumbs_title}
                    </li>}

                    {childPageLink &&
                        <li className="breadcrumb-item px-0">
                            <Link reloadDocument to={`/${childPageLink}`} className='text-link'>{childPageName}</Link>
                        </li>
                    }

                    {childPage && <li className="breadcrumb-item active px-0" aria-current="page">
                        {childPage}
                    </li>}

                </ol>
            </nav>
        </div>
    )
}

export default BreadCrumbs